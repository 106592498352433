export default {
  props: {
    taxonomyOne: {
      type: String,
      default: null,
    },
    taxonomyTwo: {
      type: String,
      default: null,
    },
    taxonomyThree: {
      type: String,
      default: null,
    },
    taxonomyFour: {
      type: String,
      default: null,
    },
  },
  computed: {
    taxonomyLocation() {
      return [
        this.taxonomyOne,
        this.taxonomyTwo,
        this.taxonomyThree,
        this.taxonomyFour,
      ].filter(t => t !== null);
    },
    taxonomyColumns() {
      return [
        "taxonomy_1",
        ...(this.taxonomyOne !== null ? ["taxonomy_2"] : []),
        ...(this.taxonomyTwo !== null ? ["taxonomy_3"] : []),
        ...(this.taxonomyThree !== null ? ["taxonomy_4"] : []),
      ];
    },
    taxonomyUrls() {
      return Array(this.taxonomyLocation.length)
        .fill("")
        .map((_, i) =>
          this.buildTaxonomyUrl(this.taxonomyLocation.slice(0, i + 1))
        );
    },
  },
  methods: {
    buildTaxonomyUrl(location) {
      const prefixes = [
        "taxonomy_one",
        "taxonomy_two",
        "taxonomy_three",
        "taxonomy_four",
      ];

      return location.map((t, i) => prefixes[i] + "/" + t).join("/");
    },
    buildTaxonomyLocationObject(location) {
      const prefixes = [
        "taxonomy_1",
        "taxonomy_2",
        "taxonomy_3",
        "taxonomy_4",
      ];

      const target = {};

      location.forEach((location, i) => target[prefixes[i]] = location);

      return target;
    },
  },
};
