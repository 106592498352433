<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use, registerTransform } from "echarts/core";
import { transform } from "echarts-stat";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart, LineChart } from "echarts/charts";
import {
  TooltipComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";

registerTransform(transform.regression);

use([
  CanvasRenderer,
  ScatterChart,
  LineChart,
  TooltipComponent,
  TransformComponent,
  DatasetComponent,
]);

export default {
  setup() {
    const { displayMonetaryValue, displayMonetaryValueRounded } = UseNumberHelper();

    return {
      displayMonetaryValue,
      displayMonetaryValueRounded,
    };
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nameGap: 60,
    };
  },
  computed: {
    option() {
      return {
        color: ["#005CAE"],
        grid: {
          containLabel: true,
          left: 25,
          top: 20,
          right: 30,
          bottom: 30,
        },
        dataset: [
          {
            source: this.data.map(
              ({ conversion_date, total_cost, attributed_revenue }) => [
                +total_cost,
                +attributed_revenue,
                conversion_date,
              ]
            ),
          },
          {
            transform: [
              {
                type: "ecStat:regression",
                config: {
                  method: "logarithmic",
                  // 'end' by default
                  // formulaOn: 'start'
                },
              },
              {
                type: "filter",
                config: {
                  dimension: 1,
                  gte: 0,
                },
              },
            ],
          },
        ],
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "none",
          },
        },
        xAxis: {
          name: "Spend",
          nameLocation: "middle",
          nameGap: 50,
          nameTextStyle: {
            color: "#5d5d5d",
          },
          axisLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisLabel: {
            color: "#4a4a4a",
            fontSize: 16,
            margin: 20,
            formatter: (value) => this.displayMonetaryValueRounded(value),
          },
        },
        yAxis: {
          name: "Attributed Revenue",
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: this.nameGap,
          nameTextStyle: {
            color: "#5d5d5d",
          },
          splitLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisLabel: {
            color: "#4a4a4a",
            fontSize: 16,
            margin: 20,
            formatter: (value) => this.displayMonetaryValueRounded(value),
          },
        },
        series: [
          {
            name: "Weeks",
            type: "scatter",
            symbolSize: 6,
            datasetIndex: 0,
            tooltip: {
              formatter: (params) => {
                return (
                  `<div>W/C ${params.value[2]}</div>` +
                  `<dl class="grid tooltip-values">
                    <dt>Spend</dt>
                    <dd>${this.displayMonetaryValue(params.value[0])}</dd>
                    <dt>Revenue</dt>
                    <dd>${this.displayMonetaryValue(params.value[1])}</dd>
                  </dl>`
                );
              },
              valueFormatter: (v) => "£" + v.toFixed(2),
            },
          },
          ...(this.data.length >= 10
            ? [
                {
                  name: "trendline",
                  type: "line",
                  smooth: true,
                  selectedMode: true,
                  datasetIndex: 1,
                  symbolSize: 0.1,
                  symbol: "circle",
                  encode: { label: 2, tooltip: 1 },
                  tooltip: {
                    show: false,
                  },
                  lineStyle: {
                    width: 15,
                    color: "#00000060",
                  },
                },
              ]
            : []),
        ],
      };
    },
  },
  methods: {
    nameGapHandler() {
      if (this.option && this.option.dataset[0]?.source) {
        const datasetSourceArray = this.option.dataset[0].source;
        const yAxisData = datasetSourceArray.map((dta) => Math.ceil(dta[1]));
        this.nameGap = isFinite(Math.max(...yAxisData))
          ? this.nameGapLengthHandler(Math.max(...yAxisData).toString().length)
          : 60;
      }
    },
    nameGapLengthHandler(len = 0) {
      switch (len) {
        case 1:
          return 45;
        case 3:
          return 60;
        case 4:
          return 70;
        case 5:
          return 85;
        case 6:
          return 100;
        case 7:
        case 8:
        case 9:
          return 110;
        default:
          return 60;
      }
    },
  },
  mounted() {
    this.nameGapHandler();
  },
};
</script>

<style scoped>
.chart {
  height: 30em;
}

:deep(.tooltip-values) {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5em 1em;
  margin-top: 0.5em;
}

:deep(.tooltip-values > dd) {
  font-weight: bold;
  text-align: right;
}
</style>
