<template><div></div></template>

<script>
import { UseLogout } from "./Hooks/UseLogout";

const { logout } = UseLogout();
export default {
  beforeMount() {
    logout();
    this.$router.push("/");
  },
};
</script>
