export const StaticColumnData = () => {
  const headers = {
    variable_cost: "Variable Spend",
    fixed_cost: "Fixed Spend",
    total_cost: "Total Spend",
    clicks: "Page Lands",
    impressions: "Impressions",
    first_click_sales: "First Touch Sales",
    first_click_revenue: "First Touch Revenue",
    first_click_conversion_rate: "First Touch Conversion Rate",
    first_click_aov: "First Touch AOV",
    last_click_sales: "Last Touch Sales",
    last_click_revenue: "Last Touch Revenue",
    last_click_conversion_rate: "Last Touch Conversion Rate",
    last_click_aov: "Last Touch AOV",
    attributed_sales: "Attributed Sales",
    attributed_revenue: "Attributed Revenue",
    attributed_conversion_rate: "Attributed Conversion Rate",
    attributed_aov: "Attributed AOV",
    attributed_revenue_variance: "Attributed Revenue Variance",
    attributed_revenue_variance_percent: "Attributed Revenue Variance Percent",
    introducer_ratio: "Introducer Ratio",
    cpa: "Attributed CPA",
    roas: "Attributed ROAS",
    roi: "ROI",
    sales_including: "Sales Involving",
    proportional_credit: "Attributed Sale Credit",
  } as const;

  const headersMetaTabularToConvert = [
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "attributed_revenue_variance",
    "attributed_revenue_variance_percent",
    "introducer_ratio",
    "cpa",
    "roas",
    "roi",
  ];
  const availableMetrics = [
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "cpa",
    "roas",
    "roi",
  ] as const;
  const availableColumns = [
    "variable_cost",
    "fixed_cost",
    "total_cost",
    "clicks",
    "impressions",
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "attributed_revenue_variance",
    "attributed_revenue_variance_percent",
    "introducer_ratio",
    "cpa",
    "roas",
    "roi",
    "sales_including",
    "proportional_credit",
  ] as const;

  const funnelColumns = [
    "solo_revenue",
    "solo_revenue_percent",
    "solo_credit",
    "solo_funnel_credit_percent",
    "upper_funnel_revenue",
    "upper_funnel_revenue_percent",
    "upper_funnel_credit",
    "mid_funnel_revenue",
    "mid_funnel_revenue_percent",
    "mid_funnel_credit",
    "lower_funnel_revenue",
    "lower_funnel_revenue_percent",
    "lower_funnel_credit",
    "total_revenue",
    "total_sales",
  ] as const;

  const funnelAvailableColumns = [
    "solo_revenue",
    "solo_revenue_percent",
    "solo_credit",
    "solo_funnel_credit_percent",
    "upper_funnel_revenue",
    "upper_funnel_revenue_percent",
    "upper_funnel_credit",
    "upper_funnel_credit_percent",
    "mid_funnel_revenue",
    "mid_funnel_revenue_percent",
    "mid_funnel_credit",
    "mid_funnel_credit_percent",
    "lower_funnel_revenue",
    "lower_funnel_revenue_percent",
    "lower_funnel_credit",
    "lower_funnel_credit_percent",
    "total_revenue",
    "total_sales",
  ] as const;

  const funnelTrendColumns = [
    "solo_revenue_percent",
    "upper_funnel_revenue_percent",
    "mid_funnel_revenue_percent",
    "lower_funnel_revenue_percent",
  ] as const;

  const awinReportColumns = [
    "publisher_id",
    "taxonomy_1",
    "taxonomy_2",
    "taxonomy_3",
    "taxonomy_4",
    "total_cost",
    "clicks",
    "impressions",
    "attributed_sales",
    "attributed_revenue",
    "attributed_revenue_variance_percent",
    "cpa",
    "roas",
    "roi",
  ] as const;

  return {
    headers,
    availableMetrics,
    availableColumns,
    headersMetaTabularToConvert,
    funnelColumns,
    funnelAvailableColumns,
    funnelTrendColumns,
    awinReportColumns,
  };
};
