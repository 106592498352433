<template>
  <div class="columns taxonomy-rule-row">
    <div class="column is-5-desktop">
      <o-input v-model="field" @input="$emit('update:field', $event.target.value)" />
    </div>
    <div class="column is-3-desktop">
      <o-select
        v-model="matchType"
        @input="$emit('update:matchType', $event.target.value)"
        rounded
      >
        <option value="contains">Contains</option>
        <option value="not contains">Does not contain</option>
        <option value="exists">Exists</option>
        <option value="not exists">Does not exist</option>
        <option value="empty">Empty</option>
        <option value="not empty">Is not empty</option>
        <option value="event_type is">Event Type Is</option>
      </o-select>
    </div>
    <div class="column is-3-desktop">
      <o-input
        v-if="showExpectedValue"
        v-model="expectedValue"
        @input="$emit('update:expectedValue', $event.target.value)"
      />
    </div>
    <div class="column is-1-desktop">
      <icon-button icon="delete" label="Delete Rule" @click="$emit('delete')" />
    </div>
  </div>
</template>

<script>
import IconButton from "../../General/IconButton.vue";
export default {
  components: { IconButton },
  computed: {
    showExpectedValue() {
      return ["contains", "not contains", "event_type is"].indexOf(this.matchType) !== -1;
    },
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    expectedValue: {
      type: String,
      required: false,
      default: null,
    },
    matchType: {
      type: String,
      required: true,
      validator(matchType) {
        return (
          [
            "contains",
            "not contains",
            "exists",
            "not exists",
            "empty",
            "not empty",
            "event_type is",
          ].indexOf(matchType) !== -1
        );
      },
    },
  },
  watch: {
    showExpectedValue(value) {
      if (!value) {
        this.$emit("update:expectedValue", null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.taxonomy-rule-row {
  align-items: center;

  &:nth-of-type(odd) {
    background: #e9eff4;
  }
}
</style>
