<template>
  <o-upload
    class="column is-full"
    v-model="dropFile"
    drag-drop
    expanded
    native
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  >
    <section class="hero">
      <div class="hero-body has-text-centered">
        <div class="container">
          <o-icon icon="upload" size="is-large"></o-icon>
          <h2 class="subtitle" v-if="dropFile === null">
            Drop your files here or click to upload
          </h2>
          <h2 class="subtitle" v-if="dropFile !== null">{{ dropFile.name }}</h2>
        </div>
      </div>
    </section>
  </o-upload>
</template>

<script>
import xlsx from "xlsx";

export default {
  name: "ExcelAndCsvFileUpload",
  components: {},
  computed: {
    processedFile: {
      get() {
        return this.file;
      },
      set(val) {
        this.$emit("update:file", val);
      },
    },
  },
  props: {
    file: {
      default: {},
    },
  },
  data() {
    return {
      dropFile: this.file,
    };
  },
  methods: {
    fileDropped() {
      if (this.dropFile === null) {
        swal.fire({
          toast: true,
          type: "error",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "That file format isn't supported",
        });
      }
    },
  },
  watch: {
    dropFile(newVal) {
      if (newVal !== null) {
        let reader = new FileReader();

        // Working ARRAY
        reader.onload = function (e) {
          let data = new Uint8Array(e.target.result);
          let workbook = xlsx.read(data, {
            // raw: false,
            type: "array",
            // dateNF: 'dd/mm/yyyy',
            // textNF: true,
            // cellText: false,
            cellDates: true,
            cellStyles: true,
          });

          this.processedFile = workbook;
        }.bind(this);
        reader.readAsArrayBuffer(newVal);
      } else {
        this.processedFile = null;
      }
    },
  },
};
</script>

<style>
.upload-draggable {
  width: 100%;
}
</style>
