<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li :class="{ 'is-active': taxonomyList.length === 0 }">
        <router-link to="/funnel"> All Channels </router-link>
      </li>
      <li
        v-for="(taxonomyName, index) in taxonomyList"
        :key="index"
        :class="{ 'is-active': index >= taxonomyList.length - 1 }"
      >
        <router-link :to="linkTo(index)">
          {{ taxonomyName }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    taxonomyOne: "",
    taxonomyTwo: "",
    taxonomyThree: "",
  },
  computed: {
    taxonomyList() {
      return [this.taxonomyOne, this.taxonomyTwo, this.taxonomyThree].filter(
        t => t !== null && typeof t !== "undefined"
      );
    },
  },
  methods: {
    linkTo(index) {
      const names = ["taxonomy_one/", "taxonomy_two/", "taxonomy_three/"];

      return (
        "/funnel/" +
        this.taxonomyList
          .slice(0, index + 1)
          .map((t, i) => names[i] + this.validateInternalParameter(t))
          .join("/")
      );
    },
  },
};
</script>
