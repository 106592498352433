import { Ref, watchEffect } from "vue";

const styleHelper = (el: Ref<HTMLElement | null>) => {
  if (el.value) {
    el.value.style.height = "100%";
    el.value.style.width = "100%";
    el.value["canvas"].style.width = "100%";
  }
};
export const UseCanvasGridHelper = (el: Ref<HTMLElement | null>) => {
  watchEffect(() => {
    styleHelper(el);
  });
  window.addEventListener("resize", () => {
    styleHelper(el);
  });
};
