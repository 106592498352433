import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedLoaded,
} from "vue-router";
import { assign } from "lodash";

import CreateVue from "./components/Modules/Admin/FixedCosts/Create.vue";
import EditVue from "./components/Modules/Admin/FixedCosts/Edit.vue";
import CustomReport2AwinAffiliateReportVue from "./components/Modules/ProgramReports/Reports/CustomReport2AwinAffiliateReport.vue";
import FixedBatchCostsVue from "./components/Pages/Account/BatchCosts/Fixed/FixedBatchCosts.vue";
import VariableBatchCostsVue from "./components/Pages/Account/BatchCosts/Variable/VariableBatchCosts.vue";
import AccountConnectionsIndex from "./components/Pages/Account/Connections/Index.vue";
import AccountConnectionsShowVue from "./components/Pages/Account/Connections/Show.vue";
import ClassificationVue from "./components/Pages/Admin/Classification.vue";
import AttributionComparison from "./components/Pages/AttributionComparison/index.vue";
import LoginVue from "./components/Pages/Auth/Login.vue";
import LogoutVue from "./components/Pages/Auth/Logout.vue";
import WelcomeVue from "./components/Welcome.vue";
import Breakdown from "./components/Pages/Breakdown/index.vue";
import Changelog from "./components/Pages/Changelog/Changelog.vue";
import CustomProgramReport from "./components/Pages/ProgramReports/CustomProgramReport.vue";
import Empty from "./components/Pages/ProgramReports/Empty.vue";

import Crossover from "./components/Pages/Crossover/Index.vue";
import CrossoverReport from "./components/Pages/Crossover/Report/Index.vue";

import FixedCostsIndexVue from "./components/Pages/Admin/FixedCosts/Index.vue";
import VariableCostsIndex from "./components/Pages/Account/BatchCosts/Variable/Index.vue";
import Funnel from "./components/Pages/Funnel.vue";
import JourneyPaths from "./components/Pages/JourneyPaths/JourneyPaths.vue";
import NotFoundComponent from "./components/Pages/404.vue";
import PerformanceSearch from "./components/Pages/Performance/Search.vue";
import Performance from "./components/Pages/Performance/Index.vue";
import ReportsIndex from "./components/Pages/Report/Index.vue";
import ReportsPreview from "./components/Pages/Report/Preview.vue";
import ReportsView from "./components/Pages/Report/View.vue";
import InitiatorRating from "./components/Pages/InitiatorRating.vue";
import FunnelTrends from "./components/Pages/FunnelTrendsReport.vue";
import DiminishingReturns from "./components/Pages/DiminishingReturnsReport.vue";
import SeasonalityConfigurationIndex from "./components/Pages/SeasonalityConfiguration/Index.vue";
import ForgottenPassword from "./components/Pages/Auth/ForgottenPassword.vue";
import Token from "./components/Pages/Auth/NewPassword/Token.vue";
import SegmentManagement from "./components/Pages/SegmentManagement/Index.vue";
import Segment from "./components/Pages/SegmentManagement/Segments/Segment.vue";
import SegmentUpload from "./components/Pages/SegmentManagement/Uploads/Upload.vue";
import SegmentComparison from "./components/Pages/SegmentComparison/Index.vue";

const routes = [
  {
    path: "/",
    component: WelcomeVue,
  },
  { path: "/login", component: LoginVue },
  { path: "/logout", component: LogoutVue },
  { path: "/recover-password", component: ForgottenPassword },
  {
    path: "/reset-password/:token",
    name: "CreateNewPassword",
    component: Token,
  },

  { path: "/account/costs/fixed/batch", component: FixedBatchCostsVue },
  { path: "/account/costs/fixed", component: FixedCostsIndexVue },
  { path: "/account/costs/fixed/create", component: CreateVue },
  {
    path: "/account/costs/fixed/:spend",
    component: EditVue,
    props: (route) => ({ spend: route.params.spend }),
  },
  { path: "/account/costs/variable/batch", component: VariableBatchCostsVue },
  { path: "/account/costs/variable", component: VariableCostsIndex },

  { path: "/account/connections", component: AccountConnectionsIndex },
  {
    path: "/account/connections/:connection",
    component: AccountConnectionsShowVue,
    props: (route: { params: { connection: any } }) => ({
      connection: route.params.connection,
    }),
  },

  { path: "/admin/classification", component: ClassificationVue },

  {
    path: "/affiliate-performance",
    component: CustomReport2AwinAffiliateReportVue,
  },

  {
    path: "/attribution-comparison",
    component: AttributionComparison,
    props: true,
    children: [
      {
        path: "taxonomy_one/:taxonomyOne",
        component: AttributionComparison,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
        component: AttributionComparison,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
        component: AttributionComparison,
        props: true,
      },
    ],
  },

  {
    path: "/breakdown",
    component: Breakdown,
    props: true,
    children: [
      {
        path: "taxonomy_one/:taxonomyOne",
        component: Breakdown,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
        component: Breakdown,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
        component: Breakdown,
        props: true,
      },
    ],
  },

  { path: "/initiator-rating", component: InitiatorRating },
  {
    path: "/initiator-rating/taxonomy_one/:taxonomyOne",
    component: InitiatorRating,
    props: true,
  },
  {
    path: "/initiator-rating/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
    component: InitiatorRating,
    props: true,
  },
  {
    path: "/initiator-rating/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
    component: InitiatorRating,
    props: true,
  },

  { path: "/funnel", component: Funnel },
  { path: "/funnel/taxonomy_one/:taxonomyOne", component: Funnel, props: true },
  {
    path: "/funnel/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
    component: Funnel,
    props: true,
  },
  {
    path: "/funnel/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
    component: Funnel,
    props: true,
  },

  { path: "/funnel-trends", component: FunnelTrends },
  {
    path: "/funnel-trends/:selectedTaxonomy",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne/:selectedTaxonomy",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/:selectedTaxonomy",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
    component: FunnelTrends,
    props: true,
  },
  {
    path: "/funnel-trends/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree/:selectedTaxonomy",
    component: FunnelTrends,
    props: true,
  },

  { path: "/diminishing-returns", component: DiminishingReturns },
  {
    path: "/diminishing-returns/:selectedTaxonomy",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne/:selectedTaxonomy",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/:selectedTaxonomy",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
    component: DiminishingReturns,
    props: true,
  },
  {
    path: "/diminishing-returns/taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree/:selectedTaxonomy",
    component: DiminishingReturns,
    props: true,
  },

  { path: "/journeys", component: JourneyPaths },

  {
    path: "/performance",
    props: true,
    component: Performance,
    children: [
      {
        path: "taxonomy_one/:taxonomyOne",
        component: Performance,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
        component: Performance,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
        component: Performance,
        props: true,
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree/taxonomy_four/:taxonomyFour",
        component: Performance,
        props: true,
      },
    ],
  },
  {
    path: "/performance/search/:search",
    component: PerformanceSearch,
    props: true,
  },

  {
    path: "/seasonality-configuration",
    name: "SeasonalityConfigurationIndex",
    component: SeasonalityConfigurationIndex,
    props: true,
  },

  { path: "/reports", component: ReportsIndex },
  {
    path: "/reports/preview",
    component: ReportsPreview,
    props: (route: RouteLocationNormalizedLoaded) => ({
      name: route.query.name,
      level: route.query.level,
      rules: route.query.rules,
    }),
  },
  {
    path: "/reports/view/:report",
    component: ReportsView,
    props: (route: RouteLocationNormalizedLoaded) => ({
      report: route.params.report,
    }),
  },
  {
    path: "/custom-reports/",
    component: Empty,
    props: true,
    children: [
      // New / Returning
      {
        path: "1",
        props: true,
        component: Empty,
        children: [
          {
            path: "",
            component: CustomProgramReport,
            props: (route: RouteLocationNormalizedLoaded) =>
              assign({ report: 1 }, route.params),
          },
          {
            path: "taxonomy_one/:taxonomyOne",
            component: CustomProgramReport,
            props: (route: RouteLocationNormalizedLoaded) =>
              assign({ report: 1 }, route.params),
          },
          {
            path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
            component: CustomProgramReport,
            props: (route: RouteLocationNormalizedLoaded) =>
              assign({ report: 1 }, route.params),
          },
          {
            path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
            component: CustomProgramReport,
            props: (route: RouteLocationNormalizedLoaded) =>
              assign({ report: 1 }, route.params),
          },
        ],
      },
    ],
  },
  { path: "/changelog", component: Changelog },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFoundComponent },
  { path: "/segment-groups", component: SegmentManagement, props: true },
  {
    path: "/segment-groups/upload/:group",
    component: SegmentUpload,
    props: true,
  },
  {
    path: "/segment-groups/:segment",
    name: "Segment",
    component: Segment,
    props: true,
  },

  {
    path: "/crossover-analysis",
    name: "CrossoverAnalysis",
    component: Crossover,
  },
  {
    path: "/crossover-analysis/report/:id",
    name: "CrossoverReport",
    component: CrossoverReport,
  },

  {
    path: "/segment-comparison",
    name: "SegmentComparison",
    component: Empty,
    children: [
      {
        path: "",
        component: SegmentComparison,
        meta: {
          report: 1,
        },
      },
      {
        path: "taxonomy_one/:taxonomyOne",
        component: SegmentComparison,
        meta: {
          report: 1,
        },
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo",
        component: SegmentComparison,
        meta: {
          report: 1,
        },
      },
      {
        path: "taxonomy_one/:taxonomyOne/taxonomy_two/:taxonomyTwo/taxonomy_three/:taxonomyThree",
        component: SegmentComparison,
        meta: {
          report: 1,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
});

export default router;
