<template>
  <div>
    <template v-if="getToken">
      <singleview-layout :has-sidebar="hasSidebar">
        <template #navigation="nav">
          <navigation :close="nav.close" :open="nav.open" />
        </template>

        <template #pageActions>
          <div id="header-actions">
            <program-date-selector v-if="!getShowSidebar" />
          </div>
        </template>

        <template #sidebar>
          <div id="sidebar" ref="sidebar"></div>
        </template>

        <section class="columns">
          <div class="column is-full">
            <router-view :key="$route.path" v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>

            <singleview-footer />
          </div>
        </section>
      </singleview-layout>
    </template>

    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import SingleviewLayout from "./Layout/SingleviewLayout.vue";
import Navigation from "./Layout/Navigation.vue";
import ProgramDateSelector from "./Modules/State/ProgramDateSelector.vue";
import SingleviewFooter from "./Layout/SingleviewFooter.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "./Modules/State/SingleViewStorePinia";
import { UseClassificationStore } from "./Modules/State/Stores/ClassificationStore";

export default {
  components: {
    SingleviewLayout,
    Navigation,
    ProgramDateSelector,
    SingleviewFooter,
  },

  setup() {
    const { getToken, getUserMessages } = storeToRefs(UseMainStore());
    const { getShowSidebar } = storeToRefs(UseClassificationStore());
    return {
      getToken,
      getUserMessages,
      getShowSidebar,
    };
  },
  data() {
    return {
      hasSidebar: false,
    };
  },
};
</script>

<style lang="scss">
@import "../../sass/app.scss";
</style>
