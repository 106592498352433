<template>
  <o-field>
    <p class="control">
      <span class="button is-static">Additional Information</span>
    </p>
    <p class="control">
      <o-select v-model="additionalInformationCompute">
        <option value="none">None</option>
        <option value="proportionalCredit">Proportional Credit</option>
        <option value="attributedRevenue">Attributed Revenue</option>
      </o-select>
    </p>
  </o-field>
</template>

<script>
export default {
  computed: {
    additionalInformationCompute: {
      get() {
        return this.additionalInformation;
      },
      set(val) {
        return this.$emit("update:additionalInformation", val);
      },
    },
  },
  props: {
    additionalInformation: {
      type: String,
    },
  },
};
</script>
