<template>
  <o-field label="Name">
    <o-input
      lazy
      :value="value"
      @input="handleInput"
      placeholder="Type new name here..."
    ></o-input>
  </o-field>
</template>

<script>
export default {
  name: "UpdateGroupInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    updatedGroupName() {
      return this.value;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("update:value", e.target.value);
    },
  },
};
</script>
