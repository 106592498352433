export const UseArrayHelper = () => {
  const checkSingleObject = <T>(obj: T) => {
    if (!obj || !Object.keys(obj).length) return false;
    return true;
  };

  const checkMultipleArrayLengths = <T, U>(arr: [a: T[], b: U[]]) => {
    const checkArray: (T[] | U[])[] = [];
    arr.forEach((list) => (list.length ? checkArray.push(list) : null));

    return checkArray;
  };

  const objectKeysArray = <T>(obj: T): (keyof T)[] => {
    return Object.keys(obj) as (keyof T)[];
  };

  const createArrayFromSingleNumber = (int: number) => {
    if (isNaN(int)) {
      int = 0;
    }
    return Array.from(Array(int).keys());
  };

  /**
   *
   * @param arrOne Array of objects
   * @param arrTwo Array of strings
   * @param key key name from arrOne
   * @param index number to index arrTwo with.
   * @returns Object from arrOne or undefined
   */
  const findItemUsingIndex = <T, U extends keyof T, V extends T[U]>(
    arrOne: T[],
    arrTwo: V[],
    key: string,
    index: number
  ) => {
    return arrOne.find((a) => a[key] === arrTwo[index]);
  };

  const itemInArrayFrequencyCounter = (arr: string[]) => {
    if (!arr.length) return {};
    let countObj: Record<string, number> = {};
    arr.forEach((val) => {
      if (!countObj[val]) {
        countObj[val] = 1;
      } else {
        countObj[val]++;
      }
    });
    return countObj;
  };

  const removeFirstItemFromArray = <T>(arr: T[]) => {
    const tempArray: T[] = [];

    arr.forEach((item, i) => {
      if (i !== 0) {
        tempArray.push(item);
      }
    });
    return tempArray;
  };

  const removeItemFromArrayUsingIndex = <T>(arr: T[], index: number) => {
    return arr.filter((_, i) => i !== index);
  };

  return {
    checkMultipleArrayLengths,
    checkSingleObject,
    objectKeysArray,
    findItemUsingIndex,
    itemInArrayFrequencyCounter,
    createArrayFromSingleNumber,
    removeFirstItemFromArray,
    removeItemFromArrayUsingIndex,
  };
};
