<template>
  <o-field>
    <div class="tw-flex">
      <!-- Per page control -->
      <o-dropdown aria-role="menu" trap-focus v-model="perPageCompute">
        <template #trigger>
          <SvButton endIcon="dropDown" variant="subdued">
            <template v-if="records > 0">
              {{ currentRecords.from }}&ndash;{{ currentRecords.to }} of
              {{ records }}
            </template>
            <template v-else> No records </template>
          </SvButton>
        </template>
        <template v-for="page in perPageOptions">
          <o-dropdown-item aria-role="menuitem" :value="page">
            {{ page }} per page
          </o-dropdown-item>
        </template>
      </o-dropdown>

      <div class="tw-flex">
        <SvButton
          startIcon="firstPage"
          hideLabel
          :disabled="page === 1"
          variant="subdued"
          @click="setFirstPage"
        >
          First
        </SvButton>
        <SvButton
          startIcon="chevronLeft"
          hideLabel
          variant="subdued"
          @click="setPreviousPage"
        >
          Previous
        </SvButton>
        <span class="tw-self-center tw-mx-3">{{ page }} of {{ maxPage }}</span>
        <SvButton
          startIcon="chevronRight"
          hideLabel
          variant="subdued"
          @click="setNextPage"
        >
          Next
        </SvButton>
        <SvButton
          startIcon="lastPage"
          hideLabel
          variant="subdued"
          @click="setLastPage"
        >
          Last
        </SvButton>
      </div>
    </div>
  </o-field>
</template>

<script>
export default {
  computed: {
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    maxPage() {
      return Math.ceil(Math.max(this.records, 1) / Math.max(this.perPage, 1));
    },
    currentRecords() {
      return {
        from: (this.page - 1) * this.perPage + 1,
        to: Math.min(this.page * this.perPage, this.records),
      };
    },
  },
  props: {
    records: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 15, 30],
    },
  },
  methods: {
    setFirstPage() {
      this.pageCompute = 1;
    },
    setPreviousPage() {
      this.pageCompute = Math.max(this.page - 1, 1);
    },
    setNextPage() {
      this.pageCompute = Math.min(this.page + 1, this.maxPage);
    },
    setLastPage() {
      this.pageCompute = this.maxPage;
    },
    fixMeUp() {
      if (this.page > this.maxPage) {
        this.pageCompute = this.maxPage;
      }
    },
  },
  watch: {
    perPage() {
      this.fixMeUp();
    },
    records() {
      this.fixMeUp();
    },
  },
};
</script>
