import { UsePerformanceTable } from "./UsePerformanceTable";
import { UseReportSettingsStore } from "../../../State/Stores/ReportSettingsStore";

import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

export const UsePerformanceTableControls = () => {
  //Get state from other hooks
  const { columns } = UsePerformanceTable();

  //Load in Store Getters

  const { getPerformanceTableColumns } = storeToRefs(UseReportSettingsStore());

  //Load in Store Actions
  const { setPerformanceTableColumns } = UseReportSettingsStore();

  //Set up data

  const isColumnModalActive = ref(false);

  //Set up computed
  const columnsCompute = computed({
    get(): string[] {
      return getPerformanceTableColumns.value;
    },
    set(val: string[]) {
      setPerformanceTableColumns(val);
    },
  });

  //Methods baby

  const setDefaultColumns = () => {
    columnsCompute.value = [
      "total_cost",
      "clicks",
      "last_click_sales",
      "last_click_revenue",
      "attributed_sales",
      "attributed_revenue",
      "cpa",
      "roas",
      "attributed_revenue_variance_percent",
    ];
  };

  return {
    columnsCompute,
    isColumnModalActive,
    columns,
    setDefaultColumns,
  };
};
