<template>
  <outer-section>
    <o-loading :full-page="false" :active="loading"></o-loading>
    <variable-costs-index v-model:loading="loading"></variable-costs-index>
  </outer-section>
</template>

<script>
import VariableCostsIndex from "../../../../Modules/Account/BatchCosts/Variable/Index";
export default {
  title: "Variable Spend",
  components: { VariableCostsIndex },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
