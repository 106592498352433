export const UseBadgeHelper = () => {
  /**
   * 
   * @param {string} name Name of taxonomy 1
   * @returns Colour for badge
   */
  const getTagType = (name: string) => {
    if (name.startsWith("Google Ad")) {
      return "blue";
    }
    if (name.startsWith("Affiliate")) {
      return "orange";
    }
    if (name.startsWith("Email")) {
      return "red";
    }
    if (name.startsWith("Bing")) {
      return "dark";
    }
    if (name.startsWith("SEO")) {
      return "blue";
    }
    if (name.startsWith("Direct") || name.startsWith("Referral")) {
      return "orange";
    }
    if (name.startsWith("Paid Social") || name.startsWith("Organic Social")) {
      return "red";
    }
    if (name.startsWith("Display")) {
      return "dark";
    }
  
    return "gray";
  };

  return {
    getTagType
  }
}