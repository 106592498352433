<template>
  <div>
    <div class="tw-mb-4 tw-flex tw-justify-end">
      <table-columns
        :columns="columns"
        :available-columns="availableColumns"
        :column-headers="headers"
      >
        <template #trigger="{ openModal }">
          <SvButton @click="openModal" startIcon="settings" variant="subdued">
            Table Settings
          </SvButton>
        </template>
      </table-columns>
    </div>

    <o-table :data="data.data" default-sort="conversion_date">
      <o-table-column field="conversion_date" label="Date" sortable v-slot="props">
        {{ props.row.conversion_date }}
      </o-table-column>
      <o-table-column
        field="solo_revenue_percent"
        label="Solo"
        sortable
        numeric
        position="right"
        :visible="columns.includes('solo_revenue_percent')"
        v-slot="props"
      >
        {{ displayNumericValueRounded(props.row.solo_revenue_percent) }}%
      </o-table-column>
      <o-table-column
        field="solo_revenue"
        :label="'Solo (' + getCurrency + ')'"
        sortable
        numeric
        position="right"
        :visible="columns.includes('solo_revenue')"
        v-slot="props"
      >
        {{ displayMonetaryValueRounded(props.row.solo_revenue) }}
      </o-table-column>
      <o-table-column
        field="upper_funnel_revenue_percent"
        label="Upper"
        sortable
        numeric
        position="right"
        :visible="columns.includes('upper_funnel_revenue_percent')"
        v-slot="props"
      >
        {{ displayNumericValueRounded(props.row.upper_funnel_revenue_percent) }}%
      </o-table-column>
      <o-table-column
        field="upper_funnel_revenue"
        :label="'Upper (' + getCurrency + ')'"
        sortable
        numeric
        position="right"
        :visible="columns.includes('upper_funnel_revenue')"
        v-slot="props"
      >
        {{ displayMonetaryValueRounded(props.row.upper_funnel_revenue) }}
      </o-table-column>
      <o-table-column
        field="mid_funnel_revenue_percent"
        label="Mid"
        sortable
        numeric
        position="right"
        :visible="columns.includes('mid_funnel_revenue_percent')"
        v-slot="props"
      >
        {{ displayNumericValueRounded(props.row.mid_funnel_revenue_percent) }}%
      </o-table-column>
      <o-table-column
        field="mid_funnel_revenue"
        :label="'Mid (' + getCurrency + ')'"
        sortable
        numeric
        position="right"
        :visible="columns.includes('mid_funnel_revenue')"
        v-slot="props"
      >
        {{ displayMonetaryValueRounded(props.row.mid_funnel_revenue) }}
      </o-table-column>
      <o-table-column
        field="lower_funnel_revenue_percent"
        label="Lower"
        sortable
        numeric
        position="right"
        :visible="columns.includes('lower_funnel_revenue_percent')"
        v-slot="props"
      >
        {{ displayNumericValueRounded(props.row.lower_funnel_revenue_percent) }}%
      </o-table-column>
      <o-table-column
        field="lower_funnel_revenue"
        :label="'Lower (' + getCurrency + ')'"
        sortable
        numeric
        position="right"
        :visible="columns.includes('lower_funnel_revenue')"
        v-slot="props"
      >
        {{ displayMonetaryValueRounded(props.row.lower_funnel_revenue) }}
      </o-table-column>
    </o-table>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";
import { UseMainStore } from "../State/SingleViewStorePinia";
import TableColumns from "../TableColumns";
import { StaticColumnData } from "../../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../../Helper/Stateful/UseColumnModalHelper";

export default {
  setup() {
    const { displayMonetaryValueRounded, displayNumericValueRounded } = UseNumberHelper();
    const { getCurrency } = storeToRefs(UseMainStore());
    const { funnelAvailableColumns } = StaticColumnData();
    const { columns } = UseColumnModalHelper(funnelAvailableColumns);
    return {
      displayMonetaryValueRounded,
      displayNumericValueRounded,
      getCurrency,
      columns,
    };
  },
  components: { TableColumns },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    availableColumns: [
      "solo_revenue_percent",
      "solo_revenue",
      "upper_funnel_revenue_percent",
      "upper_funnel_revenue",
      "mid_funnel_revenue_percent",
      "mid_funnel_revenue",
      "lower_funnel_revenue_percent",
      "lower_funnel_revenue",
    ],
    headers: {
      solo_revenue_percent: "Solo Revenue Percent",
      solo_revenue: "Solo Revenue",
      upper_funnel_revenue_percent: "Upper Revenue Percent",
      upper_funnel_revenue: "Upper Revenue",
      mid_funnel_revenue_percent: "Mid Revenue Percent",
      mid_funnel_revenue: "Mid Revenue",
      lower_funnel_revenue_percent: "Lower Revenue Percent",
      lower_funnel_revenue: "Lower Revenue",
    },
  }),
};
</script>

<style scoped>
.justify-end {
  justify-content: flex-end;
}
</style>
