import { UseStringHelper } from "./../../../Helper/Functional/UseStringHelper";
import { IGeTAllTableHeaders } from "../../../../typings/DataFetching";
import { shallowRef, ref, Ref } from "vue";

type tProps = {
  topName: Ref<string>;
  bottomName: Ref<string>;
};

const headersMetaTabular = ref<Partial<IGeTAllTableHeaders>>({});
const averagedColumns = ref<string[]>([]);
export const UseMetaStaticData = ({ topName, bottomName }: tProps) => {
  const { convertNameToHandle } = UseStringHelper();

  const headers = shallowRef({
    variable_cost: "Variable Spend",
    fixed_cost: "Fixed Spend",
    total_cost: "Total Spend",
    clicks: "Page Lands",
    impressions: "Impressions",
    first_click_sales: "First Touch Sales",
    first_click_revenue: "First Touch Revenue",
    first_click_conversion_rate: "First Touch Conversion Rate",
    first_click_aov: "First Touch AOV",
    last_click_sales: "Last Touch Sales",
    last_click_revenue: "Last Touch Revenue",
    last_click_conversion_rate: "Last Touch Conversion Rate",
    last_click_aov: "Last Touch AOV",
    attributed_sales: "Attributed Sales",
    attributed_revenue: "Attributed Revenue",
    attributed_conversion_rate: "Attributed Conversion Rate",
    attributed_aov: "Attributed AOV",
    attributed_revenue_variance: "Attributed Revenue Variance",
    attributed_revenue_variance_percent: "Attributed Revenue Variance Percent",
    introducer_ratio: "Introducer Ratio",
    cpa: "Attributed CPA",
    roas: "Attributed ROAS",
    roi: "ROI",
    sales_including: "Sales Involved",
    proportional_credit: "Attributed Sale Credit",
  });
  const headersMetaTabularConverted = shallowRef([
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "attributed_revenue_variance",
    "attributed_revenue_variance_percent",
    "introducer_ratio",
    "cpa",
    "roas",
    "roi",
  ]);

  const availableMetrics = shallowRef([
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "cpa",
    "roas",
    "roi",
  ]);

  headersMetaTabular.value = {
    variable_cost: "Variable Spend",
    fixed_cost: "Fixed Spend",
    total_cost: "Total Spend",
    clicks: "Page Lands",
    impressions: "Impressions",
    ["first_click_sales_" + convertNameToHandle(bottomName.value)]:
      "First Touch Sales " + bottomName.value,
    ["first_click_sales_" + convertNameToHandle(topName.value)]:
      "First Touch Sales " + topName.value,
    ["first_click_revenue_" + convertNameToHandle(bottomName.value)]:
      "First Touch Revenue " + bottomName.value,
    ["first_click_revenue_" + convertNameToHandle(topName.value)]:
      "First Touch Revenue " + topName.value,
    ["first_click_conversion_rate_" + convertNameToHandle(bottomName.value)]:
      "First Touch Conversion Rate " + bottomName.value,
    ["first_click_conversion_rate_" + convertNameToHandle(topName.value)]:
      "First Touch Conversion Rate " + topName.value,
    ["first_click_aov_" + convertNameToHandle(bottomName.value)]:
      "First Touch AOV " + bottomName.value,
    ["first_click_aov_" + convertNameToHandle(topName.value)]:
      "First Touch AOV " + topName.value,
    ["last_click_sales_" + convertNameToHandle(bottomName.value)]:
      "Last Touch Sales " + bottomName.value,
    ["last_click_sales_" + convertNameToHandle(topName.value)]:
      "Last Touch Sales " + topName.value,
    ["last_click_revenue_" + convertNameToHandle(bottomName.value)]:
      "Last Touch Revenue " + bottomName.value,
    ["last_click_revenue_" + convertNameToHandle(topName.value)]:
      "Last Touch Revenue " + topName.value,
    ["last_click_conversion_rate_" + convertNameToHandle(bottomName.value)]:
      "Last Touch Conversion Rate " + bottomName.value,
    ["last_click_conversion_rate_" + convertNameToHandle(topName.value)]:
      "Last Touch Conversion Rate " + topName.value,
    ["last_click_aov_" + convertNameToHandle(bottomName.value)]:
      "Last Touch AOV " + bottomName.value,
    ["last_click_aov_" + convertNameToHandle(topName.value)]:
      "Last Touch AOV " + topName.value,
    ["attributed_sales_" + convertNameToHandle(bottomName.value)]:
      "Attributed Sales " + bottomName.value,
    ["attributed_sales_" + convertNameToHandle(topName.value)]:
      "Attributed Sales " + topName.value,
    ["attributed_revenue_" + convertNameToHandle(bottomName.value)]:
      "Attributed Revenue " + bottomName.value,
    ["attributed_revenue_" + convertNameToHandle(topName.value)]:
      "Attributed Revenue " + topName.value,
    ["attributed_conversion_rate_" + convertNameToHandle(bottomName.value)]:
      "Attributed Conversion Rate " + bottomName.value,
    ["attributed_conversion_rate_" + convertNameToHandle(topName.value)]:
      "Attributed Conversion Rate " + topName.value,
    ["attributed_aov_" + convertNameToHandle(bottomName.value)]:
      "Attributed AOV " + bottomName.value,
    ["attributed_aov_" + convertNameToHandle(topName.value)]:
      "Attributed AOV " + topName.value,
    ["attributed_revenue_variance_" + convertNameToHandle(bottomName.value)]:
      "Attributed Revenue Variance " + bottomName.value,
    ["attributed_revenue_variance_" + convertNameToHandle(topName.value)]:
      "Attributed Revenue Variance " + topName.value,
    ["attributed_revenue_variance_percent_" +
    convertNameToHandle(bottomName.value)]:
      "Attributed Revenue Variance Percent " + bottomName.value,
    ["attributed_revenue_variance_percent_" +
    convertNameToHandle(topName.value)]:
      "Attributed Revenue Variance Percent " + topName.value,
    ["introducer_ratio_" + convertNameToHandle(bottomName.value)]:
      "Introducer Ratio " + bottomName.value,
    ["introducer_ratio_" + convertNameToHandle(topName.value)]:
      "Introducer Ratio " + topName.value,
    ["cpa_" + convertNameToHandle(bottomName.value)]:
      "Attributed CPA " + bottomName.value,
    ["cpa_" + convertNameToHandle(topName.value)]:
      "Attributed CPA " + topName.value,
    ["roas_" + convertNameToHandle(bottomName.value)]:
      "Attributed ROAS " + bottomName.value,
    ["roas_" + convertNameToHandle(topName.value)]:
      "Attributed ROAS " + topName.value,
    ["roi_" + convertNameToHandle(bottomName.value)]: "ROI " + bottomName.value,
    ["roi_" + convertNameToHandle(topName.value)]: "ROI " + topName.value,
    ["sales_including_" + convertNameToHandle(bottomName.value)]:
      "Sales Involving " + bottomName.value,
    ["sales_including_" + convertNameToHandle(topName.value)]:
      "Sales Involving " + topName.value,
    ["proportional_credit_" + convertNameToHandle(bottomName.value)]:
      "Attributed Sale Credit " + bottomName.value,
    ["proportional_credit_" + convertNameToHandle(topName.value)]:
      "Attributed Sale Credit " + topName.value,
  };
  averagedColumns.value = [
    "first_click_conversion_rate_" + convertNameToHandle(bottomName.value),
    "first_click_conversion_rate_" + convertNameToHandle(topName.value),
    "last_click_conversion_rate_" + convertNameToHandle(bottomName.value),
    "last_click_conversion_rate_" + convertNameToHandle(topName.value),
    "attributed_conversion_rate_" + convertNameToHandle(bottomName.value),
    "attributed_conversion_rate_" + convertNameToHandle(topName.value),
    "attributed_revenue_variance_" + convertNameToHandle(bottomName.value),
    "attributed_revenue_variance_" + convertNameToHandle(topName.value),
    "attributed_revenue_variance_percent_" +
      convertNameToHandle(bottomName.value),
    "attributed_revenue_variance_percent_" + convertNameToHandle(topName.value),
    "introducer_ratio_" + convertNameToHandle(bottomName.value),
    "introducer_ratio_" + convertNameToHandle(topName.value),
    "cpa_" + convertNameToHandle(bottomName.value),
    "cpa_" + convertNameToHandle(topName.value),
    "roas_" + convertNameToHandle(bottomName.value),
    "roas_" + convertNameToHandle(topName.value),
    "roi_" + convertNameToHandle(bottomName.value),
    "roi_" + convertNameToHandle(topName.value),
    "proportional_credit_" + convertNameToHandle(bottomName.value),
    "proportional_credit_" + convertNameToHandle(topName.value),
  ];

  return {
    headers,
    headersMetaTabularConverted,
    availableMetrics,
    headersMetaTabular,
    averagedColumns,
  };
};
