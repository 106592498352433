<template>
  <div class="contains-loader">
    <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <router-link to="/performance">All Channels</router-link>
        </li>
      </ul>
    </nav>

    <table-search-controls
      v-model:columns="columns"
      :available-columns="availableColumns"
      v-model:headers="headers"
      :search="search"
      v-model:page="page"
      v-model:perPage="perPage"
      v-model:records="this.sortedData.length"
      exportable
    >
      <template v-slot:finder>
        <o-dropdown aria-role="menu" trap-focus>
          <template #trigger>
            <button class="button" type="button">
              <template v-if="filteredChannels.length === 0">
                <o-icon icon="filter-outline"></o-icon>
              </template>
              <template
                v-if="filteredChannels.length > 0 && filteredChannels.length < 10"
              >
                <o-icon :icon="'numeric-' + filteredChannels.length + '-circle'"></o-icon>
              </template>
              <template v-if="filteredChannels.length > 9">
                <o-icon icon="numeric-9-plus-circle"></o-icon>
              </template>

              <span>Filter</span>
              <o-icon icon="menu-down"></o-icon>
            </button>
          </template>

          <o-dropdown-item @click="resetTreeData([])">
            <o-icon icon="restart"></o-icon>
            <span>Reset filter</span>
          </o-dropdown-item>
          <hr class="dropdown-divider" />
          <o-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
            <div class="modal-card" style="width: 300px">
              <section class="modal-card-body">
                <tree-bwoi
                  v-for="d in treeData"
                  parent="-"
                  :name="d.name"
                  v-model:children="d.children"
                  v-model:selected="d.selected"
                  :level="d.level"
                  :key="'tree' + d.name + d.level"
                ></tree-bwoi>
              </section>
            </div>
          </o-dropdown-item>
        </o-dropdown>
      </template>

      <template #export>
        <export-data
          :available-columns="
            ['taxonomy_1', 'taxonomy_2', 'taxonomy_3', 'taxonomy_4'].concat(
              availableColumns
            )
          "
          :selected-columns="
            ['taxonomy_1', 'taxonomy_2', 'taxonomy_3', 'taxonomy_4'].concat(columns)
          "
          :always-selected-columns="[
            'taxonomy_1',
            'taxonomy_2',
            'taxonomy_3',
            'taxonomy_4',
          ]"
          :column-headers="headers"
          :report-name="
            (getCurrentProgramName + '_search_' + getDateFrom + '_' + getDateTo)
              .toLowerCase()
              .split(' ')
              .join('_')
          "
          :exportable-data="exportData"
          :order-data-by="sortField"
          :order-data-direction="sortOrder"
        />
      </template>
    </table-search-controls>

    <o-table
      class="has-sticky-table-headers"
      ref="performanceTableSearch"
      narrowed
      hoverable
      :data="pageData"
      :default-sort-direction="sortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <!-- Taxonomy 1 -->
      <o-table-column field="aggregate" label="Taxonomies" sortable v-slot="props">
        <router-link
          :to="
            '/performance/taxonomy_one/' + validateInternalParameter(props.row.taxonomy_1)
          "
          >{{ props.row.taxonomy_1 }}</router-link
        >

        <template v-if="props.row.taxonomy_2">
          <o-icon icon="chevron-right"></o-icon>
          <router-link
            :to="
              '/performance/taxonomy_one/' +
              validateInternalParameter(props.row.taxonomy_1) +
              '/taxonomy_two/' +
              validateInternalParameter(props.row.taxonomy_2)
            "
            >{{ props.row.taxonomy_2 }}</router-link
          >
        </template>

        <template v-if="props.row.taxonomy_3">
          <o-icon icon="chevron-right"></o-icon>
          <router-link
            :to="
              '/performance/taxonomy_one/' +
              validateInternalParameter(props.row.taxonomy_1) +
              '/taxonomy_two/' +
              validateInternalParameter(props.row.taxonomy_2) +
              '/taxonomy_three/' +
              validateInternalParameter(props.row.taxonomy_3)
            "
            >{{ props.row.taxonomy_3 }}</router-link
          >
        </template>

        <template v-if="props.row.taxonomy_4">
          <o-icon icon="chevron-right"></o-icon>
          {{ props.row.taxonomy_4 }}
        </template>
      </o-table-column>

      <!-- Variable Cost -->
      <o-table-column
        field="total_cost"
        :label="headers['variable_cost'] || 'Variable Cost'"
        sortable
        position="right"
        :visible="isColumnVisible('variable_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.variable_cost) }}</o-table-column
      >

      <!-- Fixed Cost -->
      <o-table-column
        field="total_cost"
        :label="headers['fixed_cost'] || 'Fixed Cost'"
        sortable
        position="right"
        :visible="isColumnVisible('fixed_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.fixed_cost) }}</o-table-column
      >

      <!-- Cost -->
      <o-table-column
        field="total_cost"
        :label="headers['total_cost'] || 'Total Cost'"
        sortable
        position="right"
        :visible="isColumnVisible('total_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.total_cost) }}</o-table-column
      >

      <!-- Page Lands -->
      <o-table-column
        field="clicks"
        :label="headers['clicks'] || 'Page Lands'"
        sortable
        position="right"
        :visible="isColumnVisible('clicks')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.clicks) }}</o-table-column
      >

      <!-- Impressions -->
      <o-table-column
        field="impressions"
        :label="headers['impressions'] || 'Impressions'"
        sortable
        position="right"
        :visible="isColumnVisible('impressions')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.impressions) }}</o-table-column
      >

      <!-- First Click Sales -->
      <o-table-column
        field="first_click_sales"
        :label="headers['first_click_sales'] || 'First TouchSales'"
        sortable
        position="right"
        :visible="isColumnVisible('first_click_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.first_click_sales) }}</o-table-column
      >

      <!-- First Click Revenue -->
      <o-table-column
        field="first_click_revenue"
        :label="headers['first_click_revenue'] || 'First Touch Revenue'"
        sortable
        position="right"
        :visible="isColumnVisible('first_click_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.first_click_revenue) }}</o-table-column
      >

      <!-- First Click Conversion Rate -->
      <o-table-column
        field="first_click_conversion_rate"
        :label="headers['first_click_conversion_rate'] || 'First TouchConversion Rate'"
        sortable
        position="right"
        :visible="isColumnVisible('first_click_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.first_click_conversion_rate) }}%</o-table-column
      >

      <!-- First Click AOV-->
      <o-table-column
        field="first_click_aov"
        :label="headers['first_click_aov'] || 'First TouchAOV'"
        sortable
        position="right"
        :visible="isColumnVisible('first_click_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.first_click_aov) }}</o-table-column
      >

      <!-- Last Click Sales -->
      <o-table-column
        field="last_click_sales"
        :label="headers['last_click_sales'] || 'Last TouchSales'"
        sortable
        position="right"
        :visible="isColumnVisible('last_click_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.last_click_sales) }}</o-table-column
      >

      <!-- Last Click Revenue -->
      <o-table-column
        field="last_click_revenue"
        :label="headers['last_click_revenue'] || 'Last Touch Revenue'"
        sortable
        position="right"
        :visible="isColumnVisible('last_click_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.last_click_revenue) }}</o-table-column
      >

      <!-- Last Click Conversion Rate -->
      <o-table-column
        field="last_click_conversion_rate"
        :label="headers['last_click_conversion_rate'] || 'Last TouchConversion Rate'"
        sortable
        position="right"
        :visible="isColumnVisible('last_click_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.last_click_conversion_rate) }}%</o-table-column
      >

      <!-- Last Click AOV-->
      <o-table-column
        field="last_click_aov"
        :label="headers['last_click_aov'] || 'Last TouchAOV'"
        sortable
        position="right"
        :visible="isColumnVisible('last_click_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.last_click_aov) }}</o-table-column
      >

      <!-- Attributed Sales -->
      <o-table-column
        field="attributed_sales"
        :label="headers['attributed_sales'] || 'Attributed Sales'"
        sortable
        position="right"
        :visible="isColumnVisible('attributed_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.attributed_sales) }}</o-table-column
      >

      <!-- Attributed Revenue -->
      <o-table-column
        field="attributed_revenue"
        :label="headers['attributed_revenue'] || 'Attributed Revenue'"
        sortable
        position="right"
        :visible="isColumnVisible('attributed_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.attributed_revenue) }}</o-table-column
      >

      <!-- Attributed Conversion Rate -->
      <o-table-column
        field="attributed_conversion_rate"
        :label="headers['attributed_conversion_rate'] || 'Attributed Conversion Rate'"
        sortable
        position="right"
        :visible="isColumnVisible('attributed_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.attributed_conversion_rate) }}%</o-table-column
      >

      <!-- Attributed AOV-->
      <o-table-column
        field="attributed_aov"
        :label="headers['attributed_aov'] || 'Attributed AOV'"
        sortable
        position="right"
        :visible="isColumnVisible('attributed_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.attributed_aov) }}</o-table-column
      >

      <!-- Attributed Revenue Variance -->
      <o-table-column
        field="attributed_revenue_variance"
        :label="headers['attributed_revenue_variance'] || 'Attributed Revenue Variance'"
        sortable
        position="right"
        :visible="isColumnVisible('attributed_revenue_variance')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.attributed_revenue_variance) }}</o-table-column
      >

      <!-- Attributed Revenue Variance Percent -->
      <o-table-column
        field="attributed_revenue_variance_percent"
        :label="
          headers['attributed_revenue_variance_percent'] ||
          'Attributed Revenue Variance Percent'
        "
        sortable
        position="right"
        :visible="isColumnVisible('attributed_revenue_variance_percent')"
        v-slot="props"
      >
        {{ displayNumericValueOneDp(props.row.attributed_revenue_variance_percent) }}%
      </o-table-column>

      <!-- Introducer Ratio -->
      <o-table-column
        field="introducer_ratio"
        :label="headers['introducer_ratio'] || 'Introducer Ratio'"
        sortable
        position="right"
        :visible="isColumnVisible('introducer_ratio')"
        v-slot="props"
        >{{ displayNumericValue(props.row.introducer_ratio) }}</o-table-column
      >

      <!-- CPA -->
      <o-table-column
        field="cpa"
        :label="headers['cpa'] || 'Attributed CPA'"
        sortable
        position="right"
        :visible="isColumnVisible('cpa')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.cpa) }}</o-table-column
      >

      <!-- ROAS -->
      <o-table-column
        field="roas"
        :label="headers['roas'] || 'Attributed ROAS'"
        sortable
        position="right"
        :visible="isColumnVisible('roas')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.roas) }}</o-table-column
      >

      <!-- ROI -->
      <o-table-column
        field="roi"
        :label="headers['roi'] || 'ROI'"
        sortable
        position="right"
        :visible="isColumnVisible('roi')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.roi) }}</o-table-column
      >

      <!-- Sales Involved -->
      <o-table-column
        field="sales_including"
        :label="headers['sales_including'] || 'Sales Involved'"
        sortable
        position="right"
        :visible="isColumnVisible('sales_including')"
        v-slot="props"
        >{{ displayNumericValue(props.row.sales_including) }}</o-table-column
      >

      <!-- Proportional Credit -->
      <o-table-column
        field="proportional_credit"
        :label="headers['proportional_credit'] || 'Attributed Sale Credit'"
        sortable
        position="right"
        :visible="isColumnVisible('proportional_credit')"
        v-slot="props"
        >{{ displayNumericValueOneDp(props.row.proportional_credit) }}</o-table-column
      >

      <template #footer>
        <!-- Total -->
        <th class="is-hidden-mobile">
          <div class>Total</div>
        </th>
        <!-- Variable Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('variable_cost')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.variable_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Fixed Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('fixed_cost')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.fixed_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('total_cost')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.total_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Page Lands -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('clicks')">
          <div class="th-wrap is-right">
            {{
              displayNumericValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.clicks;
                })
              )
            }}
          </div>
        </th>
        <!-- Impressions -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('impressions')">
          <div class="th-wrap is-right">
            {{
              displayNumericValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.impressions;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_sales')">
          <div class="th-wrap is-right">
            {{
              displayNumericValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.first_click_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_revenue')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.first_click_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Conversion Rate -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('first_click_conversion_rate')"
        >
          <div class="th-wrap is-right">
            {{
              displayNumericValue(
                sumBy(this.sortedData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.sortedData, function (v) {
                      return v.first_click_sales;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- First Click AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_aov')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.first_click_sales;
                }) !== 0
                  ? sumBy(this.sortedData, function (v) {
                      return v.first_click_revenue;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.first_click_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Last Click Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_sales')">
          <div class="th-wrap is-right">
            {{
              displayNumericValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.last_click_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- Last Click Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_revenue')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.last_click_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- Last Click Conversion Rate -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_conversion_rate')">
          <div class="th-wrap is-right">
            {{
              displayNumericValue(
                sumBy(this.sortedData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.sortedData, function (v) {
                      return v.last_click_sales;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- Last Click AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_aov')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.last_click_sales;
                }) !== 0
                  ? sumBy(this.sortedData, function (v) {
                      return v.last_click_revenue;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.last_click_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Attributed Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_sales')">
          <div class="th-wrap is-right">
            {{
              displayNumericValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.attributed_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_revenue')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.attributed_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- Attributed Conversion Rate -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_conversion_rate')">
          <div class="th-wrap is-right">
            {{
              displayNumericValue(
                sumBy(this.sortedData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.sortedData, function (v) {
                      return v.attributed_sales;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- Attributed AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_aov')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.attributed_sales;
                }) !== 0
                  ? sumBy(this.data, function (v) {
                      return v.attributed_revenue;
                    }) /
                      sumBy(this.data, function (v) {
                        return v.attributed_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue Variance -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('attributed_revenue_variance')"
        >
          <div class="th-wrap is-right">
            {{
              displayMonetaryValueRounded(
                sumBy(this.sortedData, function (v) {
                  return v.attributed_revenue;
                }) -
                  sumBy(this.sortedData, function (v) {
                    return v.last_click_revenue;
                  })
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue Variance Percentage -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('attributed_revenue_variance_percent')"
        >
          <div class="th-wrap is-right">{{ displayNumericValueOneDp(0) }}%</div>
        </th>
        <!-- Introducer Ratio -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('introducer_ratio')">
          <div class="th-wrap is-right">-</div>
        </th>
        <!-- CPA -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('cpa')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.attributed_sales;
                }) !== 0
                  ? sumBy(this.sortedData, function (v) {
                      return v.total_cost;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.attributed_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- ROAS -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('roas')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.total_cost;
                }) !== 0
                  ? sumBy(this.sortedData, function (v) {
                      return v.attributed_revenue;
                    }) /
                      sumBy(this.sortedData, function (v) {
                        return v.total_cost;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- ROI -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('roi')">
          <div class="th-wrap is-right">
            {{
              displayMonetaryValue(
                sumBy(this.sortedData, function (v) {
                  return v.total_cost;
                }) !== 0
                  ? (sumBy(this.sortedData, function (v) {
                      return v.attributed_revenue;
                    }) -
                      sumBy(this.sortedData, function (v) {
                        return v.total_cost;
                      })) /
                      sumBy(this.sortedData, function (v) {
                        return v.total_cost;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Sales Involved -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('sales_including')">
          <div class="th-wrap is-right">
            <span>-</span>
          </div>
        </th>
        <!-- Proportional Credit -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('proportional_credit')">
          <div class="th-wrap is-right">
            <span>-</span>
          </div>
        </th>
      </template>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-right">
            <template v-if="!isLoading">
              <p>
                <o-icon icon="emoticon-sad" size="is-large"></o-icon>
              </p>
              <p>There is no data to display</p>
            </template>
          </div>
        </section>
      </template>
    </o-table>

    <o-loading :full-page="false" v-model:active="isLoading"></o-loading>
  </div>
</template>

<script>
import TableSearchControls from "./Controls";
import ExportData from "../../Pieces/ExportData.vue";
import TreeBwoi from "./TreeBwoi";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseDateStore } from "../../State/Stores/DateStore";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseNumberHelper } from "../../../Helper/Functional/UseNumberHelper";
import { UseReportSettingsStore } from "../../State/Stores/ReportSettingsStore";
import { sumBy } from "lodash";

export default {
  components: {
    TreeBwoi,
    TableSearchControls,
    ExportData,
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram, getCurrentProgramName } = storeToRefs(UseProgramStore());
    const {
      getPerformanceTableColumns,
      getPerformanceTableSortMetric,
      getPerformanceTableSortDirection,
    } = storeToRefs(UseReportSettingsStore());
    const {
      setPerformanceTableColumns,
      setPerformanceTableSortMetric,
      setPerformanceTableSortDirection,
    } = UseReportSettingsStore();

    const {
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueOneDp,
      displayNumericValueRounded,
    } = UseNumberHelper();
    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
      getCurrentProgramName,
      getPerformanceTableColumns,
      getPerformanceTableSortMetric,
      getPerformanceTableSortDirection,
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueOneDp,
      displayNumericValueRounded,
      setPerformanceTableColumns,
      setPerformanceTableSortMetric,
      setPerformanceTableSortDirection,
      sumBy,
    };
  },
  computed: {
    filteredChannels() {
      return _.filter(this.treeData, (d) => d.selected !== "none");
    },
    columns: {
      get() {
        return this.getPerformanceTableColumns;
      },
      set(val) {
        this.setPerformanceTableColumns(val);
      },
    },
    sortField: {
      get() {
        return this.getPerformanceTableSortMetric;
      },
      set(val) {
        this.setPerformanceTableSortMetric(val);
      },
    },
    sortOrder: {
      get() {
        return this.getPerformanceTableSortDirection;
      },
      set(val) {
        this.setPerformanceTableSortDirection(val);
      },
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/search" +
        "?query=" +
        this.search +
        "&date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    mappedTreeData() {
      return _.flatMapDeep(this.treeData, (d) =>
        _.flatMapDeep(d.children, (dd) =>
          _.chain(dd.children)
            .flatMapDeep("children")
            .filter((ds) => ds.selected !== "none" && ds.selected !== "None")
            .map(
              (ddd) =>
                ddd.raw.taxonomy_1 +
                ddd.raw.taxonomy_2 +
                ddd.raw.taxonomy_3 +
                ddd.raw.taxonomy_4
            )
            .value()
        )
      );
    },
    exportData() {
      return this.data.map((d) => ({
        ...d,
        first_click_conversion_rate: d.first_click_conversion_rate / 100,
        last_click_conversion_rate: d.last_click_conversion_rate / 100,
        attributed_conversion_rate: d.attributed_conversion_rate / 100,
        attributed_revenue_variance_percent: d.attributed_revenue_variance_percent / 100,
      }));
    },
    sortedData() {
      return this.data
        ? _.orderBy(
            _.filter(
              this.data,
              function (d) {
                return _.includes(
                  this.mappedTreeData,
                  d.taxonomy_1 + d.taxonomy_2 + d.taxonomy_3 + d.taxonomy_4
                );
              }.bind(this)
            ),
            [this.sortField],
            [this.sortOrder]
          )
        : [];
    },
    chunkedData() {
      return _.chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.chunkedData.length > 0 ? this.chunkedData[this.page - 1] : [];
    },
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      treeData: [],
      isLoading: false,
      headers: {},
      data: [],
      perPage: 15,
      page: 1,
      availableColumns: [
        "variable_cost",
        "fixed_cost",
        "total_cost",
        "clicks",
        "impressions",
        "first_click_sales",
        "first_click_revenue",
        "first_click_conversion_rate",
        "first_click_aov",
        "last_click_sales",
        "last_click_revenue",
        "last_click_conversion_rate",
        "last_click_aov",
        "attributed_sales",
        "attributed_revenue",
        "attributed_conversion_rate",
        "attributed_aov",
        "attributed_revenue_variance",
        "attributed_revenue_variance_percent",
        "introducer_ratio",
        "cpa",
        "roas",
        "roi",
        "sales_including",
      ],
    };
  },
  watch: {
    url() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },
  methods: {
    loadAsyncData() {
      this.isLoading = true;
      let copy =
        this.data.length === this.mappedTreeData.length
          ? []
          : _.cloneDeep(this.mappedTreeData);

      axios
        .get(this.url)
        .then(({ data }) => {
          // Add the aggregated, as we need it for the sorting of the aggregated column
          this.data = _.map(data.data, function (inner) {
            let aggregatedData = [
              inner.taxonomy_1,
              inner.taxonomy_2,
              inner.taxonomy_3,
              inner.taxonomy_4,
            ];

            return _.extend({}, inner, {
              aggregated: aggregatedData.join(","),
            });
          });

          this.headers = data.column_headers;
          this.resetTreeData(copy);
          this.isLoading = false;
        })
        .catch(() => {
          this.headers = [];
          this.isLoading = false;
        });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    isColumnVisible(column) {
      return _.includes(this.columns, column);
    },
    resetTreeData(copy) {
      this.treeData = _.chain(
        _.uniqBy(
          this.data,
          (data) => data.taxonomy_1 + data.taxonomy_2 + data.taxonomy_3 + data.taxonomy_4
        )
      )
        .groupBy("taxonomy_1")
        .map(function (val) {
          let children = _.chain(val)
            .groupBy("taxonomy_2")
            .map(function (val) {
              let children = _.chain(val)
                .groupBy("taxonomy_3")
                .map(function (val) {
                  let children = _.chain(val)
                    .groupBy("taxonomy_4")
                    .map(function (val) {
                      let first = _.first(val);
                      return {
                        raw: first,
                        name: first.taxonomy_4,
                        level: 4,
                        selected:
                          copy.length === 0 ||
                          _.includes(
                            copy,
                            first.taxonomy_1 +
                              first.taxonomy_2 +
                              first.taxonomy_3 +
                              first.taxonomy_4
                          )
                            ? "all"
                            : "none",
                        children: [],
                      };
                    })
                    .value();

                  return {
                    name: _.first(val).taxonomy_3,
                    level: 3,
                    selected: _.every(children, ["selected", "all"])
                      ? "all"
                      : _.every(children, ["selected", "none"])
                      ? "none"
                      : "some",
                    children: children,
                  };
                })
                .value();

              return {
                name: _.first(val).taxonomy_2,
                level: 2,
                selected: _.every(children, ["selected", "all"])
                  ? "all"
                  : _.every(children, ["selected", "none"])
                  ? "none"
                  : "some",
                children: children,
              };
            })
            .value();

          return {
            name: _.first(val).taxonomy_1,
            level: 1,
            selected: _.every(children, ["selected", "all"])
              ? "all"
              : _.every(children, ["selected", "none"])
              ? "none"
              : "some",
            children: children,
          };
        })
        .value();
    },
  },
};
</script>
