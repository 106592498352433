<template>
  <o-modal
    v-model:active="toggleModal"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
  >
    <div class="modal-card">
      <modal-body>
        <template v-slot:header>
          <UpdateGroupHeader :text="'Create Group'" />
        </template>
        <template v-slot:input>
          <UpdateGroupInput v-model:value="newGroup" />
        </template>
        <template v-slot:button>
          <UpdateGroupButton
            :text="'Create Group'"
            :group-name="newGroup"
            :new-name="newGroup"
            :full-update="true"
            :segment-groups="segmentGroups"
            :segment-group-url="segmentGroupUrl"
            @closeModal="$emit('handleCloseModal', false)"
          />
        </template>
      </modal-body>
    </div>
  </o-modal>
</template>

<script>
import ModalBody from "../ModalBody.vue";
import UpdateGroupHeader from "../UpdateGroupComponents/UpdateGroupHeader";
import UpdateGroupButton from "../UpdateGroupComponents/UpdateGroupButton";
import UpdateGroupInput from "../UpdateGroupComponents/UpdateGroupInput";
export default {
  components: {
    ModalBody,
    UpdateGroupButton,
    UpdateGroupHeader,
    UpdateGroupInput,
  },
  props: {
    toggleModal: {
      type: Boolean,
      default: false,
    },
    segmentGroups: {
      type: Array,
      default: () => [],
    },
    segmentGroupUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newGroup: "",
    };
  },
};
</script>
