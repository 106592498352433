import { UseStringHelper } from "./../../../Helper/Functional/UseStringHelper";
import { UseSegmentComparisonStore } from "./../../../Modules/State/Stores/SegmentComparisonStore";
import { storeToRefs } from "pinia";

import { SegmentComparisonChannelDropdowns } from "./SegmentComparisonChannelDropdowns";
import { UseArrayHelper } from "../../../Helper/Functional/UseArrayHelper";
import {
  IChartObject,
  ITableObject,
  TChartData,
  TChartExportDataNew,
  TChartMatched,
  TAllTableHeaderValues,
  IGeTAllTableHeaders,
} from "../../../../typings/DataFetching";
import { StaticColumnData } from "../../../StaticData/StaticColumnData";
const { checkMultipleArrayLengths, objectKeysArray, findItemUsingIndex } =
  UseArrayHelper();
const { convertNameToHandle } = UseStringHelper();

export const SegmentComparisonDataManipulation = () => {
  const { headersMetaTabularToConvert } = StaticColumnData();
  const { comparisonMetric, dropDownNames } =
    SegmentComparisonChannelDropdowns();
  const { getComparisonMetricLeft, getComparisonMetricRight } = storeToRefs(
    UseSegmentComparisonStore()
  );
  /**
   *
   * @param arr A TChartData[] array of objects. Each object contains a channel name and an array called data that holds the chart data
   * @returns An object with the channel names as the first level keys with their values being TChartMatched objects
   */
  const reducerHelper = (arr: TChartData[]) => {
    return arr.reduce((acc, cur): TChartMatched => {
      acc[cur.name] = {
        name: cur.name,
        data: cur.data,
      };
      return acc;
    }, {} as TChartMatched);
  };

  /**
   *
   * @param chartDataParam An array of IChart objects
   * @param metricOne Channel - A string, the value of the left hand select box
   * @param metricTwo Channel - A string, the value of the right hand select box
   * @returns An array that contains the data for both Channels separated into top and bottom as well as the channel name. Pass this to the chart component
   */
  const chartMatched = (
    chartDataParam: IChartObject[],
    metricOne: string,
    metricTwo: string
  ): TChartExportDataNew[] => {
    const top = chartDataParam.find((data) => data.tag === metricOne)!;
    const bottom = chartDataParam.find((data) => data.tag === metricTwo)!;
    if (top && bottom) {
      const topShape = reducerHelper(top.chart);
      const bottomShape = reducerHelper(bottom.chart);
      const keys = Array.from(
        new Set([...objectKeysArray(topShape), ...objectKeysArray(bottomShape)])
      );
      return keys.map((k) => ({
        top: topShape[k],
        bottom: bottomShape[k],
        name: k,
      }));
    }
    return [];
  };

  /**
   *
   * @param arr An array of ITableObjects
   * @param key A string - used as a key to an object
   * @param metric The metric selected from the table dropdown
   * @returns A One dimensional array containing the table data for a particular channel
   */
  const flatDataResponse = <T extends ITableObject>(
    arr: T[],
    key: string,
    metric: string
  ): T["table"] => {
    return arr
      .filter((item) => item[key] === metric)
      .flatMap((tab) => tab.table);
  };

  const metaTabularExportedDataHandler = <T extends object>(
    arr: T[],
    index: number
  ) => {
    return arr.map((o) => {
      const listOfKeys: string[] = Object.keys(o);
      let obj = {} as T;
      listOfKeys.forEach((key) => {
        if (headersMetaTabularToConvert.includes(key)) {
          obj[
            `${key}_${convertNameToHandle(
              findItemUsingIndex(
                dropDownNames.value,
                comparisonMetric.value,
                "tag",
                index
              )?.title || ""
            )}`
          ] = o[key];
        } else {
          obj[key] = o[key];
        }
      });
      return obj;
    });
  };

  const putItAllTogether = (
    val: { tag: string; table: TAllTableHeaderValues<IGeTAllTableHeaders>[] }[]
  ) => {
    const one = flatDataResponse(val, "tag", getComparisonMetricLeft.value);
    const two = flatDataResponse(val, "tag", getComparisonMetricRight.value);
    const firstData = metaTabularExportedDataHandler(one, 0);
    const secondData = metaTabularExportedDataHandler(two, 1);
    const checkedArrays = checkMultipleArrayLengths([firstData, secondData]);
    if (checkedArrays.length) {
      const allTogetherNow = checkedArrays[0].map((t, i) => ({
        ...t,
        ...(checkedArrays[1] ? checkedArrays[1][i] : []),
      }));
      return [...allTogetherNow].sort() as Partial<
        TAllTableHeaderValues<IGeTAllTableHeaders>
      >[];
    }
    return [];
  };

  return {
    reducerHelper,
    chartMatched,
    flatDataResponse,
    metaTabularExportedDataHandler,
    putItAllTogether,
  };
};
