<template>
  <vue-apex-charts
    :ref="'breakdownChart' + reference"
    type="area"
    :options="chartOptions"
    :series="series"
    height="250"
  ></vue-apex-charts>
</template>

<script>
import moment from "moment";
import { storeToRefs } from "pinia";
import { UseMetricHelper } from "../../Helper/Functional/UseMetricHelper";
import { UseReportSettingsStore } from "../State/Stores/ReportSettingsStore";
import VueApexCharts from "vue3-apexcharts";
export default {
  setup() {
    const { getMetaMetric } = storeToRefs(UseReportSettingsStore());
    const { fetchMetricValue } = UseMetricHelper();

    return {
      getMetaMetric,
      fetchMetricValue,
    };
  },
  components: { VueApexCharts },
  props: {
    reference: {
      type: String,
      default: "",
    },
    channelData: {
      type: Array,
      default: [],
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    topName: {
      type: String,
      default: () => "",
    },
    bottomName: {
      type: String,
      default: () => "",
    },
    allDates: {
      type: Array,
      default: [],
    },
  },
  computed: {
    chartOptions() {
      let yaxis = [
        {
          show: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          tickAmount: 5,
          forceNiceScale: true,
          min: this.min,
          max: this.max,
          labels: {
            formatter: function (value) {
              if (!this.getMetaMetric) {
                return value;
              }

              return this.fetchMetricValue(this.getMetaMetric, value);
            }.bind(this),
          },
        },
      ];

      return {
        chart: {
          id: "breakdown-chart-" + this.reference,
          stacked: true,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
          },

          zoom: {
            enabled: false,
          },
        },
        colors: ["#2799FF", "#36E7A6"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        markers: {
          size: 0,
        },
        tooltip: {
          y: [
            {
              formatter: function (value) {
                if (!this.getMetaMetric) {
                  return value;
                }

                return this.fetchMetricValue(this.getMetaMetric, value);
              }.bind(this),
            },
            {
              formatter: function (value) {
                if (!this.getMetaMetric) {
                  return value;
                }

                return this.fetchMetricValue(this.getMetaMetric, value);
              }.bind(this),
            },
          ],
        },
        xaxis: {
          labels: {
            show: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "",
              minute: "",
            },
          },
          type: "datetime",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: yaxis,
      };
    },
    series() {
      let bottom = _.values(
        _.map(
          this.allDates,
          function (date) {
            return _.defaults(
              _.get(
                _.find(this.channelData, function (x) {
                  return _.get(x, ["bottom", "referral_date"], "") == date;
                }),
                "bottom"
              ),
              { referral_date: date }
            );
          }.bind(this)
        )
      );
      let top = _.values(
        _.map(
          this.allDates,
          function (date) {
            return _.defaults(
              _.get(
                _.find(this.channelData, function (x) {
                  return _.get(x, ["top", "referral_date"], "") == date;
                }),
                "top"
              ),
              { referral_date: date }
            );
          }.bind(this)
        )
      );

      let t = this.topName,
        b = this.bottomName;

      bottom = _.orderBy(bottom, "referral_date");
      top = _.orderBy(top, "referral_date");
      return [
        {
          // 'name': this.headers[this.firstMetric] || this.firstMetric,
          name: t.charAt(0).toUpperCase() + t.slice(1),
          data: _(top)
            .mapValues((value) => ({
              x: moment.utc(value.referral_date, "YYYY-MM-DD"),
              y: _.get(value, this.getMetaMetric, 0),
            }))
            .values()
            .value(),
        },
        {
          // 'name': this.headers[this.firstMetric] || this.firstMetric,
          name: b.charAt(0).toUpperCase() + b.slice(1),
          data: _(bottom)
            .mapValues((value) => ({
              x: moment.utc(value.referral_date, "YYYY-MM-DD"),
              y: _.get(value, this.getMetaMetric, 0),
            }))
            .values()
            .value(),
        },
      ];
    },
  },
};
</script>
