import {
  IMetaFullColumnSkeleton,
  IMetaFullFooterSkeleton,
} from "./../Typings/MetaTypes";
import { computed } from "vue";

export const UseMetaTableColumns = () => {
  const columnSkeleton = computed((): IMetaFullColumnSkeleton[] => [
    {
      field: "variable_cost",
      label: "Variable Cost",
      monetary: true,
      rounded: true,
      convert: false,
    },
    {
      field: "fixed_cost",
      label: "Fixed Cost",
      monetary: true,
      rounded: true,
      convert: false,
    },
    {
      field: "total_cost",
      label: "Total Cost",
      monetary: true,
      rounded: true,
      convert: false,
    },
    {
      field: "clicks",
      label: "Page Lands",
      rounded: true,
      convert: false,
    },
    {
      field: "impressions",
      label: "Impressions",
      rounded: true,
      convert: false,
    },
    {
      field: "first_click_sales",
      label: "First Touch Sales",
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "first_click_sales",
      label: "First Touch Sales",
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "first_click_revenue",
      label: "First Touch Revenue",
      monetary: true,
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "first_click_revenue",
      label: "First Touch Revenue",
      monetary: true,
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "first_click_conversion_rate",
      label: "First Touch Conversion Rate",
      percentage: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "first_click_conversion_rate",
      label: "First Touch Conversion Rate",
      percentage: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "first_click_aov",
      label: "First Touch AOV",
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "first_click_aov",
      label: "First Touch AOV",
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "last_click_sales",
      label: "Last Touch Sales",
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "last_click_sales",
      label: "Last Touch Sales",
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "last_click_revenue",
      label: "Last Touch Revenue",
      rounded: true,
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "last_click_revenue",
      label: "Last Touch Revenue",
      rounded: true,
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "last_click_conversion_rate",
      label: "Last Touch Conversion Rate",
      percentage: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "last_click_conversion_rate",
      label: "Last Touch Conversion Rate",
      percentage: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "last_click_aov",
      label: "Last Touch AOV",
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "last_click_aov",
      label: "Last Touch AOV",
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_sales",
      label: "Attributed Sales",
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_sales",
      label: "Attributed Sales",
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_revenue",
      label: "Attributed Revenue",
      rounded: true,
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_revenue",
      label: "Attributed Revenue",
      rounded: true,
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_conversion_rate",
      label: "Attributed Conversion Rate",
      percentage: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_conversion_rate",
      label: "Attributed Conversion Rate",
      percentage: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_aov",
      label: "Attributed AOV",
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_aov",
      label: "Attributed AOV",
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_revenue_variance",
      label: "Attributed Revenue Variance",
      monetary: true,
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_revenue_variance",
      label: "Attributed Revenue Variance",
      monetary: true,
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "attributed_revenue_variance_percent",
      label: "Attributed Revenue Variance Percent",
      percentage: true,
      oneDecimal: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "attributed_revenue_variance_percent",
      label: "Attributed Revenue Variance Percent",
      percentage: true,
      oneDecimal: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "introducer_ratio",
      label: "Introducer Ratio",
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "introducer_ratio",
      label: "Introducer Ratio",
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "cpa",
      label: "Attributed CPA",
      monetary: true,
      opposite: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "cpa",
      label: "Attributed CPA",
      monetary: true,
      opposite: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "roas",
      label: "Attributed ROAS",
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "roas",
      label: "Attributed ROAS",
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "roi",
      label: "ROI",
      monetary: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "roi",
      label: "ROI",
      monetary: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "sales_including",
      label: "Sales Involved",
      rounded: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "sales_including",
      label: "Sales Involved",
      rounded: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
    {
      field: "proportional_credit",
      label: "Proportional Credit",
      oneDecimal: true,
      convert: true,
      asset: "bottomName",
      level: 1,
    },
    {
      field: "proportional_credit",
      label: "Proportional Credit",
      oneDecimal: true,
      convert: true,
      asset: "topName",
      level: 2,
    },
  ]);
  const footerSkeleton = computed((): IMetaFullFooterSkeleton[] => [
    {
      field: "variable_cost",
      convert: false,
    },
    {
      field: "fixed_cost",
      convert: false,
    },
    {
      field: "total_cost",
      convert: false,
    },
    {
      field: "clicks",
      convert: false,
    },
    {
      field: "impressions",
      convert: false,
    },
    {
      field: "first_click_sales",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "first_click_sales",
      convert: true,
      asset: "topName",
    },
    {
      field: "first_click_revenue",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "first_click_revenue",
      convert: true,
      asset: "topName",
    },
    {
      field: "first_click_conversion_rate",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "first_click_conversion_rate",
      convert: true,
      asset: "topName",
    },
    {
      field: "first_click_aov",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "first_click_aov",
      convert: true,
      asset: "topName",
    },
    {
      field: "last_click_sales",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "last_click_sales",
      convert: true,
      asset: "topName",
    },
    {
      field: "last_click_revenue",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "last_click_revenue",
      convert: true,
      asset: "topName",
    },
    {
      field: "last_click_conversion_rate",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "last_click_conversion_rate",
      convert: true,
      asset: "topName",
    },
    {
      field: "last_click_aov",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "last_click_aov",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_sales",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_sales",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_revenue",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_revenue",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_conversion_rate",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_conversion_rate",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_aov",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_aov",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_revenue_variance",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_revenue_variance",
      convert: true,
      asset: "topName",
    },
    {
      field: "attributed_revenue_variance_percent",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "attributed_revenue_variance_percent",
      convert: true,
      asset: "topName",
    },
    {
      field: "introducer_ratio",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "introducer_ratio",
      convert: true,
      asset: "topName",
    },
    {
      field: "cpa",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "cpa",
      convert: true,
      asset: "topName",
    },
    {
      field: "roas",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "roas",
      convert: true,
      asset: "topName",
    },
    {
      field: "roi",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "roi",
      convert: true,
      asset: "topName",
    },
    {
      field: "sales_including",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "sales_including",
      convert: true,
      asset: "topName",
    },
    {
      field: "proportional_credit",
      convert: true,
      asset: "bottomName",
    },
    {
      field: "proportional_credit",
      convert: true,
      asset: "topName",
    },
  ]);

  return {
    footerSkeleton,
    columnSkeleton,
  };
};
