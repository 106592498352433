<template>
  <div>
    <config-controls
      v-model:name-prop="reportName"
      v-model:level-prop="reportLevel"
      v-model:rules-prop="reportRules"
      v-model:available-columns="availableColumns"
      v-model:columns="columns"
      v-model:headers="headers"
      v-model:availableChannels="availableChannels"
      v-model:filteredAvailableChannels="filteredAvailableChannels"
      @setDefaultColumns="setDefaultColumns"
      v-model:page="page"
      v-model:per-page="perPage"
      v-model:records="sortedData.length"
      v-model:loading="loading"
      :exportAvailable="true"
    >
      <template #export>
        <export-data
          :available-columns="concatMeEvan.concat(availableColumns)"
          :selected-columns="concatMeEvan.concat(columns)"
          :column-headers="headers"
          :report-name="
            (
              getCurrentProgramName +
              '_' +
              reportName +
              '_' +
              getDateFrom +
              '_' +
              getDateTo
            )
              .toLowerCase()
              .split(' ')
              .join('_')
          "
          :exportable-data="exportData"
          :order-data-by="sortField"
          :order-data-direction="sortOrder"
        />
      </template>
    </config-controls>

    <br />

    <o-table
      class="has-sticky-table-headers"
      ref="performanceTableReport"
      narrowed
      hoverable
      :data="pageData"
      :default-sort-direction="sortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <!-- Taxonomy 1 -->
      <o-table-column field="taxonomy_1" label="Taxonomy 1" sortable v-slot="props">
        <router-link
          :to="
            '/performance/taxonomy_one/' + validateInternalParameter(props.row.taxonomy_1)
          "
          >{{ props.row.taxonomy_1 }}</router-link
        >
      </o-table-column>

      <!-- Taxonomy 2 -->
      <o-table-column
        field="taxonomy_2"
        label="Taxonomy 2"
        sortable
        :visible="loadedTaxonomy > 1"
        v-slot="props"
      >
        <router-link
          :to="
            '/performance/taxonomy_one/' +
            validateInternalParameter(props.row.taxonomy_1) +
            '/taxonomy_two/' +
            validateInternalParameter(props.row.taxonomy_2)
          "
          >{{ props.row.taxonomy_2 }}</router-link
        >
      </o-table-column>

      <!-- Taxonomy 3 -->
      <o-table-column
        field="taxonomy_3"
        label="Taxonomy 3"
        sortable
        :visible="loadedTaxonomy > 2"
        v-slot="props"
      >
        <router-link
          :to="
            '/performance/taxonomy_one/' +
            validateInternalParameter(props.row.taxonomy_1) +
            '/taxonomy_two/' +
            validateInternalParameter(props.row.taxonomy_2) +
            '/taxonomy_three/' +
            validateInternalParameter(props.row.taxonomy_3)
          "
          >{{ props.row.taxonomy_3 }}</router-link
        >
      </o-table-column>

      <!-- Taxonomy 4 -->
      <o-table-column
        field="taxonomy_4"
        label="Taxonomy 4"
        sortable
        :visible="loadedTaxonomy > 3"
        v-slot="props"
        >{{ props.row.taxonomy_4 }}</o-table-column
      >

      <!-- Variable Cost -->
      <o-table-column
        field="variable_cost"
        :label="headers['variable_cost'] || 'Variable Cost'"
        sortable
        centered
        :visible="isColumnVisible('variable_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.variable_cost) }}</o-table-column
      >

      <!-- Fixed Cost -->
      <o-table-column
        field="fixed_cost"
        :label="headers['fixed_cost'] || 'Fixed Cost'"
        sortable
        centered
        :visible="isColumnVisible('fixed_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.fixed_cost) }}</o-table-column
      >

      <!-- Cost -->
      <o-table-column
        field="total_cost"
        :label="headers['total_cost'] || 'Total Cost'"
        sortable
        centered
        :visible="isColumnVisible('total_cost')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.total_cost) }}</o-table-column
      >

      <!-- Page Lands -->
      <o-table-column
        field="clicks"
        :label="headers['clicks'] || 'Page Lands'"
        sortable
        centered
        :visible="isColumnVisible('clicks')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.clicks) }}</o-table-column
      >

      <!-- Impressions -->
      <o-table-column
        field="impressions"
        :label="headers['impressions'] || 'Impressions'"
        sortable
        centered
        :visible="isColumnVisible('impressions')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.impressions) }}</o-table-column
      >

      <!-- First Click Sales -->
      <o-table-column
        field="first_click_sales"
        :label="headers['first_click_sales'] || 'First TouchSales'"
        sortable
        centered
        :visible="isColumnVisible('first_click_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.first_click_sales) }}</o-table-column
      >

      <!-- First Click Revenue -->
      <o-table-column
        field="first_click_revenue"
        :label="headers['first_click_revenue'] || 'First Touch Revenue'"
        sortable
        centered
        :visible="isColumnVisible('first_click_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.first_click_revenue) }}</o-table-column
      >

      <!-- First Click Conversion Rate -->
      <o-table-column
        field="first_click_conversion_rate"
        :label="headers['first_click_conversion_rate'] || 'First TouchConversion Rate'"
        sortable
        centered
        :visible="isColumnVisible('first_click_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.first_click_conversion_rate) }}%</o-table-column
      >

      <!-- First Click AOV-->
      <o-table-column
        field="first_click_aov"
        :label="headers['first_click_aov'] || 'First TouchAOV'"
        sortable
        centered
        :visible="isColumnVisible('first_click_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.first_click_aov) }}</o-table-column
      >

      <!-- Last Click Sales -->
      <o-table-column
        field="last_click_sales"
        :label="headers['last_click_sales'] || 'Last TouchSales'"
        sortable
        centered
        :visible="isColumnVisible('last_click_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.last_click_sales) }}</o-table-column
      >

      <!-- Last Click Revenue -->
      <o-table-column
        field="last_click_revenue"
        :label="headers['last_click_revenue'] || 'Last Touch Revenue'"
        sortable
        centered
        :visible="isColumnVisible('last_click_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.last_click_revenue) }}</o-table-column
      >

      <!-- Last Click Conversion Rate -->
      <o-table-column
        field="last_click_conversion_rate"
        :label="headers['last_click_conversion_rate'] || 'Last TouchConversion Rate'"
        sortable
        centered
        :visible="isColumnVisible('last_click_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.last_click_conversion_rate) }}%</o-table-column
      >

      <!-- Last Click AOV-->
      <o-table-column
        field="last_click_aov"
        :label="headers['last_click_aov'] || 'Last TouchAOV'"
        sortable
        centered
        :visible="isColumnVisible('last_click_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.last_click_aov) }}</o-table-column
      >

      <!-- Attributed Sales -->
      <o-table-column
        field="attributed_sales"
        :label="headers['attributed_sales'] || 'Attributed Sales'"
        sortable
        centered
        :visible="isColumnVisible('attributed_sales')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.attributed_sales) }}</o-table-column
      >

      <!-- Attributed Revenue -->
      <o-table-column
        field="attributed_revenue"
        :label="headers['attributed_revenue'] || 'Attributed Revenue'"
        sortable
        centered
        :visible="isColumnVisible('attributed_revenue')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.attributed_revenue) }}</o-table-column
      >

      <!-- Attributed Conversion Rate -->
      <o-table-column
        field="attributed_conversion_rate"
        :label="headers['attributed_conversion_rate'] || 'Attributed Conversion Rate'"
        sortable
        centered
        :visible="isColumnVisible('attributed_conversion_rate')"
        v-slot="props"
        >{{ displayNumericValue(props.row.attributed_conversion_rate) }}%</o-table-column
      >

      <!-- Attributed AOV-->
      <o-table-column
        field="attributed_aov"
        :label="headers['attributed_aov'] || 'Attributed AOV'"
        sortable
        centered
        :visible="isColumnVisible('attributed_aov')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.attributed_aov) }}</o-table-column
      >

      <!-- Attributed Revenue Variance -->
      <o-table-column
        field="attributed_revenue_variance"
        :label="headers['attributed_revenue_variance'] || 'Attributed Revenue Variance'"
        sortable
        centered
        :visible="isColumnVisible('attributed_revenue_variance')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.attributed_revenue_variance) }}</o-table-column
      >

      <!-- Attributed Revenue Variance Percent -->
      <o-table-column
        field="attributed_revenue_variance_percent"
        :label="
          headers['attributed_revenue_variance_percent'] ||
          'Attributed Revenue Variance Percent'
        "
        sortable
        centered
        :visible="isColumnVisible('attributed_revenue_variance_percent')"
        v-slot="props"
      >
        {{ displayNumericValueOneDp(props.row.attributed_revenue_variance_percent) }}%
      </o-table-column>

      <!-- Introducer Ratio -->
      <o-table-column
        field="introducer_ratio"
        :label="headers['introducer_ratio'] || 'Introducer Ratio'"
        sortable
        centered
        :visible="isColumnVisible('introducer_ratio')"
        v-slot="props"
        >{{ displayNumericValue(props.row.introducer_ratio) }}</o-table-column
      >

      <!-- CPA -->
      <o-table-column
        field="cpa"
        :label="headers['cpa'] || 'Attributed CPA'"
        sortable
        centered
        :visible="isColumnVisible('cpa')"
        v-slot="props"
        >{{ displayMonetaryValueRounded(props.row.cpa) }}</o-table-column
      >

      <!-- ROAS -->
      <o-table-column
        field="roas"
        :label="headers['roas'] || 'Attributed ROAS'"
        sortable
        centered
        :visible="isColumnVisible('roas')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.roas) }}</o-table-column
      >

      <!-- ROI -->
      <o-table-column
        field="roi"
        :label="headers['roi'] || 'ROI'"
        sortable
        centered
        :visible="isColumnVisible('roi')"
        v-slot="props"
        >{{ displayMonetaryValue(props.row.roi) }}</o-table-column
      >

      <!-- Sales Involved -->
      <o-table-column
        field="sales_including"
        :label="headers['sales_including'] || 'Sales Involved'"
        sortable
        centered
        :visible="isColumnVisible('sales_including')"
        v-slot="props"
        >{{ displayNumericValueRounded(props.row.sales_including) }}</o-table-column
      >

      <!-- Proportional Credit -->
      <o-table-column
        field="proportional_credit"
        :label="headers['proportional_credit'] || 'Attributed Sale Credit'"
        sortable
        centered
        :visible="isColumnVisible('proportional_credit')"
        v-slot="props"
        >{{ displayNumericValueOneDp(props.row.proportional_credit) }}</o-table-column
      >

      <template #footer>
        <!-- Total -->
        <th class="is-hidden-mobile" :colspan="loadedTaxonomy">
          <div class>Total</div>
        </th>
        <!-- Variable Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('variable_cost')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.variable_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Fixed Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('fixed_cost')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.fixed_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Cost -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('total_cost')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.total_cost;
                })
              )
            }}
          </div>
        </th>
        <!-- Page Lands -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('clicks')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.clicks;
                })
              )
            }}
          </div>
        </th>
        <!-- Impressions -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('impressions')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.impressions;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_sales')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.first_click_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_revenue')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.first_click_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- First Click Conversion Rate -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('first_click_conversion_rate')"
        >
          <div class="th-wrap is-centered">
            {{
              displayNumericValue(
                sumBy(this.filteredData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.filteredData, function (v) {
                      return v.first_click_sales;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- First Click AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_aov')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.data, function (v) {
                  return v.first_click_sales;
                }) !== 0
                  ? sumBy(this.filteredData, function (v) {
                      return v.first_click_revenue;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.first_click_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Last Click Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_sales')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.last_click_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- Last Click Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_revenue')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.last_click_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- Last Click Conversion Rate -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_conversion_rate')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValue(
                sumBy(this.filteredData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.filteredData, function (v) {
                      return v.last_click_sales;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- Last Click AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_aov')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.filteredData, function (v) {
                  return v.last_click_sales;
                }) !== 0
                  ? sumBy(this.filteredData, function (v) {
                      return v.last_click_revenue;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.last_click_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Attributed Sales -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_sales')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.attributed_sales;
                })
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_revenue')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.attributed_revenue;
                })
              )
            }}
          </div>
        </th>
        <!-- Attributed Conversion Rate -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_conversion_rate')">
          <div class="th-wrap is-centered">
            {{
              displayNumericValue(
                sumBy(this.filteredData, function (v) {
                  return v.clicks;
                }) !== 0
                  ? (sumBy(this.filteredData, function (v) {
                      return v.attributed_sales;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.clicks;
                      })) *
                      100
                  : 0
              )
            }}%
          </div>
        </th>
        <!-- Attributed AOV-->
        <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_aov')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.filteredData, function (v) {
                  return v.attributed_sales;
                }) !== 0
                  ? sumBy(this.filteredData, function (v) {
                      return v.attributed_revenue;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.attributed_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue Variance -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('attributed_revenue_variance')"
        >
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValueRounded(
                sumBy(this.filteredData, function (v) {
                  return v.attributed_revenue;
                }) -
                  sumBy(this.filteredData, function (v) {
                    return v.last_click_revenue;
                  })
              )
            }}
          </div>
        </th>
        <!-- Attributed Revenue Variance Percentage -->
        <th
          class="is-hidden-mobile"
          v-if="isColumnVisible('attributed_revenue_variance_percent')"
        >
          <div class="th-wrap is-centered">{{ displayNumericValueOneDp(0) }}%</div>
        </th>
        <!-- Introducer Ratio -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('introducer_ratio')">
          <div class="th-wrap is-centered">-</div>
        </th>
        <!-- CPA -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('cpa')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.filteredData, function (v) {
                  return v.attributed_sales;
                }) !== 0
                  ? sumBy(this.filteredData, function (v) {
                      return v.total_cost;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.attributed_sales;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- ROAS -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('roas')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.filteredData, function (v) {
                  return v.total_cost;
                }) !== 0
                  ? sumBy(this.filteredData, function (v) {
                      return v.attributed_revenue;
                    }) /
                      sumBy(this.filteredData, function (v) {
                        return v.total_cost;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- ROI -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('roi')">
          <div class="th-wrap is-centered">
            {{
              displayMonetaryValue(
                sumBy(this.filteredData, function (v) {
                  return v.total_cost;
                }) !== 0
                  ? (sumBy(this.filteredData, function (v) {
                      return v.attributed_revenue;
                    }) -
                      sumBy(this.filteredData, function (v) {
                        return v.total_cost;
                      })) /
                      sumBy(this.filteredData, function (v) {
                        return v.total_cost;
                      })
                  : 0
              )
            }}
          </div>
        </th>
        <!-- Sales Involved -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('sales_including')">
          <div class="th-wrap is-centered">
            <span>-</span>
          </div>
        </th>
        <!-- Proportional Credit -->
        <th class="is-hidden-mobile" v-if="isColumnVisible('proportional_credit')">
          <div class="th-wrap is-centered">
            <span>-</span>
          </div>
        </th>
      </template>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <template v-if="!loading">
              <p>
                <o-icon icon="emoticon-sad" size="is-large"></o-icon>
              </p>
              <p>There is no data to display</p>
            </template>
          </div>
        </section>
      </template>
    </o-table>
  </div>
</template>

<script>
import ConfigControls from "./Configuration/Controls";
import ExportData from "../../Modules/Pieces/ExportData";

import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseDateStore } from "../../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../../Modules/State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";
import { sumBy } from "lodash";
export default {
  components: { ConfigControls, ExportData },

  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgramName, getCurrentProgram } = storeToRefs(UseProgramStore());
    const {
      displayMonetaryValueRounded,
      displayNumericValueRounded,
      displayNumericValue,
      displayMonetaryValue,
      displayNumericValueOneDp,
    } = UseNumberHelper();
    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
      getCurrentProgramName,
      displayMonetaryValueRounded,
      displayNumericValueRounded,
      displayNumericValue,
      displayMonetaryValue,
      displayNumericValueOneDp,
      sumBy,
    };
  },
  computed: {
    concatMeEvan() {
      let rtn = [];

      if (this.loadedTaxonomy > 0) {
        rtn.push("taxonomy_1");
      }
      if (this.loadedTaxonomy > 1) {
        rtn.push("taxonomy_2");
      }
      if (this.loadedTaxonomy > 2) {
        rtn.push("taxonomy_3");
      }
      if (this.loadedTaxonomy > 3) {
        rtn.push("taxonomy_4");
      }

      return rtn;
    },
    displayedTaxonomies() {
      let rtn = [];
      let i;

      for (i = 1; i <= this.loadedTaxonomy; i++) {
        rtn.push("taxonomy_" + parseInt(i));
      }

      return rtn;
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/report/data/" +
        this.level +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    availableTaxonomyOnes() {
      return _.uniq(_.map(this.data, "taxonomy_1"));
    },
    configFilteredData() {
      let copy = this.data;

      return _.filter(
        copy,
        function (value) {
          let keep = true;

          this.rules.forEach(
            function (inner) {
              let rule = _.get(inner, "configuration");

              // Check that the rule isn't being edited
              if (rule.editing) {
                return;
              }

              // Check that the rule provided is a column that can be filtered
              if (_.includes(this.availableColumns, rule.metric)) {
                switch (rule.filter) {
                  case "=":
                    if (!(value[rule.metric] == rule.value)) {
                      keep = false;
                    }
                    break;
                  case "<":
                    if (!(value[rule.metric] < rule.value)) {
                      keep = false;
                    }
                    break;
                  case "<=":
                    if (!(value[rule.metric] <= rule.value)) {
                      keep = false;
                    }
                    break;
                  case ">":
                    if (!(value[rule.metric] > rule.value)) {
                      keep = false;
                    }
                    break;
                  case ">=":
                    if (!(value[rule.metric] >= rule.value)) {
                      keep = false;
                    }
                    break;
                }
              }

              if (!keep) {
                return;
              }
            }.bind(this)
          );

          return keep;
        }.bind(this)
      );
    },
    filteredData() {
      let copy = this.configFilteredData;

      return _.filter(
        copy,
        function (value) {
          return _.includes(this.filteredAvailableChannels, value.taxonomy_1);
        }.bind(this)
      );
    },
    sortedData() {
      return this.data
        ? _.orderBy(this.filteredData, [this.sortField], [this.sortOrder])
        : [];
    },
    exportData() {
      return this.sortedData.map((d) => ({
        ...d,
        first_click_conversion_rate: d.first_click_conversion_rate / 100,
        last_click_conversion_rate: d.last_click_conversion_rate / 100,
        attributed_conversion_rate: d.attributed_conversion_rate / 100,
        attributed_revenue_variance_percent: d.attributed_revenue_variance_percent / 100,
      }));
    },
    chunkedData() {
      return _.chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.chunkedData.length > 0 ? this.chunkedData[this.page - 1] : [];
    },
    reportName: {
      get() {
        return this.name;
      },
      set(val) {
        this.$emit("update:name", val);
      },
    },
    reportLevel: {
      get() {
        return this.level;
      },
      set(val) {
        this.$emit("update:level", val);
      },
    },
    reportRules: {
      get() {
        return this.rules;
      },
      set(val) {
        this.$emit("update:rules", val);
      },
    },
    filteredAvailableChannels: {
      get() {
        return this.channels;
      },
      set(val) {
        this.$emit("update:channels", val);
      },
    },
  },
  watch: {
    url() {
      if (_.includes(this.allowedLevels, this.level)) {
        this.loadAsyncData();
      }
    },
    filteredAvailableChannels(newVal) {
      _.forEach(this.availableChannels, function (value) {
        value.visible = false;
      });

      _.forEach(
        newVal,
        function (value) {
          let f = _.find(this.availableChannels, { name: value });

          f.visible = true;
        }.bind(this)
      );
    },
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    level: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
      required: false,
    },
    channels: {
      type: Array,
      required: false,
      default: () => [],
    },
    preview: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allowedLevels: ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"],
      loadedTaxonomy: 1,
      data: [],
      headers: {},
      columns: [],
      sortField: "taxonomy_1",
      sortOrder: "asc",
      perPage: 10,
      page: 1,
      availableColumns: [
        "variable_cost",
        "fixed_cost",
        "total_cost",
        "clicks",
        "impressions",
        "first_click_sales",
        "first_click_revenue",
        "first_click_conversion_rate",
        "first_click_aov",
        "last_click_sales",
        "last_click_revenue",
        "last_click_conversion_rate",
        "last_click_aov",
        "attributed_sales",
        "attributed_revenue",
        "attributed_conversion_rate",
        "attributed_aov",
        "attributed_revenue_variance",
        "attributed_revenue_variance_percent",
        "introducer_ratio",
        "cpa",
        "roas",
        "roi",
        "sales_including",
        "proportional_credit",
      ],
      availableChannels: [],
    };
  },
  methods: {
    loadAsyncData() {
      this.setLoading(true);
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data;

          let oldAvailableChannels = _.cloneDeep(this.availableChannels);

          this.availableChannels = _.map(
            _.uniq(_.map(data.data, "taxonomy_1")),
            function (value) {
              return {
                name: value,
                visible:
                  _.isEmpty(this.filteredAvailableChannels) &&
                  _.isEmpty(oldAvailableChannels)
                    ? true
                    : _.includes(this.filteredAvailableChannels, value) ||
                      _.get(
                        _.find(oldAvailableChannels, { name: value }),
                        "visible",
                        false
                      ),
              };
            }.bind(this)
          );
          this.filteredAvailableChannels = _.map(
            _.filter(this.availableChannels, (val) => val.visible),
            "name"
          );

          this.headers = data.column_headers;
          this.setLoading(false);
          this.loadedTaxonomy = this.level
            ? parseInt(this.level[this.level.length - 1])
            : 1;
        })
        .catch(() => {
          this.data = [];
          this.setLoading(false);
        });
    },
    isColumnVisible(column) {
      return _.includes(this.columns, column);
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    setDefaultColumns() {
      this.columns = [
        "total_cost",
        "clicks",
        "last_click_sales",
        "last_click_revenue",
        "attributed_sales",
        "attributed_revenue",
        "cpa",
        "roas",
        "attributed_revenue_variance_percent",
      ];
    },
    setLoading(value) {
      this.$emit("update:loading", value);
    },
  },
  mounted() {
    if (this.preview) {
      this.setDefaultColumns();
    } else {
      this.setDefaultColumns();
      this.loadAsyncData();
    }
  },
};
</script>
