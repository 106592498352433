<template>
  <!--<outer-section>-->
  <section class="section">
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div id="toc"></div>
            <vue-markdown
              :source="changelogSource"
              toc
              toc-id="toc"
              :toc-first-level="2"
              :toc-last-level="5"
            ></vue-markdown>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--</outer-section>-->
</template>

<script>
import ChangelogSource from "raw-loader!../../../../../changelog.md";

export default {
  title: "Changelog",
  data() {
    return {
      changelogSource: ChangelogSource,
    };
  },
};
</script>
