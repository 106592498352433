<template>
  <div>
    <!-- Default -->
    <o-field grouped group-multiline>
      <o-field grouped>
        <o-field>
          <p class="control">
            <o-select v-model="sortMetricCompute">
              <option v-for="metric in metrics" :value="metric" :key="metric">
                {{ headers[metric] || metric }}
              </option>
            </o-select>
          </p>
          <p class="control">
            <button class="button is-primary" @click="toggleSortDirection">
              <o-icon :icon="'sort-' + toggleSortDirectionIcon()"></o-icon>
            </button>
          </p>
        </o-field>
      </o-field>
      <o-field>
        <p class="control">
          <o-dropdown v-model="comparisonCompute" aria-role="list">
            <template #trigger>
              <button class="button" type="button" role="button" variant="primary">
                <template v-if="comparison === 'lcr'">
                  <span>Attributed Revenue vs Last Click Revenue</span>
                </template>
                <template v-else>
                  <span>Attributed Revenue vs Spend</span>
                </template>
                <o-icon icon="chevron-down" variant="primary"></o-icon>
              </button>
            </template>
            <o-dropdown-item :value="'lcr'" aria-role="menuitem">
              <span>Attributed Revenue vs Last Click Revenue</span>
            </o-dropdown-item>
            <o-dropdown-item :value="'cost'" aria-role="menuitem">
              <span>Attributed Revenue vs Spend</span>
            </o-dropdown-item>
          </o-dropdown>
        </p>
      </o-field>
      <NewPagination
        v-model:page="pageCompute"
        v-model:per-page="perPageCompute"
        :records="recordsCompute"
        :per-page-options="[8, 16, 32]"
      ></NewPagination>
    </o-field>
  </div>
</template>

<script>
import NewPagination from "../Breakdown/NewPagination";
export default {
  components: { NewPagination },
  computed: {
    comparisonCompute: {
      get() {
        return this.comparison;
      },
      set(val) {
        this.$emit("update:comparison", val);
      },
    },
    sortMetricCompute: {
      get() {
        return this.sortMetric;
      },
      set(val) {
        this.$emit("update:sortMetric", val);
      },
    },
    sortDirectionCompute: {
      get() {
        return this.sortDirection;
      },
      set(val) {
        this.$emit("update:sortDirection", val);
      },
    },
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    recordsCompute: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },
  },
  props: {
    headers: {
      type: Object,
      default: {},
    },
    metrics: {
      type: Array,
      default: [],
    },
    comparison: {
      type: String,
      default: "",
    },
    sortMetric: "",
    sortDirection: "",
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    records: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    toggleSortDirection() {
      if (this.sortDirectionCompute === "asc") {
        this.sortDirectionCompute = "desc";
      } else {
        this.sortDirectionCompute = "asc";
      }
    },
    toggleSortDirectionIcon() {
      return this.sortDirectionCompute === "asc" ? "ascending" : "descending";
    },
    previousPage() {
      this.pageCompute = this.page - 1;
    },
    nextPage() {
      this.pageCompute = this.page + 1;
    },
  },
};
</script>
