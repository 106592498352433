import { UseNumberHelper } from "../../../Helper/Functional/UseNumberHelper";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { UseReportSettingsStore } from "./../../../Modules/State/Stores/ReportSettingsStore";
export const UseAttributionComparison = () => {
  const { setAttributionSortMetric, setAttributionSortDirection } =
    UseReportSettingsStore();
  const { getAttributionSortMetric, getAttributionSortDirection } = storeToRefs(
    UseReportSettingsStore()
  );
  const { displayMonetaryValueRounded, displayNumericValueRounded } =
    UseNumberHelper();

  const page = ref(1);
  const perPage = ref(8);
  const comparison = ref("lcr");
  const firstMetric = ref("attributed_revenue");
  const secondMetric = ref("last_click_revenue");

  const sortMetric = computed({
    get() {
      return getAttributionSortMetric.value.startsWith("taxonomy")
        ? "taxonomy_1"
        : getAttributionSortMetric.value;
    },
    set(val: string) {
      setAttributionSortMetric(val);
    },
  });
  const sortMetricDirection = computed({
    get() {
      return getAttributionSortDirection.value;
    },
    set(val: string) {
      setAttributionSortDirection(val);
    },
  });

  const headers = computed(() => {
    return {
      taxonomy_1: "Name",
      attributed_revenue: "Attributed Revenue",
      last_click_revenue: "Last Touch Revenue",
      total_cost: "Spend",
    };
  });

  const monetaryMetrics = computed(() => {
    return ["total_cost", "attributed_revenue", "last_click_revenue"];
  });

  const fetchDifferenceMonetary = (comparison) => {
    if (!firstMetric.value || !secondMetric.value) {
      return [];
    }

    return displayMonetaryValueRounded(
      comparison[firstMetric.value] - comparison[secondMetric.value]
    );
  };
  const fetchDifferencePercentage = (comparison) => {
    return (
      displayNumericValueRounded(
        (comparison[firstMetric.value] /
          Math.max(1, comparison[secondMetric.value])) *
          100 -
          100
      ) + "%"
    );
  };

  const fetchDifferenceClass = (comparison) => {
    return comparison[firstMetric.value] - comparison[secondMetric.value] > 0
      ? "has-text-success"
      : "has-text-danger";
  };

  const makeMyData = (comparison) => {
    if (!firstMetric.value || !secondMetric.value) {
      return [];
    }

    return [
      {
        name: headers.value[firstMetric.value] || firstMetric.value,
        data: [comparison[firstMetric.value]],
      },
      {
        name: headers.value[secondMetric.value] || secondMetric.value,
        data: [comparison[secondMetric.value]],
      },
    ];
  };

  return {
    page,
    perPage,
    comparison,
    sortMetric,
    sortMetricDirection,
    firstMetric,
    secondMetric,
    headers,
    monetaryMetrics,
    fetchDifferenceClass,
    fetchDifferenceMonetary,
    fetchDifferencePercentage,
    makeMyData,
  };
};
