<template>
  <div class="tax-list-container" v-if="!reordering">
    <o-skeleton :active="!loaded" :animated="true" :count="6" height="45px"></o-skeleton>

    <div class="o-menu" :activable="false" v-if="loaded">
      <ul class="o-menu-list" label="Taxonomy List">
        <menu-item-with-buttons
          v-for="taxonomy in taxonomies"
          :key="taxonomy.id"
          :active="taxonomy.id === selectedTaxonomy"
          @click="$emit('update:selectedTaxonomy', taxonomy.id)"
        >
          <template #label>{{ taxonomy.title }}</template>
          <template #buttons>
            <icon-button
              class="delete-button"
              @click.stop="deleteTaxonomy(taxonomy.id)"
              label="Delete"
              icon="delete"
            />
          </template>
        </menu-item-with-buttons>
        <li v-if="addingTaxonomy">
          <form class="add-taxonomy-form" @submit.prevent="addTaxonomy">
            <o-field>
              <div class="field has-addons">
                <o-input
                  style="flex-grow: 1"
                  required
                  validation-message="Taxonomy name is required"
                  ref="addTaxonomyInput"
                  v-model="newTaxonomyName"
                  @keypress.esc="addingTaxonomy = false"
                />
                <div class="control">
                  <o-button
                    icon-right="close"
                    aria-label="Cancel"
                    @click="addingTaxonomy = false"
                  ></o-button>
                </div>
                <div class="control">
                  <o-button
                    variant="primary"
                    icon-right="check"
                    aria-label="Add"
                    native-type="submit"
                  ></o-button>
                </div>
              </div>
            </o-field>
          </form>
        </li>
      </ul>
    </div>

    <div class="tax-list-button-group" v-if="loaded && !addingTaxonomy">
      <o-button icon-left="swap-vertical" rounded @click="reordering = true"
        >Reorder</o-button
      >
      <o-button icon-left="plus" rounded @click="showAddTaxonomy">Add</o-button>
    </div>
  </div>

  <div class="tax-list-container" v-else>
    <div class="o-menu">
      <ul class="o-menu-list" label="Taxonomy List">
        <draggable
          :list="unsavedTaxonomyList"
          @start="dragging = true"
          @end="dragging = false"
          :item-key="unsavedTaxonomyList[0].toString()"
        >
          <template #item="{ element }">
            <menu-item-with-buttons style="cursor: grab" v-bind="$attrs">
              <template #label>{{ getTitle(element) }}</template>
              <template #buttons>
                <icon-button
                  label="Move Up"
                  icon="chevron-up"
                  :style="{
                    visibility: getIndex(element) === 0 ? 'hidden' : 'visible',
                  }"
                  @click="swapTaxonomies(getIndex(element), -1)"
                />
                <icon-button
                  label="Move Down"
                  icon="chevron-down"
                  :style="{
                    visibility:
                      getIndex(element) + 1 === taxonomies.length ? 'hidden' : 'visible',
                  }"
                  @click="swapTaxonomies(getIndex(element), 1)"
                />
              </template>
            </menu-item-with-buttons>
          </template>
        </draggable>
      </ul>
    </div>

    <div class="tax-list-button-group">
      <o-button icon-left="check" variant="primary" rounded @click="saveOrdering"
        >Done Ordering</o-button
      >
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import IconButton from "../../General/IconButton.vue";
import MenuItemWithButtons from "../../General/MenuItemWithButtons.vue";
import { UseClassificationStore } from "../../State/Stores/ClassificationStore";
import { storeToRefs } from "pinia";
import { UseClassification } from "../../../Pages/Admin/Hooks/UseClassification";

export default {
  components: { Draggable, IconButton, MenuItemWithButtons },
  computed: {
    taxonomies() {
      return this.getTaxonomies(this.unsavedTaxonomyList);
    },
  },
  setup() {
    const { loaded, taxonomyList, getTaxonomies } = storeToRefs(UseClassificationStore());
    const { setTaxonomyList } = UseClassificationStore();
    const { deleteTaxonomy, addTaxonomy: addNewTaxonomy } = UseClassification();
    return {
      loaded,
      taxonomyList,
      getTaxonomies,
      setTaxonomyList,
      deleteTaxonomy,
      addNewTaxonomy,
    };
  },
  data() {
    return {
      unsavedTaxonomyList: [],
      reordering: false,
      addingTaxonomy: false,
      newTaxonomyName: "",
      dragging: false,
    };
  },
  methods: {
    swapTaxonomies(index, offset) {
      const cloned = [...this.unsavedTaxonomyList];
      const newIndex = index + offset;

      if (newIndex < 0 || newIndex >= cloned.length) return;

      [cloned[index], cloned[index + offset]] = [cloned[index + offset], cloned[index]];

      this.unsavedTaxonomyList = cloned;
    },
    saveOrdering() {
      this.reordering = false;
      this.setTaxonomyList(this.unsavedTaxonomyList);
    },
    showAddTaxonomy() {
      this.newTaxonomyName = "";
      this.addingTaxonomy = true;
      this.$nextTick(() => {
        this.$refs.addTaxonomyInput.focus();
      });
    },
    addTaxonomy() {
      const id = this.addNewTaxonomy(this.newTaxonomyName);
      this.newTaxonomyName = "";
      this.addingTaxonomy = false;
      this.$emit("update:selectedTaxonomy", id);
    },
    getTitle(id = 0) {
      return this.taxonomies.find((item) => item.id === id).title;
    },
    getIndex(id = 0) {
      return this.taxonomies.findIndex((item) => item.id === id);
    },
  },
  props: {
    selectedTaxonomy: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    taxonomyList: {
      immediate: true,
      handler(value, oldValue) {
        if (value != oldValue) {
          this.unsavedTaxonomyList = value;
        }
      },
    },
  },
};
</script>

<style scoped>
.tax-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 2rem;
}

.tax-list-button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

:deep(.sortable-ghost > *) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #363636;
}

.add-taxonomy-form {
  margin: 1rem 0;
}

.delete-button {
  visibility: hidden;
}

li .active .delete-button,
li:hover .delete-button {
  visibility: visible;
}
</style>
