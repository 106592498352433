import { orderBy, chunk } from "lodash";
import { computed, ComputedRef, ref, Ref } from "vue";
import {
  TAllTableHeaders,
  TAllTableHeaderValues,
} from "../../../../../typings/DataFetching";

export const UsePerformancePeekTaxonomy = () => {
  //Peek Two data
  const peekTwoData = ref<TAllTableHeaderValues<TAllTableHeaders>[]>([]);
  const peekTwoComparisonData = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekTwoHeaders = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekTwoTaxonomy = ref("");
  //Peek Three data
  const peekThreeData = ref<TAllTableHeaderValues<TAllTableHeaders>[]>([]);
  const peekThreeComparisonData = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekThreeHeaders = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekThreeTaxonomy = ref("");
  //Peek Four data
  const peekFourData = ref<TAllTableHeaderValues<TAllTableHeaders>[]>([]);
  const peekFourComparisonData = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekFourHeaders = ref<TAllTableHeaders>({} as TAllTableHeaders);
  const peekFourTaxonomy = ref("");
  //Methods
  const sortedDataHook = (
    data: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,
    sortField: Ref<string>,
    sortOrder: Ref<string>
  ) => {
    return computed(() => {
      return data.value
        ? orderBy(
            data.value,
            [sortField.value],
            [sortOrder.value as "desc" | "asc"]
          )
        : [];
    });
  };
  const chunkedDataHook = (
    arr: ComputedRef<TAllTableHeaderValues<TAllTableHeaders>[]>
  ) => {
    return computed(() => {
      return chunk(arr.value, 10);
    });
  };

  return {
    peekTwoData,
    peekTwoComparisonData,
    peekTwoTaxonomy,
    peekTwoHeaders,
    peekThreeData,
    peekThreeComparisonData,
    peekThreeHeaders,
    peekThreeTaxonomy,
    peekFourData,
    peekFourComparisonData,
    peekFourHeaders,
    peekFourTaxonomy,
    sortedDataHook,
    chunkedDataHook,
  };
};
