<template>
  <o-modal
    v-model:active="isOpen"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :width="null"
  >
    <div class="modal-card" style="width: auto; min-with: 70vw">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirm Classification Changes</p>
      </header>
      <section class="modal-card-body">
        <template v-if="hasChanges">
          <div v-html="htmlDiff"></div>
        </template>
        <template v-else>
          <p>There have been no changes made to the classification</p>
        </template>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isOpen = false">
          Close
        </button>
        <o-button variant="primary" @click="save" :loading="isSaving">
          Confirm & Save
        </o-button>
      </footer>
    </div>
  </o-modal>
</template>

<script>
import * as Diff from "diff";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";

export default {
  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    initialData: {
      type: Array,
      required: true,
    },
    newData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
    initial() {
      return this.transformTaxonomyListToComparableArray(this.initialData).join(
        "\n"
      );
    },
    new() {
      return this.transformTaxonomyListToComparableArray(this.newData).join(
        "\n"
      );
    },
    diff() {
      return Diff.createTwoFilesPatch(
        "Old Rules vs New Rules",
        "Old Rules vs New Rules",
        this.initial,
        this.new
      );
    },
    htmlDiff() {
      return Diff2Html.html(this.diff, {
        drawFileList: false,
        matching: "words",
        outputFormat: "side-by-side",
        renderNothingWhenEmpty: true,
      });
    },
    hasChanges() {
      return this.initial !== this.new;
    },
  },
  methods: {
    save() {
      this.isSaving = true;
      this.$emit("save");
    },
    transformTaxonomyListToComparableArray(list) {
      let rtnarr = [];

      let rules = function (r) {
        return (
          "[" + r.field + "] [" + r.match_type + "] [" + r.expected_value + "]"
        );
      };
      let extracts = function (e) {
        return "[" + e.mapped_to + "] = [" + e.field + "]";
      };
      let child = function (c, level, append, arrAddition) {
        arrAddition.push(
          append +
            "Taxonomy " +
            level +
            ": " +
            c.title +
            " [" +
            c.join_type +
            "] - Execution order [" +
            c.execution_order +
            "]"
        );
        arrAddition.push(append + "  " + "Rules:");
        _.each(c.rules, function (r) {
          arrAddition.push(append + "    " + rules(r));
        });
        if (c.rules.length === 0) {
          arrAddition.push(append + "    " + "No rules at Taxonomy " + level);
        }

        _.each(c.children, function (cc) {
          child(cc, level + 1, "    " + append, arrAddition);
        });

        arrAddition.push(append + "  " + "Extracts:");
        _.each(c.extracts, function (e) {
          arrAddition.push(append + "    " + extracts(e));
        });
        if (c.extracts.length === 0) {
          arrAddition.push(
            append + "    " + "No extracts at Taxonomy " + level
          );
        }
      };

      _.each(list, function (t1) {
        child(t1, 1, "", rtnarr);
        rtnarr.push("---------- ---------- ---------- ---------- ----------");
      });

      return rtnarr;
    },
  },
  watch: {
    open() {
      this.isSaving = false;
    },
  },
};
</script>

<style></style>
