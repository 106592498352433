import { UseDateStore } from "./../../State/Stores/DateStore";
import { storeToRefs } from "pinia";
import { IComparisonDates } from "./../Typings/MetaTypes";
import { computed, ref, Ref } from "vue";
import moment from "moment";

const comparisonDates = ref<IComparisonDates>({
  start: "",
  end: "",
});
export const UseMetaComparison = () => {
  const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());

  const init = () => {
    let to = moment(getDateFrom.value).subtract(1, "day");
    let fromx = to.clone();
    fromx.subtract(
      moment(getDateTo.value).diff(moment(getDateFrom.value), "days"),
      "days"
    );

    let toyo = to.format("YYYY-MM-DD");
    let fromxyo = fromx.format("YYYY-MM-DD");

    if (toyo != comparisonDates.value.end) {
      comparisonDates.value.end = toyo;
    }

    if (fromxyo != comparisonDates.value.start) {
      comparisonDates.value.start = fromxyo;
    }
  };
  const comparisonConfigurationHandler = (
    cancelledName: Ref<string>,
    validName: Ref<string>
  ) =>
    computed(() => {
      return {
        cancellation: {
          top: {
            name: cancelledName.value,
            filter: { field: "status", operator: "=", value: "cancelled" },
          },
          bottom: {
            name: validName.value,
            filter: { field: "status", operator: "!=", value: "cancelled" },
          },
        },
      };
    });
  return {
    comparisonDates,
    init,
    comparisonConfigurationHandler,
  };
};
