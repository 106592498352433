<template>
  <outer-section>
    <div class="box">
      <o-loading :full-page="false" v-model:active="loading"></o-loading>

      <div>
        <h1 class="title has-text-grey-darker">Affiliate Performance</h1>

        <br />

        <o-field grouped group-multiline>
          <!-- Columns -->
          <p class="control">
            <button class="button" @click="isColumnModalActive = true">
              <o-icon icon="table-row-height"></o-icon>
              <span>Columns</span>
            </button>
          </p>
          <modal-template
            v-model:modalActive="isColumnModalActive"
            :width="960"
            :title="`Selected Columns (${columns.length})`"
            :columns="columns"
            :available-columns="availableColumns"
            :column-headers="exportColumnsHeaders"
          ></modal-template>
          <!-- End Columns -->

          <!-- Export -->
          <export-data
            :available-columns="exportColumns"
            :selected-columns="exportColumns"
            :always-selected-columns="exportAlwaysColumns"
            :column-headers="exportColumnsHeaders"
            :report-name="'Affiliate Report Export'"
            :exportable-data="exportData"
            :order-data-by="['taxonomy_1', 'taxonomy_2', 'taxonomy_3', 'taxonomy_4']"
            :order-data-direction="['asc', 'asc', 'asc', 'asc']"
          ></export-data>

          <!-- Pagination -->
          <new-pagination
            v-model:page="page"
            v-model:per-page="perPage"
            v-model:records="sortedData.length"
          ></new-pagination>
          <!-- End Pagination -->

          <!-- Search -->
          <o-field>
            <o-input
              placeholder="Search..."
              type="search"
              icon="magnify"
              v-model="searchTerm"
            ></o-input>
          </o-field>
          <!-- End Search -->
        </o-field>
      </div>

      <br />

      <o-table
        class="has-sticky-table-headers"
        ref="customReport2AwinAffiliateReportTable"
        hoverable
        :data="pageData"
        :default-sort-direction="sortOrder"
        :default-sort="[sortField, sortOrder]"
        backend-sorting
        @sort="onSort"
      >
        <!-- Affiliate ID -->
        <o-table-column
          field="publisher_id"
          label="Publisher ID"
          sortable
          :visible="isColumnVisible('publisher_id')"
          v-slot="props"
          >{{ props.row.publisher_id }}</o-table-column
        >

        <!-- Taxonomy 1 -->
        <o-table-column
          field="taxonomy_1"
          label="Taxonomy 1"
          sortable
          style="vertical-align: center"
          :visible="isColumnVisible('taxonomy_1')"
          v-slot="props"
        >
          <router-link
            :to="
              '/performance/taxonomy_one/' +
              validateInternalParameter(props.row.taxonomy_1)
            "
            >{{ props.row.taxonomy_1 }}</router-link
          >
        </o-table-column>

        <!-- Taxonomy 2 -->
        <o-table-column
          field="taxonomy_2"
          label="Taxonomy 2"
          sortable
          :visible="isColumnVisible('taxonomy_2')"
          v-slot="props"
        >
          <template v-if="props.row.taxonomy_2 == 'None'">{{
            props.row.taxonomy_2
          }}</template>
          <template v-else>
            <router-link
              :to="
                '/performance/taxonomy_one/' +
                validateInternalParameter(props.row.taxonomy_1) +
                '/taxonomy_two/' +
                validateInternalParameter(props.row.taxonomy_2)
              "
              >{{ props.row.taxonomy_2 }}</router-link
            >
          </template>
        </o-table-column>

        <!-- Taxonomy 3 -->
        <o-table-column
          field="taxonomy_3"
          label="Taxonomy 3"
          sortable
          :visible="isColumnVisible('taxonomy_3')"
          v-slot="props"
        >
          <template v-if="props.row.taxonomy_3 == 'None'">{{
            props.row.taxonomy_3
          }}</template>
          <template v-else>
            <router-link
              :to="
                '/performance/taxonomy_one/' +
                validateInternalParameter(props.row.taxonomy_1) +
                '/taxonomy_two/' +
                validateInternalParameter(props.row.taxonomy_2) +
                '/taxonomy_three/' +
                validateInternalParameter(props.row.taxonomy_3)
              "
              >{{ props.row.taxonomy_3 }}</router-link
            >
          </template>
        </o-table-column>

        <!-- Taxonomy 4 -->
        <o-table-column
          field="taxonomy_4"
          label="Taxonomy 4"
          sortable
          :visible="isColumnVisible('taxonomy_4')"
          v-slot="props"
        >
          <template v-if="props.row.taxonomy_4 == 'None'">{{
            props.row.taxonomy_4
          }}</template>
          <template v-else>
            <router-link
              :to="
                '/performance/taxonomy_one/' +
                validateInternalParameter(props.row.taxonomy_1) +
                '/taxonomy_two/' +
                validateInternalParameter(props.row.taxonomy_2) +
                '/taxonomy_three/' +
                validateInternalParameter(props.row.taxonomy_3) +
                '/taxonomy_four/' +
                validateInternalParameter(props.row.taxonomy_4)
              "
              >{{ props.row.taxonomy_4 }}</router-link
            >
          </template>
        </o-table-column>

        <!-- Variable Cost -->
        <o-table-column
          field="variable_cost"
          :label="headers['variable_cost'] || 'Variable Cost'"
          sortable
          centered
          :visible="isColumnVisible('variable_cost')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.variable_cost"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Fixed Cost -->
        <o-table-column
          field="fixed_cost"
          :label="headers['fixed_cost'] || 'Fixed Cost'"
          sortable
          centered
          :visible="isColumnVisible('fixed_cost')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.fixed_cost"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Cost -->
        <o-table-column
          field="total_cost"
          :label="headers['total_cost'] || 'Total Cost'"
          sortable
          centered
          :visible="isColumnVisible('total_cost')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.total_cost"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Page Lands -->
        <o-table-column
          field="clicks"
          :label="headers['clicks'] || 'Page Lands'"
          sortable
          centered
          :visible="isColumnVisible('clicks')"
          v-slot="props"
        >
          <performance-field :current="props.row.clicks" rounded></performance-field>
        </o-table-column>

        <!-- Impressions -->
        <o-table-column
          field="impressions"
          :label="headers['impressions'] || 'Impressions'"
          sortable
          centered
          :visible="isColumnVisible('impressions')"
          v-slot="props"
        >
          <performance-field :current="props.row.impressions" rounded></performance-field>
        </o-table-column>

        <!-- First Click Sales -->
        <o-table-column
          field="first_click_sales"
          :label="headers['first_click_sales'] || 'First Touch Sales'"
          sortable
          centered
          :visible="isColumnVisible('first_click_sales')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.first_click_sales"
            rounded
          ></performance-field>
        </o-table-column>

        <!-- First Click Revenue -->
        <o-table-column
          field="first_click_revenue"
          :label="headers['first_click_revenue'] || 'First Touch Revenue'"
          sortable
          centered
          :visible="isColumnVisible('first_click_revenue')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.first_click_revenue"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- First Click Conversion Rate -->
        <o-table-column
          field="first_click_conversion_rate"
          :label="headers['first_click_conversion_rate'] || 'First Touch Conversion Rate'"
          sortable
          centered
          :visible="isColumnVisible('first_click_conversion_rate')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.first_click_conversion_rate"
            percentage
          ></performance-field>
        </o-table-column>

        <!-- First Click AOV-->
        <o-table-column
          field="first_click_aov"
          :label="headers['first_click_aov'] || 'First Touch AOV'"
          sortable
          centered
          :visible="isColumnVisible('first_click_aov')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.first_click_aov"
            monetary
          ></performance-field>
        </o-table-column>

        <!-- Last Click Sales -->
        <o-table-column
          field="last_click_sales"
          :label="headers['last_click_sales'] || 'Last Touch Sales'"
          sortable
          centered
          :visible="isColumnVisible('last_click_sales')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.last_click_sales"
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Last Click Revenue -->
        <o-table-column
          field="last_click_revenue"
          :label="headers['last_click_revenue'] || 'Last Touch Revenue'"
          sortable
          centered
          :visible="isColumnVisible('last_click_revenue')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.last_click_revenue"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Last Click Conversion Rate -->
        <o-table-column
          field="last_click_conversion_rate"
          :label="headers['last_click_conversion_rate'] || 'Last Touch Conversion Rate'"
          sortable
          centered
          :visible="isColumnVisible('last_click_conversion_rate')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.last_click_conversion_rate"
            percentage
          ></performance-field>
        </o-table-column>

        <!-- Last Click AOV-->
        <o-table-column
          field="last_click_aov"
          :label="headers['last_click_aov'] || 'Last Touch AOV'"
          sortable
          centered
          :visible="isColumnVisible('last_click_aov')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.last_click_aov"
            monetary
          ></performance-field>
        </o-table-column>

        <!-- Attributed Sales -->
        <o-table-column
          field="attributed_sales"
          :label="headers['attributed_sales'] || 'Attributed Sales'"
          sortable
          centered
          :visible="isColumnVisible('attributed_sales')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_sales"
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Attributed Revenue -->
        <o-table-column
          field="attributed_revenue"
          :label="headers['attributed_revenue'] || 'Attributed Revenue'"
          sortable
          centered
          :visible="isColumnVisible('attributed_revenue')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_revenue"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Attributed Conversion Rate -->
        <o-table-column
          field="attributed_conversion_rate"
          :label="headers['attributed_conversion_rate'] || 'Attributed Conversion Rate'"
          sortable
          centered
          :visible="isColumnVisible('attributed_conversion_rate')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_conversion_rate"
            percentage
          ></performance-field>
        </o-table-column>

        <!-- Attributed AOV-->
        <o-table-column
          field="attributed_aov"
          :label="headers['attributed_aov'] || 'Attributed AOV'"
          sortable
          centered
          :visible="isColumnVisible('attributed_aov')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_aov"
            monetary
          ></performance-field>
        </o-table-column>

        <!-- Attributed Revenue Variance -->
        <o-table-column
          field="attributed_revenue_variance"
          :label="headers['attributed_revenue_variance'] || 'Attributed Revenue Variance'"
          sortable
          centered
          :visible="isColumnVisible('attributed_revenue_variance')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_revenue_variance"
            monetary
            rounded
          ></performance-field>
        </o-table-column>

        <!-- Attributed Revenue Variance Percent -->
        <o-table-column
          field="attributed_revenue_variance_percent"
          :label="
            headers['attributed_revenue_variance_percent'] ||
            'Attributed Revenue Variance Percent'
          "
          sortable
          centered
          :visible="isColumnVisible('attributed_revenue_variance_percent')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.attributed_revenue_variance_percent"
            percentage
            one-decimal
          ></performance-field>
        </o-table-column>

        <!-- Introducer Ratio -->
        <o-table-column
          field="introducer_ratio"
          :label="headers['introducer_ratio'] || 'Introducer Ratio'"
          sortable
          centered
          :visible="isColumnVisible('introducer_ratio')"
          v-slot="props"
        >
          <performance-field :current="props.row.introducer_ratio"></performance-field>
        </o-table-column>

        <!-- CPA -->
        <o-table-column
          field="cpa"
          :label="headers['cpa'] || 'Attributed CPA'"
          sortable
          centered
          :visible="isColumnVisible('cpa')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.cpa"
            monetary
            opposite
          ></performance-field>
        </o-table-column>

        <!-- ROAS -->
        <o-table-column
          field="roas"
          :label="headers['roas'] || 'Attributed ROAS'"
          sortable
          centered
          :visible="isColumnVisible('roas')"
          v-slot="props"
        >
          <performance-field :current="props.row.roas" monetary></performance-field>
        </o-table-column>

        <!-- ROI -->
        <o-table-column
          field="roi"
          :label="headers['roi'] || 'ROI'"
          sortable
          centered
          :visible="isColumnVisible('roi')"
          v-slot="props"
        >
          <performance-field :current="props.row.roi" monetary></performance-field>
        </o-table-column>

        <!-- Sales Involved -->
        <o-table-column
          field="sales_including"
          :label="headers['sales_including'] || 'Sales Involved'"
          sortable
          centered
          :visible="isColumnVisible('sales_including')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.sales_including"
            rounded
          ></performance-field>
        </o-table-column>
        <!-- Proportional Credit -->
        <o-table-column
          field="proportional_credit"
          :label="headers['proportional_credit'] || 'Attributed Sale Credit'"
          sortable
          centered
          :visible="isColumnVisible('proportional_credit')"
          v-slot="props"
        >
          <performance-field
            :current="props.row.proportional_credit"
            one-decimal
          ></performance-field>
        </o-table-column>

        <template #footer>
          <!-- Total -->
          <th class="is-hidden-mobile" :colspan="tableFooterColSpan">
            <div class>Total</div>
          </th>
          <!-- Variable Cost -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('variable_cost')">
            <performance-field
              :current="sumBy(data, 'variable_cost')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Fixed Cost -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('fixed_cost')">
            <performance-field
              :current="sumBy(data, 'fixed_cost')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Cost -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('total_cost')">
            <performance-field
              :current="sumBy(data, 'total_cost')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Page Lands -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('clicks')">
            <performance-field
              :current="sumBy(data, 'clicks')"
              rounded
            ></performance-field>
          </th>
          <!-- Impressions -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('impressions')">
            <performance-field
              :current="sumBy(data, 'impressions')"
              rounded
            ></performance-field>
          </th>
          <!-- First Click Sales -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_sales')">
            <performance-field
              :current="sumBy(data, 'first_click_sales')"
              rounded
            ></performance-field>
          </th>
          <!-- First Click Revenue -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_revenue')">
            <performance-field
              :current="sumBy(data, 'first_click_revenue')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- First Click Conversion Rate -->
          <th
            class="is-hidden-mobile"
            v-if="isColumnVisible('first_click_conversion_rate')"
          >
            <performance-field
              :current="
                sumBy(data, 'clicks') !== 0
                  ? (sumBy(data, 'first_click_sales') / sumBy(data, 'clicks')) * 100
                  : 0
              "
              percentage
            ></performance-field>
          </th>
          <!-- First Click AOV-->
          <th class="is-hidden-mobile" v-if="isColumnVisible('first_click_aov')">
            <performance-field
              :current="
                sumBy(data, 'first_click_sales') !== 0
                  ? sumBy(data, 'first_click_revenue') / sumBy(data, 'first_click_sales')
                  : 0
              "
              monetary
            ></performance-field>
          </th>
          <!-- Last Click Sales -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_sales')">
            <performance-field
              :current="sumBy(data, 'last_click_sales')"
              rounded
            ></performance-field>
          </th>
          <!-- Last Click Revenue -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_revenue')">
            <performance-field
              :current="sumBy(data, 'last_click_revenue')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Last Click Conversion Rate -->
          <th
            class="is-hidden-mobile"
            v-if="isColumnVisible('last_click_conversion_rate')"
          >
            <performance-field
              :current="
                sumBy(data, 'clicks') !== 0
                  ? (sumBy(data, 'last_click_sales') / sumBy(data, 'clicks')) * 100
                  : 0
              "
              percentage
            ></performance-field>
          </th>
          <!-- Last Click AOV-->
          <th class="is-hidden-mobile" v-if="isColumnVisible('last_click_aov')">
            <performance-field
              :current="
                sumBy(data, 'last_click_sales') !== 0
                  ? sumBy(data, 'last_click_revenue') / sumBy(data, 'last_click_sales')
                  : 0
              "
              monetary
            ></performance-field>
          </th>
          <!-- Attributed Sales -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_sales')">
            <performance-field
              :current="sumBy(data, 'attributed_sales')"
              rounded
            ></performance-field>
          </th>
          <!-- Attributed Revenue -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_revenue')">
            <performance-field
              :current="sumBy(data, 'attributed_revenue')"
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Attributed Conversion Rate -->
          <th
            class="is-hidden-mobile"
            v-if="isColumnVisible('attributed_conversion_rate')"
          >
            <performance-field
              :current="
                sumBy(data, 'clicks') !== 0
                  ? (sumBy(data, 'attributed_sales') / sumBy(data, 'clicks')) * 100
                  : 0
              "
              percentage
            ></performance-field>
          </th>
          <!-- Attributed AOV-->
          <th class="is-hidden-mobile" v-if="isColumnVisible('attributed_aov')">
            <performance-field
              :current="
                sumBy(data, 'attributed_sales') !== 0
                  ? sumBy(data, 'attributed_revenue') / sumBy(data, 'attributed_sales')
                  : 0
              "
              monetary
            ></performance-field>
          </th>
          <!-- Attributed Revenue Variance -->
          <th
            class="is-hidden-mobile"
            v-if="isColumnVisible('attributed_revenue_variance')"
          >
            <performance-field
              :current="
                sumBy(data, 'attributed_revenue') - sumBy(data, 'last_click_revenue')
              "
              monetary
              rounded
            ></performance-field>
          </th>
          <!-- Attributed Revenue Variance Percentage -->
          <th
            class="is-hidden-mobile"
            v-if="isColumnVisible('attributed_revenue_variance_percent')"
          >
            <performance-field
              :current="
                sumBy(data, 'last_click_revenue') !== 0
                  ? ((sumBy(data, 'attributed_revenue') -
                      sumBy(data, 'last_click_revenue')) /
                      sumBy(data, 'last_click_revenue')) *
                    100
                  : 0
              "
              percentage
              one-decimal
            ></performance-field>
          </th>
          <!-- Introducer Ratio -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('introducer_ratio')">
            <div class="th-wrap is-centered">-</div>
          </th>
          <!-- CPA -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('cpa')">
            <performance-field
              :current="
                sumBy(data, 'attributed_sales') !== 0
                  ? sumBy(data, 'total_cost') / sumBy(data, 'attributed_sales')
                  : 0
              "
              monetary
              opposite
            ></performance-field>
          </th>
          <!-- ROAS -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('roas')">
            <performance-field
              :current="
                sumBy(data, 'total_cost') !== 0
                  ? sumBy(data, 'attributed_revenue') / sumBy(data, 'total_cost')
                  : 0
              "
              monetary
            ></performance-field>
          </th>
          <!-- ROI -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('roi')">
            <performance-field
              :current="
                sumBy(data, 'total_cost') !== 0
                  ? (sumBy(data, 'attributed_revenue') - sumBy(data, 'total_cost')) /
                    sumBy(data, 'total_cost')
                  : 0
              "
              monetary
            ></performance-field>
          </th>
          <!-- Sales Involved -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('sales_including')">
            <div>
              <span class="th-wrap is-centered">-</span>
            </div>
          </th>
          <!-- Attributed Sale Credit -->
          <th class="is-hidden-mobile" v-if="isColumnVisible('proportional_credit')">
            <div>
              <span class="th-wrap is-centered">-</span>
            </div>
          </th>
        </template>
      </o-table>
    </div>
  </outer-section>
</template>

<script>
import ColumnSelection from "../../../Modules/Pieces/ColumnSelection";
import ExportData from "../../../Modules/Pieces/ExportData";
import NewPagination from "../../../Modules/Breakdown/NewPagination";
import OuterSection from "../../../Elements/OuterSection.vue";
import {
  sumBy,
  orderBy,
  filter,
  cloneDeep,
  chunk,
  map,
  get,
  assign,
  includes,
} from "lodash";
import { storeToRefs } from "pinia";
import { UseDateStore } from "../../State/Stores/DateStore";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import ModalTemplate from "../../Pieces/ModalTemplate.vue";
import { StaticColumnData } from "../../../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../../../Helper/Stateful/UseColumnModalHelper";

export default {
  components: {
    ColumnSelection,
    ExportData,
    NewPagination,
    OuterSection,
    ModalTemplate,
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram, getCurrentProgramName } = storeToRefs(UseProgramStore());
    const {
      awinReportColumns,
      availableColumns: awinAvailableColumns,
    } = StaticColumnData();
    const { columns, setColumns } = UseColumnModalHelper(awinReportColumns);

    const availableColumns = [
      "taxonomy_1",
      "taxonomy_2",
      "taxonomy_3",
      "taxonomy_4",
      ...awinAvailableColumns,
    ];
    UseAxiosErrorHelper();
    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
      getCurrentProgramName,
      columns,
      availableColumns,
      setColumns,
      sumBy,
    };
  },
  computed: {
    tableFooterColSpan() {
      let count = 1;
      ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"].forEach((el) => {
        if (this.columns.includes(el)) {
          count++;
        }
      });
      return count;
    },
    sortedData() {
      return this.data
        ? orderBy(
            filter(
              cloneDeep(this.data),
              function (value) {
                // return true;
                return value.search.includes(
                  this.searchTerm.replace(/\s+/g, "").toLowerCase()
                );
              }.bind(this)
            ),
            this.sortField,
            this.sortOrder
          )
        : [];
    },
    chunkedData() {
      return chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.data ? this.chunkedData[this.page - 1] : [];
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/named/" +
        "default" +
        "/determined/table/single/taxonomy_4/affiliate?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    exportData() {
      return this.sortedData.map((d) => ({
        ...d,
        first_click_conversion_rate: d.first_click_conversion_rate / 100,
        last_click_conversion_rate: d.last_click_conversion_rate / 100,
        attributed_conversion_rate: d.attributed_conversion_rate / 100,
        attributed_revenue_variance_percent: d.attributed_revenue_variance_percent / 100,
      }));
    },
    exportAlwaysColumns() {
      return ["publisher_id", "taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"];
    },
    exportColumns() {
      return [
        "publisher_id",
        "taxonomy_1",
        "taxonomy_2",
        "taxonomy_3",
        "taxonomy_4",
        "variable_cost",
        "fixed_cost",
        "total_cost",
        "clicks",
        "impressions",
        "last_click_sales",
        "last_click_revenue",
        "last_click_conversion_rate",
        "last_click_aov",
        "first_click_sales",
        "first_click_revenue",
        "first_click_conversion_rate",
        "first_click_aov",
        "attributed_sales",
        "attributed_revenue",
        "attributed_conversion_rate",
        "attributed_aov",
        "introducer_ratio",
        "cpa",
        "roi",
        "roas",
        "attributed_revenue_variance",
        "attributed_revenue_variance_percent",
        "sales_including",
        "proportional_credit",
      ];
    },
    exportColumnsHeaders() {
      return {
        publisher_id: "Publisher ID",
        taxonomy_1: "Taxonomy 1",
        taxonomy_2: "Taxonomy 2",
        taxonomy_3: "Taxonomy 3",
        taxonomy_4: "Taxonomy 4",
        variable_cost: "Variable Spend",
        fixed_cost: "Fixed Spend",
        total_cost: "Total Spend",
        clicks: "Page Lands",
        impressions: "Impressions",
        last_click_sales: "Last Touch Sales",
        last_click_revenue: "Last Touch Revenue",
        last_click_conversion_rate: "Last Touch Conversion Rate",
        last_click_aov: "Last Touch AOV",
        first_click_sales: "First Touch Sales",
        first_click_revenue: "First Touch Revenue",
        first_click_conversion_rate: "First Touch Conversion Rate",
        first_click_aov: "First Touch AOV",
        attributed_sales: "Attributed Sales",
        attributed_revenue: "Attributed Revenue",
        attributed_conversion_rate: "Attributed Conversion Rate",
        attributed_aov: "Attributed AOV",
        introducer_ratio: "Introducer Ratio",
        cpa: "CPA",
        roi: "ROI",
        roas: "ROAS",
        attributed_revenue_variance: "Attributed Revenue Variance",
        attributed_revenue_variance_percent: "Attributed Revenue Variance Percent",
        sales_including: "Sales Including",
        proportional_credit: "Attributed Sale Credit",
      };
    },
  },
  watch: {
    url() {
      this.loadAsyncData();
    },
  },
  data() {
    return {
      data: [],
      headers: {},
      total: 0,
      perPage: 15,
      page: 1,
      loading: false,
      isColumnModalActive: false,
      sortField: "attributed_revenue",
      sortOrder: "desc",
      comparing: false,
      comparisonData: {},
      searchTerm: "",
    };
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data;
          this.data = map(data.data, function (value) {
            let t =
              get(value, "publisher_id", "") +
              " " +
              get(value, "taxonomy_1", "") +
              " " +
              get(value, "taxonomy_2", "") +
              " " +
              get(value, "taxonomy_3", "") +
              " " +
              get(value, "taxonomy_4", "");
            assign(value, {
              search: t.replace(/\s+/g, "").toLowerCase(),
            });

            return value;
          });
          this.total = data.total;
          this.loading = false;
          this.page = 1;
        })
        .catch(() => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          this.page = 1;
        });
    },
    isColumnVisible(column) {
      return includes(this.columns, column);
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>
