<!--
Ordering Asc / Desc component
-->
<template>
  <button class="button is-primary" @click="toggleSortDirection">
    <o-icon :icon="'sort-' + toggleSortDirectionIcon()"></o-icon>
  </button>
</template>

<script>
export default {
  computed: {
    sortDirectionCompute: {
      get() {
        return this.sortDirection;
      },
      set(val) {
        this.$emit("update:sortDirection", val);
      },
    },
  },
  props: {
    sortDirection: {
      type: String,
      default: "asc",
    },
  },
  methods: {
    toggleSortDirection() {
      if (this.sortDirectionCompute === "asc") {
        this.sortDirectionCompute = "desc";
      } else {
        this.sortDirectionCompute = "asc";
      }
    },
    toggleSortDirectionIcon() {
      return this.sortDirectionCompute === "asc" ? "ascending" : "descending";
    },
  },
};
</script>
