<template>
  <apexchart
    :ref="'pieChart' + type"
    type="donut"
    :options="chartOptions"
    :series="series"
    height="250"
  ></apexchart>
</template>

<script>
import { storeToRefs } from "pinia";
import { UseMetricHelper } from "../../Helper/Functional/UseMetricHelper";
import { UseReportSettingsStore } from "../State/Stores/ReportSettingsStore";

export default {
  setup() {
    const { getMetaMetric } = storeToRefs(UseReportSettingsStore());
    const { fetchMetricValue } = UseMetricHelper();

    return {
      getMetaMetric,
      fetchMetricValue,
    };
  },
  props: {
    type: {
      type: String,
      default: "Current",
    },
    firstHalf: {
      type: Object,
      default: () => {},
    },
    secondHalf: {
      type: Object,
      default: () => {},
    },
    topName: {
      type: String,
      default: () => "",
    },
    bottomName: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    chartOptions() {
      let yaxis = [
        {
          show: true,
        },
      ];

      return {
        chart: {
          id: "pie-chart-" + this.type,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
          },
        },
        colors: ["#2799FF", "#36E7A6"],
        dataLabels: {
          enabled: false,
        },
        labels: [this.topName, this.bottomName],
        legend: {
          show: true,
          fontSize: "16px",
          position: "bottom",
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          itemMargin: {
            horizontal: 1,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "category",
          categories: ["abc", "def"],
        },
        yaxis: yaxis,
      };
    },
    series() {
      return [
        _.get(this.secondHalf, this.getMetaMetric, 0),
        _.get(this.firstHalf, this.getMetaMetric, 0),
      ];
    },
  },
};
</script>
