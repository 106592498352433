import { StaticColumnData } from "./../../StaticData/StaticColumnData";
import { UseStringHelper } from "./UseStringHelper";
import { UseMetricHelper } from "./UseMetricHelper";
import { computed, ComputedRef, Ref, WritableComputedRef } from "vue";
import { IGeTAllTableHeaders } from "./../../../typings/DataFetching";

const { convertNameToHandle } = UseStringHelper();
const { headersMetaTabularToConvert } = StaticColumnData();

export const UseColumnsHelper = () => {
  const exportAlwaysColumnsList = (taxonomy: Ref<number>) => [
    "taxonomy_" + taxonomy.value,
    "referral_date",
    "type",
  ];

  const exportSelectedColumnsList = (
    taxonomy: Ref<number>,
    columns: WritableComputedRef<string[]>,
    topName: Ref<string>,
    bottomName: Ref<string>
  ) => {
    return [
      "taxonomy_" + taxonomy.value,
      ...columns.value
        .map((col) => {
          if (headersMetaTabularToConvert.includes(col)) {
            return [
              col + "_" + convertNameToHandle(bottomName.value),
              col + "_" + convertNameToHandle(topName.value),
            ];
          } else {
            return col;
          }
        })
        .flatMap((x) => x),
    ];
  };
  const exportTabularHeadersList = (
    taxonomy: Ref<number>,
    headersMetaTabular: ComputedRef<Partial<IGeTAllTableHeaders>>
  ) => {
    return {
      ["taxonomy_" + taxonomy.value]: "Taxonomy",
      ...headersMetaTabular.value,
    };
  };
  const exportTabularColumnsList = (
    taxonomy: Ref<number>,
    headersMetaTabular: ComputedRef<Partial<IGeTAllTableHeaders>>
  ) => {
    return [
      "taxonomy_" + taxonomy.value,
      ...Object.keys(headersMetaTabular.value),
    ];
  };
  const headersKeyPair = (topName: Ref<string>, bottomName: Ref<string>) =>
    computed(() => ({
      variable_cost: "Variable Spend",
      fixed_cost: "Fixed Spend",
      total_cost: "Total Spend",
      clicks: "Page Lands",
      impressions: "Impressions",
      ["first_click_sales_" + convertNameToHandle(bottomName.value)]:
        "First Touch Sales " + bottomName.value,
      ["first_click_sales_" + convertNameToHandle(topName.value)]:
        "First Touch Sales " + topName.value,
      ["first_click_revenue_" + convertNameToHandle(bottomName.value)]:
        "First Touch Revenue " + bottomName.value,
      ["first_click_revenue_" + convertNameToHandle(topName.value)]:
        "First Touch Revenue " + topName.value,
      ["first_click_conversion_rate_" + convertNameToHandle(bottomName.value)]:
        "First Touch Conversion Rate " + bottomName.value,
      ["first_click_conversion_rate_" + convertNameToHandle(topName.value)]:
        "First Touch Conversion Rate " + topName.value,
      ["first_click_aov_" + convertNameToHandle(bottomName.value)]:
        "First Touch AOV " + bottomName.value,
      ["first_click_aov_" + convertNameToHandle(topName.value)]:
        "First Touch AOV " + topName.value,
      ["last_click_sales_" + convertNameToHandle(bottomName.value)]:
        "Last Touch Sales " + bottomName.value,
      ["last_click_sales_" + convertNameToHandle(topName.value)]:
        "Last Touch Sales " + topName.value,
      ["last_click_revenue_" + convertNameToHandle(bottomName.value)]:
        "Last Touch Revenue " + bottomName.value,
      ["last_click_revenue_" + convertNameToHandle(topName.value)]:
        "Last Touch Revenue " + topName.value,
      ["last_click_conversion_rate_" + convertNameToHandle(bottomName.value)]:
        "Last Touch Conversion Rate " + bottomName.value,
      ["last_click_conversion_rate_" + convertNameToHandle(topName.value)]:
        "Last Touch Conversion Rate " + topName.value,
      ["last_click_aov_" + convertNameToHandle(bottomName.value)]:
        "Last Touch AOV " + bottomName.value,
      ["last_click_aov_" + convertNameToHandle(topName.value)]:
        "Last Touch AOV " + topName.value,
      ["attributed_sales_" + convertNameToHandle(bottomName.value)]:
        "Attributed Sales " + bottomName.value,
      ["attributed_sales_" + convertNameToHandle(topName.value)]:
        "Attributed Sales " + topName.value,
      ["attributed_revenue_" + convertNameToHandle(bottomName.value)]:
        "Attributed Revenue " + bottomName.value,
      ["attributed_revenue_" + convertNameToHandle(topName.value)]:
        "Attributed Revenue " + topName.value,
      ["attributed_conversion_rate_" + convertNameToHandle(bottomName.value)]:
        "Attributed Conversion Rate " + bottomName.value,
      ["attributed_conversion_rate_" + convertNameToHandle(topName.value)]:
        "Attributed Conversion Rate " + topName.value,
      ["attributed_aov_" + convertNameToHandle(bottomName.value)]:
        "Attributed AOV " + bottomName.value,
      ["attributed_aov_" + convertNameToHandle(topName.value)]:
        "Attributed AOV " + topName.value,
      ["attributed_revenue_variance_" + convertNameToHandle(bottomName.value)]:
        "Attributed Revenue Variance " + bottomName.value,
      ["attributed_revenue_variance_" + convertNameToHandle(topName.value)]:
        "Attributed Revenue Variance " + topName.value,
      ["attributed_revenue_variance_percent_" +
      convertNameToHandle(bottomName.value)]:
        "Attributed Revenue Variance Percent " + bottomName.value,
      ["attributed_revenue_variance_percent_" +
      convertNameToHandle(topName.value)]:
        "Attributed Revenue Variance Percent " + topName.value,
      ["introducer_ratio_" + convertNameToHandle(bottomName.value)]:
        "Introducer Ratio " + bottomName.value,
      ["introducer_ratio_" + convertNameToHandle(topName.value)]:
        "Introducer Ratio " + topName.value,
      ["cpa_" + convertNameToHandle(bottomName.value)]:
        "Attributed CPA " + bottomName.value,
      ["cpa_" + convertNameToHandle(topName.value)]:
        "Attributed CPA " + topName.value,
      ["roas_" + convertNameToHandle(bottomName.value)]:
        "Attributed ROAS " + bottomName.value,
      ["roas_" + convertNameToHandle(topName.value)]:
        "Attributed ROAS " + topName.value,
      ["roi_" + convertNameToHandle(bottomName.value)]:
        "ROI " + bottomName.value,
      ["roi_" + convertNameToHandle(topName.value)]: "ROI " + topName.value,
      ["sales_including_" + convertNameToHandle(bottomName.value)]:
        "Sales Involving " + bottomName.value,
      ["sales_including_" + convertNameToHandle(topName.value)]:
        "Sales Involving " + topName.value,
      ["proportional_credit_" + convertNameToHandle(bottomName.value)]:
        "Attributed Sale " + bottomName.value,
      ["proportional_credit_" + convertNameToHandle(topName.value)]:
        "Attributed Sale " + topName.value,
    }));
  const headersKeyPairArray = (topName: string, bottomName: string) =>
    computed(() => [
      "first_click_conversion_rate_" + convertNameToHandle(bottomName),
      "first_click_conversion_rate_" + convertNameToHandle(topName),
      "last_click_conversion_rate_" + convertNameToHandle(bottomName),
      "last_click_conversion_rate_" + convertNameToHandle(topName),
      "attributed_conversion_rate_" + convertNameToHandle(bottomName),
      "attributed_conversion_rate_" + convertNameToHandle(topName),
      "attributed_revenue_variance_" + convertNameToHandle(bottomName),
      "attributed_revenue_variance_" + convertNameToHandle(topName),
      "attributed_revenue_variance_percent_" + convertNameToHandle(bottomName),
      "attributed_revenue_variance_percent_" + convertNameToHandle(topName),
      "introducer_ratio_" + convertNameToHandle(bottomName),
      "introducer_ratio_" + convertNameToHandle(topName),
      "cpa_" + convertNameToHandle(bottomName),
      "cpa_" + convertNameToHandle(topName),
      "roas_" + convertNameToHandle(bottomName),
      "roas_" + convertNameToHandle(topName),
      "roi_" + convertNameToHandle(bottomName),
      "roi_" + convertNameToHandle(topName),
      "proportional_credit_" + convertNameToHandle(bottomName),
      "proportional_credit_" + convertNameToHandle(topName),
    ]);
  const standardColumnKeysForAveraging = [
    "first_click_conversion_rate",
    "first_click_conversion_rate",
    "last_click_conversion_rate",
    "last_click_conversion_rate",
    "attributed_conversion_rate",
    "attributed_conversion_rate",
    "attributed_revenue_variance",
    "attributed_revenue_variance",
    "attributed_revenue_variance_percent",
    "attributed_revenue_variance_percent",
    "introducer_ratio",
    "introducer_ratio",
    "cpa",
    "cpa",
    "roas",
    "roas",
    "roi",
    "roi",
    "proportional_credit",
    "proportional_credit",
  ];
  const isColumnVisible = (column: string, columns: string[]) => {
    return columns.some((col) => col === column);
  };
  const isColumnAvailable = (column: string, excludedColumns: string[]) => {
    return !excludedColumns.includes(column);
  };

  /**
   * Using with Oruga event, sort. This will automatically pass two parameters to the function: field and order.
   * @param fieldRef Ref<string>
   * @param orderRef Ref<string>
   */
  const onSort =
    (fieldRef: Ref<string>, orderRef: Ref<string>) =>
    (field: string, order: string) => {
      fieldRef.value = field;
      orderRef.value = order;
    };

  const { fetchMetricValue } = UseMetricHelper();

  const calculateTotalsMetaTable = <T>(
    arr: T[],
    key: string,
    bottomName: string,
    topName: string,
    av = false
  ) => {
    const total = arr
      .map((item) => item[key])
      ?.reduce((acc, cur) => {
        return acc + cur;
      }, 0);

    if (!isNaN(total)) {
      return fetchMetricValue(
        key,
        av ? total / arr.length : total,
        convertNameToHandle(bottomName),
        convertNameToHandle(topName)
      );
    }
    return "-";
  };

  const findAverageKey = (key: string, arr: string[]) => {
    const keyFound = arr.find((col: string) => col === key);

    if (keyFound?.length) {
      return true;
    }
    return false;
  };

  return {
    standardColumnKeysForAveraging,
    exportAlwaysColumnsList,
    exportSelectedColumnsList,
    exportTabularHeadersList,
    exportTabularColumnsList,
    headersKeyPair,
    headersKeyPairArray,
    isColumnVisible,
    isColumnAvailable,
    calculateTotalsMetaTable,
    findAverageKey,
    onSort,
  };
};
