import { UseMetaWritableComputed } from "./UseMetaWritableComputed";
import {
  IGroupedData,
  ITabledData,
  TMetaDataShapeOne,
  TMetaDataShapeTwo,
  TMetaMatched,
} from "./../Typings/MetaTypes";
import { IGeTAllTableHeaders } from "../../../../typings/DataFetching";
import {
  forEach,
  get,
  sum,
  flatten,
  map,
  cloneDeep,
  orderBy,
  uniq,
  keyBy,
  find,
  defaults,
  min,
  max,
} from "lodash";
import { computed, Ref } from "vue";

interface IProps {
  groupedData: Ref<IGroupedData>;
  data: Ref<TMetaDataShapeOne | TMetaDataShapeTwo<IGeTAllTableHeaders>[][]>;
  comparisonData: Ref<TMetaDataShapeOne>;
  taxonomy: Ref<number>;
}
export const UseMetaDataManipulation = ({
  groupedData,
  data,
  comparisonData,
  taxonomy,
}: IProps) => {
  const { sortField, sortOrder, metaMetric, metaComparison } =
    UseMetaWritableComputed();

  //Dry Functions
  const dryFunctionMinMax = (m: number[]) => {
    forEach(matched.value, function (x) {
      forEach(x?.data, function (inner) {
        m.push(
          get(inner, ["bottom", metaMetric.value], 0),
          get(inner, ["top", metaMetric.value], 0)
        );
      });
    });
    return m;
  };
  const dryFunctionFlattenAndSum = () => {
    return (
      sum(
        flatten(
          map(tabled.value, (x) => [
            get(x, "data.p1x1.attributed_sales", 0),
            get(x, "data.p1x1.last_click_sales", 0),
            get(x, "data.p1x2.attributed_sales", 0),
            get(x, "data.p1x2.last_click_sales", 0),
          ])
        )
      ) !== 0
    );
  };

  const tabled = computed((): ITabledData[] => {
    if (groupedData.value && groupedData.value.p1x1) {
      return map(groupedData.value.p1x1, (v): ITabledData => {
        return v
          ? {
              name: v["taxonomy_" + taxonomy.value],
              data: {
                p1x1: v,
                p1x2: get(
                  groupedData.value.p1x2,
                  v["taxonomy_" + taxonomy.value],
                  {}
                ),
                p2x1: get(
                  groupedData.value.p2x1,
                  v["taxonomy_" + taxonomy.value],
                  {}
                ),
                p2x2: get(
                  groupedData.value.p2x2,
                  v["taxonomy_" + taxonomy.value],
                  {}
                ),
                bottom: data.value,
                top: comparisonData.value,
              },
            }
          : ({} as ITabledData);
      });
    }
    return [];
  });
  const sortedTabled = computed(() => {
    let deep = cloneDeep(tabled.value);
    if (deep.length) {
      let abc = sortField.value.includes("taxonomy_")
        ? "name"
        : sortField.value.split(".");
      let order = sortOrder.value;

      return tabled.value.length
        ? orderBy(deep, [(val) => get(val, abc, 0)], order as "desc" | "asc")
        : [];
    }
    return [];
  });

  const allDates = computed(() => {
    return uniq(
      flatten(
        map(
          data.value,
          function (inner: TMetaDataShapeTwo<IGeTAllTableHeaders>[]) {
            return map(inner, (x) => x["referral_date"]);
          }
        )
      )
    );
  });
  const matched = computed((): TMetaMatched => {
    return comparisonData.value && data.value
      ? keyBy(
          map(
            keyBy(
              map(
                data.value,
                function (val: TMetaDataShapeTwo<IGeTAllTableHeaders>[]) {
                  return map(
                    val,
                    function (inner: TMetaDataShapeTwo<IGeTAllTableHeaders>) {
                      return {
                        bottom: inner,
                        top: defaults(
                          find(
                            get(
                              comparisonData.value,
                              [inner["taxonomy_" + taxonomy.value]],
                              []
                            ),
                            ["referral_date", inner.referral_date]
                          ),
                          {}
                        ),
                      };
                    }
                  );
                }
              ),
              (x) =>
                get(x, [0, "bottom", "taxonomy_" + taxonomy.value]) ||
                get(x, [0, "top", "taxonomy_" + taxonomy.value])
            ),
            function (val, index) {
              return {
                name: index,
                data: val,
              };
            }
          ),
          "name"
        )
      : {};
  });
  const overallMax = computed(() => {
    let m = [1];
    return max(dryFunctionMinMax(m));
  });
  const overallMin = computed(() => {
    let m = [0];
    return min(dryFunctionMinMax(m));
  });
  const dataLooksLikeItExists = computed(() => {
    if (metaComparison.value === "cancellation") {
      return (
        sum(
          flatten(
            map(tabled.value, (x) => [
              get(x, "data.p1x2.attributed_sales", 0),
              get(x, "data.p1x2.last_click_sales", 0),
            ])
          )
        ) !== 0
      );
    } else if (metaComparison.value === "customer") {
      return dryFunctionFlattenAndSum();
    } else if (metaComparison.value === "payment") {
      return dryFunctionFlattenAndSum();
    } else {
      return false;
    }
  });

  return {
    tabled,
    sortedTabled,
    allDates,
    matched,
    overallMax,
    overallMin,
    dataLooksLikeItExists,
  };
};
