<template>
  <p class="control" @click="trigger">
    <slot name="trigger">
      <button class="button" :disabled="disabled">
        <o-icon icon="file-export"></o-icon>
        <span>Export Data</span>
      </button>
    </slot>
  </p>
</template>

<script>
import ExportDataModalComponent from "./ExportDataModalComponent";

export default {
  components: { ExportDataModalComponent },
  data() {
    return {
      open: false,
    };
  },
  props: {
    comparisonData: {
      type: Object,
      default: null
    },
    showComparison: {
      type: Boolean,
      default: false
    },
    taxonomyFilter: {
      type: Boolean,
      default: false,
    },
    taxonomyFilterDates: {
      type: Object,
      default: () => {},
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    selectedColumns: {
      type: Array,
      default: () => [],
    },
    alwaysSelectedColumns: {
      type: Array,
      default: () => ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"],
    },
    columnHeaders: {
      type: Object,
      default: () => {},
    },
    reportName: {
      type: String,
      default: "",
    },
    exportableData: {
      type: Array,
      default: () => [],
    },
    orderDataBy: {
      type: [String, Array],
      default: "taxonomy_1",
    },
    orderDataDirection: {
      type: [String, Array],
      default: "asc",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trigger() {
      this.$oruga.modal.open({
        component: ExportDataModalComponent,
        trapFocus: true,
        props: {
          taxonomyFilter: this.taxonomyFilter,
          taxonomyFilterDates: this.taxonomyFilterDates,
          availableColumns: this.availableColumns,
          selectedColumns: this.selectedColumns,
          alwaysSelectedColumns: this.alwaysSelectedColumns,
          columnHeaders: this.columnHeaders,
          reportName: this.reportName,
          exportableData: this.exportableData,
          orderDataBy: this.orderDataBy,
          orderDataDirection: this.orderDataDirection,
          comparisonData: this.comparisonData,
          showComparison: this.showComparison
        },
      });
    },
  },
};
</script>
