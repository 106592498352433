import {
  IGetAllColumns,
  TAllTableHeaderValues,
} from "../../../../typings/DataFetching";
import { IAxiosDataShape } from "./../../../../generics/generics";
import {
  IGroupedData,
  TMetaDataShapeOne,
  TMetaDataShapeTwo,
} from "./../Typings/MetaTypes";
import {
  TComparisonDataFirstProps,
  IGeTAllTableHeaders,
} from "./../../Performance/Table/Typings/TableTypes";

import { ref, Ref, ComputedRef } from "vue";
import axios from "axios";
import { chain, cloneDeep, map } from "lodash";

interface IProps {
  taxonomy: Ref<number>;
  p1x1Url: ComputedRef<string>;
  p1x2Url: ComputedRef<string>;
  p2x1Url: ComputedRef<string>;
  p2x2Url: ComputedRef<string>;
  bottomUrl: ComputedRef<string>;
  topUrl: ComputedRef<string>;
  useNewDataSource: Ref<boolean>;
}

interface IModulesLoading {
  top: boolean;
  bottom: boolean;
  p1x1: boolean;
  p1x2: boolean;
  p2x1: boolean;
  p2x2: boolean;
}

interface IClonedData {
  data: IGeTAllTableHeaders[];
}

export const modulesLoading = ref<IModulesLoading>({
  top: false,
  bottom: false,
  p1x1: false,
  p1x2: false,
  p2x1: false,
  p2x2: false,
});

const groupedData = ref<IGroupedData>({} as IGroupedData);
const data = ref<
  TMetaDataShapeOne | TMetaDataShapeTwo<IGeTAllTableHeaders>[][]
>({} as TMetaDataShapeOne | TMetaDataShapeTwo<IGeTAllTableHeaders>[][]);
const comparisonData = ref<TMetaDataShapeOne>({} as TMetaDataShapeOne);
export const UseMetaFetchData = ({
  taxonomy,
  bottomUrl,
  topUrl,
  p1x1Url,
  p1x2Url,
  p2x1Url,
  p2x2Url,
  useNewDataSource,
}: IProps) => {
  const allDataIsLoaded = ref(false);

  //Dry functions
  const dryFunctionSetCorrected = <
    T extends
      | IAxiosDataShape<TComparisonDataFirstProps[], IGetAllColumns>
      | IAxiosDataShape<TAllTableHeaderValues<IGetAllColumns>[], IGetAllColumns>
  >(
    dataTee: T
  ) => {
    return chain(cloneDeep(dataTee.data))
      .keyBy("name")
      .mapValues(function (v: IClonedData) {
        return map(v.data, function (inner) {
          inner.referral_date = inner.conversion_date;
          return inner;
        });
      })
      .value();
  };

  const loadp1x1 = () => {
    modulesLoading.value.p1x1 = true;
    axios
      .get<IAxiosDataShape<TComparisonDataFirstProps[], IGetAllColumns>>(
        p1x1Url.value
      )
      .then(({ data: fetchedP1x1 }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = chain(cloneDeep(fetchedP1x1.data))
            .keyBy("taxonomy_" + taxonomy.value)
            .value();
        } else {
          corrected = fetchedP1x1.data;
        }

        groupedData.value.p1x1 = corrected;
        modulesLoading.value.p1x1 = false;
      })
      .catch(() => {
        groupedData.value.p1x1 = {};
        modulesLoading.value.p1x1 = false;
      });
  };

  const loadp1x2 = async () => {
    modulesLoading.value.p1x2 = true;
    axios
      .get<
        IAxiosDataShape<TAllTableHeaderValues<IGetAllColumns>[], IGetAllColumns>
      >(p1x2Url.value)
      .then(({ data: fetchedP1x2 }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = chain(cloneDeep(fetchedP1x2.data))
            .keyBy("taxonomy_" + taxonomy.value)
            .value();
        } else {
          corrected = fetchedP1x2.data;
        }
        groupedData.value.p1x2 = corrected;
        modulesLoading.value.p1x2 = false;
      })
      .catch(() => {
        groupedData.value.p1x2 = {};
        modulesLoading.value.p1x2 = false;
      });
  };

  const loadp2x1 = async () => {
    modulesLoading.value.p2x1 = true;
    axios
      .get<
        IAxiosDataShape<TAllTableHeaderValues<IGetAllColumns>[], IGetAllColumns>
      >(p2x1Url.value)
      .then(({ data: fetchedP2x1 }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = chain(cloneDeep(fetchedP2x1.data))
            .keyBy("taxonomy_" + taxonomy.value)
            .value();
        } else {
          corrected = fetchedP2x1.data;
        }

        groupedData.value.p2x1 = corrected;
        modulesLoading.value.p2x1 = false;
      })
      .catch(() => {
        groupedData.value.p2x1 = {};
        modulesLoading.value.p2x1 = false;
      });
  };

  const loadp2x2 = async () => {
    modulesLoading.value.p2x2 = true;
    axios
      .get<IAxiosDataShape<TComparisonDataFirstProps[], IGetAllColumns>>(
        p2x2Url.value
      )
      .then(({ data: fetchedP2x2 }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = chain(cloneDeep(fetchedP2x2.data))
            .keyBy("taxonomy_" + taxonomy.value)
            .value();
        } else {
          corrected = fetchedP2x2.data;
        }
        groupedData.value.p2x2 = corrected;
        modulesLoading.value.p2x2 = false;
      })
      .catch(() => {
        groupedData.value.p2x2 = {};
        modulesLoading.value.p2x2 = false;
      });
  };

  const loadAsyncData = async () => {
    modulesLoading.value.bottom = true;
    axios
      .get<IAxiosDataShape<TComparisonDataFirstProps[], IGetAllColumns>>(
        bottomUrl.value
      )
      .then(({ data: resData }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = dryFunctionSetCorrected(resData);
        } else {
          corrected = resData.data;
        }
        data.value = corrected as
          | TMetaDataShapeOne
          | TMetaDataShapeTwo<IGeTAllTableHeaders>[][];
        modulesLoading.value.bottom = false;
      })
      .catch(() => {
        data.value = [];

        modulesLoading.value.bottom = false;
      });
  };

  const loadComparisonData = async () => {
    modulesLoading.value.top = true;
    axios
      .get<
        IAxiosDataShape<TAllTableHeaderValues<IGetAllColumns>[], IGetAllColumns>
      >(topUrl.value)
      .then(({ data: fetchedComparisonData }) => {
        let corrected = {};

        if (useNewDataSource.value) {
          corrected = dryFunctionSetCorrected(fetchedComparisonData);
        } else {
          corrected = fetchedComparisonData.data;
        }

        comparisonData.value = corrected as TMetaDataShapeOne;
        modulesLoading.value.top = false;
      })
      .catch(() => {
        comparisonData.value = {} as TMetaDataShapeOne;
        modulesLoading.value.top = false;
      });
  };

  return {
    loadAsyncData,
    loadComparisonData,
    loadp1x1,
    loadp1x2,
    loadp2x1,
    loadp2x2,
    data,
    groupedData,
    comparisonData,
    allDataIsLoaded,
  };
};
