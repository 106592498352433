<template>
  <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
    <ul>
      <li :class="{ 'is-active': isOverview }">
        <router-link to="/performance"> All Channels </router-link>
      </li>
      <li v-if="taxonomyOne" :class="{ 'is-active': isTaxonomyOne }">
        <router-link :to="'/performance/taxonomy_one/' + this.getTaxonomyOne">
          {{ taxonomyOne }}
        </router-link>
      </li>
      <li v-if="taxonomyTwo" :class="{ 'is-active': isTaxonomyTwo }">
        <router-link
          :to="
            '/performance/taxonomy_one/' +
            getTaxonomyOne +
            '/taxonomy_two/' +
            getTaxonomyTwo
          "
        >
          {{ taxonomyTwo }}
        </router-link>
      </li>
      <li v-if="taxonomyThree" :class="{ 'is-active': isTaxonomyThree }">
        <router-link
          :to="
            '/performance/taxonomy_one/' +
            getTaxonomyOne +
            '/taxonomy_two/' +
            getTaxonomyTwo +
            '/taxonomy_three/' +
            getTaxonomyThree
          "
        >
          {{ taxonomyThree }}
        </router-link>
      </li>

      <li v-if="taxonomyFour" :class="{ 'is-active': isTaxonomyFour }">
        <router-link
          :to="
            '/performance/taxonomy_one/' +
            getTaxonomyOne +
            '/taxonomy_two/' +
            getTaxonomyTwo +
            '/taxonomy_three/' +
            getTaxonomyThree +
            '/taxonomy_four/' +
            getTaxonomyFour
          "
        >
          {{ taxonomyFour }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    taxonomyOne: "",
    taxonomyTwo: "",
    taxonomyThree: "",
    taxonomyFour: "",
  },
  computed: {
    isOverview: function () {
      return this.taxonomyOne === undefined || this.taxonomyOne === "";
    },
    isTaxonomyOne: function () {
      return (
        (this.taxonomyOne !== undefined || this.taxonomyOne !== "") &&
        (this.taxonomyTwo === undefined || this.taxonomyTwo === "")
      );
    },
    isTaxonomyTwo: function () {
      return (
        (this.taxonomyTwo !== undefined || this.taxonomyTwo !== "") &&
        (this.taxonomyThree === undefined || this.taxonomyThree === "")
      );
    },
    isTaxonomyThree: function () {
      return (
        (this.taxonomyThree !== undefined || this.taxonomyThree !== "") &&
        (this.taxonomyFour === undefined || this.taxonomyFour === "")
      );
    },
    isTaxonomyFour: function () {
      return this.taxonomyFour !== undefined || this.taxonomyFour !== "";
    },
    getTaxonomyOne() {
      return this.validateInternalParameter(this.taxonomyOne);
    },
    getTaxonomyTwo() {
      return this.validateInternalParameter(this.taxonomyTwo);
    },
    getTaxonomyThree() {
      return this.validateInternalParameter(this.taxonomyThree);
    },
    getTaxonomyFour() {
      return this.validateInternalParameter(this.taxonomyFour);
    },
  },
};
</script>
