<template>
  <div>
    <div class="new-group-segment">
      <h2>Segment Groups</h2>
      <button type="button" class="button is-primary" @click="toggleModal = true">
        Create Group
      </button>
    </div>
    <add-group-button
      :toggle-modal="toggleModal"
      :segment-groups="segmentGroups"
      :segment-group-url="segmentGroupUrl"
      @handleCloseModal="(val) => (toggleModal = val)"
    >
    </add-group-button>
    <ul class="columns">
      <li
        class="column"
        v-if="segmentGroups.length"
        v-for="group in segmentGroups"
        :key="group.id"
      >
        <group-segment-card
          @closeModal="(val) => (toggleModal = val)"
          :group="group"
          :url="segmentGroupUrl"
          :toggle-upload-modal="toggleUploadModal"
        ></group-segment-card>
      </li>
    </ul>
  </div>
</template>

<script>
import ModalBody from "./ModalBody.vue";
import UpdateGroupHeader from "./UpdateGroupComponents/UpdateGroupHeader";
import UpdateGroupButton from "./UpdateGroupComponents/UpdateGroupButton";
import UpdateGroupInput from "./UpdateGroupComponents/UpdateGroupInput";
import GroupSegmentCard from "./GroupSegmentCard.vue";
import AddGroupButton from "./UpdateGroupComponents/AddGroupButton.vue";

export default {
  name: "GroupSegmentsCards",
  props: {
    segmentGroups: {
      type: Array,
      default: () => [],
    },
    columnHeaders: {
      type: Array,
      default: () => [],
    },
    segmentGroupUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      toggleModal: false,
      toggleUploadModal: false,
      newGroup: "",
    };
  },
  methods: {
    removeUnwantedCharacters(str = "") {
      return str
        .toLowerCase()
        .replaceAll(/[!"#£$%&'()*+,_./:;<=>?@[\]^`{|}~]/g, "")
        .replaceAll(" ", "-");
    },
  },
  components: {
    ModalBody,
    UpdateGroupHeader,
    UpdateGroupInput,
    UpdateGroupButton,
    GroupSegmentCard,
    AddGroupButton,
  },
};
</script>

<style scoped lang="scss">
.new-group-segment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;

  h2 {
    font-size: 1.4rem;
  }
}

.columns {
  flex-wrap: wrap;
  .column {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
  }
}
</style>
