import { defineStore } from "pinia";
import moment, { Moment } from "moment";

interface DateState {
  dateFrom: Date;
  dateTo: Date;
}

export const getDefaultDateFrom = () => moment().subtract(7, "days").toDate();
export const getDefaultDateTo = () => moment().subtract(1, "days").toDate();

export const UseDateStore = defineStore("date", {
  state: (): DateState => ({
    dateFrom: getDefaultDateFrom(),
    dateTo: getDefaultDateTo(),
  }),
  getters: {
    getDateFrom: (state): string =>
      moment(state.dateFrom).format(moment.HTML5_FMT.DATE),
    getDateFromAsDate: (state): Date => state.dateFrom,
    getDateTo: (state): string =>
      moment(state.dateTo).format(moment.HTML5_FMT.DATE),
    getDateToAsDate: (state): Date => state.dateTo,
  },
  actions: {
    setDateFrom(date: Moment | Date | null) {
      if (date) {
        this.dateFrom = moment(date).toDate();
      }
    },
    setDateTo(date: Moment | Date | null) {
      if (date) {
        this.dateTo = moment(date).toDate();
      }
    },
  },
});
