<template>
  <li>
    <component
      :is="tag"
      :class="{
        'semantic-wrapper': true,
        active,
      }"
      @click="$emit('click')"
    >
      <slot name="label" />
      <div class="item-buttons">
        <slot name="buttons" />
      </div>
    </component>
  </li>
</template>

<script>
export default {
  computed: {
    hasClickHandler() {
      return this.$attrs && this.$attrs.onClick;
    },
    tag() {
      return this.to ? "a" : this.hasClickHandler ? "button" : "div";
    },
  },
  props: {
    to: {
      type: String,
      required: false,
      default: null,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.semantic-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.2rem 0.2rem 0.8rem;
  border-radius: 2px;
  color: #4a4a4a;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 1em;
}

button.semantic-wrapper {
  cursor: pointer;
}

.semantic-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #363636;
}

.semantic-wrapper.active:hover,
.semantic-wrapper.active {
  background-color: #005cae;
  color: white;
}

.item-buttons {
  display: flex;
}
</style>
