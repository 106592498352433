<template>
  <div v-if="seriesReady && data.length">
    <apexchart
      :ref="'breakdownChart' + reference"
      :type="typeOfChart"
      :options="chartOptions"
      :series="series"
      height="250"
    ></apexchart>
  </div>
</template>

<script>
import moment from "moment";
import { UseMetricHelper } from "../../Helper/Functional/UseMetricHelper";

export default {
  setup() {
    const { fetchMetricValue } = UseMetricHelper();

    return {
      fetchMetricValue,
    };
  },
  props: {
    reference: {
      type: String,
      default: "",
    },
    typeOfChart: {
      type: String,
      default: "",
    },
    firstMetric: {
      type: String,
      default: "",
    },
    firstMetricMax: {
      type: Number,
      default: 0,
    },
    secondMetric: {
      type: String,
      default: "",
    },
    secondMetricMax: {
      type: Number,
      default: 0,
    },
    monetaryMetrics: {
      type: Array,
      default: [],
    },
    data: {
      type: Array,
      default: [],
    },
    headers: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  computed: {
    playTogetherMetrics() {
      return [
        "total_cost",
        "first_click_revenue",
        "last_click_revenue",
        "attributed_revenue",
      ];
    },
    metricsShareScale() {
      let rtn = false;

      let sharing = [
        ["first_click_sales", "last_click_sales", "attributed_sales"],
        [
          "first_click_conversion_rate",
          "last_click_conversion_rate",
          "attributed_conversion_rate",
        ],
      ];

      sharing.forEach(
        function (value) {
          if (
            _.includes(value, this.firstMetric) &&
            _.includes(value, this.secondMetric)
          ) {
            rtn = true;
          }
        }.bind(this)
      );

      return rtn;
    },
    tickAmount() {
      let max = Math.max(
        _.get(_.maxBy(this.data, this.firstMetric), this.firstMetric),
        _.get(_.maxBy(this.data, this.secondMetric), this.secondMetric),
        1
      );

      if (max < 7) {
        return Math.ceil(max);
      }

      return 7;
    },
    chartOptions() {
      let yaxis;
      let sharedMax = Math.max(this.firstMetricMax, this.secondMetricMax, 1);

      // If it doesn't share the axis together?
      if (
        !(
          (_.includes(this.playTogetherMetrics, this.firstMetric) &&
            _.includes(this.playTogetherMetrics, this.secondMetric)) ||
          this.metricsShareScale
        )
      ) {
        yaxis = [
          {
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            min: 0,
            max: this.firstMetricMax,
          },
          {
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            min: 0,
            max: this.secondMetricMax,
          },
        ];
      } else {
        yaxis = [
          {
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            tickAmount: sharedMax > 1 ? 5 : 1,
            forceNiceScale: true,
            min: 0,
            max: sharedMax,
          },
          {
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            tickAmount: sharedMax > 1 ? 5 : 1,
            forceNiceScale: true,
            min: 0,
            max: sharedMax,
          },
        ];
      }

      return {
        chart: {
          id: "breakdown-chart-" + this.reference,
          stacked: false,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#36E7A6", "#2799FF"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        markers: {
          size: 0,
        },
        tooltip: {
          y: [
            {
              formatter: function (value) {
                if (!this.firstMetric) {
                  return value;
                }

                return this.fetchMetricValue(this.firstMetric, value);
              }.bind(this),
            },
            {
              formatter: function (value) {
                if (!this.secondMetric) {
                  return value;
                }

                return this.fetchMetricValue(this.secondMetric, value);
              }.bind(this),
            },
          ],
        },
        xaxis: {
          labels: {
            show: false,
          },
          type: "datetime",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: yaxis,
      };
    },
    series() {
      if (!this.firstMetric || !this.secondMetric) {
        return [];
      }
      return [
        {
          name: this.headers[this.firstMetric] || this.firstMetric,
          data: _(this.data)
            .mapValues((value) => ({
              x: moment(value.conversion_date, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              y: value[this.firstMetric],
            }))
            .values()
            .value(),
        },
        {
          name: this.headers[this.secondMetric] || this.secondMetric,
          data: _(this.data)
            .mapValues((value) => ({
              x: moment(value.conversion_date, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              y: value[this.secondMetric],
            }))
            .values()
            .value(),
        },
      ];
    },
    seriesReady() {
      return this.series.map((s) => s.data).every((x) => x.length > 0);
    },
  },
};
</script>
