import { TColumns } from "../../State/Typings/SingleViewStoreTypes";
import { UseReportSettingsStore } from "../../State/Stores/ReportSettingsStore";
import { storeToRefs } from "pinia";
import { computed, WritableComputedRef } from "vue";

let metaMetric: WritableComputedRef<string>;
let metaComparison: WritableComputedRef<string>;
let columns: WritableComputedRef<string[]>;
let sortField: WritableComputedRef<string>;
let sortOrder: WritableComputedRef<string>;

export const UseMetaWritableComputed = () => {
  const {
    getMetaMetric,
    getMetaComparison,
    getMetaTableColumns,
    getMetaTableSortMetric,
    getMetaTableSortDirection,
  } = storeToRefs(UseReportSettingsStore());
  const {
    setMetaMetric,
    setMetaComparison,
    setMetaTableColumns,
    setMetaTableSortMetric,
    setMetaTableSortDirection,
  } = UseReportSettingsStore();

  metaMetric = computed({
    get() {
      return getMetaMetric.value;
    },
    set(val: string) {
      return setMetaMetric(val);
    },
  });
  metaComparison = computed({
    get() {
      return getMetaComparison.value;
    },
    set(val: string) {
      return setMetaComparison(val);
    },
  });
  columns = computed({
    get() {
      return getMetaTableColumns.value;
    },
    set(val: TColumns) {
      setMetaTableColumns(val);
    },
  });

  sortField = computed({
    get() {
      return getMetaTableSortMetric.value;
    },
    set(val: string) {
      setMetaTableSortMetric(val);
    },
  });
  sortOrder = computed({
    get() {
      return getMetaTableSortDirection.value;
    },
    set(val: string) {
      setMetaTableSortDirection(val);
    },
  });

  return {
    metaMetric,
    metaComparison,
    columns,
    sortField,
    sortOrder,
  };
};
