import { UseTaxonomyHelper } from "./UseTaxonomyHelper";
import { UseComparisonHelper } from "./UseComparisonHelper";
import {
  performanceLoadAsyncData,
  performanceLoadComparisonData,
} from "../../../generics/generics";
import {
  IGetAllColumns,
  TAllTableHeaderValues,
  IGeTAllTableHeaders,
  TComparisonDataFirstProps,
  TChartData,
  TAllTableHeaders,
} from "../../../typings/DataFetching";
import { IDetailTreeSingle } from "../../../typings/TableTypes";
import { UseTableAndChartLoading } from "./UseTableAndChartLoading";
import { ref } from "vue";
import { UseUrlBuilder } from "./UseUrlBuilder";

const tableData = ref<TAllTableHeaderValues<IGeTAllTableHeaders>[]>([]);
const chartData = ref<TAllTableHeaderValues<IGetAllColumns>[]>([]);
const tableHeaders = ref<TAllTableHeaders>({} as TAllTableHeaders);
const chartHeaders = ref<IGetAllColumns>({} as IGetAllColumns);
const detailTree = ref<IDetailTreeSingle>({});
const comparisonData = ref<TComparisonDataFirstProps>(
  {} as TComparisonDataFirstProps
);
const breakdownChartData = ref<TChartData[]>([]);

export const PerformanceTableAndChartData = () => {
  const { basicChartUrl, basicTableUrl, basicTableComparisonUrl } =
    UseUrlBuilder({
      dataSet: "",
    });
  const { chartLoading, tableLoading } = UseTableAndChartLoading();
  const { comparing } = UseComparisonHelper();

  const { currentTaxonomyInteger } = UseTaxonomyHelper();
  //Load Table data
  const loadTableData = () => {
    performanceLoadAsyncData(
      tableData,
      tableHeaders,
      detailTree
    )(basicTableUrl, tableLoading);
  };
  //Load Chart data
  const loadChartData = () => {
    performanceLoadAsyncData(chartData, chartHeaders)(
      basicChartUrl,
      chartLoading
    );
  };
  //Load Comparison data
  const loadComparisonData = () => {
    performanceLoadComparisonData(comparing, comparisonData)(
      basicTableComparisonUrl,
      tableLoading,
      `taxonomy_${currentTaxonomyInteger.value}`
    );
  };
  //Load Breakdown Chart Data
  const loadBreakdownChartData = () => {
    performanceLoadAsyncData(breakdownChartData, chartHeaders)(
      basicChartUrl,
      chartLoading
    );
  };
  //Clear Table data
  const clearTableData = () => {
    tableData.value = [];
  };
  //Clear Chart data
  const clearChartData = () => {
    chartData.value = [];
  };
  //Clear Comparison data
  const clearComparisonData = () => {
    comparisonData.value = {} as TComparisonDataFirstProps;
  };
  //Clear Breakdown Chart Data
  const clearBreakdownChartData = () => {
    breakdownChartData.value = [];
  };

  return {
    tableData,
    chartData,
    detailTree,
    tableHeaders,
    chartHeaders,
    chartLoading,
    tableLoading,
    comparisonData,
    breakdownChartData,
    clearChartData,
    clearTableData,
    clearComparisonData,
    clearBreakdownChartData,
    loadChartData,
    loadTableData,
    loadComparisonData,
    loadBreakdownChartData,
  };
};
