<template>
  <div class="column is-full">
    <div class="box">
      <o-loading :full-page="false" v-model:active="loading"></o-loading>
      <o-table v-if="props.report" :data="formattedData">
        <o-table-column
          field="publisher"
          label="Publisher"
          v-slot:default="props">
          <sv-badge :color="getTagType(props.row.publisher.taxonomy_1)">
            {{formatEntity(props.row.publisher)}}
          </sv-badge>
        </o-table-column>
        <o-table-column
          field="order_value"
          label="Average Order Value"
          v-slot:default="props">
          {{displayMonetaryValue(props.row.order_value)}}
        </o-table-column>
        <o-table-column
          field="average_steps"
          label="Average Steps To Convert"
          v-slot:default="props">
          {{displayNumericValue(props.row.steps)}}
        </o-table-column>
        <o-table-column
          field="average_days"
          label="Average Days To Convert"
          v-slot:default="props">
          {{displayNumericValue(props.row.days)}}
        </o-table-column>
        <o-table-column
          field="average_revenue"
          label="Average Attributed Revenue"
          v-slot:default="props">
          {{displayMonetaryValue(props.row.revenue)}}
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { UseNumberHelper } from '../../../Helper/Functional/UseNumberHelper';
import { UseBadgeHelper } from '../../../Helper/Functional/UseBadgeHelper';

const { displayNumericValue, displayMonetaryValue } = UseNumberHelper();
const { getTagType } = UseBadgeHelper();

const props = defineProps({report: Object, loading: Boolean});

const formattedData = computed(() => {
  return [
    {
      publisher: props.report.entity_1,
      order_value: props.report.entity_1_avg_order_value || 0,
      steps: props.report.entity_1_avg_steps_to_conversion || 0,
      days: props.report.entity_1_avg_days_to_conversion || 0,
      revenue: props.report.entity_1_avg_attributed_revenue || 0
    },
    {
      publisher: props.report.entity_2,
      order_value: props.report.entity_2_avg_order_value || 0,
      steps: props.report.entity_2_avg_steps_to_conversion || 0,
      days: props.report.entity_2_avg_days_to_conversion || 0,
      revenue: props.report.entity_2_avg_attributed_revenue || 0
    },
    {
      publisher: {taxonomy_1: 'Crossover'},
      order_value: props.report.crossover_avg_order_value || 0,
      steps: props.report.crossover_avg_steps_to_conversion || 0,
      days: props.report.crossover_avg_days_to_conversion || 0,
      revenue: props.report.crossover_avg_attributed_revenue || 0
    }
  ]
});

const formatEntity = (entity) => {
  let entityStr = '';
  const entityArr = Object.values(entity);

  for (const i in entityArr) {
    if (i === '0') {
      entityStr += entityArr[i];
      continue;
    }

    if (entityArr[i]) entityStr += ` (${entityArr[i]})`;
  }
  return entityStr;
}
</script>