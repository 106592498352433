import { ref } from "vue";
const columns = ref<string[]>([]);

export const UseColumnModalHelper = (cols: string[]) => {
  columns.value = cols;

  const setColumns = (newCols: string[]) => {
    columns.value = newCols;
  };

  return {
    columns,
    setColumns,
  };
};
