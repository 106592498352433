<template>
  <apexchart
    :ref="'attributionChart' + reference"
    type="bar"
    :options="chartOptions"
    :series="series"
    height="200"
  ></apexchart>
</template>

<script>
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";

export default {
  setup() {
    const { displayMonetaryValueRounded, displayNumericValueRounded } =
      UseNumberHelper();

    return {
      displayMonetaryValueRounded,
      displayNumericValueRounded,
    };
  },
  props: {
    reference: {
      type: String,
      default: "",
    },
    typeOfChart: {
      type: String,
      default: "",
    },
    firstMetric: {
      type: String,
      default: "",
    },
    secondMetric: {
      type: String,
      default: "",
    },
    monetaryMetrics: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    playTogetherMetrics() {
      return [
        "variable_cost",
        "fixed_cost",
        "total_cost",
        "attributed_revenue",
      ];
    },
    chartOptions() {
      return {
        chart: {
          id: "attribution-chart-" + this.reference,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
          },
          zoom: {
            enabled: false,
          },
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "center",
            },
            columnWidth: "100%",
          },
        },
        colors: ["#36E7A6", "#2799FF"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"],
          },
          textAnchor: "start",
          formatter: function (value, { seriesIndex }) {
            // Can't do it like tooltip.y[].formatter,
            // so we have to rely on the seriesIndex to find out the metric
            if (seriesIndex === 0) {
              if (!this.firstMetric) {
                return value;
              } else {
                if (_.includes(this.monetaryMetrics, this.firstMetric)) {
                  return this.displayMonetaryValueRounded(value);
                } else {
                  return this.displayNumericValueRounded(value);
                }
              }
            } else {
              if (!this.secondMetric) {
                return value;
              } else {
                if (_.includes(this.monetaryMetrics, this.secondMetric)) {
                  return this.displayMonetaryValueRounded(value);
                } else {
                  return this.displayNumericValueRounded(value);
                }
              }
            }
          }.bind(this),
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [
            this.firstMetric ? this.headers[this.firstMetric] : "",
            this.secondMetric ? this.headers[this.secondMetric] : "",
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      };
    },
    series() {
      if (!this.firstMetric || !this.secondMetric) {
        return [];
      }

      return this.data;
    },
  },
};
</script>
