<template>
  <div>
    <component
      v-if="programHasAccessToCurrentReport"
      :is="getCurrentReportComponent"
      :key="getCurrentProgram + '.customProgramReport.' + report"
      v-bind="getCurrentReportProps"
    ></component>

    <outer-section v-if="!programHasAccessToCurrentReport">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="has-text-centered">
              <h1 class="title">You don't have access to this report</h1>
              <h2 class="subtitle">
                Try
                <a @click="$router.go(-1)">going back 🤷‍♂️</a> or
                <router-link to="/">going home 🏠</router-link>
              </h2>
              <br /><br /><br /><br /><br />
              <br /><br /><br /><br /><br />
            </div>
          </div>
        </div>
      </section>
    </outer-section>
  </div>
</template>

<script>
import { UseProgramStore } from "../../Modules/State/Stores/ProgramStore";
import { storeToRefs } from "pinia";
import CustomReport1ValidVsCancelled from "./Reports/CustomReport1ValidVsCancelled";

export default {
  title: "Active / Not Active Report",
  setup() {
    const { getCurrentProgram, getCurrentProgramCustomReports } = storeToRefs(
      UseProgramStore()
    );

    return {
      getCurrentProgram,
      getCurrentProgramCustomReports,
    };
  },
  computed: {
    existingReport() {
      return _.find(this.availableReports, { id: this.report });
    },
    programHasAccessToCurrentReport() {
      // Fake it
      // return true;
      return (
        this.existingReport !== undefined &&
        _.find(this.getCurrentProgramCustomReports, {
          report_id: this.report,
        }) !== undefined
      );
    },
    getCurrentReport() {
      if (this.programHasAccessToCurrentReport) {
        return _.find(this.availableReports, { id: this.report });
      }

      return undefined;
    },
    getCurrentReportComponent() {
      return _.get(this.getCurrentReport, "component");
    },
    getCurrentReportProps() {
      return _.get(this.getCurrentReport, "props");
    },
    getCurrentReportForProgram() {
      return _.find(this.getCurrentProgramCustomReports, {
        report_id: this.report,
      });
    },
    getCurrentReportMetaForProgram() {
      return _.get(this.getCurrentReportForProgram, "meta", {});
    },
    availableReports() {
      return [
        {
          id: 1,
          component: CustomReport1ValidVsCancelled,
          props: {
            taxonomyOne: this.$route.params.taxonomyOne,
            taxonomyTwo: this.$route.params.taxonomyTwo,
            taxonomyThree: this.$route.params.taxonomyThree,
            taxonomyFour: this.$route.params.taxonomyFour,
            validName: _.get(
              this.getCurrentReportMetaForProgram,
              "validName",
              "Valid"
            ),
            cancelledName: _.get(
              this.getCurrentReportMetaForProgram,
              "cancelledName",
              "Cancelled"
            ),
            meta: this.getCurrentReportMetaForProgram,
            tab: _.get(this.$route.query, "tab"),
          },
        },
      ];
    },
  },
  props: ["report"],
};
</script>
