<template>
  <p class="control">
    <o-button
      @click="download"
      type="is-default"
      icon-left="file-excel"
      :loading="loading"
    >
      Export as CSV
    </o-button>
  </p>
</template>

<script>
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseDateStore } from "../../State/Stores/DateStore";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";

export default {
  props: {
    taxonomyOne: {
      type: String,
      default: "",
    },
    taxonomyTwo: {
      type: String,
      default: "",
    },
    taxonomyThree: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
    };
  },
  computed: {
    url() {
      let tax = "/taxonomy_1";

      if (this.taxonomyOne) {
        tax += "/" + this.taxonomyOne + "/taxonomy_2";
      }

      if (this.taxonomyTwo) {
        tax += "/" + this.taxonomyTwo + "/taxonomy_3";
      }

      if (this.taxonomyThree) {
        tax += "/" + this.taxonomyThree + "/taxonomy_4";
      }

      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/table/csv" +
        tax +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    downloadName() {
      let name = "overview";

      if (this.taxonomyOne) {
        name = this.taxonomyOne;
      }

      if (this.taxonomyTwo) {
        name += "_" + this.taxonomyTwo;
      }

      if (this.taxonomyThree) {
        name += "_" + this.taxonomyThree;
      }

      name += ".csv";

      return name;
    },
  },
  methods: {
    download() {
      this.loading = true;
      axios({
        url: this.url,
        method: "GET",
        responseType: "blob",
      })
        .then(({ data }) => {
          this.loading = false;

          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.downloadName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
