<template>
  <div>
    <o-field>
      <button
        type="button"
        :disabled="newName.length === 0 || disabled"
        class="button is-primary"
        @click="updateGroupData"
      >
        {{ text }}
      </button>
    </o-field>
    <SegmentAlert
      :show-duplicate-tag-alert="showDuplicateTagAlert"
      :alert-string="'This group already exists. Please choose another one'"
    />
  </div>
</template>

<script>
import SegmentAlert from "../SegmentAlert.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseSegementStore } from "../../State/Stores/SegmentStore";
export default {
  name: "UpdateGroupButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    groupName: {
      type: String,
      default: "",
    },
    newName: {
      type: String,
      default: "",
    },
    fullUpdate: {
      type: Boolean,
      default: false,
    },
    segmentGroups: {
      type: Array,
      default: () => [],
    },
    segmentGroupUrl: {
      type: String,
      default: "",
    },
    currentGroupName: {
      type: String,
      default: "",
    },
    currentGroupIngestMethod: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      disabled: false,
      showDuplicateTagAlert: false,
    };
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { updateSegmentGroupDataWithNewGroup, updateSegmentGroupName } =
      UseSegementStore();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      updateSegmentGroupDataWithNewGroup,
      updateSegmentGroupName,
    };
  },
  computed: {
    checkMatching() {
      const alreadyExists = this.segmentGroups.find(
        (group) =>
          group.group_name.toLowerCase() ===
          this.removeUnwantedCharacters(this.groupName).toLowerCase()
      );
      return !!alreadyExists;
    },
  },
  updated() {
    if (!this.checkMatching && this.newName.length) {
      this.disabled = false;
      this.showDuplicateTagAlert = false;
    }
  },
  methods: {
    updateGroupData() {
      if (this.fullUpdate) {
        //Check to see if tag already exists.
        if (this.checkMatching) {
          //Alert
          this.showDuplicateTagAlert = true;
          this.disabled = true;
          return;
        }
        const handle = this.removeUnwantedCharacters(this.groupName);
        try {
          axios({
            method: "post",
            url: this.segmentGroupUrl,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              group_title: this.groupName,
              group_name: handle,
              ingest_method: "manual",
            },
          })
            .then(({ data }) => {
              if (data.data) {
                this.updateSegmentGroupDataWithNewGroup({ data: data.data });
                this.$emit("closeModal", false);
              }
            })
            .catch((err) => console.log(err));
        } catch (err) {
          console.log("From try/catch", err);
        }
      } else {
        try {
          axios({
            method: "patch",
            url: this.segmentGroupUrl + "/" + this.groupName,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              group_title: this.newName,
              ingest_method: this.currentGroupIngestMethod,
            },
          })
            .then(({ data }) => {
              if (data.data) {
                this.updateSegmentGroupName({
                  group_name: this.currentGroupName,
                  group_title: this.newName,
                });
                this.$emit("closeModal", false);
                this.$emit("reload");
              }
            })
            .catch((err) => console.log(err));
        } catch (err) {
          console.log("From try/catch", err);
        }
      }
    },
    removeUnwantedCharacters(str = "") {
      return str
        .toLowerCase()
        .replaceAll(/[!"#£$%&'()*+,-./:;<=>?@[\]^`{|}~]/g, "")
        .replaceAll(" ", "_");
    },
  },
  components: { SegmentAlert },
};
</script>
