<template>
  <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
    <ul>
      <li :class="{ 'is-active': isOverview }">
        <router-link :to="'/' + getBaseUrl"> All Channels </router-link>
      </li>
      <li v-if="taxonomyOne" :class="{ 'is-active': isTaxonomyOne }">
        <router-link
          :to="'/' + getBaseUrl + '/taxonomy_one/' + this.getTaxonomyOne"
        >
          {{ taxonomyOne }}
        </router-link>
      </li>
      <li v-if="taxonomyTwo" :class="{ 'is-active': isTaxonomyTwo }">
        <router-link
          :to="
            '/' +
            getBaseUrl +
            '/taxonomy_one/' +
            getTaxonomyOne +
            '/taxonomy_two/' +
            getTaxonomyTwo
          "
        >
          {{ taxonomyTwo }}
        </router-link>
      </li>
      <li v-if="taxonomyThree" :class="{ 'is-active': isTaxonomyThree }">
        <router-link
          :to="
            '/' +
            getBaseUrl +
            '/taxonomy_one/' +
            getTaxonomyOne +
            '/taxonomy_two/' +
            getTaxonomyTwo +
            '/taxonomy_three/' +
            getTaxonomyThree
          "
        >
          {{ taxonomyThree }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    taxonomyOne: "",
    taxonomyTwo: "",
    taxonomyThree: "",
    baseUrl: "",
  },
  data() {
    return {
      base: "custom-reports/1",
    };
  },
  computed: {
    isOverview: function () {
      return this.taxonomyOne === undefined || this.taxonomyOne === "";
    },
    isTaxonomyOne: function () {
      return (
        (this.taxonomyOne !== undefined || this.taxonomyOne !== "") &&
        (this.taxonomyTwo === undefined || this.taxonomyTwo === "")
      );
    },
    isTaxonomyTwo: function () {
      return (
        (this.taxonomyTwo !== undefined || this.taxonomyTwo !== "") &&
        (this.taxonomyThree === undefined || this.taxonomyThree === "")
      );
    },
    isTaxonomyThree: function () {
      return this.taxonomyThree !== undefined && this.taxonomyThree !== "";
    },
    getTaxonomyOne() {
      return this.validateInternalParameter(this.taxonomyOne);
    },
    getTaxonomyTwo() {
      return this.validateInternalParameter(this.taxonomyTwo);
    },
    getTaxonomyThree() {
      return this.validateInternalParameter(this.taxonomyThree);
    },
    getBaseUrl() {
      return this.baseUrl || this.base;
    },
  },
};
</script>
