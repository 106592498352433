export const UseMetaStatelessFuncs = () => {
  /**
   * Takes a string an removes spaces and replaces them with underscores
   * @param str Any string containing letters or numbers
   * @returns a handelised string separated by underscores
   */
  const convertNameToHandle = (str: string) => {
    if (!str) return "";
    return str.toLowerCase().replaceAll(" ", "_");
  };

  return {
    convertNameToHandle,
  };
};
