<template>
  <outer-section>
    <o-loading :full-page="false" :active="loading"></o-loading>
    <fixed-costs-index v-model:loading="loading"></fixed-costs-index>
  </outer-section>
</template>

<script>
import FixedCostsIndex from "../../../Modules/Admin/FixedCosts/Index";

export default {
  components: { FixedCostsIndex },
  title: "Fixed Spend",
  data() {
    return {
      loading: false,
    };
  },
};
</script>
