import { UseSegmentComparisonStore } from "./../../../Modules/State/Stores/SegmentComparisonStore";
import { UseProgramStore } from "./../../../Modules/State/Stores/ProgramStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
export const SegmentComparisonChannelDropdowns = () => {
  const { getCurrentProgramDataSets, getCurrentProgram } = storeToRefs(
    UseProgramStore()
  );
  const { getComparisonMetricLeft, getComparisonMetricRight } = storeToRefs(
    UseSegmentComparisonStore()
  );
  const { setComparisonMetricLeft, setComparisonMetricRight } =
    UseSegmentComparisonStore();
  const customReports = computed(() =>
    getCurrentProgramDataSets.value.filter(
      (rep) => rep.programcode === getCurrentProgram.value
    )
  );

  //Dropdowns
  const dropDownNames = computed(
    () =>
      customReports.value.map((rep) => ({
        tag: rep.data_set_tag,
        title: rep.data_set_title,
      })) || []
  );
  setComparisonMetricLeft(dropDownNames.value[0]?.tag);
  setComparisonMetricRight(dropDownNames.value[1]?.tag);

  const comparisonMetric = ref<string[]>([
    getComparisonMetricLeft.value || "",
    getComparisonMetricRight.value || "",
  ]);

  return {
    dropDownNames,
    comparisonMetric,
  };
};
