import { computed, ref } from "vue";

export const SegmentComparisonLoading = () => {
  //State
  const tableLoading = ref(false);
  const chartLoading = ref(false);
  const loading = computed(() => tableLoading.value && chartLoading.value);

  //Actions
  /**
   *
   * @param val boolean
   * @returns updates the tableLoading ref
   */
  const setTableLoading = (val: boolean) => (tableLoading.value = val);

  /**
   *
   * @param val boolean
   * @returns updates the chartLoading ref
   */
  const setChartLoading = (val: boolean) => (chartLoading.value = val);

  return {
    tableLoading,
    chartLoading,
    loading,
    setTableLoading,
    setChartLoading,
  };
};
