import { ref } from "vue";

type TaxonomyParameters =
  | "taxonomy_1"
  | "taxonomy_2"
  | "taxonomy_3"
  | "taxonomy_4";
const reportLevel = ref<TaxonomyParameters>("taxonomy_1");
export const UseReport = () => {
  const setReportLevel = (val: TaxonomyParameters) => {
    reportLevel.value = val;
  };
  return {
    reportLevel,
    setReportLevel,
  };
};
