import { IComparisonConfiguration } from "../Typings/MetaTypes";
import { IComparisonDates } from "../../../../typings/ComparisonTypes";
import { UseMetaWritableComputed } from "./UseMetaWritableComputed";
import { UseMetaGetterComputed } from "./UseMetaGetterComputed";
import { UseDateStore } from "../../State/Stores/DateStore";
import { storeToRefs } from "pinia";
import { Ref, computed, ComputedRef } from "vue";
import { UseStringHelper } from "../../../Helper/Functional/UseStringHelper";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { get } from "lodash";

interface IProps {
  taxonomy: Ref<number>;
  taxonomyOne: Ref<string>;
  taxonomyTwo: Ref<string>;
  taxonomyThree: Ref<string>;
  taxonomyFour: Ref<string>;
  meta: Ref<{
    cancelledName: string;
    excludedColumns: string[];
    validName: string;
  }>;
  comparisonDates: Ref<IComparisonDates>;
  comparisonConfiguration: ComputedRef<IComparisonConfiguration>;
}

export const UseMetaUrlMethods = ({
  taxonomy,
  taxonomyOne,
  taxonomyTwo,
  taxonomyThree,
  meta,
  comparisonDates,
  comparisonConfiguration,
}: IProps) => {
  const { getApiBaseUrl } = storeToRefs(UseMainStore());
  const { getCurrentProgram } = storeToRefs(UseProgramStore());
  const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
  const { validateInternalParameter } = UseStringHelper();
  const { useNewDataSource, dataSetOne, dataSetTwo } = UseMetaGetterComputed({
    meta,
  });
  const { metaComparison } = UseMetaWritableComputed();

  const fetchDataUrl = (
    dataset: string,
    datatype: string,
    useComparisonDates = false
  ) => {
    return (
      getApiBaseUrl.value +
      "/programs/" +
      getCurrentProgram.value +
      "/performance/named/" +
      dataset +
      "/" +
      datatype +
      "/taxonomy_1" +
      (taxonomy.value > 1
        ? "/" + validateInternalParameter(taxonomyOne.value) + "/taxonomy_2"
        : "") +
      (taxonomy.value > 2
        ? "/" + validateInternalParameter(taxonomyTwo.value) + "/taxonomy_3"
        : "") +
      (taxonomy.value > 3
        ? "/" + validateInternalParameter(taxonomyThree.value) + "/taxonomy_4"
        : "") +
      "?date_from=" +
      (useComparisonDates ? comparisonDates.value.start : getDateFrom.value) +
      "&date_to=" +
      (useComparisonDates ? comparisonDates.value.end : getDateTo.value)
    );
  };

  const giveMeAUrl = (
    metaType: string,
    position: string,
    useComparisonDates = false
  ) => {
    return (
      getApiBaseUrl.value +
      "/programs/" +
      getCurrentProgram.value +
      "/performance/meta/" +
      metaType +
      "/taxonomy_1" +
      (taxonomy.value > 1
        ? "/" + validateInternalParameter(taxonomyOne.value) + "/taxonomy_2"
        : "") +
      (taxonomy.value > 2
        ? "/" + validateInternalParameter(taxonomyTwo.value) + "/taxonomy_3"
        : "") +
      (taxonomy.value > 3
        ? "/" + validateInternalParameter(taxonomyThree.value) + "/taxonomy_4"
        : "") +
      "?date_from=" +
      (useComparisonDates ? comparisonDates.value.start : getDateFrom.value) +
      "&date_to=" +
      (useComparisonDates ? comparisonDates.value.end : getDateTo.value) +
      "&meta=" +
      JSON.stringify([
        get(comparisonConfiguration.value, [
          metaComparison.value,
          position,
          "filter",
        ]),
      ])
    );
  };

  const bottomUrl = computed(() => {
    if (useNewDataSource.value) {
      const parameter = dataSetTwo.value.data_set_tag;
      return fetchDataUrl(parameter, "chart/multi");
    }
    return giveMeAUrl("daily", "bottom");
  });

  const topUrl = computed(() => {
    if (useNewDataSource.value) {
      const parameter = dataSetOne.value.data_set_tag;
      return fetchDataUrl(parameter, "chart/multi");
    }

    return giveMeAUrl("daily", "top");
  });

  const p1x1Url = computed(() => {
    if (useNewDataSource.value) {
      return fetchDataUrl(dataSetTwo.value.data_set_tag, "table/single");
    }

    return giveMeAUrl("grouped", "bottom");
  });

  const p1x2Url = computed(() => {
    if (useNewDataSource.value) {
      return fetchDataUrl(dataSetOne.value.data_set_tag, "table/single");
    }

    return giveMeAUrl("grouped", "top");
  });

  const p2x1Url = computed(() => {
    if (useNewDataSource.value) {
      return fetchDataUrl(dataSetTwo.value.data_set_tag, "table/single", true);
    }

    return giveMeAUrl("grouped", "bottom", true);
  });

  const p2x2Url = computed(() => {
    if (useNewDataSource.value) {
      return fetchDataUrl(dataSetOne.value.data_set_tag, "table/single", true);
    }

    return giveMeAUrl("grouped", "top", true);
  });

  const fetchNavigationUrl = computed(() => {
    return (
      "/custom-reports/1" +
      (taxonomy.value > 0
        ? "/taxonomy_one/" + validateInternalParameter(taxonomyOne.value)
        : "") +
      (taxonomy.value > 1
        ? "/taxonomy_two/" + validateInternalParameter(taxonomyTwo.value)
        : "") +
      (taxonomy.value > 2
        ? "/taxonomy_three/" + validateInternalParameter(taxonomyThree.value)
        : "")
    );
  });

  return {
    bottomUrl,
    topUrl,
    p1x1Url,
    p1x2Url,
    p2x1Url,
    p2x2Url,
    fetchNavigationUrl,
  };
};
