<template>
  <div class="box">
    <div class="is-clearfix">
      <o-field class="is-pulled-right">
        <p class="control">
          <router-link
            to="/account/costs/variable/batch"
            class="button is-primary is-outlined is-pulled-right"
            >Batch Import</router-link
          >
        </p>
      </o-field>

      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li class="is-active">
            <router-link to="/account/costs/variable">Variable Spend</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <o-field grouped group-multiline>
      <new-pagination>
        v-model:page="page" v-model::per-page="perPage"
        v-model::records="sortedData.length"
      </new-pagination>
      <o-field>
        <o-input
          placeholder="Search..."
          type="search"
          icon="magnify"
          v-model="searchTerm"
        ></o-input>
      </o-field>
    </o-field>

    <o-table
      ref="variableCostsIndexTable"
      narrowed
      hoverable
      :data="pageData"
      :backend-sorting="true"
      :default-sort-direction="sortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <template slot-scope="props">
        <!-- Id -->
        <o-table-column field="id" label="ID" sortable>
          {{ props.row.id }}
        </o-table-column>
        <!-- Taxonomy 1 -->
        <o-table-column field="taxonomy_1" label="Taxonomy 1" sortable>{{
          props.row.taxonomy_1
        }}</o-table-column>
        <!-- Cost -->
        <o-table-column field="cost" label="Spend" sortable centered>{{
          displayMonetaryValue(props.row.cost)
        }}</o-table-column>
        <!-- Date -->
        <o-table-column field="date" label="Date" sortable centered>{{
          props.row.date
        }}</o-table-column>
        <!-- Cost Link -->
        <o-table-column field="cost_link" label="Cost Link" sortable centered>{{
          props.row.cost_link
        }}</o-table-column>
        <o-table-column numeric label="Actions">
          <button
            class="button is-small is-rounded is-danger is-outlined"
            @click="removeCost(props.row.id)"
          >
            Delete
          </button>
        </o-table-column>
      </template>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <template v-if="!loading">
              <p>
                <o-icon icon="emoticon-sad" size="is-large"></o-icon>
              </p>
              <p>There are no variable spend configurations to show</p>
            </template>
          </div>
        </section>
      </template>
    </o-table>
  </div>
</template>

<script>
import NewPagination from "../../../Breakdown/NewPagination";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../../State/SingleViewStorePinia";
import { UseProgramStore } from "../../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseNumberHelper } from "../../../../Helper/Functional/UseNumberHelper";

export default {
  components: { NewPagination },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { displayMonetaryValue } = UseNumberHelper();

    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      displayMonetaryValue,
    };
  },
  computed: {
    url() {
      return (
        this.getApiBaseUrl + "/programs/" + this.getCurrentProgram + "/variable-costs"
      );
    },
    loadingCompute: {
      get() {
        return this.loading;
      },
      set(val) {
        return this.$emit("update:loading", val);
      },
    },
    sortedData() {
      return this.data
        ? _.orderBy(
            _.filter(
              this.data,
              function (value) {
                return value.search.includes(this.searchTerm);
              }.bind(this)
            ),
            this.sortField,
            this.sortOrder
          )
        : [];
    },
    chunkedData() {
      return _.chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.data ? this.chunkedData[this.page - 1] : [];
    },
  },
  data() {
    return {
      data: [],
      sortField: "id",
      sortOrder: "desc",
      page: 1,
      perPage: 15,
      searchTerm: "",
    };
  },
  props: {
    loading: false,
  },
  methods: {
    loadAsyncData() {
      this.loadingCompute = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data;

          this.data = _.sortBy(data.data, ["id"]);
          this.loadingCompute = false;
        })
        .catch(() => {
          this.loadingCompute = false;
        });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    removeCost(id) {
      swal
        .fire({
          type: "warning",
          title: "Warning",
          text: "Are you sure you want to remove this spend configuration?",
          confirmButtonText: "I'm sure",
          showCancelButton: true,
        })
        .then(
          function ({ value }) {
            if (value) {
              axios.delete(this.url + "/" + id).then(() => {
                swal.fire({
                  type: "success",
                  title: "Success",
                  text: "The spend configuration was successfully removed",
                });
              });

              this.loadAsyncData();
            }
          }.bind(this)
        );
    },
  },
  watch: {
    url() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>
