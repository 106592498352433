import { sumBy, isEmpty, values, round } from "lodash";
import { computed, Ref } from "vue";
import {
  IColumnSkeleton,
  IFooterFullSkeleton,
} from "../../../../../typings/TableTypes";
import {
  TAllTableHeaders,
  TAllTableHeaderValues,
  TComparisonDataFirstProps,
} from "../../../../../typings/DataFetching";
export const UseTableColumns = () => {
  const columnSkeleton = computed(
    () =>
      <IColumnSkeleton[]>[
        {
          field: "variable_cost",
          label: "Variable Cost",
          monetary: true,
          rounded: true,
        },
        {
          field: "fixed_cost",
          label: "Fixed Cost",
          monetary: true,
          rounded: true,
        },
        {
          field: "total_cost",
          label: "Total Cost",
          monetary: true,
          rounded: true,
        },
        {
          field: "clicks",
          label: "Page Lands",
          rounded: true,
        },
        {
          field: "impressions",
          label: "Impressions",
          rounded: true,
        },
        {
          field: "first_click_sales",
          label: "First Touch Sales",
          rounded: true,
        },
        {
          field: "first_click_revenue",
          label: "First Touch Revenue",
          monetary: true,
          rounded: true,
        },
        {
          field: "first_click_conversion_rate",
          label: "First Touch Conversion Rate",
          percentage: true,
        },
        {
          field: "first_click_aov",
          label: "First Touch AOV",
          monetary: true,
        },
        {
          field: "last_click_sales",
          label: "Last Touch Sales",
          rounded: true,
        },
        {
          field: "last_click_revenue",
          label: "Last Touch Revenue",
          rounded: true,
          monetary: true,
        },
        {
          field: "last_click_conversion_rate",
          label: "Last Touch Conversion Rate",
          percentage: true,
        },
        {
          field: "last_click_aov",
          label: "Last TouchAOV",
          monetary: true,
        },
        {
          field: "attributed_sales",
          label: "Attributed Sales",
          rounded: true,
        },
        {
          field: "attributed_revenue",
          label: "Attributed Revenue",
          rounded: true,
          monetary: true,
        },
        {
          field: "attributed_conversion_rate",
          label: "Attributed Conversion Rate",
          percentage: true,
        },
        {
          field: "attributed_aov",
          label: "Attributed AOV",
          monetary: true,
        },
        {
          field: "attributed_revenue_variance",
          label: "Attributed Revenue Variance",
          monetary: true,
          rounded: true,
        },
        {
          field: "attributed_revenue_variance_percent",
          label: "Attributed Revenue Variance Percent",
          percentage: true,
          oneDecimal: true,
        },
        {
          field: "introducer_ratio",
          label: "Introducer Ratio",
        },
        {
          field: "cpa",
          label: "Attributed CPA",
          monetary: true,
          opposite: true,
        },
        {
          field: "roas",
          label: "Attributed ROAS",
          monetary: true,
        },
        {
          field: "roi",
          label: "ROI",
          monetary: true,
        },
        {
          field: "sales_including",
          label: "Sales Involved",
          rounded: true,
        },
        {
          field: "proportional_credit",
          label: "Attributed Sale Credit",
          oneDecimal: true,
        },
      ]
  );

  const valueDataHelper = (
    data: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,

    valOne: string,
    valTwo: string,
    multiply: boolean,
    minusVal?: string
  ) => {
    if (sumBy(data.value, valTwo) !== 0) {
      let minusInt: number;
      if (minusVal) {
        minusInt = sumBy(data.value, minusVal) - sumBy(data.value, valOne);
      } else {
        minusInt = sumBy(data.value, valOne);
      }
      return (minusInt / sumBy(data.value, valTwo)) * (multiply ? 100 : 1);
    }
    return 0;
  };
  const valueComparisonDataHelper = (
    comparisonData: Ref<TComparisonDataFirstProps>,
    valOne: string,
    valTwo: string,
    multiply: boolean,
    minusVal?: string
  ) => {
    if (isEmpty(comparisonData.value)) return NaN;
    if (sumBy(values(comparisonData.value), valTwo) === 0) return 0;

    let minusInt: number;
    if (minusVal) {
      minusInt =
        sumBy(values(comparisonData.value), minusVal) -
        sumBy(values(comparisonData.value), valOne);
    } else {
      minusInt = sumBy(values(comparisonData.value), valOne);
    }
    return (
      (minusInt / sumBy(values(comparisonData.value), valTwo)) *
      (multiply ? 100 : 1)
    );
  };

  const footerSkeleton = (
    data: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,
    comparisonData: Ref<TComparisonDataFirstProps>
  ) => {
    return computed(
      () =>
        <IFooterFullSkeleton[]>[
          {
            field: "variable_cost",
            current: sumBy(data.value, "variable_cost"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.variable_cost
            ),
            monetary: true,
            rounded: true,
          },
          {
            field: "fixed_cost",
            current: sumBy(data.value, "fixed_cost"),
            previous: sumBy(values(comparisonData.value), (x) => +x.fixed_cost),
            monetary: true,
            rounded: true,
          },
          {
            field: "total_cost",
            current: sumBy(data.value, "total_cost"),
            previous: sumBy(values(comparisonData.value), (x) => +x.total_cost),
            monetary: true,
            rounded: true,
          },
          {
            field: "clicks",
            current: sumBy(data.value, "clicks"),
            previous: sumBy(values(comparisonData.value), (x) => +x.clicks),
            rounded: true,
          },
          {
            field: "impressions",
            current: sumBy(data.value, "impressions"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.impressions
            ),
            rounded: true,
          },
          {
            field: "first_click_sales",
            current: sumBy(data.value, "first_click_sales"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.first_click_sales
            ),
            rounded: true,
          },
          {
            field: "first_click_revenue",
            current: sumBy(data.value, "first_click_revenue"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.first_click_revenue
            ),
            rounded: true,
            monetary: true,
          },
          {
            field: "first_click_conversion_rate",
            current: valueDataHelper(data, "first_click_sales", "clicks", true),
            previous: valueComparisonDataHelper(
              comparisonData,
              "first_click_sales",
              "clicks",
              true
            ),
            percentage: true,
          },
          {
            field: "first_click_aov",
            current: valueDataHelper(
              data,
              "first_click_revenue",
              "first_click_sales",
              false
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "first_click_revenue",
              "first_click_sales",
              false
            ),
            monetary: true,
          },
          {
            field: "last_click_sales",
            current: sumBy(data.value, "last_click_sales"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.last_click_sales
            ),
            rounded: true,
          },

          {
            field: "last_click_revenue",
            current: sumBy(data.value, "last_click_revenue"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.last_click_revenue
            ),
            rounded: true,
            monetary: true,
          },
          {
            field: "last_click_conversion_rate",
            current: valueDataHelper(data, "last_click_sales", "clicks", true),
            previous: valueComparisonDataHelper(
              comparisonData,
              "last_click_sales",
              "clicks",
              true
            ),
            percentage: true,
          },
          {
            field: "last_click_aov",
            current: valueDataHelper(
              data,
              "last_click_revenue",
              "last_click_sales",
              false
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "last_click_revenue",
              "last_click_sales",
              false
            ),
            monetary: true,
          },
          {
            field: "attributed_sales",
            current: sumBy(data.value, "attributed_sales"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.attributed_sales
            ),
            monetary: false,
            rounded: true,
          },
          {
            field: "attributed_revenue",
            current: sumBy(data.value, "attributed_revenue"),
            previous: sumBy(
              values(comparisonData.value),
              (x) => +x.attributed_revenue
            ),
            monetary: false,
            rounded: true,
          },
          {
            field: "attributed_conversion_rate",
            current: valueDataHelper(data, "attributed_sales", "clicks", true),
            previous: valueComparisonDataHelper(
              comparisonData,
              "attributed_sales",
              "clicks",
              true
            ),
            percentage: true,
          },
          {
            field: "attributed_aov",
            current: valueDataHelper(
              data,
              "attributed_revenue",
              "attributed_sales",
              false
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "attributed_revenue",
              "attributed_sales",
              false
            ),
            monetary: true,
          },
          {
            field: "attributed_revenue_variance",
            current:
              round(sumBy(data.value, "attributed_revenue")) -
              round(sumBy(data.value, "last_click_revenue")),
            previous:
              sumBy(values(comparisonData.value), "attributed_revenue") -
              sumBy(values(comparisonData.value), "last_click_revenue"),
            monetary: true,
            rounded: true,
          },
          {
            field: "attributed_revenue_variance_percent",
            fallback: "-",
          },
          {
            field: "introducer_ratio",
            fallback: "-",
          },
          {
            field: "cpa",
            current: valueDataHelper(
              data,
              "total_cost",
              "attributed_sales",
              false
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "total_cost",
              "attributed_sales",
              false
            ),
            monetary: true,
            opposite: true,
          },
          {
            field: "roas",
            current: valueDataHelper(
              data,
              "attributed_revenue",
              "total_cost",
              false
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "attributed_revenue",
              "total_cost",
              false
            ),
            monetary: true,
          },
          {
            field: "roi",
            current: valueDataHelper(
              data,
              "total_cost",
              "total_cost",
              false,
              "attributed_revenue"
            ),
            previous: valueComparisonDataHelper(
              comparisonData,
              "total_cost",
              "total_cost",
              false,
              "attributed_revenue"
            ),
            monetary: true,
          },
          {
            field: "sales_including",
            fallback: "-",
          },
          {
            field: "proportional_credit",
            fallback: "-",
          },
        ]
    );
  };

  return {
    columnSkeleton,
    footerSkeleton,
  };
};
