<template>
  <div>
    <outer-section ref="funnelTaxonomyOneSection">
      <o-loading v-model:active="loading"></o-loading>
      <div class="columns is-multiline">
        <div class="column is-full">
          <h1 class="title">Initiator Rating</h1>
        </div>
        <div class="column is-full">
          <div class="box">
            <div class="is-flex breadcrumo-container">
              <taxonomy-breadcrumbs
                :taxonomyUrls="taxonomyUrls"
                :taxonomyLocation="taxonomyLocation"
                baseUrl="/initiator-rating/"
              />
              <export-data
                :taxonomyFilter="true"
                :taxonomyFilterDates="{ from: getDateFrom, to: getDateTo }"
                :available-columns="
                  exportColumns.concat(['mid_lower_revenue', 'mid_lower_revenue_percent'])
                "
                :selected-columns="exportColumns"
                :column-headers="{
                  taxonomy_1: 'Taxonomy 1',
                  taxonomy_2: 'Taxonomy 2',
                  taxonomy_3: 'Taxonomy 3',
                  taxonomy_4: 'Taxonomy 4',
                  total_funnel_revenue: 'Total Revenue',
                  upper_solo_revenue: 'Solo + Upper Revenue (Initiator)',
                  upper_solo_revenue_percent: 'Initiator Percent',
                  mid_lower_revenue: 'Mid + Lower Revenue',
                  mid_lower_revenue_percent: 'Mid + Lower Percent',
                }"
                :report-name="
                  (
                    getCurrentProgramName +
                    '_performance_' +
                    getDateFrom +
                    '_' +
                    getDateTo
                  )
                    .toLowerCase()
                    .split(' ')
                    .join('_')
                "
                :exportable-data="exportData"
                :order-data-by="'total_funnel_revenue'"
                :order-data-direction="'desc'"
                v-if="data.length && !loading"
              />
            </div>
            <template v-if="loading">
              <div class="content has-text-centered">
                <p>Loading...</p>
              </div>
            </template>
            <template v-else-if="!data.length">
              <div class="content has-text-centered">
                <p>There are no results, try expanding the dates of your search</p>
              </div>
            </template>
            <template v-else>
              <initiator-chart :chart-data="visibleTableData" />
              <initiator-table
                :columns="columns"
                :tableData="formattedData"
                :linkBuilder="(l) => '/initiator-rating/' + buildTaxonomyUrl(l)"
                @update:visibleData="(data) => (visibleTableData = data)"
                :comparisonUrl="baseUrl"
                :taxonomy="taxonomy"
              />
            </template>
          </div>
        </div>
      </div>
    </outer-section>
  </div>
</template>

<script>
import ExportData from "../Modules/Pieces/ExportData";
import InitiatorChart from "../Modules/InitiatorRating/InitiatorChart";
import InitiatorTable from "../Modules/InitiatorRating/InitiatorTable";
import TaxonomyBreadcrumbs from "../Modules/Pieces/Breadcrumbs.vue";
import TaxonomyNavigation from "../Mixins/TaxonomyNavigation";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../Modules/State/SingleViewStorePinia";
import { UseDateStore } from "../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../Modules/State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseTaxonomyHelper } from "../Helper/Stateful/UseTaxonomyHelper";
import { UseBreadcrumbBuilder } from "../Helper/Stateful/BreadcrumbBuilder";
import { UseColumnModalHelper } from "../Helper/Stateful/UseColumnModalHelper";

export default {
  title: "Initiator Rating",
  components: {
    ExportData,
    InitiatorChart,
    InitiatorTable,
    TaxonomyBreadcrumbs,
  },
  mixins: [TaxonomyNavigation],
  props: {
    taxonomyOne: {
      type: String,
      required: false,
      default: null,
    },
    taxonomyTwo: {
      type: String,
      required: false,
      default: null,
    },
    taxonomyThree: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram, getCurrentProgramName } = storeToRefs(UseProgramStore());

    const { currentTaxonomyInteger, taxonomyRouteParams } = UseTaxonomyHelper();
    const { breadcrumbBuilder } = UseBreadcrumbBuilder();
    const columnsInitial = [
      "total_funnel_revenue",
      "upper_solo_revenue",
      "upper_solo_revenue_percent",
    ];
    const { columns } = UseColumnModalHelper(columnsInitial);
    taxonomyRouteParams();
    breadcrumbBuilder("/initiator-rating");
    UseAxiosErrorHelper();
    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
      getCurrentProgramName,
      currentTaxonomyInteger,
      columns,
    };
  },
  computed: {
    taxonomy() {
      return this.currentTaxonomyInteger;
    },
    url() {
      return (
        this.baseUrl +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo +
        "&interval=" +
        this.interval
      );
    },
    baseUrl() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/funnel/single/taxonomy_1" +
        (this.taxonomyOne
          ? "/" + this.validateApiParameter(this.taxonomyOne) + "/taxonomy_2"
          : "") +
        (this.taxonomyTwo
          ? "/" + this.validateApiParameter(this.taxonomyTwo) + "/taxonomy_3"
          : "") +
        (this.taxonomyThree
          ? "/" + this.validateApiParameter(this.taxonomyThree) + "/taxonomy_4"
          : "")
      );
    },
    formattedData() {
      return (
        this.data?.map((d) => ({
          location: [
            ...this.taxonomyLocation,
            d.taxonomy_4 || d.taxonomy_3 || d.taxonomy_2 || d.taxonomy_1,
          ].filter((t) => t !== null),
          taxonomy_1: d.taxonomy_1,
          taxonomy_2: d.taxonomy_2,
          taxonomy_3: d.taxonomy_3,
          taxonomy_4: d.taxonomy_4,
          upper_solo_revenue: d.upper_funnel_revenue + d.solo_revenue,
          mid_lower_revenue: d.mid_funnel_revenue + d.lower_funnel_revenue,
          total_funnel_revenue:
            d.upper_funnel_revenue +
            d.mid_funnel_revenue +
            d.lower_funnel_revenue +
            d.solo_revenue,
          upper_solo_revenue_percent:
            d.upper_funnel_revenue_percent + d.solo_revenue_percent,
          mid_lower_revenue_percent:
            d.mid_funnel_revenue_percent + d.lower_funnel_revenue_percent,
        })) || []
      );
    },
    exportData() {
      return this.formattedData.map((d) => ({
        ...d,
        taxonomy_1: d.location[0],
        ...(d.location.length > 1 ? { taxonomy_2: d.location[1] } : {}),
        ...(d.location.length > 2 ? { taxonomy_3: d.location[2] } : {}),
        ...(d.location.length > 3 ? { taxonomy_4: d.location[3] } : {}),
        mid_lower_revenue_percent: d.mid_lower_revenue_percent / 100,
        upper_solo_revenue_percent: d.upper_solo_revenue_percent / 100,
      }));
    },
    exportColumns() {
      return [
        "taxonomy_1",
        ...(this.taxonomyOne !== null ? ["taxonomy_2"] : []),
        ...(this.taxonomyTwo !== null ? ["taxonomy_3"] : []),
        ...(this.taxonomyThree !== null ? ["taxonomy_4"] : []),
        ...this.columns,
      ];
    },
    currentLocation() {
      return [this.taxonomyOne, this.taxonomyTwo, this.taxonomyThree].filter(
        (taxonomyName) => taxonomyName != null
      );
    },
    previousPage() {
      return (
        "/initiator-rating/" +
        (taxonomyOne ? validateApiParameter(taxonomyOne) + "/" : "") +
        (taxonomyTwo ? validateApiParameter(taxonomyTwo) + "/" : "") +
        (taxonomyThree ? validateApiParameter(taxonomyThree) + "/" : "") +
        validateInternalParameter(props.row.taxonomyName)
      );
    },
  },
  data() {
    return {
      data: [],
      interval: "day",
      loading: true,
      visibleTableData: [],
    };
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.loadAsyncData();
      },
    },
  },
};
</script>

<style scoped>
.breadcrumo-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

.content.has-text-centered {
  padding: 4em;
}
</style>
