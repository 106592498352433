export const UseValidateHelper = () => {
  const isRequired = (text: string) => (value: string) => {
    if (value && value.trim()) {
      return true;
    }
    return text;
  };

  const numericalAndDecimalValidator = (text: string) => (value: string) => {
    if (!/^\d*\.?\d*$/.test(value)) {
      return text;
    }
    return true;
  };

  const twoDecimalValidator = (text: string) => (value: string) => {
    if (!/^\d+(.\d{2})$/.test(value)) {
      return text;
    } else {
      return true;
    }
  };

  const isDate = (text: string) => (value: Date) => {
    if (value) {
      return true;
    }
    return text;
  };

  return {
    isRequired,
    twoDecimalValidator,
    numericalAndDecimalValidator,
    isDate,
  };
};
