<template>
  <div>
    <!-- Responsiveness, gotta make 2x buttons, one for touch and one for desktop -->
    <new-pagination
      v-model:records="recordsCompute"
      v-model:page="pageCompute"
      v-model:per-page="perPageCompute"
      class="is-hidden-touch is-pulled-right"
    ></new-pagination>
    <o-field grouped group-multiline class="is-hidden-mobile">
      <search-component
        :search="search"
        ref="searchComponentControlsOne"
      ></search-component>

      <o-field>
        <p class="control" v-if="exportable">
          <slot name="export">
            <!--Export-->
          </slot>
        </p>
        <p class="control">
          <button class="button" @click="isColumnModalActive = true">
            <o-icon icon="table-row-height"></o-icon>
            <span>Columns</span>
          </button>
        </p>
      </o-field>

      <slot name="finder">
        <!--Evan-->
      </slot>

      <new-pagination
        v-model:records="recordsCompute"
        v-model:page="pageCompute"
        v-model:per-page="perPageCompute"
        class="is-hidden-desktop is-hidden-mobile"
      ></new-pagination>
    </o-field>

    <!-- Mobile -->
    <o-field class="is-hidden-tablet">
      <search-component
        :search="search"
        is-mobile
        ref="searchComponentControlsTwo"
      ></search-component>
    </o-field>
    <o-field grouped class="is-hidden-tablet">
      <o-field>
        <p class="control">
          <button class="button" @click="isColumnModalActive = true">
            <o-icon icon="table-row-height"></o-icon>
            <span>Columns</span>
          </button>
        </p>
      </o-field>
    </o-field>
    <div class="is-hidden-tablet">
      <slot name="finder">
        <!--Evan-->
      </slot>
    </div>
    <div class="is-hidden-tablet" v-if="exportable">
      <slot name="export">
        <!--Export-->
      </slot>
    </div>
    <new-pagination
      v-model:records="recordsCompute"
      v-model:page="pageCompute"
      v-model:per-page="perPageCompute"
      class="is-hidden-tablet"
    ></new-pagination>

    <br class="is-hidden-tablet" />

    <section>
      <o-modal
        v-model:active="isColumnModalActive"
        has-modal-card
        ref="columnModal"
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Columns</p>
          </header>
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <template v-for="column in availableColumnsChunked">
                <div class="column is-half">
                  <o-checkbox v-model="columnsCompute" :native-value="column">
                    {{ headers[column] || column }}
                  </o-checkbox>
                </div>
              </template>
            </div>
          </section>
          <footer class="modal-card-foot">
            <o-button @click="$refs.columnModal.close()" variant="primary"
              >Close</o-button
            >
            <o-button @click="setDefaultColumns" icon-left="restart"
              >Reset to Defaults</o-button
            >
          </footer>
        </div>
      </o-modal>
    </section>
  </div>
</template>

<script>
import SearchComponent from "./Component";
import NewPagination from "../../Breakdown/NewPagination";

export default {
  components: { NewPagination, SearchComponent },
  computed: {
    columnsCompute: {
      get() {
        return this.columns;
      },
      set(val) {
        this.$emit("update:columns", val);
      },
    },
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    recordsCompute: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },
    availableColumnsChunked() {
      let chunked = _.chunk(
        this.availableColumns,
        _.ceil(this.availableColumns.length / 2)
      );

      let rtn = [];

      _.forEach(chunked[0], function (value, index) {
        rtn.push(value);

        if (_.get(chunked[1], index)) {
          rtn.push(chunked[1][index]);
        }
      });

      return rtn;
    },
  },
  data() {
    return {
      isColumnModalActive: false,
    };
  },
  props: {
    headers: {
      type: Object,
      default: {},
    },
    availableColumns: {
      type: Array,
      default: [],
    },
    columns: {},
    search: "",
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    records: {
      type: Number,
      default: 0,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setDefaultColumns() {
      this.columnsCompute = [
        "total_cost",
        "clicks",
        "last_click_sales",
        "last_click_revenue",
        "attributed_sales",
        "attributed_revenue",
        "cpa",
        "roas",
        "attributed_revenue_variance_percent",
      ];
    },
    previousPage() {
      this.pageCompute = this.page - 1;
    },
    nextPage() {
      this.pageCompute = this.page + 1;
    },
  },
};
</script>
