import { sortBy, find, get, first, filter, findIndex } from "lodash";
import { defineStore } from "pinia";
import CONSTANTS from "../Constants";
import {
  IGetAvailablePrograms,
  TGetCurrentProgram,
  ICustomReports,
  IGetAllProgramMessages,
  TGetCurrentProgramDataSets,
  TGetCurrentProgramMessages,
} from "../Typings/SingleViewStoreTypes";

export interface ProgramState {
  programMessages: IGetAllProgramMessages[] | null;
  currentProgram: TGetCurrentProgram | null;
  availablePrograms: IGetAvailablePrograms[] | null;
}

export const UseProgramStore = defineStore("program", {
  state: (): ProgramState => ({
    programMessages: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_PROGRAM_MESSAGES) || "[]"
    ),
    currentProgram: localStorage.getItem(CONSTANTS.SINGLEVIEW_PROGRAM),
    availablePrograms: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_AVAILABLE_PROGRAMS) || "[]"
    ),
  }),
  getters: {
    getCurrentProgram: (state) =>
      state.currentProgram ? state.currentProgram : "",
    getCurrentProgramName: (state): TGetCurrentProgram => {
      if (state.availablePrograms) {
        const currentProgramName = (
          state.availablePrograms as IGetAvailablePrograms[]
        ).find((prog) => prog.programcode === state.currentProgram);
        if (currentProgramName) {
          return currentProgramName.program_name;
        }
      }
      return "";
    },
    getCurrentProgramCustomReports: (state): ICustomReports[] => {
      if (state.availablePrograms) {
        const currentProgram = (
          state.availablePrograms as IGetAvailablePrograms[]
        ).find((prog) => prog.programcode === state.currentProgram);

        if (currentProgram) {
          return currentProgram.program_custom_reports;
        }
      }
      return [];
    },
    getAvailablePrograms: (state): IGetAvailablePrograms[] => {
      if (state.availablePrograms?.length) {
        return state.availablePrograms;
      }
      return [];
    },
    getCurrentProgramDataSets: (state): TGetCurrentProgramDataSets[] => {
      if (state.availablePrograms?.length) {
        const currentProgram = (
          state.availablePrograms as IGetAvailablePrograms[]
        ).find((prog) => prog.programcode === state.currentProgram);

        if (currentProgram) {
          return currentProgram.program_data_sets;
        }
      }
      return [];
    },
    getAllProgramMessages: (state) => state.programMessages,
    getCurrentProgramMessages: (state): TGetCurrentProgramMessages => {
      if (state.availablePrograms?.length) {
        const currentProgram = (
          state.availablePrograms as IGetAvailablePrograms[]
        ).find((prog) => prog.programcode === state.currentProgram);

        if (currentProgram) {
          return currentProgram.program_messages;
        }
      }
      return [];
    },
  },
  actions: {
    setProgram(val: string, fn: Function) {
      this.currentProgram = val;
      localStorage.setItem(CONSTANTS.SINGLEVIEW_PROGRAM, val);
      fn();
    },
    removeProgramMessages() {
      this.programMessages = [];
      localStorage.removeItem(CONSTANTS.SINGLEVIEW_PROGRAM_MESSAGES);
    },
    removeAvailablePrograms() {
      // First time work-around
      this.availablePrograms = [];
      localStorage.removeItem(CONSTANTS.SINGLEVIEW_AVAILABLE_PROGRAMS);
    },
    updateAvailablePrograms(programs: IGetAvailablePrograms[]) {
      let available = sortBy(programs, "program_name");

      if (
        (this.currentProgram ||
          localStorage.getItem(CONSTANTS.SINGLEVIEW_PROGRAM)) &&
        find(available, { programcode: this.currentProgram })
      ) {
        let existing = get(
          find(available, { programcode: this.currentProgram }),
          "programcode"
        );

        // First time work-around
        this.currentProgram = existing;
        localStorage.setItem(CONSTANTS.SINGLEVIEW_PROGRAM, existing);
      } else {
        let firstProg = get(first(available), "programcode");

        // First time work-around
        this.currentProgram = firstProg as string;
        localStorage.setItem(CONSTANTS.SINGLEVIEW_PROGRAM, firstProg as string);
      }

      // First time work-around
      this.availablePrograms = available;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_AVAILABLE_PROGRAMS,
        JSON.stringify(available)
      );
    },
    updateProgramMessages(messages: IGetAllProgramMessages[]) {
      this.programMessages = messages;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_PROGRAM_MESSAGES,
        JSON.stringify(messages)
      );
    },
    readProgramMessage(id: string) {
      let msgs = this.getAllProgramMessages;
      let i = findIndex(msgs, ["programcode", this.getCurrentProgram]);

      if (msgs) {
        let current = msgs[i];
        current.program_messages = filter(
          current.program_messages,
          (x) => x[id] !== id
        );
        msgs.splice(i, 1, current);
        this.updateProgramMessages(msgs);
      }
    },
  },
});
