<template>
  <div class="tw-mb-4">
    <o-field expanded>
      <o-inputitems
        v-model="tags"
        :data="filteredData"
        :disabled="!hasInput"
        autocomplete
        allow-duplicates
        :open-on-focus="hasInput"
        ref="autocomplete"
        :closable="true"
        @typing="autoCompleteField"
      >
        <template #selected="props">
          <div class="badge-wrapper tw-flex">
            <div
              v-for="(tag, index) in props.items"
              :key="getNormalizedTagText(tag) + index"
              @click="
                index === tags.length - 1
                  ? $refs.autocomplete.removeItem(index, $event)
                  : null
              "
            >
              <sv-badge
                :color="getTagType(tag)"
                :addonText="index === tags.length - 1 ? 'X' : ''"
              >
                <slot
                  ><div>
                    {{ getNormalizedTagText(tag) }}
                  </div></slot
                >
              </sv-badge>
            </div>
          </div>
        </template>
        <template #[headerSlotName]>
          <slot name="header" />
        </template>

        <template v-slot="props">{{ props.option }}</template>

        <template #[emptySlotName]>
          <slot name="empty" />
        </template>
        <template #[footerSlotName]>
          <slot name="footer" />
        </template>
      </o-inputitems>
    </o-field>
  </div>
</template>

<script>
//Oruga stuff
import { getValueByPath } from "@/node_modules/@oruga-ui/oruga-next/src/utils/helpers";
import FormElementMixin from "@/node_modules/@oruga-ui/oruga-next/src/utils/FormElementMixin";

export default {
  mixins: [FormElementMixin],
  inheritAttrs: false,
  props: {
    allTaxonomiesData: {
      type: Object,
      default: () => {},
    },
    touchpointQuery: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    allowNew: Boolean,
    allowDuplicates: {
      type: Boolean,
      default: false,
    },
    checkInfiniteScroll: {
      type: Boolean,
      default: false,
    },
    appendToBody: Boolean,
  },
  data() {
    return {
      // tags: Array.isArray(this.value) ? this.value.slice(0) : (this.value || []),
      tags: [],
      newTag: "",
      confirmKeyCodes: [13, 188],
      removeOnKeys: [8],
      _elementRef: "input",
      _isTaginput: true,
      filteredData: Object.keys(this.allTaxonomiesData),
      testData: [],
    };
  },
  computed: {
    whatAreWeSearchingFor() {
      let arr = [];
      if (this.tags.length) {
        arr = _.get(this.allTaxonomiesData, this.tags);

        if (this.tags.length < 3) {
          arr = _.keys(arr);
        }
      } else {
        arr = _.keys(this.allTaxonomiesData);
      }
      return _.chain(arr)
        .filter(
          function (str) {
            return _.includes(str.toLowerCase(), this.newTag.toLowerCase());
          }.bind(this)
        )
        .value();
    },
    rootClasses() {
      return {
        "is-expanded": this.expanded,
      };
    },

    containerClasses() {
      return {
        "is-focused": this.isFocused,
        "is-focusable": this.hasInput,
      };
    },

    valueLength() {
      return this.newTag.trim().length;
    },

    defaultSlotName() {
      return this.hasDefaultSlot ? "default" : "dontrender";
    },

    emptySlotName() {
      return this.hasEmptySlot ? "empty" : "dontrender";
    },

    headerSlotName() {
      return this.hasHeaderSlot ? "header" : "dontrender";
    },

    footerSlotName() {
      return this.hasFooterSlot ? "footer" : "dontrender";
    },

    hasDefaultSlot() {
      return !!this.$scopedSlots;
    },

    hasEmptySlot() {
      return !!this.$slots.empty;
    },

    hasHeaderSlot() {
      return !!this.$slots.header;
    },

    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    /**
     * Show the input field if a maxtags hasn't been set or reached.
     */
    hasInput() {
      if (
        this.whatAreWeSearchingFor.length === 1 &&
        this.whatAreWeSearchingFor[0] === "None"
      ) {
        return false;
      }

      return this.tagsLength < 4;
    },
    tagsLength() {
      return this.tags.length;
    },
    taxonomies() {
      return ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"];
    },
  },
  watch: {
    /**
     * When v-model is changed set internal value.
     */
    value(value) {
      this.tags = value;
    },
    tags: {
      handler(value) {
        let taxonomiesLabels = ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"];
        this.$emit(
          "update:touchpointQuery",
          _.chain(_.take(taxonomiesLabels, value.length)).zip(value).fromPairs().value()
        );
      },
      deep: true,
    },
    whatAreWeSearchingFor(val) {
      this.filteredData = val;
    },
  },
  emits: ["input", "add", "remove", "typing", "update:touchpointQuery"],
  mounted() {
    this.filteredData = Object.keys(this.allTaxonomiesData);
  },
  methods: {
    getTagType(tag) {
      if (tag.startsWith("Google Ad")) {
        return "blue";
      }
      if (tag.startsWith("Affiliate")) {
        return "orange";
      }
      if (tag.startsWith("Email")) {
        return "red";
      }
      if (tag.startsWith("Bing")) {
        return "dark";
      }
      if (tag.startsWith("SEO")) {
        return "blue";
      }
      if (tag.startsWith("Direct") || tag.startsWith("Referral")) {
        return "orange";
      }
      if (tag.startsWith("Paid Social") || tag.startsWith("Organic Social")) {
        return "red";
      }
      if (tag.startsWith("Display")) {
        return "dark";
      }
    
      return "gray";
    },

    autoCompleteField(val) {
      if (this.tagsLength >= 4) {
        this.filteredData = [];
        return;
      }
      if (!val.length) {
        if (
          this.whatAreWeSearchingFor.length === 1 &&
          this.whatAreWeSearchingFor[0] === "None"
        ) {
          this.filteredData = [];
          return;
        } else {
          this.filteredData = this.whatAreWeSearchingFor;
          return;
        }
      }
      const filteredTags = this.filteredData.filter((k) =>
        k.toLowerCase().includes(val.toLowerCase())
      );

      if (filteredTags.length) {
        this.filteredData = filteredTags;
      } else {
        this.filteredData = this.whatAreWeSearchingFor;
      }
    },

    addTag(tag) {
      const tagToAdd = tag || this.newTag.trim();

      if (tagToAdd) {
        this.tags = [tagToAdd, ...this.tags];
      }

      this.newTag = "";

      if (this.hasInput) {
        this.$refs.autocomplete.focus();
      }
    },
    getNormalizedTagText(tag) {
      if (typeof tag === "object") {
        return getValueByPath(tag, this.field);
      }
      return tag;
    },

    customOnBlur(event) {
      this.onBlur(event);
    },

    onSelect(option) {
      if (!option) return;

      this.addTag(option);
      this.$nextTick(() => {
        this.newTag = "";
      });
    },

    removeTag(index, event) {
      this.tags.splice(index, 1);
      if (event) event.stopPropagation();
    },

    removeLastTag() {
      if (this.tagsLength > 0) {
        this.removeTag(this.tagsLength - 1);
      }
    },

    keydown(event) {
      if (this.removeOnKeys.indexOf(event.keyCode) !== -1 && !this.newTag.length) {
        this.removeLastTag();
      }
      // Stop if is to accept select only
      if (this.autocomplete && !this.allowNew) return;

      if (this.confirmKeyCodes.indexOf(event.keyCode) >= 0) {
        event.preventDefault();
        this.addTag();
      }
    },

    onTyping(event) {
      this.$emit("typing", event.trim());
    },
  },
};
</script>
