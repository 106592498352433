<template>
  <section class="segment-edit column">
    <div>
      <h2 class="subtitle" v-if="segmentData.length">Edit Segment Items</h2>
      <div v-else class="content has-text-grey has-text-centered">
        <p>
          <o-icon icon="emoticon-sad" size="is-large"></o-icon>
        </p>
        <p>There are no segments. Please add them below</p>
      </div>
      <div class="card box">
        <form action>
          <o-field
            class="radio-field"
            v-if="segmentData.length"
            v-for="segment in segmentData"
            :key="segment.segment_name"
          >
            <o-radio
              v-model="selectedSegment"
              :native-value="segment.segment_name"
              >{{ capitalise(segment.segment_title) }}</o-radio
            >
            <span
              v-if="segment.segment_name === selectedSegment"
              class="radio-field-delete"
              @click="toggleModal = true"
              >Edit Title</span
            >
          </o-field>
          <o-field>
            <button
              type="button"
              class="button is-primary is-outlined"
              @click="showAddSegmentFields = !showAddSegmentFields"
            >
              Add a new Segment
            </button>
          </o-field>
          <div v-if="showAddSegmentFields">
            <o-field class="radio-field-input">
              <o-input
                lazy
                placeholder="Title"
                v-model="additionalSegmentsTitle"
                :value="additionalSegmentsTitle"
              ></o-input>
            </o-field>
            <o-field class="radio-field-input">
              <o-input
                lazy
                :placeholder="additionalSegmentsTag || handeliseTitle || 'Tag'"
                validation-message="Only lowercase, numbers and '_' are allowed"
                pattern="[a-z|0-9||_]*"
                v-model="sanitisedTagInput"
                :value="additionalSegmentsTag"
                @focus="updateTag"
              ></o-input>
            </o-field>
            <SegmentAlert
              :show-duplicate-tag-alert="showDuplicateTagAlert"
              :alert-string="alertString"
            />
            <o-field>
              <button
                type="button"
                :disabled="disabled"
                class="button is-primary"
                @click="uploadNewSegments"
              >
                Upload
              </button>
            </o-field>
            <div v-if="isLoading">
              <o-loading
                class="is-loader-overlay"
                :is-full-page="false"
                v-model:active="isLoading"
              ></o-loading>
            </div>
          </div>
        </form>
      </div>
    </div>
    <o-modal
      v-model:active="toggleModal"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <modal-body>
        <template v-slot:header>
          <UpdateGroupHeader
            :text="'You are editing ' + selectedSegment + '\'s title'"
          />
        </template>
        <template v-slot:input>
          <UpdateGroupInput v-model="newSegmentTitle" />
        </template>
        <template v-slot:button>
          <div class="button-group">
            <button
              type="button"
              class="button is-danger"
              @click="editSegment(selectedSegment, newSegmentTitle)"
            >
              Edit
            </button>
            <button
              type="button"
              class="button is-primary"
              @click="toggleModal = false"
            >
              Cancel
            </button>
          </div>
        </template>
      </modal-body>
    </o-modal>
  </section>
</template>

<script>
import { watchEffect } from "vue";
import SegmentAlert from "../SegmentAlert.vue";
import ModalBody from "../ModalBody.vue";
import UpdateGroupHeader from "../UpdateGroupComponents/UpdateGroupHeader.vue";
import UpdateGroupInput from "../UpdateGroupComponents/UpdateGroupInput";
import { UseSegementStore } from "../../State/Stores/SegmentStore";
import { UseStringHelper } from "../../../Helper/Functional/UseStringHelper";

export default {
  name: "UpdateSegments",
  props: {
    segmentData: {
      type: Array,
      default: () => [],
    },
    groupName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { editSegmentTitle, addSegmentData } = UseSegementStore();
    const { capitalise } = UseStringHelper();

    return {
      editSegmentTitle,
      addSegmentData,
      capitalise,
    };
  },
  computed: {
    handeliseTitle() {
      return this.removeUnwantedCharacters(this.additionalSegmentsTitle);
    },
    sanitisedTagInput: {
      get() {
        return this.additionalSegmentsTag;
      },
      set(char) {
        if (this.showDuplicateTagAlert) {
          this.showDuplicateTagAlert = false;
        }
        this.additionalSegmentsTag = this.removeUnwantedCharacters(char);
      },
    },
  },
  data() {
    return {
      selectedSegment: "",
      newSegmentTitle: "",
      additionalSegmentsTitle: "",
      additionalSegmentsTag: "",
      showAddSegmentFields: false,
      disabled: true,
      showDuplicateTagAlert: false,
      toggleModal: false,
      isLoading: false,
      alertString: "",
    };
  },
  methods: {
    editSegment(name, title) {
      axios({
        method: "patch",
        url: this.url + "/" + this.groupName + "/segments" + "/" + name,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          segment_title: title,
        },
      }).then(({ data }) => {
        if (data) {
          this.editSegmentTitle({
            group_name: this.groupName,
            segment_name: name,
            segment_title: title,
          });
          this.toggleModal = false;
        }
      });
    },
    uploadNewSegments() {
      //Check to see if tag already exists.
      const tagAlreadyExists = this.findDuplicatedStrings(
        this.additionalSegmentsTag,
        "segment_name"
      );
      const titleAlreadyExists = this.findDuplicatedStrings(
        this.additionalSegmentsTitle,
        "segment_title"
      );
      if (titleAlreadyExists) {
        //Alert
        this.alertString =
          "This title already exists. Please choose another one";
        this.showDuplicateTagAlert = true;
        this.disabled = true;
        this.additionalSegmentsTitle = "";
        return;
      }
      if (tagAlreadyExists) {
        //Alert
        this.alertString = "This tag already exists. Please choose another one";
        this.showDuplicateTagAlert = true;
        this.disabled = true;
        this.additionalSegmentsTag = "";
        return;
      }
      this.showDuplicateTagAlert = false;
      this.isLoading = true;
      axios({
        method: "post",
        url: this.url + "/" + this.groupName + "/segments",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          segment_title: this.additionalSegmentsTitle,
          segment_name: this.additionalSegmentsTag,
        },
      })
        .then(({ data }) => {
          if (data) {
            this.addSegmentData({
              group_name: this.groupName,
              segment_title: this.additionalSegmentsTitle,
              segment_name: this.additionalSegmentsTag,
            });

            this.additionalSegmentsTitle = "";
            this.additionalSegmentsTag = "";
            this.showAddSegmentFields = false;
            this.isLoading = false;
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    removeUnwantedCharacters(str = "") {
      return str
        .toLowerCase()
        .replaceAll(/[!"#£$%&'()*+,-./:;<=>?@[\]^`{|}~]/g, "")
        .replaceAll(" ", "_");
    },
    findDuplicatedStrings(str = "", seg_name = "") {
      return this.segmentData.some((seg) => seg[seg_name] === str);
    },
    updateTag() {
      if (this.additionalSegmentsTag === "") {
        this.additionalSegmentsTag = this.handeliseTitle;
      }
    },
  },
  mounted() {
    watchEffect(() => {
      if (this.additionalSegmentsTitle && this.additionalSegmentsTag) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    });
  },
  components: { SegmentAlert, ModalBody, UpdateGroupHeader, UpdateGroupInput },
};
</script>

<style lang="scss">
@import "../../../../../sass/_variables.scss";

.segment-edit {
  .card {
    margin-top: 1rem;

    .radio-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .radio-field-delete {
        cursor: pointer;
        text-decoration: underline;
        color: $red;
      }
    }

    .radio-field-input {
      padding-top: 0.5rem;
      margin-top: 1rem;
      border-top: 1px solid $border-color;
    }
  }

  .modal {
    .animation-content {
      z-index: 1;
      .button-group {
        display: flex;
        justify-content: space-between;

        .button {
          width: unset;
        }
      }
    }
  }
}
</style>
