<template>
  <div>
    <div class="tw-my-4">
      <o-dropdown v-model="useAbsoluteValues" aria-role="list">
        <template #trigger>
          <sv-button endIcon="expandMore">
            {{ useAbsoluteValues ? "Revenue" : "Revenue Percent" }}
          </sv-button>
        </template>

        <o-dropdown-item :value="true" aria-role="listitem">
          Revenue
        </o-dropdown-item>
        <o-dropdown-item :value="false" aria-role="listitem">
          Revenue Percent
        </o-dropdown-item>
      </o-dropdown>
    </div>

    <v-chart class="chart" :option="chartOptions" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TooltipComponent,
  DatasetComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";

use([
  CanvasRenderer,
  BarChart,
  TooltipComponent,
  DatasetComponent,
  LegendComponent,
]);

const seriesDefaults = {
  stack: "total",
  areaStyle: {},
  showSymbol: false,
  itemStyle: {
    borderColor: "transparent",
    borderWidth: 2,
  },
  emphasis: {
    focus: "series",
  },
};

const defaultTextStyle = {
  color: "#4a4a4a",
  fontSize: 16,
  margin: 20,
  fontFamily:
    'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
};

export default {
  components: { VChart },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    multiFunnelData: {
      type: Object,
      required: true,
      validator: (val) => {
        return (
          typeof val?.name === "string" &&
          val.name !== "" &&
          Array.isArray(val.data) &&
          val.data.every(
            (d) =>
              typeof d?.conversion_date === "string" &&
              d.conversion_date !== "" &&
              typeof d?.upper_funnel_revenue_percent === "number" &&
              typeof d?.lower_funnel_revenue_percent === "number" &&
              typeof d?.mid_funnel_revenue_percent === "number" &&
              typeof d?.solo_revenue_percent === "number" &&
              typeof d?.upper_funnel_revenue === "number" &&
              typeof d?.lower_funnel_revenue === "number" &&
              typeof d?.mid_funnel_revenue === "number" &&
              typeof d?.solo_revenue === "number"
          )
        );
      },
    },
  },
  data: () => ({
    useAbsoluteValues: true,
  }),
  setup() {
    const { displayMonetaryValue, displayNumericValueRounded } =
      UseNumberHelper();

    return {
      displayMonetaryValue,
      displayNumericValueRounded,
    };
  },
  computed: {
    chartType() {
      return this.useAbsoluteValues ? "bar" : "line";
    },
    chartOptions() {
      return {
        color: ["#009AFF", "#89D0FF", "#A6A6A6", "#5D5D5D"],
        textStyle: {
          ...defaultTextStyle,
        },
        legend: {
          bottom: 0,
          itemGap: 30,
          itemWidth: 16,
          itemHeight: 16,
          icon: "roundRect",
        },
        grid: {
          containLabel: true,
          left: 10,
          top: 12,
          right: 10,
          bottom: 45,
        },
        series: [
          {
            name: "Solo",
            data: this.soloSeriesData,
            type: this.chartType,
            ...seriesDefaults,
          },
          {
            name: "Upper",
            type: this.chartType,
            data: this.upperSeriesData,
            ...seriesDefaults,
          },
          {
            name: "Mid",
            type: this.chartType,
            data: this.midSeriesData,
            ...seriesDefaults,
          },
          {
            name: "Lower",
            type: this.chartType,
            data: this.lowerSeriesData,
            ...seriesDefaults,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (series) => {
            return (
              `<strong>${series[0].axisValueLabel.split(" ")[0]}</strong>
                <table class="tooltip-table">
                  <tbody>` +
              series
                .map((s) => {
                  return `<tr>
                    <td class="flex gap-2" style="align-items:center">
                      <span
                        class="inline-block w-4 h-4 rounded-lg"
                        style="background-color: ${s.color}"
                      ></span> ${s.seriesName}
                    </td>
                    <td align="right"><strong>${s.data[2]}</strong></td>
                    <td align="right"><strong>${s.data[3]}</strong></td>
                  </tr>`;
                })
                .join("") +
              "</tbody></table>"
            );
          },
        },
        xAxis: {
          type: "time",
          axisLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisLabel: {
            ...defaultTextStyle,
            formatter: {
              month: "{month|{MMM}}",
              day: "{d}",
            },
            rich: {
              month: {
                ...defaultTextStyle,
                fontWeight: "bold",
              },
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#c4c4c4",
            },
          },
          axisLabel: {
            ...defaultTextStyle,
            margin: 15,
            formatter: this.useAbsoluteValues
              ? (value) => this.displayMonetaryValue(value)
              : "{value}%",
          },
        },
      };
    },
    correctedFunnelData() {
      return this.multiFunnelData.data.map(
        ({
          solo_revenue_percent,
          upper_funnel_revenue_percent,
          mid_funnel_revenue_percent,
          lower_funnel_revenue_percent,
          ...d
        }) => {
          const total =
            solo_revenue_percent +
            upper_funnel_revenue_percent +
            mid_funnel_revenue_percent +
            lower_funnel_revenue_percent;

          return {
            ...d,
            solo_revenue_percent,
            upper_funnel_revenue_percent,
            mid_funnel_revenue_percent,
            lower_funnel_revenue_percent,
            solo_percent: total <= 0 ? 0 : (100 * solo_revenue_percent) / total,
            upper_percent:
              total <= 0 ? 0 : (100 * upper_funnel_revenue_percent) / total,
            mid_percent:
              total <= 0 ? 0 : (100 * mid_funnel_revenue_percent) / total,
            lower_percent:
              total <= 0 ? 0 : (100 * lower_funnel_revenue_percent) / total,
          };
        }
      );
    },
    soloSeriesData() {
      return this.correctedFunnelData.map((d) => [
        d.conversion_date,
        this.useAbsoluteValues ? d.solo_revenue : d.solo_percent,
        this.displayMonetaryValue(d.solo_revenue),
        this.displayNumericValueRounded(d.solo_revenue_percent) + "%",
      ]);
    },
    upperSeriesData() {
      return this.correctedFunnelData.map((d) => [
        d.conversion_date,
        this.useAbsoluteValues ? d.upper_funnel_revenue : d.upper_percent,
        this.displayMonetaryValue(d.upper_funnel_revenue),
        this.displayNumericValueRounded(d.upper_funnel_revenue_percent) + "%",
      ]);
    },
    midSeriesData() {
      return this.correctedFunnelData.map((d) => [
        d.conversion_date,
        this.useAbsoluteValues ? d.mid_funnel_revenue : d.mid_percent,
        this.displayMonetaryValue(d.mid_funnel_revenue),
        this.displayNumericValueRounded(d.mid_funnel_revenue_percent) + "%",
      ]);
    },
    lowerSeriesData() {
      return this.correctedFunnelData.map((d) => [
        d.conversion_date,
        this.useAbsoluteValues ? d.lower_funnel_revenue : d.lower_percent,
        this.displayMonetaryValue(d.lower_funnel_revenue),
        this.displayNumericValueRounded(d.lower_funnel_revenue_percent) + "%",
      ]);
    },
    series() {
      return [
        {
          name: "Solo",
          data: this.soloSeriesData,
        },
        {
          name: "Upper",
          data: this.upperSeriesData,
        },
        {
          name: "Mid",
          data: this.midSeriesData,
        },
        {
          name: "Lower",
          data: this.lowerSeriesData,
        },
      ];
    },
  },
};
</script>

<style scoped>
.chart {
  height: 30em;
}
:deep(.tooltip-table td) {
  border-left: 1rem solid transparent;
  border-top: 0.25rem solid transparent;
}
:deep(.tooltip-table td:first-child) {
  border-left: 0;
}
</style>
