<template>
  <report-modal
    v-model:modalActive="reportCreateModal"
    v-model:reportData="reportData"
    :all-taxonomies-data="passAllTaxonomyDataParsed"
  >
  </report-modal>
  <div class="column is-full">
    <o-loading full-page v-model:active="loading"></o-loading>
    <div class="box">
      <div class="table-controls">
        <div class="is-clearfix">
          <span>Crossover Analysis</span>
          <button
            :disabled="taxonomiesLoading"
            class="button is-primary is-pulled-right"
            @click="reportCreateModal = true"
          >
            Create Report
          </button>
        </div>
      </div>
      <o-table v-if="data" :data="data">
        <o-table-column
          field="date_from"
          label="Date From"
          sortable
          v-slot:default="props"
        >
          {{ props.row.date_from }}
        </o-table-column>
        <o-table-column field="date_to" label="Date To" sortable v-slot:default="props">
          {{ props.row.date_to }}
        </o-table-column>
        <o-table-column
          field="entity_1"
          label="Publisher 1"
          sortable
          v-slot:default="props"
        >
          <sv-badge :color="getTagType(props.row.entity_1.taxonomy_1)">
            {{ formatEntity(props.row.entity_1) }}
          </sv-badge>
        </o-table-column>
        <o-table-column
          field="entity_2"
          label="Publisher 2"
          sortable
          v-slot:default="props"
        >
          <sv-badge :color="getTagType(props.row.entity_2.taxonomy_1)">
            {{ formatEntity(props.row.entity_2) }}
          </sv-badge>
        </o-table-column>
        <o-table-column field="link" label="Status" v-slot:default="props">
          <span v-if="props.row.status !== 'Complete'">{{ props.row.status }}</span>
          <o-button
            v-else
            tag="router-link"
            :to="{ name: 'CrossoverReport', params: { id: props.row.id } }"
            >View Report</o-button
          >
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../State/SingleViewStorePinia";
import { UseProgramStore } from "../State/Stores/ProgramStore";
import { UseUrlBuilder } from "../../Helper/Stateful/UseUrlBuilder";
import { UseBadgeHelper } from "../../Helper/Functional/UseBadgeHelper";
import axios from "axios";
import ReportModal from "./ReportModal.vue";

import { map, mapValues, groupBy } from "lodash";

const { getCurrentProgram } = storeToRefs(UseProgramStore());
const { getApiBaseUrl } = storeToRefs(UseMainStore());

const data = ref(null);
const reportCreateModal = ref(false);
const reportData = ref(null);
const loading = ref(false);
const taxonomiesLoading = ref(false);

const { getTagType } = UseBadgeHelper();

const reportsUrl = computed(() => {
  return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/performance/crossover`;
});

const formatEntity = (entity) => {
  let entityStr = "";
  const entityArr = Object.values(entity);

  for (const i in entityArr) {
    if (i === "0") {
      entityStr += entityArr[i];
      continue;
    }

    if (entityArr[i]) entityStr += ` (${entityArr[i]})`;
  }
  return entityStr;
};

const getExistingReports = async () => {
  loading.value = true;
  axios.get(reportsUrl.value).then((d) => {
    data.value = d.data.data;
    loading.value = false;
  });
};
const { basicURlBuilder: taxonomiesUrl } = UseUrlBuilder({
  dataSet: "",
});

const taxonomiesData = ref([]);

const loadAvailableTaxonomies = () => {
  taxonomiesLoading.value = true;
  axios
    .get(taxonomiesUrl.value)
    .then(({ data }) => {
      taxonomiesLoading.value = false;
      taxonomiesData.value = data.data;
    })
    .catch(() => {
      taxonomiesLoading.value = false;
    });
};

onMounted(() => {
  loadAvailableTaxonomies();
});

watch(taxonomiesUrl, () => {
  loadAvailableTaxonomies();
});
const passAllTaxonomyDataParsed = computed(() => {
  return mapValues(groupBy(taxonomiesData.value, "taxonomy_1"), (a) =>
    mapValues(groupBy(a, "taxonomy_2"), (b) =>
      mapValues(groupBy(b, "taxonomy_3"), (c) => map(c, "taxonomy_4"))
    )
  );
});

getExistingReports();
</script>
