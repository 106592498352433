<template>
  <div>
    <outer-section>
      <div class="is-clearfix">
        <button
          class="button is-primary is-pulled-right"
          @click="isCreateModalActive = true"
        >
          Create Report
        </button>
      </div>

      <br />
      <div class="box contains-loader">
        <o-loading :full-page="false" :active="loading"></o-loading>
        <o-table
          class="has-sticky-table-headers"
          ref="reportsIndexTable"
          narrowed
          hoverable
          :data="reportData"
        >
          <template>
            <!-- ID -->
            <o-table-column field="id" label="ID" sortable v-slot="props"
              >#{{ props.row.id }}</o-table-column
            >

            <!-- Name -->
            <o-table-column field="name" label="Report Name" sortable v-slot="props">
              <router-link :to="'/reports/view/' + props.row.id">
                {{ props.row.name }}
              </router-link>
            </o-table-column>

            <!-- Level -->
            <o-table-column field="level" label="Report Level" sortable v-slot="props">
              <span class="is-capitalized">
                {{ displayLevel(props.row.level) }}
              </span>
            </o-table-column>

            <!-- Number of Filters -->
            <o-table-column
              field="number_of_rules"
              label="Rules Applied"
              sortable
              centered
              v-slot="props"
            >
              <span class="is-capitalized">
                {{ props.row.number_of_rules }}
              </span>
            </o-table-column>
          </template>

          <template #empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="!loading">
                  <p>
                    <o-icon icon="emoticon-sad" size="is-large"></o-icon>
                  </p>
                  <p>You have no reports to show</p>
                  <a @click="isCreateModalActive = true">Create one here</a>
                </template>
              </div>
            </section>
          </template>
        </o-table>
      </div>
    </outer-section>

    <configuration
      v-model:modal-active="isCreateModalActive"
      v-model:name-prop="reportName"
      v-model:level-prop="reportLevel"
      v-model:rules-prop="rules"
      :previewing="true"
    ></configuration>
  </div>
</template>

<script>
import ReportFilter from "./Filter";
import Configuration from "./Configuration/Configuration";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseAxiosErrorHelper } from "../../Helper/ThirdParty/UseAxiosErrorHelper";

export default {
  title: "Custom Reports",
  components: { Configuration, ReportFilter },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    UseAxiosErrorHelper();
    return {
      getApiBaseUrl,
    };
  },
  computed: {
    url() {
      return this.getApiBaseUrl + "/reports/configuration";
    },
    reportData() {
      let copy = this.data;

      return _.map(
        copy,
        function (value) {
          _.assign(value, { number_of_rules: value.rules.length });

          return value;
        }.bind(this)
      );
    },
  },
  data() {
    return {
      isCreateModalActive: false,
      rules: [
        {
          configuration: {
            metric: "",
            filter: "",
            value: "",
            editing: true,
            rule: 1,
          },
        },
      ],
      reportName: "",
      reportLevel: "taxonomy_1",
      data: [],
      loading: true,
    };
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.data = [];
          this.loading = false;
        });
    },
    delete(report) {
      swal
        .fire({
          type: "warning",
          title: "Are you sure?",
          text: 'Do you want to delete the report "' + report.name + '"',
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            axios
              .delete(this.url + "/" + report.id, {})
              .then(() => {
                this.$router.go();
              })
              .catch(() => {
                this.$router.go();
              });
          }
        });
    },
    displayLevel(value) {
      return value.replace(/_/g, " ");
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style>
.fuckentiny {
  min-width: 20px;
}
</style>
