import { UseSegementStore } from "./../../../../Modules/State/Stores/SegmentStore";
import { storeToRefs } from "pinia";
import { useRoute, RouteParams } from "vue-router";
import { computed, reactive, ref } from "vue";
import { assign } from "lodash";
import xlsx, { WorkBook, Sheet2JSONOpts } from "xlsx";

interface ISegGroupParams extends RouteParams {
  group: string;
}

type TConfigiurationShape = {
  order_id: {
    type: "dynamic" | "static";
    value: string;
  };
  segment: {
    type: "dynamic" | "static";
    value: string;
  };
};

export type TJsonFromSheet = Record<string, string>;

const unknownSegmentNamesRef = ref<{ segmentName: string; index: number }[]>(
  []
);
const currentToJsonRef = ref<TJsonFromSheet[]>([]);
const file = ref<WorkBook | File | null>(null);
const showError = ref(false);
const showLastStep = ref(false);
const uniqueId = ref("");
const currentSheet = ref("");
const showUniqueIdField = ref(false);
const step = ref(1);
const skipRows = ref(0);
const firstRowContainsHeaders = ref(false);
const configuration = reactive<TConfigiurationShape>({
  order_id: {
    type: "dynamic",
    value: "",
  },
  segment: {
    type: "dynamic",
    value: "",
  },
});
const rows = ref(0);

const allUniqueIds = ref<{ upload_id: string }[]>([]);

let availableColumns = ref<string[]>([]);

export const UseUpload = () => {
  const { getSegmentGroupData: allSegmentGroupData } = storeToRefs(
    UseSegementStore()
  );
  const { params } = useRoute();
  const segmentGroup = ref((params as ISegGroupParams).group || "");

  const getGroupTitle = (val = "") => {
    const group = allSegmentGroupData.value.find((g) => g.group_name === val);
    if (group) {
      return group.group_title;
    }
  };
  const uniqueSheetId = computed({
    get() {
      return uniqueId.value;
    },
    set(val: string) {
      uniqueId.value = val;
      if (uniqueId.value.length === 0) {
        uniqueId.value = currentSheet.value;
      }
    },
  });

  const allSheets = computed(() => {
    if (file.value === null) {
      return [];
    } else {
      return (file.value as WorkBook).SheetNames;
    }
  });

  const segmentGroupData = computed(() => {
    return allSegmentGroupData.value.find(
      (seg) => seg.group_name === params.group
    );
  });

  //Actions

  const setCurrentToJsonRef = (val: TJsonFromSheet[]) => {
    currentToJsonRef.value = val;
  };
  const setCurrentToJsonRefString = (index: number, val: string) => {
    currentToJsonRef.value[index][availableColumns.value[1]] = val;
  };

  const setConfigurationValues = (arr: string[]) => {
    configuration.order_id.value = arr[0];
    configuration.segment.value = arr[1];
  };

  const fileDataExtractorToJson = (newFile: WorkBook) => {
    return xlsx.utils.sheet_to_json<TJsonFromSheet>(
      newFile.Sheets[currentSheet.value],
      assign(firstRowContainsHeaders.value ? {} : { header: "A" }, {
        range: skipRows.value,
        cellDates: true,
        cellNf: true,
        cellText: false,
        raw: false,
        blankrows: true,
        defval: "",
      }) as Sheet2JSONOpts
    );
  };

  const sheetSegments = computed(() =>
    currentToJsonRef.value.map((segs, index) => ({
      segmentName: segs[availableColumns.value[1]],
      index,
    }))
  );
  const knownSegmentNames = computed(() => {
    const foundSegments = allSegmentGroupData.value.find(
      (seg) => seg.group_name === segmentGroup.value
    );
    if (foundSegments) {
      return foundSegments.segments;
    }
    return [];
  });

  const removeHeaderFromList = (index: number) => {
    unknownSegmentNamesRef.value = unknownSegmentNamesRef.value.filter(
      (segs) => segs.index !== index
    );
    removeItemfromCurrentJson(index);
  };
  const removeItemfromCurrentJson = (index: number) => {
    currentToJsonRef.value = currentToJsonRef.value.filter(
      (_, i) => index !== i
    );
    showLastStep.value = true;
  };

  return {
    segmentGroup,
    file,
    showError,
    uniqueSheetId,
    currentSheet,
    showUniqueIdField,
    allSheets,
    skipRows,
    firstRowContainsHeaders,
    configuration,
    availableColumns,
    showLastStep,
    segmentGroupData,
    uniqueId,
    allUniqueIds,
    step,
    currentToJsonRef,
    knownSegmentNames,
    unknownSegmentNamesRef,
    rows,
    sheetSegments,
    fileDataExtractorToJson,
    getGroupTitle,
    removeHeaderFromList,
    setCurrentToJsonRefString,
    setCurrentToJsonRef,
    setConfigurationValues,
  };
};
