<template>
  <section class="hero is-fullheight">
    <div class="notices is-top">
      <template v-for="message in getSystemMessages">
        <system-message :message="message"></system-message>
      </template>
    </div>
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <svg
            width="200"
            class="is-centred"
            viewBox="0 0 225 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            alt=""
          >
            <g clip-path="url(#clip0_475_25198)">
              <path
                d="M24.2303 28.3468C24.5638 28.3457 24.8944 28.4093 25.2029 28.534C25.5114 28.6588 25.792 28.8423 26.0283 29.0739C26.2647 29.3056 26.4524 29.5808 26.5805 29.884C26.7086 30.1871 26.7747 30.5121 26.775 30.8404V40H21.6945V30.8404C21.6951 30.1789 21.9625 29.5446 22.438 29.077C22.9135 28.6095 23.5581 28.3468 24.2303 28.3468Z"
                fill="#00568B"
              ></path>
              <path
                d="M31.0995 25.1578C31.4325 25.1578 31.7623 25.2224 32.0699 25.3478C32.3776 25.4732 32.6571 25.6571 32.8926 25.8888C33.128 26.1206 33.3148 26.3957 33.4422 26.6985C33.5697 27.0013 33.6353 27.3259 33.6353 27.6536V40H28.575V27.6514C28.575 26.9895 28.8422 26.3547 29.3177 25.8866C29.7933 25.4185 30.4382 25.1556 31.1108 25.1556L31.0995 25.1578Z"
                fill="#00568B"
              ></path>
              <path
                d="M37.971 21.3066C38.642 21.3084 39.2849 21.5718 39.7589 22.0392C40.2329 22.5066 40.4994 23.1398 40.5 23.8002V39.9978H35.4352V23.8002C35.4358 23.1387 35.7033 22.5044 36.1787 22.0368C36.6542 21.5693 37.2989 21.3066 37.971 21.3066Z"
                fill="#00568B"
              ></path>
              <path
                d="M20.1263 36.9842C16.7101 36.9605 13.3772 35.9432 10.5468 34.0603C7.71635 32.1774 5.51467 29.5128 4.21858 26.4017C2.92248 23.2905 2.58982 19.8717 3.26242 16.5749C3.93503 13.2782 5.58287 10.2508 7.99876 7.87333C10.4125 5.49711 13.4856 3.87573 16.8324 3.21276C20.1791 2.54979 23.6502 2.87478 26.81 4.14694C29.9697 5.41909 32.6771 7.5817 34.5924 10.3632C36.5076 13.1447 37.5452 16.4212 37.575 19.7812C37.7033 19.7812 37.8338 19.7613 37.9665 19.7613C38.8849 19.7586 39.7774 20.0605 40.5 20.6183C40.5 20.3924 40.5 20.1636 40.5 19.9318C40.4999 15.9952 39.3155 12.1469 37.0961 8.872C34.8767 5.59715 31.7218 3.0425 28.0292 1.53024C24.3366 0.0179828 20.2717 -0.384153 16.3472 0.374549C12.4227 1.13325 8.8143 3.01881 5.97716 5.79343C3.14002 8.56804 1.20117 12.1074 0.405148 15.9652C-0.390878 19.8231 -0.00843212 23.8265 1.50425 27.4706C3.01693 31.1148 5.59211 34.2364 8.90499 36.4419C12.2179 38.6473 16.1201 39.8378 20.1195 39.8632L20.1263 36.9842Z"
                fill="#23282C"
              ></path>
              <path
                d="M20.1264 30.8405C20.1268 30.6066 20.1479 30.3732 20.1894 30.1429C17.9673 30.1301 15.8081 29.4156 14.0295 28.1046C12.2509 26.7936 10.9468 24.9552 10.3092 22.8601C9.67161 20.7649 9.73413 18.5237 10.4876 16.4661C11.241 14.4086 12.6456 12.6434 14.4946 11.4304C16.3437 10.2174 18.5394 9.62062 20.7589 9.72791C22.9783 9.83519 25.1041 10.6409 26.8234 12.0263C28.5428 13.4118 29.7649 15.304 30.3099 17.4243C30.8549 19.5446 30.694 21.7811 29.8509 23.8047C30.8545 23.4904 31.9427 23.5698 32.8884 24.0261C33.7657 21.404 33.7782 18.576 32.9242 15.9465C32.0701 13.3169 30.3931 11.0205 28.133 9.38564C25.8729 7.75077 23.1455 6.86115 20.3408 6.844C17.5361 6.82685 14.7976 7.68305 12.5171 9.29015C10.2366 10.8973 8.53073 13.173 7.6435 15.7919C6.75628 18.4108 6.73311 21.2387 7.57732 23.8714C8.42152 26.504 10.0899 28.8065 12.3438 30.4496C14.5977 32.0927 17.3217 32.9922 20.1264 33.0196V30.8405Z"
                fill="#23282C"
              ></path>
              <path
                d="M59.796 11.3299C55.7797 9.52275 54.7852 8.58155 54.7852 6.5862C54.7852 4.66615 56.124 3.08714 59.1457 3.08714C60.6418 3.10292 62.1106 3.48335 63.4207 4.19444C63.5676 4.2726 63.7314 4.31493 63.8984 4.31789C64.0653 4.32086 64.2306 4.28436 64.3802 4.21147C64.5299 4.13858 64.6596 4.03141 64.7585 3.89898C64.8573 3.76654 64.9225 3.61269 64.9485 3.45034L65.2702 1.4572C63.7335 0.602369 61.8232 0 59.2605 0C54.4815 0 51.2302 2.67301 51.2302 6.84974C51.2302 10.5769 53.1045 12.1958 58.0747 14.4148C62.0527 16.1865 63.1215 17.1631 63.1215 19.5349C63.1215 22.0551 61.173 23.4858 58.419 23.4858C56.3647 23.4858 54.405 22.848 52.794 22.0153C52.6461 21.9393 52.4818 21.8993 52.315 21.8986C52.1482 21.8979 51.9836 21.9366 51.8351 22.0114C51.6866 22.0863 51.5584 22.195 51.4614 22.3286C51.3644 22.4622 51.3013 22.6168 51.2775 22.7793L50.967 24.8787C53.2511 26.0197 55.7812 26.6016 58.3425 26.5751C63.2745 26.5751 66.6787 23.7515 66.6787 19.267C66.6787 15.356 64.9192 13.6242 59.796 11.3299Z"
                fill="#23282C"
              ></path>
              <path
                d="M74.8643 8.31802H71.46V26.5508H74.8643V8.31802Z"
                fill="#23282C"
              ></path>
              <path
                d="M73.1792 0.442932C72.6031 0.449274 72.0525 0.677469 71.6454 1.07861C71.2383 1.47974 71.007 2.0219 71.0012 2.58887C71.0089 3.15161 71.242 3.68859 71.6499 4.083C72.0578 4.47741 72.6074 4.69734 73.1792 4.69495C73.4604 4.69554 73.739 4.64147 73.999 4.53587C74.259 4.43026 74.4953 4.27519 74.6943 4.07953C74.8933 3.88388 75.0511 3.65149 75.1587 3.39569C75.2662 3.1399 75.3215 2.86572 75.3212 2.58887C75.3236 2.0255 75.0999 1.48398 74.6988 1.08211C74.2976 0.680232 73.7515 0.450517 73.1792 0.442932Z"
                fill="#23282C"
              ></path>
              <path
                d="M90.0834 7.94153C87.4824 7.94153 85.3404 9.37216 83.6191 10.9157L83.3401 9.18613C83.301 8.94416 83.1756 8.72383 82.9863 8.56472C82.797 8.40561 82.5563 8.31813 82.3074 8.31801H80.4849V26.5508H82.8429C83.12 26.5502 83.3855 26.4414 83.5812 26.2484C83.7769 26.0553 83.8869 25.7937 83.8869 25.521V13.8501C85.5316 12.1205 87.3676 10.9534 89.1654 10.9534C91.1521 10.9534 91.9554 12.1958 91.9554 14.0383V25.521C91.9554 25.7941 92.0656 26.056 92.2618 26.2491C92.458 26.4423 92.7241 26.5508 93.0016 26.5508H95.3596V13.21C95.3596 9.96567 93.5236 7.94153 90.0834 7.94153Z"
                fill="#23282C"
              ></path>
              <path
                d="M116.163 8.05445L111.535 9.44743C110.187 8.43405 108.528 7.90291 106.832 7.94151C103.122 7.94151 99.8348 10.3133 99.8348 14.1889C99.8348 16.636 101.21 18.5184 103.237 19.5349C102.013 20.2502 101.324 21.1139 101.324 21.971C101.324 22.6486 101.669 23.2997 102.472 23.8534C99.9113 25.2087 98.6108 26.6393 98.6108 28.4841C98.6108 31.6443 102.395 33.1126 107.138 33.1126C111.881 33.1126 116.048 31.3409 116.048 27.5761C116.048 24.7901 113.639 23.5898 109.775 22.9077L108.477 22.6863C105.264 22.1304 104.625 21.9798 104.625 21.3398C104.625 21.001 105.008 20.5891 105.543 20.2502C105.998 20.3252 106.459 20.363 106.92 20.3632C110.628 20.3632 113.918 18.029 113.918 14.1889C113.931 13.2118 113.675 12.2495 113.175 11.4051L115.546 11.1837C115.689 11.1701 115.827 11.1279 115.952 11.0597C116.077 10.9915 116.186 10.8989 116.273 10.7874C116.36 10.6759 116.423 10.548 116.458 10.4116C116.493 10.2752 116.499 10.1332 116.476 9.99444L116.163 8.05445ZM104.958 24.8854C105.685 25.036 106.22 25.1489 107.208 25.3283L109.386 25.7048C111.987 26.1477 112.829 26.9095 112.829 28.0013C112.829 29.5072 110.84 30.7098 107.285 30.7098C103.73 30.7098 101.853 29.6556 101.853 28.2272C101.86 26.987 102.825 25.9329 104.958 24.8787V24.8854ZM106.832 17.998C104.582 17.998 103.122 16.3791 103.122 14.3085C103.122 12.1271 104.537 10.3953 106.832 10.3953C109.082 10.3953 110.617 12.0141 110.617 14.1601C110.617 16.306 109.163 17.9913 106.832 17.9913V17.998Z"
                fill="#23282C"
              ></path>
              <path
                d="M123.581 0H120.177V26.5508H123.581V0Z"
                fill="#23282C"
              ></path>
              <path
                d="M136.202 7.94153C131.612 7.94153 128.169 11.4849 128.169 17.2428C128.169 23.829 131.879 26.6526 136.622 26.6526C139.642 26.6526 141.975 25.7491 143.926 24.2432L143.701 22.879C143.673 22.7101 143.602 22.5507 143.496 22.4153C143.39 22.2799 143.251 22.1728 143.092 22.1035C142.933 22.0343 142.758 22.0051 142.585 22.0187C142.412 22.0323 142.245 22.0882 142.099 22.1814C140.548 23.1779 138.949 23.7914 136.888 23.7914C133.715 23.7914 131.726 21.8337 131.573 17.657H143.964V16.9372C143.964 11.9699 141.525 7.94153 136.202 7.94153ZM131.726 15.1301C132.262 12.0828 134.059 10.5769 136.163 10.5769C138.8 10.5769 140.101 12.2334 140.521 15.1301H131.726Z"
                fill="#23282C"
              ></path>
              <path
                d="M175.129 0.442932C174.552 0.449263 174.002 0.677386 173.594 1.07846C173.187 1.47954 172.955 2.0217 172.948 2.58887C172.956 3.15199 173.19 3.68931 173.598 4.08378C174.006 4.47825 174.557 4.69793 175.129 4.69495C175.41 4.69554 175.689 4.64147 175.949 4.53587C176.209 4.43026 176.445 4.27519 176.644 4.07953C176.843 3.88388 177.001 3.65149 177.108 3.39569C177.216 3.1399 177.271 2.86572 177.271 2.58887C177.273 2.0255 177.049 1.48398 176.648 1.08211C176.247 0.680232 175.701 0.450517 175.129 0.442932Z"
                fill="#23282C"
              ></path>
              <path
                d="M176.812 8.31802H173.407V26.2717H176.812V8.31802Z"
                fill="#23282C"
              ></path>
              <path
                d="M189.432 7.94153C184.842 7.94153 181.402 11.4849 181.402 17.2428C181.402 23.829 185.11 26.6526 189.851 26.6526C192.872 26.6526 195.206 25.7491 197.156 24.2432L196.931 22.879C196.903 22.7101 196.833 22.5507 196.726 22.4153C196.62 22.2799 196.481 22.1728 196.322 22.1035C196.163 22.0343 195.989 22.0051 195.816 22.0187C195.642 22.0323 195.475 22.0882 195.329 22.1814C193.779 23.1779 192.179 23.7914 190.118 23.7914C186.946 23.7914 184.957 21.8337 184.804 17.657H197.195V16.9372C197.195 11.9699 194.747 7.94153 189.432 7.94153ZM184.95 15.1301C185.486 12.0828 187.283 10.5769 189.387 10.5769C192.024 10.5769 193.325 12.2334 193.745 15.1301H184.95Z"
                fill="#23282C"
              ></path>
              <path
                d="M170.503 7.69827e-07H167.353C167.139 -4.14322e-05 166.93 0.0647438 166.754 0.185571C166.579 0.306399 166.445 0.477423 166.372 0.675452L165.521 2.96313L158.533 21.6034L152.341 5.21537L150.631 0.664379C150.555 0.468777 150.421 0.300477 150.245 0.181816C150.07 0.0631557 149.862 -0.000254854 149.65 7.69827e-07H146.408L146.722 0.788396L156.847 26.4577H160.137L168.462 5.20872H168.478L170.231 0.693168L170.386 0.298971L170.503 7.69827e-07Z"
                fill="#23282C"
              ></path>
              <path
                d="M222.345 8.07221C222.118 8.0722 221.898 8.14481 221.717 8.27905C221.535 8.41329 221.404 8.60187 221.341 8.81631L219.969 13.509L217.615 21.6034L213.819 8.81188C213.756 8.59827 213.624 8.41066 213.443 8.27728C213.262 8.14389 213.042 8.07193 212.816 8.07221H211.513C211.287 8.07228 211.067 8.14439 210.886 8.27772C210.706 8.41105 210.573 8.59845 210.51 8.81188L206.716 21.6034L204.376 13.509L202.965 8.81409C202.902 8.60089 202.769 8.41378 202.589 8.28082C202.408 8.14786 202.188 8.07622 201.962 8.07663H199.237L199.514 8.89382L205.263 26.3537H208.093L212.107 12.7981L216.157 26.347H219.026L223.625 12.1736L224.995 8.07663L222.345 8.07221Z"
                fill="#23282C"
              ></path>
              <path
                d="M156.843 31.2258H158.85C160.596 31.2258 161.678 32.0917 161.678 33.6087C161.678 35.2519 160.378 36.1178 158.67 36.1178H157.455V39.8826H156.843V31.2258ZM158.83 31.7639H157.455V35.5774H158.704C160.155 35.5774 161.048 34.9264 161.048 33.633C161.048 32.3973 160.27 31.7573 158.83 31.7573V31.7639Z"
                fill="#23282C"
              ></path>
              <path
                d="M166.669 39.8738L166.527 39.0832C165.904 39.6235 165.163 40 164.412 40C163.406 40 162.612 39.4973 162.612 38.4321C162.612 37.1144 163.631 36.4124 166.511 36.1488V35.635C166.511 34.8068 166.014 34.4037 165.186 34.4037C164.469 34.415 163.765 34.5957 163.134 34.9308L163.057 34.3794C163.728 34.0549 164.463 33.8796 165.211 33.8656C166.435 33.8656 167.096 34.479 167.096 35.6085V39.8738H166.669ZM166.5 36.6382C164.025 36.9018 163.213 37.4156 163.213 38.3811C163.213 39.1341 163.759 39.4884 164.434 39.4884C165.071 39.4884 165.85 39.1142 166.5 38.5494V36.6382Z"
                fill="#23282C"
              ></path>
              <path
                d="M169.726 35.9982V39.8737H169.125V33.9785H169.521L169.713 35.2076C170.163 34.5432 170.871 33.8656 171.866 33.8523L171.969 34.4037C170.986 34.4679 170.134 35.212 169.726 35.9982Z"
                fill="#23282C"
              ></path>
              <path
                d="M175.167 39.9734C174.312 39.9734 173.752 39.6235 173.752 38.6314V34.479H172.823V33.9785H173.752V31.8901L174.35 31.7639V33.9785H175.817L175.894 34.479H174.35V38.5339C174.35 39.2227 174.632 39.4618 175.295 39.4618C175.498 39.4546 175.698 39.4249 175.894 39.3733L175.982 39.8516C175.719 39.9364 175.444 39.9776 175.167 39.9734Z"
                fill="#23282C"
              ></path>
              <path
                d="M182.774 40C181.282 40 180.137 38.8706 180.137 36.9262C180.137 34.9817 181.321 33.8523 182.774 33.8523C184.279 33.8523 185.425 34.9596 185.425 36.9262C185.425 38.8927 184.241 40 182.774 40ZM182.774 34.3661C181.627 34.3661 180.749 35.2519 180.749 36.9262C180.749 38.5561 181.629 39.484 182.774 39.484C183.919 39.484 184.799 38.5982 184.799 36.9262C184.801 35.2962 183.922 34.3661 182.774 34.3661Z"
                fill="#23282C"
              ></path>
              <path
                d="M189.056 31.4827C188.447 31.4827 188.075 31.7839 188.075 32.5103V33.9785H189.338L189.428 34.479H188.078V39.8738H187.493V34.479H186.561V33.9785H187.493V32.4859C187.493 31.4694 188.039 30.9357 189.034 30.9357C189.166 30.932 189.298 30.9365 189.43 30.949L189.518 31.5137C189.366 31.484 189.211 31.4736 189.056 31.4827Z"
                fill="#23282C"
              ></path>
              <path
                d="M199.112 36.9129H195.098L193.925 39.8738H193.289L196.781 31.1682H197.442L200.925 39.8229L200.313 39.9624L199.112 36.9129ZM195.314 36.3747H198.9L197.1 31.8459L195.314 36.3747Z"
                fill="#23282C"
              ></path>
              <path
                d="M208.019 39.9358H207.472L205.841 34.7935L204.21 39.9358H203.661L201.622 34.0029L202.208 33.8789L203.942 39.2205L205.585 33.9409H206.134L207.776 39.2205L209.446 33.9785H210.058L208.019 39.9358Z"
                fill="#23282C"
              ></path>
              <path
                d="M211.95 32.3862C211.813 32.3807 211.683 32.3245 211.586 32.2289C211.49 32.1332 211.433 32.0051 211.428 31.8702C211.432 31.7363 211.488 31.609 211.586 31.5155C211.683 31.422 211.814 31.3697 211.95 31.3697C212.085 31.3697 212.215 31.4224 212.31 31.5162C212.406 31.61 212.46 31.7373 212.461 31.8702C212.459 32.0041 212.404 32.1322 212.31 32.2281C212.215 32.324 212.086 32.3806 211.95 32.3862ZM211.658 39.8738V33.9785H212.245V39.8738H211.658Z"
                fill="#23282C"
              ></path>
              <path
                d="M218.43 39.8737V35.7723C218.43 34.8865 217.998 34.3926 217.143 34.3926C216.481 34.3926 215.793 34.7691 215.041 35.4578V39.8737H214.441V33.9785H214.875L215.028 34.8643C215.755 34.2376 216.416 33.8478 217.208 33.8478C218.443 33.8478 219.031 34.5388 219.031 35.717V39.8693L218.43 39.8737Z"
                fill="#23282C"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_475_25198">
                <rect width="225" height="40" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <!--<h3 class="title has-text-grey">Login</h3>-->
          <br />
          <br />
          <p class="subtitle has-text-grey has-text-weight-light">
            Please login to proceed
          </p>
          <form class="box" @submit.prevent>
            <section>
              <o-field>
                <o-input
                  placeholder="Username"
                  icon="account-circle-outline"
                  v-model="username"
                ></o-input>
              </o-field>
              <o-field>
                <o-input
                  placeholder="Password"
                  type="password"
                  icon="form-textbox-password"
                  v-model="password"
                  password-reveal
                ></o-input>
              </o-field>
              <button
                :class="[
                  'button',
                  'is-block',
                  'is-info',
                  'is-fullwidth',
                  { 'is-loading': loading },
                ]"
                @click="submit"
              >
                Login
              </button>
              <p class="forgotten-password">
                <router-link to="/recover-password"
                  >I've forgotten my password</router-link
                >
              </p>
            </section>
            <!--<br/>-->
          </form>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SystemMessage from "../../Modules/Auth/SystemMessage";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";
import { UseAxiosErrorHelper } from "../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseLogin } from "./Hooks/UseLogin";

export default {
  title: "Login",
  components: { SystemMessage },
  setup() {
    const { getSystemMessages } = storeToRefs(UseMainStore());
    const { loadSystemMessages } = UseMainStore();
    UseAxiosErrorHelper();
    return {
      getSystemMessages,
      loadSystemMessages,
    };
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      UseLogin({
        username: this.username,
        password: this.password,
      })
        .then(() => {
          this.$router.push("/performance");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadSystemMessages();
  },
};
</script>

<style scoped>
.forgotten-password {
  text-align: right;
  font-size: 0.8rem;
  margin-top: 0.8rem;
}
</style>
