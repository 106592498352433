<template>
  <outer-section>
    <div class="box">
      <o-steps v-model="step" variant="black" :animated="false">
        <!-- Datagrid -->
        <section class="tw-py-4" v-if="file !== null && step > 1">
          <canvas-datagrid ref="abc" :data.prop="currentToJson" keep-alive>
          </canvas-datagrid>
        </section>

        <o-step-item label="File Upload" icon="file-upload" class="tw-p-4">
          <div class="columns">
            <batch-fixed-file-upload
              v-model:file="file"
            ></batch-fixed-file-upload>
          </div>
        </o-step-item>
        <o-step-item label="Options" icon="file-question">
          <hr />

          <!-- Sheet selector -->
          <o-field
            v-if="allSheets.length > 1"
            label="Which sheet is being processed?"
          >
            <o-select v-model="currentSheet" placeholder="Select a sheet">
              <option v-for="sheetName in allSheets" :value="sheetName">
                {{ sheetName }}
              </option>
            </o-select>
          </o-field>
          <!-- Skip Rows -->
          <o-field
            label="How many rows (if any) are to be skipped at the start?"
          >
            <o-input v-model="skipRows" controls-position="compact"></o-input>
          </o-field>
          <!-- Does the first row contain the headers -->
          <o-field label="Does the top row contain the column headers?">
            <o-switch v-model="firstRowContainsHeaders">{{
              firstRowContainsHeaders ? "Yes" : "No"
            }}</o-switch>
          </o-field>
        </o-step-item>
        <o-step-item label="Configuration" icon="settings">
          <hr />

          <batch-fixed-field-configuration
            v-model:start-date="configuration.start_date"
            v-model:end-date="configuration.end_date"
            v-model:taxonomy-one="configuration.taxonomy_1"
            v-model:taxonomy-two="configuration.taxonomy_2"
            v-model:taxonomy-three="configuration.taxonomy_3"
            v-model:taxonomy-four="configuration.taxonomy_4"
            v-model:cost="configuration.cost"
            :available-columns="availableColumns"
            @showLastStepEmitter="(val) => (showLastStep = val)"
          ></batch-fixed-field-configuration>
        </o-step-item>
        <o-step-item label="Process" icon="cloud-upload" v-if="showLastStep">
          <hr />

          <div class="content">
            <h2>Summary</h2>

            <ul>
              <li>There are {{ summary.count }} lines to be processed</li>
              <li v-if="summary.min_date || summary.max_date">
                <template v-if="summary.min_date"
                  >The period of costs starts from
                  {{ summary.min_date }}</template
                >
                <template v-if="summary.min_date && summary.max_date">
                  and ends on
                </template>
                <template v-if="summary.max_date && !summary.min_date"
                  >The period of costs ends on
                </template>
                <template v-if="summary.max_date">{{
                  summary.max_date
                }}</template>
              </li>
              <li>
                The total amount to be processed is
                {{ displayMonetaryValue(summary.total) }}
              </li>
            </ul>

            <button
              class="button is-primary"
              :class="[{ 'is-loading': loading }]"
              @click="process"
            >
              Process
            </button>
            <template v-if="loading"
              >Processed {{ processed }} of {{ sendData.length }}</template
            >
          </div>
        </o-step-item>
      </o-steps>
    </div>
  </outer-section>
</template>

<script>
import xlsx from "xlsx";
import { ConcurrencyManager } from "axios-concurrency";
import BatchFixedFileUpload from "../../../../Modules/Account/BatchCosts/ExcelAndCsvFileUpload";
import BatchFixedFieldConfiguration from "../../../../Modules/Account/BatchCosts/Fixed/BatchFixedFieldConfiguration";
import moment from "moment";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../../../Modules/State/SingleViewStorePinia";
import { UseProgramStore } from "../../../../Modules/State/Stores/ProgramStore";
import { UseNumberHelper } from "../../../../Helper/Functional/UseNumberHelper";
import OuterSection from "../../../../Elements/OuterSection.vue";
import { UseCanvasGridHelper } from "../../../../Helper/ThirdParty/UseCanvasGridHelper";
import { ref } from "vue";

export default {
  title: "Fixed Spend",
  components: {
    BatchFixedFieldConfiguration,
    BatchFixedFileUpload,
    OuterSection,
  },
  setup() {
    const { getApiBaseUrl, getToken } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    const { displayMonetaryValue } = UseNumberHelper();
    const abc = ref(null);
    UseCanvasGridHelper(abc);

    return {
      getApiBaseUrl,
      getToken,
      getCurrentProgram,
      displayMonetaryValue,
      abc,
    };
  },
  computed: {
    postUrl() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/fixed-costs?token=" +
        this.getToken
      );
    },
    summary() {
      return {
        count: this.sendData.length,
        min_date: _.get(
          _.minBy(this.sendData, "start_date"),
          "start_date",
          null
        ),
        max_date: _.get(
          _.maxBy(this.sendData, "start_date"),
          "start_date",
          null
        ),
        total: _.sum(
          _.map(this.sendData, function (val) {
            return parseFloat(val.cost);
          })
        ),
      };
    },
    sendData() {
      return _.chain(this.currentToJson)
        .map(
          function (val) {
            let rtn = {};

            _.each(
              this.configuration,
              function (inner, innerKey) {
                if (inner.value === null) {
                  return;
                }

                if (inner.type === "dynamic") {
                  if (_.get(val, inner.value, "") === "") {
                    return;
                  }
                  _.assign(rtn, { [innerKey]: _.get(val, inner.value) });
                } else {
                  _.assign(rtn, { [innerKey]: inner.value });
                }
              }.bind(this)
            );

            if (rtn.start_date) {
              try {
                let a = moment(rtn.start_date);
                if (a.isValid()) {
                  rtn.start_date = a.format("YYYY-MM-DD");
                } else {
                  // Fallback
                  let b = moment(chrono.parseDate(rtn.start_date));
                  if (b.isValid()) {
                    rtn.start_date = b.format("YYYY-MM-DD");
                  }
                }
              } catch (e) {
                delete rtn.start_date;
              }
            }

            if (rtn.cost) {
              rtn.cost = parseFloat(rtn.cost.replace(/[^0-9.-]+/g, "")).toFixed(
                2
              );
            }

            if (!rtn.end_date) {
              rtn.frequency = "single";
            }
            return rtn;
          }.bind(this)
        )
        .filter(function (val) {
          return (
            _.has(val, "start_date") &&
            _.has(val, "taxonomy_1") &&
            _.has(val, "cost") &&
            _.every(_.pick(val, ["start_date", "taxonomy_1", "cost"]))
          );
        })
        .value();
    },
    allSheets() {
      if (this.file === null) {
        return [];
      } else {
        return this.file.SheetNames;
      }
    },
    availableColumns() {
      return _.chain(this.currentToJson).first().keys().value();
    },
    currentToJson() {
      xlsx.utils.encode_col(1);

      if (this.currentSheet !== null) {
        return xlsx.utils.sheet_to_json(
          this.file.Sheets[this.currentSheet],
          _.assign(this.firstRowContainsHeaders ? {} : { header: "A" }, {
            range: this.skipRows,
            cellDates: true,
            cellNf: true,
            cellText: false,
            raw: false,
            blankrows: true,
            defval: "",
          })
        );
      }

      return [];
    },
  },
  data() {
    return {
      processed: 0,
      loading: false,
      processedData: [],
      step: 1,
      file: null,
      currentSheet: null,
      firstRowContainsHeaders: false,
      skipRows: 0,
      configuration: {
        start_date: {
          type: "dynamic",
          format: "yyyy-mm-dd",
          value: null,
        },
        end_date: {
          type: "dynamic",
          value: null,
        },
        taxonomy_1: {
          type: "dynamic",
          value: null,
        },
        taxonomy_2: {
          type: "dynamic",
          value: null,
        },
        taxonomy_3: {
          type: "dynamic",
          value: null,
        },
        taxonomy_4: {
          type: "dynamic",
          value: null,
        },
        cost: {
          type: "dynamic",
          value: null,
        },
      },
      showLastStep: false,
    };
  },
  methods: {
    process() {
      this.processedData = _.cloneDeep(this.sendData);

      this.loading = true;

      let all = [];
      let api = axios.create({});

      const MAX_CONCURRENT = 10;

      const manager = ConcurrencyManager(api, MAX_CONCURRENT);

      _.each(
        this.processedData,
        function (val) {
          if (val.cost === 0) {
            _.assign(val, { processed: "zero_cost" });

            return;
          }

          all.push(
            api
              .post(this.postUrl, val)
              .then(
                function () {
                  _.assign(val, { processed: "success" });
                  this.processed++;
                }.bind(this)
              )
              .catch(function () {
                _.assign(val, { processed: "error" });
              })
          );
        }.bind(this)
      );

      Promise.all(all).then(
        function () {
          manager.detach();
          swal
            .fire({
              type: "success",
              title: "Finished",
              text: "A total of " + this.processed + " records were processed",
              confirmButtonText: "Close",
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
            .then(
              function (result) {
                if (result.value) {
                }

                this.step = 0;
              }.bind(this)
            );

          this.loading = false;
        }.bind(this)
      );
    },
  },
  watch: {
    allSheets(newVal) {
      if (newVal === null || newVal.length === 0) {
        this.currentSheet = null;
      } else {
        if (newVal.length > 0) {
          this.currentSheet = newVal[0];
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hideGrid {
  display: none;
}
.showGrid {
  display: block;
}
canvas-datagrid {
  width: 100%;
  height: 25vh;
}
</style>
