<template>
  <o-field>
    <o-dropdown aria-role="list" v-model="metaCompute">
      <template #trigger>
        <button class="button" type="button">
          <o-icon icon="code-brackets"></o-icon>
          <span>Comparison Type</span>
          <o-icon icon="menu-down"></o-icon>
        </button>
      </template>

      <o-dropdown-item value="cancellation" aria-role="listitem">
        <div class="media">
          <o-icon class="media-left" icon="close"></o-icon>
          <div class="media-content">
            <small>Cancelled / Confirmed</small>
          </div>
        </div>
      </o-dropdown-item>

      <o-dropdown-item value="customer" aria-role="listitem">
        <div class="media">
          <o-icon class="media-left" icon="new-box"></o-icon>
          <div class="media-content">
            <small>New / Returning Customers</small>
          </div>
        </div>
      </o-dropdown-item>

      <o-dropdown-item value="payment" aria-role="listitem">
        <div class="media">
          <o-icon class="media-left" icon="podium-gold"></o-icon>
          <div class="media-content">
            <small>Cash / Credit Payments</small>
          </div>
        </div>
      </o-dropdown-item>
    </o-dropdown>
  </o-field>
</template>

<script>
export default {
  computed: {
    metaCompute: {
      get() {
        return this.meta;
      },
      set(val) {
        this.$emit("update:meta", val);
      },
    },
  },
  props: {
    meta: "",
  },
};
</script>
