import swal from "sweetalert2";
import { UseReportSettingsStore } from "./../../../Modules/State/Stores/ReportSettingsStore";
import {
  UseDateStore,
  getDefaultDateFrom,
  getDefaultDateTo,
} from "./../../../Modules/State/Stores/DateStore";
import { UseProgramStore } from "./../../../Modules/State/Stores/ProgramStore";
import { UseMainStore } from "./../../../Modules/State/SingleViewStorePinia";

import {
  TGetUserAdmin,
  TGetUserId,
  TGetAvailablePrograms,
  TGetUserMessages,
  IGetAllProgramMessages,
  IGetPerformanceTableColumnSets,
} from "./../../../Modules/State/Typings/SingleViewStoreTypes";
import axios from "axios";
import CONSTANTS from "../../../Modules/State/Constants";
import { get, find, findIndex, map, omit } from "lodash";

interface ILoginData {
  username: string;
  password: string;
}

interface ILoginResponse {
  data: {
    token: string;
    user_is_admin: TGetUserAdmin;
    user_id: TGetUserId;
    user_allowed_programs: TGetAvailablePrograms[];
    user_messages: TGetUserMessages;
    program_messages: IGetAllProgramMessages[];
    user_column_sets: IGetPerformanceTableColumnSets[];
  };
}

const setUserColumnSetsonLogin = (sets: IGetPerformanceTableColumnSets[]) => {
  let favourite = get(find(sets, { favourite: true }), "id", 0);
  let prepend = CONSTANTS.SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMN_SETS;

  if (favourite === 0) {
    prepend[findIndex(prepend, { id: 0 })].favourite = true;
  }

  let whole = map(prepend.concat(sets), (data) => omit(data, "favourite"));

  return {
    whole,
    favourite,
  };
};

export const UseLogin = (data: ILoginData) => {
  const {
    updateToken,
    updateUserAdmin,
    updateUserId,
    updateUserMessages,
    removeUserMessages,
  } = UseMainStore();
  const { updateAvailablePrograms, updateProgramMessages } = UseProgramStore();
  const { setDateFrom, setDateTo } = UseDateStore();

  const {
    setAttributionSortDirection,
    setAttributionSortMetric,
    setFunnelSortDirection,
    setFunnelSortMetric,
    setPerformanceTableColumnSet,
    setPerformanceTableColumnSetFavourite,
    setPerformanceTableColumnSets,
    setPerformanceTableColumns,
    setPerformanceAnnotations,
    setAvailablePerformanceAnnotations,
    setPerformanceMetricOne,
    setPerformanceMetricTwo,
    setPerformanceChartType,
    setPerformanceChartInterval,
    setPerformanceTableSortMetric,
    setPerformanceTableSortDirection,
    setMetaMetric,
    setMetaComparison,
    setMetaTableColumns,
    setMetaTableSortDirection,
    setMetaTableSortMetric,
    setSegmentComparisonMetric,
    setSegmentComparisonTableSortDirection,
    setSegmentComparisonTableSortMetric,
    setSegmentComparisonTableColumns,
  } = UseReportSettingsStore();

  return new Promise((resolve, reject) => {
    axios
      .post(`${CONSTANTS.SINGLEVIEW_API_BASE}/login`, data)
      .then((response: ILoginResponse | "undefined") => {
        if (!response || response === undefined || response === "undefined") {
          throw new Error(
            "The given combination does not match any records we have"
          );
        }

        const {
          data: {
            program_messages,
            token,
            user_allowed_programs,
            user_column_sets,
            user_id,
            user_is_admin,
            user_messages,
          },
        } = response;

        updateToken(token);
        updateUserAdmin(user_is_admin);
        updateUserId(user_id);
        updateAvailablePrograms(user_allowed_programs);
        updateUserMessages(user_messages);
        updateProgramMessages(program_messages);
        setDateFrom(getDefaultDateFrom());
        setDateTo(getDefaultDateTo());

        //Performance Updates;
        const { whole, favourite } = setUserColumnSetsonLogin(user_column_sets);
        setPerformanceTableColumnSet(
          get(find(user_column_sets, { favourite: true }), "id", 0)
        );
        setPerformanceTableColumnSetFavourite(
          get(find(user_column_sets, { favourite: true }), "id", 0)
        );
        setPerformanceTableColumnSets(
          whole as IGetPerformanceTableColumnSets[]
        );
        setPerformanceTableColumns(
          get(find(whole, { id: favourite }), "columns", [])
        );
        setPerformanceAnnotations([]);
        setAvailablePerformanceAnnotations([]);
        setPerformanceMetricOne("attributed_revenue");
        setPerformanceMetricTwo("first_click_aov");
        setPerformanceChartType("area");
        setPerformanceChartInterval("day");
        setPerformanceTableSortMetric("attributed_revenue");
        setPerformanceTableSortDirection("desc");
        setAttributionSortDirection("asc");
        setAttributionSortMetric("taxonomy_1");
        setFunnelSortDirection("asc");
        setFunnelSortMetric("taxonomy_1");
        setMetaMetric("attributed_revenue");
        setMetaComparison("cancellation");
        setMetaTableColumns([
          "last_click_sales",
          "last_click_revenue",
          "attributed_sales",
          "attributed_revenue",
          "cpa",
          "roas",
          "attributed_revenue_variance_percent",
        ]);
        setMetaTableSortDirection("asc");
        setMetaTableSortMetric("taxonomy_1");
        setSegmentComparisonMetric("taxonomy_1");
        setSegmentComparisonTableSortMetric("taxonomy_1");
        setSegmentComparisonTableSortDirection("asc");
        setSegmentComparisonTableColumns([
          "last_click_sales",
          "last_click_revenue",
          "attributed_sales",
          "attributed_revenue",
          "cpa",
          "roas",
          "attributed_revenue_variance_percent",
        ]);

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        resolve(response);
      })
      .catch((error) => {
        updateToken("");
        updateAvailablePrograms([]);
        updateUserId("");
        removeUserMessages();
        swal.fire({
          type: "error",
          title: "Whoops",
          text: "We couldn't match the credentials given, please try again",
          confirmButtonText: "Okay",
          allowOutsideClick: false,
          allowEscapeKey: false,
          footer: "If the problem persists, let us know",
        });
        reject(error);
      });
  });
};
