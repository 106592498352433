<template>
  <div class="box">
    <div class="is-clearfix">
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/account/costs/fixed">Fixed Spend</router-link>
          </li>
          <li class="is-active">
            <router-link to="/account/costs/fixed/create">Create</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <br />

    <fixed-costs-fields
      v-if="!isEmpty(data)"
      v-model:cost="data.cost"
      v-model:startDate="data.start_date"
      v-model:endDate="data.end_date"
      :fixed-costs="data.fixed_costs"
      v-model:frequency="data.frequency"
      v-model:originalFrequency="data.original_frequency"
      v-model:taxonomyOne="data.taxonomy_1"
      v-model:taxonomyTwo="data.taxonomy_2"
      v-model:taxonomyThree="data.taxonomy_3"
      v-model:taxonomyFour="data.taxonomy_4"
      v-model:request="request"
      v-model:saving="saving"
      :isNew="true"
      @save="save"
    ></fixed-costs-fields>
  </div>
</template>

<script>
import FixedCostsFields from "./Fields";
import moment from "moment";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";
import { isEmpty } from "lodash";

export default {
  components: { FixedCostsFields },
  name: "FixedCostsCreate",
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      isEmpty,
    };
  },
  computed: {
    url() {
      return this.getApiBaseUrl + "/programs/" + this.getCurrentProgram + "/fixed-costs/";
    },
    loadingCompute: {
      get() {
        return this.loading;
      },
      set(val) {
        return this.$emit("update:loading", val);
      },
    },
  },
  data() {
    return {
      data: {
        cost: "0",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().add(1, "d").format("YYYY-MM-DD"),
        fixed_costs: [],
        frequency: "daily",
        original_frequency: "daily",
        taxonomy_1: "",
        taxonomy_2: "",
        taxonomy_3: "",
        taxonomy_4: "",
      },
      request: {},
      saving: false,
    };
  },
  props: {
    loading: false,
  },
  methods: {
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    save() {
      this.saving = true;
      axios
        .post(this.url, this.request)
        .then(() => {
          this.saving = false;

          swal
            .fire({
              type: "success",
              title: "Success",
              text: "The spend configuration has been saved",
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
            .then(() => {
              this.$router.push("/account/costs/fixed");
            });
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
