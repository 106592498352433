/**
 * Imports
 */
import { createApp, h } from "vue";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";
import axios from "axios";
import router from "./router";
import swal from "sweetalert2";
import moment from "moment";
import VueCookie from "vue3-cookies";
import VueApexCharts from "vue3-apexcharts";
import VueMarkdown from "vue-markdown";
import titleMixin from "./components/Mixins/title";
import SvVueComponents from "@singleviewtech/vue-components";
import Application from "./components/Application";
import Datepicker from "vue-ctk-date-time-picker";
import { UseMainStore } from "../js/components/Modules/State/SingleViewStorePinia";
import ChartAnnotations from "./components/Modules/Performance/Chart/AnnotationsNew.vue";
import OuterSection from "./components/Elements/OuterSection.vue";
import PerformanceField from "./components/Modules/Performance/Table/PerformanceField";
import "canvas-datagrid";
const app = createApp({
  render: () => h(Application),
});

if (process.env.NODE_ENV === "development") {
  app.config.performance = true;
}
/**
 * Module Usages
 */
app.use(SvVueComponents);
app.use(Oruga, bulmaConfig);
app.use(VueCookie);
app.mixin(titleMixin);

/**
 * Prototypes
 */
app.config.globalProperties.moment = moment;
app.config.globalProperties.axios = axios;
app.config.globalProperties.oruga = Oruga;
app.config.globalProperties.$apexcharts = VueApexCharts;
window.axios = axios;
window.swal = swal;
window.Vue = app;
window.oruga = Oruga;

var chrono = require("chrono-node");
app.config.globalProperties.chrono = chrono;
window.chrono = chrono;

/**
 * Globals
 */
app.mixin({
  methods: {
    validateApiParameter: (str) =>
      encodeURIComponent(str).replace(/%2F/g, "%252F"),
    validateInternalParameter: (str) => encodeURIComponent(str),
  },
});

// eslint-disable-next-line vue/multi-word-component-names
app.component("apexchart", VueApexCharts);
app.component("ctk-date-picker", Datepicker);
app.component("vue-markdown", VueMarkdown);
if (process.env.NODE_ENV === "production") {
  app.config.compilerOptions.isCustomElement = (tag) =>
    tag === "canvas-datagrid";
}

/**
 * Store
 */
import { createPinia } from "pinia";

app.use(createPinia());
app.config.globalProperties.mainStore = UseMainStore();

/**
 * Directives
 */
app.directive("wrap-if", {
  bind: function (el, binding, vnode) {
    console.log(vnode);
    var s = JSON.stringify;
    el.innerHTML =
      "name: " +
      s(binding.name) +
      "<br>" +
      "value: " +
      s(binding.value) +
      "<br>" +
      "expression: " +
      s(binding.expression) +
      "<br>" +
      "argument: " +
      s(binding.arg) +
      "<br>" +
      "modifiers: " +
      s(binding.modifiers) +
      "<br>" +
      "vnode keys: " +
      Object.keys(vnode).join(", ");
  },
});

/**
 * Components
 */

app
  .component("chart-annotations", ChartAnnotations)
  .component("outer-section", OuterSection)
  .component("performance-field", PerformanceField);

router.beforeEach((to, from, next) => {
  let authRequired = !UseMainStore().getToken;
  let onLoginPage = "/login" == to.path;
  let fromLoginPage = "/login" == from.path;

  if (
    (authRequired && to.path === "/recover-password") ||
    to.path.includes("/reset-password")
  ) {
    return next();
  }

  if (!authRequired && onLoginPage) {
    return next("/performance");
  }

  if (authRequired && !fromLoginPage && !onLoginPage) {
    return next("/login");
  }

  if (!axios.defaults.headers.common["Authorization"]) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + UseMainStore().getToken;
  }
  next();
});

app.use(router);

/**
 * Instantiation
 */
window.onload = function () {
  app.mount("#app");
};

/**
 * Keep this, for instantiation when the scrip is loaded after the body
 */
// const singleview = new Vue({
//     store,
//     router,
// }).$mount('#singleview');
