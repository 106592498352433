export const SeasonalityTypes = {
    high_seasonality: {
        label: 'High Seasonality',
    },
    low_seasonality: {
        label: 'Low Seasonality',
    },
    active_market_support: {
        label: 'Active Market Support',
    },
    inactive_market_support: {
        label: 'Inactive Market Support',
    },
}

export const getLabel = (seasonality) => {
    return SeasonalityTypes[seasonality]?.label || 'None'
}