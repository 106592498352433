<template>
  <div class="page-container">
    <outer-section>
      <div class="columns is-multiline">
        <div class="column is-full">
          <h1 class="title">Diminishing Returns</h1>
        </div>
        <div class="column is-full">
          <div class="grid">
            <div class="toolbar">
              <div class="is-flex breadcrumo-container">
                <taxonomy-breadcrumbs
                  :taxonomyUrls="taxonomyUrls"
                  :taxonomyLocation="taxonomyLocation"
                  baseUrl="/diminishing-returns/"
                />
              </div>
              <SvSkeleton :loading="loading">
                <export-data
                  :available-columns="Object.keys(columnHeaders)"
                  :selected-columns="Object.keys(columnHeaders)"
                  :column-headers="columnHeaders"
                  :exportable-data="activeData"
                  :report-name="
                    (
                      getCurrentProgramName +
                      '_diminishingReturns_' +
                      getDateFrom +
                      '_' +
                      getDateTo
                    )
                      .toLowerCase()
                      .split(' ')
                      .join('_')
                  "
                  v-if="activeData.length || loading"
                >
                  <template v-slot:trigger>
                    <SvButton startIcon="download">Export</SvButton>
                  </template>
                </export-data>
              </SvSkeleton>
            </div>

            <div class="grid page-layout-columns">
              <div class="taxonomy-sidebar sv-reset">
                <SvNavigationList
                  :items="navigationItems"
                  v-if="!loading"
                  :parent="navigationParent"
                />
                <div class="tw-flex tw-flex-col tw-gap-2" v-else>
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                </div>
              </div>

              <div>
                <div class="tw-flex tw-gap-6 tw-flex-wrap tw-justify-center">
                  <o-checkbox
                    v-for="chb in seasonalityCheckboxes"
                    :key="chb.key"
                    :native-value="chb.key"
                    v-model="segments"
                    >{{ chb.label }}</o-checkbox
                  >
                  <o-checkbox native-value="none" v-model="segments"
                    >Untagged</o-checkbox
                  >
                </div>

                <div
                  class="tw-w-1/2 tw-m-auto tw-my-12"
                  v-if="!loading && activeData.length < 10"
                >
                  <SvAlert icon="warning" color="orange">
                    Not enough data to show trendline. Try expanding the dates,
                    including more data segments or choosing another Taxonomy.
                  </SvAlert>
                </div>

                <diminishing-returns-chart
                  :key="url + activeData.length"
                  :data="activeData"
                />

                <DiminishingReturnsTable :data="activeData" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </outer-section>
  </div>
</template>

<script>
import TaxonomyNavigation from "../Mixins/TaxonomyNavigation";
import TaxonomyBreadcrumbs from "../Modules/TaxonomyBreadcrumbs.vue";
import IconButton from "../Modules/General/IconButton.vue";
import ExportData from "../Modules/Pieces/ExportData";
import { SeasonalityTypes } from "../Modules/SeasonalityConfiguration/SeasonalityTypes";

import DiminishingReturnsChart from "../Modules/DiminishingReturns/DiminishingReturnsChart.vue";
import DiminishingReturnsTable from "../Modules/DiminishingReturns/DiminishingReturnsTable.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../Modules/State/SingleViewStorePinia";
import { UseDateStore } from "../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../Modules/State/Stores/ProgramStore";
import { UseDiminishingReturnsStore } from "../Modules/State/Stores/DiminishingReturnsStore";
import { UseAxiosErrorHelper } from "../Helper/ThirdParty/UseAxiosErrorHelper";

export default {
  title: "Diminishing Returns",
  SeasonalityTypes,
  components: {
    TaxonomyBreadcrumbs,
    IconButton,
    ExportData,
    DiminishingReturnsChart,
    DiminishingReturnsTable,
  },
  mixins: [TaxonomyNavigation],
  props: {
    selectedTaxonomy: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    segments: [...Object.keys(SeasonalityTypes), "none"],
  }),
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram, getCurrentProgramName } = storeToRefs(
      UseProgramStore()
    );
    const diminishingReturnsStore = UseDiminishingReturnsStore();
    const { getDiminishingReturns, getColumnHeaders } = storeToRefs(
      diminishingReturnsStore
    );

    UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
      getCurrentProgramName,
      data: getDiminishingReturns,
      columnHeaders: getColumnHeaders,
      store: diminishingReturnsStore,
    };
  },
  computed: {
    seasonalityCheckboxes() {
      return Object.entries(SeasonalityTypes).map(([key, obj]) => ({
        ...obj,
        key,
      }));
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/diminishing/multi/taxonomy_1" +
        (this.taxonomyOne
          ? "/" + this.validateApiParameter(this.taxonomyOne) + "/taxonomy_2"
          : "") +
        (this.taxonomyTwo
          ? "/" + this.validateApiParameter(this.taxonomyTwo) + "/taxonomy_3"
          : "") +
        (this.taxonomyThree
          ? "/" + this.validateApiParameter(this.taxonomyThree) + "/taxonomy_4"
          : "") +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    seasonalityRequest() {
      return axios.get(
        `${this.getApiBaseUrl}/programs/${this.getCurrentProgram}/seasonality`,
        {
          params: {
            date_from: this.getDateFrom,
            date_to: this.getDateTo,
          },
        }
      );
    },
    activeTaxonomy() {
      return this.selectedTaxonomy !== null
        ? this.selectedTaxonomy
        : Object.keys(this.data)[0];
    },
    navigationItems() {
      return Object.values(this.data).map((d) => ({
        name: d.name,
        to: [
          "/diminishing-returns",
          this.taxonomyUrls[this.taxonomyUrls.length - 1],
          d.name,
        ]
          .filter((_d) => _d)
          .join("/"),
        active: d.name === this.activeTaxonomy,
        children:
          d.children.map((child) => ({
            name: child,
            to:
              "/diminishing-returns/" +
              this.buildTaxonomyUrl([...this.taxonomyLocation, d.name]) +
              "/" +
              child,
          })) ?? [],
      }));
    },
    navigationParent() {
      const name = this.taxonomyLocation[this.taxonomyLocation.length - 1];
      return this.taxonomyLocation.length > 0
        ? {
            name,
            to: [
              "/diminishing-returns",
              this.buildTaxonomyUrl(
                this.taxonomyLocation.slice(0, this.taxonomyLocation.length - 1)
              ),
              name,
            ]
              .filter((s) => s)
              .join("/"),
          }
        : undefined;
    },
    activeData() {
      return this.data && this.activeTaxonomy in this.data
        ? Object.values(this.data[this.activeTaxonomy].data).filter(
            (children) => this.segments.indexOf(children.seasonality) !== -1
          )
        : [];
    },
  },
  watch: {
    url: {
      immediate: true,
      async handler() {
        this.loading = true;
        try {
          await this.store.loadDiminishingReturns(
            this.url,
            this.seasonalityRequest
          );
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.page-container {
  background: white;
  border-bottom: 1px solid #e2e5ed;
}

.breadcrumo-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.taxonomy-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.grid {
  display: grid;
  gap: 1.5rem 3rem;
}

.page-layout-columns {
  grid-template-columns: minmax(min-content, 20vw) 1fr;
}
</style>
