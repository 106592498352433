import { UseComparisonHelper } from "./UseComparisonHelper";
import { UseReportSettingsStore } from "../../Modules/State/Stores/ReportSettingsStore";
import { UseDateStore } from "../../Modules/State/Stores/DateStore";
import { UseStringHelper } from "../Functional/UseStringHelper";
import { UseProgramStore } from "../../Modules/State/Stores/ProgramStore";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { UseTaxonomyHelper } from "./UseTaxonomyHelper";

interface IProps {
  dataSet: string;
}
//Stateful URLS
const basicChartUrl = ref("");
const basicTableUrl = ref("");
const basicTableComparisonUrl = ref("");
const segmentUrls = ref<{
  table: string;
  chart: string;
}>({
  table: "",
  chart: "",
});

/**
 * Build various computed URLS
 * @param dataSet Pass and object with dataSet as a key. Pass an empty string to utilise the basic Url builder or the taxonomyUrlBuilder
 * @returns buildFullUrlSegment method for Segment Comparison URl ie with dataSet declared as the segment name.
 * @returns basicUrlBuilder computed property
 * @returns taxonomyUrlBuilder computed property
 */
export const UseUrlBuilder = ({ dataSet = "" }: IProps) => {
  const { getApiBaseUrl } = storeToRefs(UseMainStore());
  const { getCurrentProgram } = storeToRefs(UseProgramStore());
  const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
  const { getPerformanceChartInterval } = storeToRefs(UseReportSettingsStore());

  const {
    currentTaxonomyInteger: taxonomy,
    taxonomyOne,
    taxonomyTwo,
    taxonomyThree,
    taxonomyFour,
  } = UseTaxonomyHelper();

  const { comparisonDates } = UseComparisonHelper();

  const { validateInternalParameter } = UseStringHelper();

  const basicURlBuilder = computed(() => {
    return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/performance/taxonomies?date_from=${getDateFrom.value}&date_to=${getDateTo.value}`;
  });

  const segmentGroup = ref("");
  const basicSegmentPostUrlBuilder = computed(() => {
    return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/segment-groups/${segmentGroup.value}/uploads`;
  });
  const basicSegmentGroupUrl = computed(() => {
    return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/segment-groups`;
  });

  const taxonomyUrlBuilder = (programType: string) =>
    `${taxonomyUrlDeterminer(programType)}`;

  const buildAnyInitialUrl = (programType: string, dataType: string) => {
    return `${getApiBaseUrl.value}/programs/${
      getCurrentProgram.value
    }/${programUrlDeterminer(programType, dataType)}`;
  };

  const programUrlDeterminer = (programType: string, dataType: string) => {
    switch (programType) {
      case "performanceTable":
        return `performance/${dataType}/taxonomy_1${taxonomyUrlBuilder(
          "performanceTable"
        )}?date_from=${getDateFrom.value}&date_to=${getDateTo.value}`;
      case "performanceChart":
        return `performance/${dataType}${taxonomyUrlBuilder(
          "performanceChart"
        )}?date_from=${getDateFrom.value}&date_to=${getDateTo.value}&interval=${
          getPerformanceChartInterval.value
        }`;
      case "segment":
        return `performance/named/${dataSet}${dataType}/taxonomy_1${taxonomyUrlBuilder(
          "segment"
        )}?date_from=${getDateFrom.value}&date_to=${getDateTo.value}`;
      case "breakdown":
        return `performance/${dataType}/taxonomy_1${taxonomyUrlBuilder(
          "breakdown"
        )}?date_from=${getDateFrom.value}&date_to=${getDateTo.value}&interval=${
          getPerformanceChartInterval.value
        }`;
      case "comparison":
        return `performance/${dataType}/taxonomy_1${taxonomyUrlBuilder(
          "comparison"
        )}?date_from=${comparisonDates.value.start}&date_to=${
          comparisonDates.value.end
        }`;
    }
    return "";
  };
  const taxonomyUrlDeterminer = (programType: string) => {
    let urlEnd = "/";
    if (taxonomy.value === 1) {
      urlEnd = "";
    }
    switch (programType) {
      case "performanceTable":
      case "breakdown":
      case "comparison":
      case "segment":
        if (taxonomy.value > 1) {
          urlEnd += `${validateInternalParameter(
            taxonomyOne.value
          )}/taxonomy_2`;
        }
        if (taxonomy.value > 2) {
          urlEnd += `/${validateInternalParameter(
            taxonomyTwo.value
          )}/taxonomy_3`;
        }
        if (taxonomy.value > 3) {
          urlEnd += `/${validateInternalParameter(
            taxonomyThree.value
          )}/taxonomy_4`;
        }
        //Will need to add this in once API has been updated
        if (taxonomy.value > 4) {
          urlEnd += `/${validateInternalParameter(taxonomyFour.value)}`;
        }
        break;
      case "performanceChart":
        if (taxonomy.value > 1) {
          urlEnd += `taxonomy_1/${validateInternalParameter(
            taxonomyOne.value
          )}`;
        }
        if (taxonomy.value > 2) {
          urlEnd += `/taxonomy_2/${validateInternalParameter(
            taxonomyTwo.value
          )}`;
        }
        if (taxonomy.value > 3) {
          urlEnd += `/taxonomy_3/${validateInternalParameter(
            taxonomyThree.value
          )}`;
        }
        if (taxonomy.value > 4) {
          urlEnd += `/taxonomy_4/${validateInternalParameter(
            taxonomyFour.value
          )}`;
        }
        break;
    }
    return urlEnd;
  };
  const buildFullUrlBasic = <
    T extends string | "table/single",
    C extends string | "chart/multi"
  >(
    table: T,
    chart: C
  ) => {
    return {
      table: buildAnyInitialUrl("performanceTable", table),
      chart: buildAnyInitialUrl("performanceChart", chart),
    };
  };
  const buildFullUrlSegment = <
    T extends string | "table/single",
    C extends string | "chart/multi"
  >(
    table: T,
    chart: C,
    tag: string = ""
  ) => {
    return {
      tag,
      table: buildAnyInitialUrl("segment", table),
      chart: buildAnyInitialUrl("segment", chart),
    };
  };

  //State Actions

  const setBasicChartUrl = (str: string) => {
    basicChartUrl.value = str;
  };

  const setBasicTableUrl = (str: string) => {
    basicTableUrl.value = str;
  };

  const setSegmentUrls = <T extends { table: string; chart: string }>(
    obj: T
  ) => {
    segmentUrls.value = obj;
  };

  const setBasicTableComparisonUrl = (str: string) => {
    basicTableComparisonUrl.value = str;
  };

  return {
    buildFullUrlSegment,
    buildFullUrlBasic,
    setSegmentUrls,
    setBasicChartUrl,
    setBasicTableUrl,
    setBasicTableComparisonUrl,
    buildAnyInitialUrl,
    taxonomyUrlBuilder,
    basicURlBuilder,
    segmentUrls,
    basicChartUrl,
    basicTableUrl,
    basicTableComparisonUrl,
    basicSegmentGroupUrl,
    basicSegmentPostUrlBuilder,
    segmentGroup,
  };
};
