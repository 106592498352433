import { UseStringHelper } from "../Functional/UseStringHelper";
import { computed, ref, watch } from "vue";
import { RouteParams, useRoute } from "vue-router";

interface ISegComParams extends RouteParams {
  taxonomyOne: string;
  taxonomyTwo: string;
  taxonomyThree: string;
  taxonomyFour: string;
}

const taxonomyOne = ref("");
const taxonomyTwo = ref("");
const taxonomyThree = ref("");
const taxonomyFour = ref("");

const allTaxonomies = computed(() => [
  taxonomyOne.value,
  taxonomyTwo.value,
  taxonomyThree.value,
]);

export const SetTaxonomyHelper = () => {
  const setTaxonomyOne = (taxOne: string) => {
    taxonomyOne.value = taxOne || "";
  };
  const setTaxonomyTwo = (taxTwo: string) => {
    taxonomyTwo.value = taxTwo || "";
  };
  const setTaxonomyThree = (taxThree: string) => {
    taxonomyThree.value = taxThree || "";
  };
  const setTaxonomyFour = (taxFour: string) => {
    taxonomyFour.value = taxFour || "";
  };
  return {
    setTaxonomyOne,
    setTaxonomyTwo,
    setTaxonomyThree,
    setTaxonomyFour,
  };
};

export const UseTaxonomyHelper = () => {
  const { validateInternalParameter } = UseStringHelper();

  const currentTaxonomy = () => {
    if (taxonomyFour.value !== "" && taxonomyFour.value !== undefined) {
      return 5;
    } else if (
      taxonomyThree.value !== "" &&
      taxonomyThree.value !== undefined
    ) {
      return 4;
    } else if (taxonomyTwo.value !== "" && taxonomyTwo.value !== undefined) {
      return 3;
    } else if (taxonomyOne.value !== "" && taxonomyOne.value !== undefined) {
      return 2;
    }
    return 1;
  };

  const currentTaxonomyInteger = computed(() => {
    let taxonomy = 1;
    [
      taxonomyOne.value,
      taxonomyTwo.value,
      taxonomyThree.value,
      taxonomyFour.value,
    ].forEach((tax) => {
      if (tax) {
        taxonomy++;
      }
    });

    return taxonomy;
  });

  const documentTitleHandler = (title: string) => {
    let available = "";
    const taxonomies = [
      taxonomyOne.value,
      taxonomyTwo.value,
      taxonomyThree.value,
      taxonomyFour.value,
    ];
    taxonomies.forEach((tax) => {
      if (tax) {
        available = tax;
      }
    });
    if (available.length) {
      return `${available} | ${title}`;
    }
    return title;
  };

  const fetchNavigationUrlHandler = (str: string) => {
    return (
      str +
      (currentTaxonomy() > 0
        ? "/taxonomy_one/" + validateInternalParameter(taxonomyOne.value)
        : "") +
      (currentTaxonomy() > 1
        ? "/taxonomy_two/" + validateInternalParameter(taxonomyTwo.value)
        : "") +
      (currentTaxonomy() > 2
        ? "/taxonomy_three/" + validateInternalParameter(taxonomyThree.value)
        : "") +
      (currentTaxonomy() > 3
        ? "/taxonomy_four/" + validateInternalParameter(taxonomyFour.value)
        : "")
    );
  };

  const taxonomyRouteParams = () => {
    const { params } = useRoute();
    const routeParams = computed(() => params as ISegComParams);
    const {
      setTaxonomyOne,
      setTaxonomyTwo,
      setTaxonomyThree,
      setTaxonomyFour,
    } = SetTaxonomyHelper();
    if (routeParams.value) {
      const { taxonomyOne, taxonomyTwo, taxonomyThree, taxonomyFour } =
        routeParams.value;
      setTaxonomyOne(taxonomyOne);
      setTaxonomyTwo(taxonomyTwo);
      setTaxonomyThree(taxonomyThree);
      setTaxonomyFour(taxonomyFour);
    }

    watch(
      routeParams,
      (val) => {
        if (val) {
          setTaxonomyOne(val.taxonomyOne);
          setTaxonomyTwo(val.taxonomyTwo);
          setTaxonomyThree(val.taxonomyThree);
          setTaxonomyFour(val.taxonomyFour);
        }
      },
      { deep: true }
    );
  };
  return {
    taxonomyOne,
    taxonomyTwo,
    taxonomyThree,
    taxonomyFour,
    allTaxonomies,
    currentTaxonomyInteger,
    fetchNavigationUrlHandler,
    documentTitleHandler,
    taxonomyRouteParams,
  };
};
