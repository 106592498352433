<template>
  <o-notification :type="type" :icon="icon" @close="closeMessage">
    <div v-html="content"></div>
  </o-notification>
</template>

<script>
import { UseMainStore } from "../State/SingleViewStorePinia";

export default {
  setup() {
    const { readMessage } = UseMainStore();

    return {
      readMessage,
    };
  },
  computed: {
    id() {
      return _.get(this.message, "id");
    },
    content() {
      return _.get(this.message, "message", "");
    },
    icon() {
      return _.get(this.message, "meta.icon", "");
    },
    type() {
      return _.get(this.message, "meta.type", "is-info");
    },
  },
  props: {
    message: {
      type: Object,
      default: {},
    },
  },
  methods: {
    closeMessage() {
      this.readMessage(this.id);
    },
  },
};
</script>
