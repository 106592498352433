import { UseMetaStatelessFuncs } from "./UseMetaStatelessFuncs";
import { flatMapDeep, assign } from "lodash";
import {
  computed,
  ComputedRef,
  Ref,
  ShallowRef,
  WritableComputedRef,
} from "vue";
import {
  IMetaTabularExportedData,
  ITabledData,
  TMetaMatched,
} from "../Typings/MetaTypes";
import { IGeTAllTableHeaders } from "../../../../typings/DataFetching";

interface IProps {
  matched: ComputedRef<TMetaMatched>;
  tabled: ComputedRef<ITabledData[]>;
  bottomName: Ref<string>;
  topName: Ref<string>;
  taxonomy: Ref<number>;
  headersMetaTabular: Ref<Partial<IGeTAllTableHeaders>>;
  headersMetaTabularConverted: ShallowRef<string[]>;
  columns: WritableComputedRef<string[]>;
}

export const UseMetaExportData = ({
  matched,
  tabled,
  bottomName,
  topName,
  taxonomy,
  headersMetaTabular,
  headersMetaTabularConverted,
  columns,
}: IProps) => {
  const { convertNameToHandle } = UseMetaStatelessFuncs();

  const exportData = computed(() => {
    return flatMapDeep(matched.value, function (d) {
      return flatMapDeep(d?.data, function (e) {
        assign(e.bottom, { type: bottomName.value });
        assign(e.top, { type: topName.value });

        return flatMapDeep(e, (inner) => ({
          ...inner,
          first_click_conversion_rate: +inner.first_click_conversion_rate / 100,
          last_click_conversion_rate: +inner.last_click_conversion_rate / 100,
          attributed_conversion_rate: +inner.attributed_conversion_rate / 100,
          attributed_revenue_variance_percent:
            +inner.attributed_revenue_variance_percent / 100,
        }));
      });
    });
  });
  const metaTabularExportedData = computed((): IMetaTabularExportedData[] => {
    return tabled.value.map(
      ({
        data: {
          p1x1: {
            variable_cost,
            fixed_cost,
            total_cost,
            clicks,
            impressions,
            first_click_sales: first_click_sales_returning,
            first_click_revenue: first_click_revenue_returning,
            first_click_conversion_rate: first_click_conversion_rate_returning,
            first_click_aov: first_click_aov_returning,
            last_click_sales: last_click_sales_returning,
            last_click_revenue: last_click_revenue_returning,
            last_click_aov: last_click_aov_returning,
            last_click_conversion_rate: last_click_conversion_rate_returning,
            attributed_conversion_rate: attributed_conversion_rate_returning,
            attributed_sales: attributed_sales_returning,
            attributed_revenue: attributed_revenue_returning,
            attributed_aov: attributed_aov_returning,
            attributed_revenue_variance: attributed_revenue_variance_returning,
            attributed_revenue_variance_percent:
              attributed_revenue_variance_percent_returning,
            introducer_ratio: introducer_ratio_returning,
            cpa: cpa_returning,
            roas: roas_returning,
            roi: roi_returning,
            sales_including: sales_including_returning,
            proportional_credit: proportional_credit_returning,
          },
          p1x2: {
            first_click_sales: first_click_sales_new,
            first_click_revenue: first_click_revenue_new,
            first_click_conversion_rate: first_click_conversion_rate_new,
            first_click_aov: first_click_aov_new,
            last_click_sales: last_click_sales_new,
            last_click_revenue: last_click_revenue_new,
            last_click_aov: last_click_aov_new,
            last_click_conversion_rate: last_click_conversion_rate_new,
            attributed_sales: attributed_sales_new,
            attributed_revenue: attributed_revenue_new,
            attributed_conversion_rate: attributed_conversion_rate_new,
            attributed_aov: attributed_aov_new,
            attributed_revenue_variance: attributed_revenue_variance_new,
            attributed_revenue_variance_percent:
              attributed_revenue_variance_percent_new,
            introducer_ratio: introducer_ratio_new,
            cpa: cpa_new,
            roas: roas_new,
            roi: roi_new,
            sales_including: sales_including_new,
            proportional_credit: proportional_credit_new,
          },
        },
        name,
      }) => ({
        ["taxonomy_" + taxonomy.value]: name,
        variable_cost,
        fixed_cost,
        total_cost,
        clicks,
        impressions,
        ["first_click_sales_" + convertNameToHandle(bottomName.value)]:
          first_click_sales_returning,
        ["first_click_sales_" + convertNameToHandle(topName.value)]:
          first_click_sales_new,
        ["first_click_revenue_" + convertNameToHandle(bottomName.value)]:
          first_click_revenue_returning,
        ["first_click_revenue_" + convertNameToHandle(topName.value)]:
          first_click_revenue_new,
        ["first_click_conversion_rate_" +
        convertNameToHandle(bottomName.value)]:
          first_click_conversion_rate_returning,
        ["first_click_conversion_rate_" + convertNameToHandle(topName.value)]:
          first_click_conversion_rate_new,
        ["first_click_aov_" + convertNameToHandle(bottomName.value)]:
          first_click_aov_returning,
        ["first_click_aov_" + convertNameToHandle(topName.value)]:
          first_click_aov_new,
        ["last_click_sales_" + convertNameToHandle(bottomName.value)]:
          last_click_sales_returning,
        ["last_click_sales_" + convertNameToHandle(topName.value)]:
          last_click_sales_new,
        ["last_click_revenue_" + convertNameToHandle(bottomName.value)]:
          last_click_revenue_returning,
        ["last_click_revenue_" + convertNameToHandle(topName.value)]:
          last_click_revenue_new,
        ["last_click_conversion_rate_" + convertNameToHandle(bottomName.value)]:
          last_click_conversion_rate_returning,
        ["last_click_conversion_rate_" + convertNameToHandle(topName.value)]:
          last_click_conversion_rate_new,
        ["last_click_aov_" + convertNameToHandle(bottomName.value)]:
          last_click_aov_returning,
        ["last_click_aov_" + convertNameToHandle(topName.value)]:
          last_click_aov_new,
        ["attributed_sales_" + convertNameToHandle(bottomName.value)]:
          attributed_sales_returning,
        ["attributed_sales_" + convertNameToHandle(topName.value)]:
          attributed_sales_new,
        ["attributed_revenue_" + convertNameToHandle(bottomName.value)]:
          attributed_revenue_returning,
        ["attributed_revenue_" + convertNameToHandle(topName.value)]:
          attributed_revenue_new,
        ["attributed_conversion_rate_" + convertNameToHandle(bottomName.value)]:
          attributed_conversion_rate_returning,
        ["attributed_conversion_rate_" + convertNameToHandle(topName.value)]:
          attributed_conversion_rate_new,
        ["attributed_aov_" + convertNameToHandle(bottomName.value)]:
          attributed_aov_returning,
        ["attributed_aov_" + convertNameToHandle(topName.value)]:
          attributed_aov_new,
        ["attributed_revenue_variance_" +
        convertNameToHandle(bottomName.value)]:
          attributed_revenue_variance_returning,
        ["attributed_revenue_variance_" + convertNameToHandle(topName.value)]:
          attributed_revenue_variance_new,
        ["attributed_revenue_variance_percent_" +
        convertNameToHandle(bottomName.value)]:
          attributed_revenue_variance_percent_returning,
        ["attributed_revenue_variance_percent_" +
        convertNameToHandle(topName.value)]:
          attributed_revenue_variance_percent_new,
        ["introducer_ratio_" + convertNameToHandle(bottomName.value)]:
          introducer_ratio_returning,
        ["introducer_ratio_" + convertNameToHandle(topName.value)]:
          introducer_ratio_new,
        ["cpa_" + convertNameToHandle(bottomName.value)]: cpa_returning,
        ["cpa_" + convertNameToHandle(topName.value)]: cpa_new,
        ["roas_" + convertNameToHandle(bottomName.value)]: roas_returning,
        ["roas_" + convertNameToHandle(topName.value)]: roas_new,
        ["roi_" + convertNameToHandle(bottomName.value)]: roi_returning,
        ["roi_" + convertNameToHandle(topName.value)]: roi_new,
        ["sales_including_" + convertNameToHandle(bottomName.value)]:
          sales_including_returning,
        ["sales_including_" + convertNameToHandle(topName.value)]:
          sales_including_new,
        ["proportional_credit_" + convertNameToHandle(bottomName.value)]:
          proportional_credit_returning,
        ["proportional_credit_" + convertNameToHandle(topName.value)]:
          proportional_credit_new,
      })
    );
  });
  const exportAlwaysColumns = computed(() => {
    return ["taxonomy_" + taxonomy.value, "referral_date", "type"];
  });
  const exportColumns = computed(() => {
    return [
      "taxonomy_" + taxonomy.value,
      "referral_date",
      "type",
      "variable_cost",
      "fixed_cost",
      "total_cost",
      "clicks",
      "impressions",
      "last_click_sales",
      "last_click_revenue",
      "last_click_conversion_rate",
      "last_click_aov",
      "first_click_sales",
      "first_click_revenue",
      "first_click_conversion_rate",
      "first_click_aov",
      "attributed_sales",
      "attributed_revenue",
      "attributed_conversion_rate",
      "attributed_aov",
      "introducer_ratio",
      "cpa",
      "roi",
      "roas",
      "attributed_revenue_variance",
      "attributed_revenue_variance_percent",
    ];
  });
  const exportColumnsHeaders = computed(() => {
    return {
      ["taxonomy_" + taxonomy.value]: "Taxonomy",
      referral_date: "Date",
      type: "Status",
      variable_cost: "Variable Spend",
      fixed_cost: "Fixed Spend",
      total_cost: "Total Spend",
      clicks: "Page Lands",
      impressions: "Impressions",
      last_click_sales: "Last Click Sales",
      last_click_revenue: "Last Click Revenue",
      last_click_conversion_rate: "Last Click Conversion Rate",
      last_click_aov: "Last Click AOV",
      first_click_sales: "First Click Sales",
      first_click_revenue: "First Click Revenue",
      first_click_conversion_rate: "First Click Conversion Rate",
      first_click_aov: "First Click AOV",
      attributed_sales: "Attributed Sales",
      attributed_revenue: "Attributed Revenue",
      attributed_conversion_rate: "Attributed Conversion Rate",
      attributed_aov: "Attributed AOV",
      introducer_ratio: "Introducer Ratio",
      cpa: "CPA",
      roi: "ROI",
      roas: "ROAS",
      attributed_revenue_variance: "Attributed Revenue Variance",
      attributed_revenue_variance_percent:
        "Attributed Revenue Variance Percent",
    };
  });
  const exportTabularHeaders = computed(() => {
    return {
      ["taxonomy_" + taxonomy.value]: "Taxonomy",
      ...headersMetaTabular.value,
    };
  });
  const exportTabularColumns = computed(() => {
    return [
      "taxonomy_" + taxonomy.value,
      ...Object.keys(headersMetaTabular.value),
    ];
  });
  const exportSelectedColumns = computed(() => {
    return [
      "taxonomy_" + taxonomy.value,
      ...columns.value
        .map((col) => {
          if (headersMetaTabularConverted.value.includes(col)) {
            return [
              col + "_" + convertNameToHandle(bottomName.value),
              col + "_" + convertNameToHandle(topName.value),
            ];
          } else {
            return col;
          }
        })
        .flatMap((x) => x),
    ];
  });
  return {
    exportData,
    metaTabularExportedData,
    exportAlwaysColumns,
    exportColumns,
    exportColumnsHeaders,
    exportTabularHeaders,
    exportTabularColumns,
    exportSelectedColumns,
  };
};
