<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li
        v-for="(name, index) in names"
        :key="name"
        :class="{
          'is-active': index === names.length - 1,
        }"
      >
        <router-link :to="urls[index]">{{ name }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    taxonomyUrls: {
      type: Array,
      required: true,
    },
    taxonomyLocation: {
      type: Array,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    baseUrlWithSlashes() {
      if (this.baseUrl.slice(-1) === "/") {
        return this.baseUrl;
      }

      return this.baseUrl + "/";
    },
    urls() {
      return [
        this.baseUrlWithSlashes,
        ...this.taxonomyUrls.map(url => this.baseUrlWithSlashes + url),
      ];
    },
    names() {
      return ["All Channels", ...this.taxonomyLocation];
    },
    breadcrumbs() {},
  },
};
</script>

<style></style>
