import { UseChartHelper } from "./UseChartHelper";
import { IChartSeries } from "../../../typings/ChartTypes";
import {
  TAllTableHeaderValues,
  IGetAllColumns,
  TChartData,
} from "../../../typings/DataFetching";
import { UseMathsHelper } from "./UseMathsHelper";
import { UseArrayHelper } from "../Functional/UseArrayHelper";
import moment from "moment";

import { ApexOptions } from "apexcharts";
import { computed, ref, Ref } from "vue";

const firstMetric = ref("");
const secondMetric = ref("");
export const UseChartStatefulData = () => {
  const setFirstMetric = (val: string) => {
    firstMetric.value = val;
  };
  const setSecondMetric = (val: string) => {
    secondMetric.value = val;
  };

  return {
    firstMetric,
    secondMetric,
    setFirstMetric,
    setSecondMetric,
  };
};

export const UseChartTemplateHelper = ({
  name,
  metric,
  top,
  bottom,
  topName,
  bottomName,
}: {
  name: string;
  metric: Ref<keyof TAllTableHeaderValues<IGetAllColumns>>;
  top: TChartData;
  bottom: TChartData;
  topName: string;
  bottomName: string;
}) => {
  const { dryFunctionMinMax, tickAmountTwoDatasources } = UseMathsHelper();
  const { chartFormatter } = UseChartHelper();
  let min = computed(() => dryFunctionMinMax([0], metric, top, bottom).min);
  let max = computed(() => dryFunctionMinMax([1], metric, top, bottom).max);

  const chartOptions = computed((): ApexOptions => {
    return {
      yaxis: [
        {
          show: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          tickAmount: tickAmountTwoDatasources(metric, top, bottom, 5),
          forceNiceScale: true,

          labels: {
            formatter: chartFormatter(metric),
          },
          min: min ? min.value : 0,
          max: max ? max.value : 1,
        },
      ],
      chart: {
        id: "segment-chart-" + name,
        stacked: series.value.length > 1,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 300,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#2799FF", "#36E7A6"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      markers: {
        size: 0,
      },
      tooltip: {
        y: [
          {
            formatter: chartFormatter(metric),
          },
          {
            formatter: chartFormatter(metric),
          },
        ],
      },
      xaxis: {
        labels: {
          show: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "",
            minute: "",
          },
        },
        type: "datetime",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
    };
  });
  const series = computed((): IChartSeries[] => {
    const { checkSingleObject } = UseArrayHelper();
    const bottomDataAll = checkSingleObject(bottom) ? bottom.data : [];
    const topDataAll = checkSingleObject(top) ? top.data : [];

    if (!bottomDataAll && !topDataAll) return [];
    const resolvedData: {
      data: {
        x: moment.Moment;
        y: string | number | undefined;
      }[];
      name: string;
    }[] = [];
    if (topDataAll.length) {
      const topData = topDataAll.map((d) => ({
        x: moment.utc(d.conversion_date, "YYYY-MM-DD"),
        y: d[metric.value],
      }));
      resolvedData.push({ data: topData, name: topName });
    }
    if (bottomDataAll.length) {
      const bottomData = bottomDataAll.map((d) => ({
        x: moment.utc(d.conversion_date, "YYYY-MM-DD"),
        y: d[metric.value],
      }));
      resolvedData.push({ data: bottomData, name: bottomName });
    }

    return resolvedData as IChartSeries[];
  });

  return {
    chartOptions,
    series,
  };
};
