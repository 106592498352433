<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">
        <slot name="header"></slot>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="modal-card-items">
        <form action @submit.prevent>
          <slot name="input"></slot>
          <div class="update-button">
            <slot name="button"></slot>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ModalBody",
}
</script>

<style scoped lang="scss">
@import "../../../../sass/variables";
.update-button {
  margin-top: 1rem;
}
</style>