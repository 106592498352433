import { UseNumberHelper } from "./UseNumberHelper";
import {
  TGetPerformanceMetricOne,
  TGetPerformanceMetricTwo,
} from "../../Modules/State/Typings/SingleViewStoreTypes";

export const UseMetricHelper = () => {
  const {
    displayMonetaryValueRounded,
    displayNumericValueOneDp,
    displayMonetaryValue,
    displayNumericValue,
    displayNumericValueRounded,
  } = UseNumberHelper();

  const fetchMetricValue = (
    metricName: TGetPerformanceMetricOne | TGetPerformanceMetricTwo,
    value: number | string,
    bottom = "",
    top = ""
  ): string => {
    switch (metricName) {
      case "variable_cost":
      case "fixed_cost":
      case "total_cost":
      case "first_click_revenue":
      case "first_click_revenue_" + bottom:
      case "first_click_revenue_" + top:
      case "last_click_revenue":
      case "last_click_revenue_" + bottom:
      case "last_click_revenue_" + top:
      case "attributed_revenue":
      case "attributed_revenue_" + bottom:
      case "attributed_revenue_" + top:
      case "attributed_revenue_variance":
      case "attributed_revenue_variance_" + bottom:
      case "attributed_revenue_variance_" + top:
        return displayMonetaryValueRounded(value);
      case "clicks":
      case "impressions":
      case "first_click_sales":
      case "first_click_sales_" + bottom:
      case "first_click_sales_" + top:
      case "last_click_sales":
      case "last_click_sales_" + bottom:
      case "last_click_sales_" + top:
      case "attributed_sales":
      case "attributed_sales_" + bottom:
      case "attributed_sales_" + top:
        return displayNumericValueRounded(value);
      case "first_click_conversion_rate":
      case "first_click_conversion_rate_" + bottom:
      case "first_click_conversion_rate_" + top:
      case "last_click_conversion_rate":
      case "last_click_conversion_rate_" + bottom:
      case "last_click_conversion_rate_" + top:
      case "attributed_conversion_rate":
      case "attributed_conversion_rate_" + bottom:
      case "attributed_conversion_rate_" + top:
        return displayNumericValue(value) + "%";
      case "attributed_revenue_variance_percent":
      case "attributed_revenue_variance_percent_" + bottom:
      case "attributed_revenue_variance_percent_" + top:
      case "introducer_ratio_" + bottom:
        return displayNumericValueOneDp(value) + "%";
      case "introducer_ratio":
      case "introducer_ratio_" + top:
        return displayNumericValue(value);
      case "first_click_aov":
      case "first_click_aov_" + bottom:
      case "first_click_aov_" + top:
      case "last_click_aov":
      case "last_click_aov_" + bottom:
      case "last_click_aov_" + top:
      case "attributed_aov":
      case "attributed_aov_" + bottom:
      case "attributed_aov_" + top:
      case "cpa":
      case "cpa_" + bottom:
      case "cpa_" + top:
      case "roas":
      case "roas_" + bottom:
      case "roas_" + top:
      case "roi":
      case "roi_" + bottom:
      case "roi_" + top:
        return displayMonetaryValue(value);
      case "proportional_credit_" + bottom:
      case "proportional_credit_" + top:
      case "sales_including_" + bottom:
      case "sales_including_" + top:
        return "-";
    }

    return "-";
  };

  return {
    fetchMetricValue,
  };
};
