<template>
  <o-field>
    <p class="control">
      <span class="button is-static">Taxonomy Level</span>
    </p>
    <p class="control">
      <button
        :class="['button', { 'is-primary': taxonomyLevel === 2 }]"
        @click="taxonomyLevelCompute = 2"
      >Two</button>
    </p>
    <p class="control">
      <button
        :class="['button', { 'is-primary': taxonomyLevel === 3 }]"
        @click="taxonomyLevelCompute = 3"
      >Three</button>
    </p>
    <p class="control">
      <button
        :class="['button', { 'is-primary': taxonomyLevel === 4 }]"
        @click="taxonomyLevelCompute = 4"
      >Four</button>
    </p>
  </o-field>
</template>

<script>
export default {
  computed: {
    taxonomyLevelCompute: {
      get() {
        return this.taxonomyLevel;
      },
      set(val) {
        return this.$emit("update:taxonomyLevel", val);
      },
    },
  },
  props: {
    taxonomyLevel: {
      type: Number,
    },
  },
};
</script>
