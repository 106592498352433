<template>
  <form action>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Export Data</p>
      </header>
      <section class="modal-card-body">
        <o-field label="Report Name">
          <o-input v-model="reportNameCopy"></o-input>
        </o-field>
        <o-field v-if="showComparison" label="Comparison Data">
          <o-checkbox v-model="exportComparisonData"
            >Include Comparison Data in Report</o-checkbox
          >
        </o-field>
        <o-field v-if="taxonomyFilterCopy" label="Export by Taxonomy Level">
          <o-field>
            <o-select
              :loading="loading"
              placeholder="Taxonomy"
              v-model="export_taxonomy_1"
            >
              <option
                v-for="taxonomy in taxonomyOneSelection"
                :value="taxonomy"
              >
                {{ taxonomy }}
              </option>
            </o-select>
            <o-select
              placeholder="Select Taxonomy Level"
              v-if="export_taxonomy_1"
              v-model="export_taxonomy_level"
            >
              <option value="2">Taxonomy Two</option>
              <option value="3">Taxonomy Three</option>
              <option value="4">Taxonomy Four</option>
            </o-select>
            <o-button
              @click="resetTaxDropDowns"
              v-if="export_taxonomy_1"
              variant="is-light"
              icon-right="close"
            />
          </o-field>
        </o-field>
        <!-- Exportable Columns -->
        <o-field
          :label="'Exportable Columns (' + selectedColumnsCopy.length + ')'"
        >
          <o-field>
            <p class="control">
              <button class="button" @click.prevent="setCurrentColumns">
                <o-icon icon="restart"></o-icon>
                <span>Reset to visible</span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="setAllColumns">
                <o-icon icon="check-circle-outline"></o-icon>
                <span>Select All</span>
              </button>
            </p>
            <p class="control">
              <button class="button" @click.prevent="setNoColumns">
                <o-icon icon="close"></o-icon>
                <span>Remove All</span>
              </button>
            </p>
          </o-field>
        </o-field>

        <o-field>
          <column-selection
            :always-selected-columns="alwaysSelectedColumnsCopy"
            :column-headers="columnHeadersCopy"
            v-model:selected-columns="selectedColumnsCopy"
            :available-columns="availableColumnsCopy"
          ></column-selection>
        </o-field>

        <div class="o-field">
          <p class="label">Custom Column Ordering</p>
          <p class="help">
            When enabled, the order in which you select the exportable columns
            in will be the order the columns appear in the exported file
          </p>
        </div>

        <o-field>
          <o-switch
            v-model="userDefinedColumnOrdering"
            variant="success"
          ></o-switch>
        </o-field>
      </section>

      <!-- space-around to put the buttons in the middle, bulma doesnt support left and right -->
      <!-- But we can put the field and grouped on the footer, spacep-between instead of around and BAM -->
      <footer
        class="modal-card-foot field is-grouped"
        style="justify-content: space-between"
      >
        <button
          class="button is-danger"
          variant="danger"
          @click.prevent="$emit('close')"
        >
          Cancel
        </button>
        <o-field grouped>
          <p class="control">
            <button class="button" @click.prevent="exportExcel">
              <o-icon icon="file-excel"></o-icon>
              <span>Download Excel</span>
            </button>
          </p>
          <p class="control">
            <button class="button" @click.prevent="exportCsv">
              <o-icon icon="file-delimited-outline"></o-icon>
              <span>Download CSV</span>
            </button>
          </p>
        </o-field>
      </footer>
    </div>
  </form>
</template>

<script>
import { saveAs } from "file-saver";
import { storeToRefs } from "pinia";
import xlsx from "xlsx";
import { UseMainStore } from "../State/SingleViewStorePinia";
import { UseProgramStore } from "../State/Stores/ProgramStore";
import ColumnSelection from "./ColumnSelection";
export default {
  components: { ColumnSelection },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    return {
      getApiBaseUrl,
      getCurrentProgram,
    };
  },
  computed: {
    taxonomyOneUrl() {
      if (!this.taxonomyFilter) return null;
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/funnel/single/taxonomy_1/"
      );
    },
    taxonomyOneSelection() {
      if (!this.taxonomyOneOptions) return null;
      return this.taxonomyOneOptions.map((d) => d.taxonomy_1);
    },
    fetchOrderedColumns() {
      if (this.userDefinedColumnOrdering) {
        return _.cloneDeep(this.selectedColumnsCopy);
      } else {
        return _.intersection(this.selectedColumnsCopy, [
          ...this.alwaysSelectedColumnsCopy,
          ...this.availableColumns,
        ]);
      }
    },
    fetchProcessedHeaders() {
      return _.pick(this.columnHeaders, this.fetchOrderedColumns);
    },
    fetchProcessedData() {
      return _.orderBy(
        _.map(
          this.exportableDataCopy,
          function (d) {
            return _.pick(d, this.fetchOrderedColumns);
          }.bind(this)
        ),
        this.orderDataBy,
        this.orderDataDirection
      );
    },
    formattedData() {
      if (!this.filteredData) return null;
      return (
        this.filteredData?.map((d) => ({
          taxonomy_1: d.taxonomy_1,
          taxonomy_2: d.taxonomy_2,
          taxonomy_3: d.taxonomy_3,
          taxonomy_4: d.taxonomy_4,
          upper_solo_revenue: d.upper_funnel_revenue + d.solo_revenue,
          mid_lower_revenue: d.mid_funnel_revenue + d.lower_funnel_revenue,
          total_funnel_revenue:
            d.upper_funnel_revenue +
            d.mid_funnel_revenue +
            d.lower_funnel_revenue +
            d.solo_revenue,
          upper_solo_revenue_percent:
            d.upper_funnel_revenue_percent + d.solo_revenue_percent,
          mid_lower_revenue_percent:
            d.mid_funnel_revenue_percent + d.lower_funnel_revenue_percent,
        })) || []
      );
    },
    fetchFilteredData() {
      if (!this.formattedData) return null;
      return _.orderBy(
        _.map(
          this.formattedData,
          function (d) {
            return _.pick(d, this.fetchOrderedColumns);
          }.bind(this)
        ),
        this.orderDataBy,
        this.orderDataDirection
      );
    },
    fetchMergedDataExport() {
      if (this.exportComparisonData) {
        return [this.fetchComparisonHeaders].concat(this.fetchComparisonData);
      }
      if (this.export_taxonomy_level) {
        return [this.fetchProcessedHeaders].concat(this.fetchFilteredData);
      }
      return [this.fetchProcessedHeaders].concat(this.fetchProcessedData);
    },
    fetchComparisonHeaders() {
      const comparisonColumns = {};

      for (const [key, value] of Object.entries(this.fetchProcessedHeaders)) {
        if (key === "taxonomy_1") {
          comparisonColumns[key] = value;
          continue;
        }
        comparisonColumns[`${key}_current`] = `Current ${value}`;
        comparisonColumns[`${key}_previous`] = `Previous ${value}`;
        comparisonColumns[`${key}_difference`] = `${value} Difference`;
      }

      return comparisonColumns;
    },
    fetchComparisonData() {
      const processedComparisonData = [];

      for (const entry of Object.values(this.fetchProcessedData)) {
        const data = {};
        let comparisonValue = null;
        for (const [key, value] of Object.entries(entry)) {
          if (key === "taxonomy_1") {
            comparisonValue = this.comparisonData.filter(
              (d) => d.taxonomy_1 === value
            )[0];
            data[key] = value;
            continue;
          }

          if (comparisonValue[key] !== null) {
            data[`${key}_previous`] = comparisonValue[key];
          }

          data[`${key}_current`] = value;
        }

        processedComparisonData.push(data);
      }

      for (const entry of processedComparisonData) {
        for (const key of Object.keys(entry)) {
          if (key.endsWith("current")) {
            const originalKey = key.replace("_current", "");
            if (
              toString(entry[`${originalKey}_current`]).length > 0 &&
              toString(entry[`${originalKey}_previous`]).length > 0
            ) {
              const diff =
                entry[`${originalKey}_current`] -
                entry[`${originalKey}_previous`];
              if (diff === 0) {
                entry[`${originalKey}_difference`] = 0;
              } else {
                entry[`${originalKey}_difference`] =
                  diff / entry[`${originalKey}_previous`];
              }
            }
          }
        }
      }

      return processedComparisonData;
    },
  },
  data() {
    return {
      exportComparisonData: false,
      userDefinedColumnOrdering: false,
      taxonomyFilterCopy: false,
      availableColumnsCopy: [],
      selectedColumnsCopy: [],
      alwaysSelectedColumnsCopy: [],
      columnHeadersCopy: {},
      reportNameCopy: "",
      exportableDataCopy: [],
      orderDataByCopy: "taxonomy_1",
      orderDataDirectionCopy: "asc",
      export_taxonomy_1: this.$route.params.taxonomyOne,
      export_taxonomy_level: null,
      taxonomyOneOptions: [],
      loading: false,
      filteredData: null,
      allLevelSelections: [],
    };
  },
  props: {
    comparisonData: {
      type: Object,
      default: null,
    },
    showComparison: {
      type: Boolean,
      default: false,
    },
    taxonomyFilter: {
      type: Boolean,
      default: false,
    },
    taxonomyFilterDates: {
      type: Object,
      default: {},
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    selectedColumns: {
      type: Array,
      default: () => [],
    },
    alwaysSelectedColumns: {
      type: Array,
      default: () => ["taxonomy_1", "taxonomy_2", "taxonomy_3", "taxonomy_4"],
    },
    columnHeaders: {
      type: Object,
      default: () => {},
    },
    reportName: {
      type: String,
      default: "",
    },
    exportableData: {
      type: Array,
      default: () => [],
    },
    orderDataBy: {
      type: [String, Array],
      default: "taxonomy_1",
    },
    orderDataDirection: {
      type: [String, Array],
      default: "asc",
    },
  },
  methods: {
    loadAsyncData(url) {
      this.loading = true;
      axios
        .get(url)
        .then(({ data }) => {
          this.taxonomyOneOptions = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async loadExportData(url) {
      return axios
        .get(url)
        .then(({ data }) => {
          return data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setTaxonomyFilter() {
      this.taxonomyFilterCopy = this.taxonomyFilter;
    },
    setCurrentColumns() {
      this.selectedColumnsCopy = this.selectedColumns;
    },
    setAllColumns() {
      this.selectedColumnsCopy = this.availableColumns;
    },
    setNoColumns() {
      this.selectedColumnsCopy = _.intersection(
        this.selectedColumns,
        this.alwaysSelectedColumns
      );
    },
    fetchWorksheet() {
      return xlsx.utils.json_to_sheet(this.fetchMergedDataExport, {
        // header: this.excelHeaders,
        skipHeader: true,
      });
    },
    fetchArrayBuffer(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;

      return buf;
    },
    async getTaxonomyData() {
      const loadingComponent = this.$oruga.loading.open({
        container: null,
      });
      const export_values = [];
      let url =
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/funnel/single/taxonomy_1/" +
        encodeURIComponent(this.export_taxonomy_1) +
        "/taxonomy_2";
      const taxonomy_2_url =
        url +
        "?date_from=" +
        this.taxonomyFilterDates.from +
        "&date_to=" +
        this.taxonomyFilterDates.to +
        "&interval=day";
      if (this.export_taxonomy_level === "2") {
        loadingComponent.close();
        return this.loadExportData(taxonomy_2_url);
      }
      const taxonomy_2_data = await this.loadExportData(taxonomy_2_url);
      const taxonomy_2_values = Object.values(taxonomy_2_data).map(
        (d) => d.taxonomy_2
      );
      for (const tax_2_value of taxonomy_2_values) {
        let taxonomy_3_url = url;
        taxonomy_3_url += "/" + encodeURIComponent(tax_2_value) + "/taxonomy_3";
        if (this.export_taxonomy_level === "3") {
          export_values.push(
            ...(await this.loadExportData(
              taxonomy_3_url +
                "?date_from=" +
                this.taxonomyFilterDates.from +
                "&date_to=" +
                this.taxonomyFilterDates.to +
                "&interval=day"
            ))
          );
          if (tax_2_value === taxonomy_2_values[taxonomy_2_values.length - 1]) {
            loadingComponent.close();
          }
          continue;
        }
        const taxonomy_3_data = await this.loadExportData(
          taxonomy_3_url +
            "?date_from=" +
            this.taxonomyFilterDates.from +
            "&date_to=" +
            this.taxonomyFilterDates.to +
            "&interval=day"
        );
        const taxonomy_3_values = Object.values(taxonomy_3_data).map(
          (d) => d.taxonomy_3
        );
        for (const tax_3_value of taxonomy_3_values) {
          let taxonomy_4_url = taxonomy_3_url;
          taxonomy_4_url +=
            "/" +
            encodeURIComponent(tax_3_value) +
            "/taxonomy_4?date_from=" +
            this.taxonomyFilterDates.from +
            "&date_to=" +
            this.taxonomyFilterDates.to +
            "&interval=day";
          export_values.push(...(await this.loadExportData(taxonomy_4_url)));
        }
        if (tax_2_value === taxonomy_2_values[taxonomy_2_values.length - 1]) {
          loadingComponent.close();
        }
      }

      return export_values;
    },
    exportExcel() {
      let wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, this.fetchWorksheet(), this.name);

      saveAs(
        new Blob(
          [
            this.fetchArrayBuffer(
              xlsx.write(wb, { bookType: "xlsx", type: "binary" })
            ),
          ],
          {
            type: "application/octet-stream",
          }
        ),
        this.reportNameCopy + ".xlsx"
      );

      this.$emit("close");
    },
    async exportCsv() {
      if (this.export_taxonomy_level) {
        this.filteredData = await this.getTaxonomyData();
      }

      saveAs(
        new Blob(
          [
            this.fetchArrayBuffer(
              xlsx.utils.sheet_to_csv(this.fetchWorksheet())
            ),
          ],
          {
            type: "application/octet-stream",
          }
        ),
        this.reportNameCopy + ".csv"
      );

      this.$emit("close");
    },
    shoudAddSelectedColumn(arr = [], key = "") {
      arr.forEach((a) => {
        if (!this[a].includes(key) || key !== "taxonomy_1") {
          this[a].push(key);
        }
      });
    },
    filterOutDeletedColumn(arr = []) {
      return arr.filter((col) => !this.allLevelSelections.includes(col));
    },
    filterOutAllDeletedColumns(arr = []) {
      arr.forEach((a) => {
        this[a] = this.filterOutDeletedColumn(this[a]);
      });
    },
    resetTaxDropDowns() {
      this.filterOutAllDeletedColumns([
        "availableColumnsCopy",
        "selectedColumnsCopy",
      ]);
      this.export_taxonomy_1 = null;
      this.export_taxonomy_level = null;
      this.allLevelSelections = [];
    },
    createArrayFromStringNumber(str = "") {
      if (isNaN(parseInt(str))) return [];
      return Array.from(Array(parseInt(str)).keys());
    },
    addTaxToColumnState(val = "") {
      this.filterOutAllDeletedColumns([
        "availableColumnsCopy",
        "selectedColumnsCopy",
      ]);
      this.allLevelSelections = [];
      const int = parseInt(val);
      if (!isNaN(int)) {
        Array.from(Array(int).keys()).forEach((k) => {
          const taxonomy = `taxonomy_${k + 1}`;
          this.shoudAddSelectedColumn(
            ["availableColumnsCopy", "selectedColumnsCopy"],
            taxonomy
          );

          this.allLevelSelections = [
            ...new Set([taxonomy, ...this.allLevelSelections]),
          ].filter((col) => col !== "taxonomy_1");
        });
      }
    },
  },
  mounted() {
    let orderDataBy = JSON.parse(JSON.stringify(this.orderDataBy));
    let orderDataDirection = JSON.parse(
      JSON.stringify(this.orderDataDirection)
    );
    this.taxonomyFilterCopy = JSON.parse(JSON.stringify(this.taxonomyFilter));
    this.availableColumnsCopy = JSON.parse(
      JSON.stringify(this.availableColumns)
    );
    this.selectedColumnsCopy = JSON.parse(JSON.stringify(this.selectedColumns));
    this.alwaysSelectedColumnsCopy = JSON.parse(
      JSON.stringify(this.alwaysSelectedColumns)
    );
    this.columnHeadersCopy = JSON.parse(JSON.stringify(this.columnHeaders));
    this.reportNameCopy = JSON.parse(JSON.stringify(this.reportName));
    this.exportableDataCopy = JSON.parse(JSON.stringify(this.exportableData));
    this.orderDataByCopy = Array.isArray(orderDataBy)
      ? orderDataBy
      : [orderDataBy];
    this.orderDataDirectionCopy = Array.isArray(orderDataDirection)
      ? orderDataDirection
      : [orderDataDirection];
  },
  watch: {
    taxonomyOneUrl: {
      immediate: true,
      handler() {
        if (this.taxonomyFilter) {
          this.loadAsyncData(
            this.taxonomyOneUrl +
              "?date_from=" +
              this.taxonomyFilterDates.from +
              "&date_to=" +
              this.taxonomyFilterDates.to +
              "&interval=day"
          );
        }
      },
    },
    export_taxonomy_level(val) {
      this.addTaxToColumnState(val);
    },
  },
};
</script>

<style scoped lang="scss">
.is-aligned-super {
  vertical-align: super;
}

footer.modal-card-foot {
  .o-field {
    flex-grow: 0;
  }
}
</style>
