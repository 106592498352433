<template>
  <div v-if="seriesReady && chartData.length">
    <vue-apex-charts
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="100 + chartData.length * 30"
    ></vue-apex-charts>
  </div>
</template>

<script>
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
      required: true,
      validator: (dataArray) =>
        dataArray.every(
          (chartEntry) =>
            "location" in chartEntry &&
            Array.isArray(chartEntry.location) &&
            chartEntry.location.length > 0 &&
            chartEntry.location.length <= 4 &&
            "upper_solo_revenue" in chartEntry &&
            "mid_lower_revenue" in chartEntry
        ),
    },
  },
  setup() {
    const { displayMonetaryValueRounded } = UseNumberHelper();
    return {
      displayMonetaryValueRounded,
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          id: "initiator_chart",
          stacked: true,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#005cae", "#b5b5b5"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        markers: {
          size: 0,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return this.displayMonetaryValueRounded(value);
            }.bind(this),
          },
          x: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: this.chartData?.map((d) => d.location.slice(-1)[0]),
          labels: {
            show: true,
            formatter: (value) => this.displayMonetaryValueRounded(value),
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            height: "100%",
          },
        },
      };
    },
    series() {
      return [
        {
          name: "Solo + Upper Revenue (Initiator)",
          data: this.chartData?.map((d) => d.upper_solo_revenue),
        },
        {
          name: "Mid + Lower Revenue",
          data: this.chartData?.map((d) => d.mid_lower_revenue),
        },
      ];
    },
    seriesReady() {
      return this.series.map((s) => s.data).every((x) => x.length > 0);
    },
  },
};
</script>
