<script>
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";

export default {
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());

    return {
      getApiBaseUrl,
    };
  },
  computed: {
    url() {
      return this.getBaseUrl + "/auth/check";
    },
  },
  methods: {
    loggedIn() {},
  },
  created() {
    this.loggedIn();
  },
};
</script>
