<template>
  <div class="box">
    <div class="box-title">
      <router-link
        :to="{
          path: `/segment-groups/${group.group_name}`,
        }"
        >{{ capitalise(group.group_title) }}</router-link
      >
      <div class="button-group">
        <router-link :to="`/segment-groups/upload/${group.group_name}`"
          ><button type="button" class="button is-primary is-outlined">
            Import Segment Data
          </button></router-link
        >
        <router-link
          :to="{
            path: `/segment-groups/${group.group_name}`,
          }"
        >
          <button type="button" class="button is-danger">Edit Group</button>
        </router-link>
      </div>
    </div>

    <div class="box-body" v-if="group.segments && group.segments.length">
      <p>Segments:</p>
      <ul class="segment-list">
        <li class="is-rounded" v-for="(segment, i) in group.segments" :key="i">
          {{ segment.segment_title }}
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="content has-text-grey has-text-centered">
        <p>
          <o-icon icon="emoticon-sad" size="is-large"></o-icon>
        </p>
        <p>
          There are no segments yet for this group. Click
          <router-link
            :to="{
              path: `/segment-groups/${group.group_name}`,
            }"
            >here</router-link
          >
          to create some.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBody from "./ModalBody.vue";
import { UseStringHelper } from "../../Helper/Functional/UseStringHelper";
export default {
  name: "GroupSegmentCard",
  components: { ModalBody },
  props: {
    group: {},
    url: {
      type: String,
      default: "",
    },
    toggleUploadModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { capitalise } = UseStringHelper();

    return {
      capitalise,
    };
  },
  data() {
    return {
      segments: [],
      isLoading: false,
    };
  },
  computed: {
    showLoader() {
      return this.group ? this.group.segments && this.group.segments.length === 0 : true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../sass/variables";
.box {
  flex: 1;
  .loading-container {
    position: relative;
    padding: 1rem 0 0;
    min-height: 100px;
  }
  .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;

    button {
      a {
        color: currentColor;
      }
    }
  }
  .content,
  .box-body {
    padding: 1rem 0;
  }

  .box-body {
    .segment-list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-top: 1rem;

      .is-rounded {
        border: 1px solid #dbdbdb;
        border-radius: 290486px;
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}
.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0 0 0.5rem;
}
</style>
