import { IProgramDataSets } from "../../State/Typings/SingleViewStoreTypes";
import { ref, watchEffect, Ref, computed } from "vue";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { storeToRefs } from "pinia";
import { every, partial, has, some, get, find } from "lodash";

interface IProps {
  meta: Ref<{
    cancelledName: string;
    excludedColumns: string[];
    validName: string;
  }>;
}

const useNewDataSource = ref(false);
const dataSetOne = ref<IProgramDataSets>({} as IProgramDataSets);
const dataSetTwo = ref<IProgramDataSets>({} as IProgramDataSets);

export const UseMetaGetterComputed = ({ meta }: IProps) => {
  const { getCurrentProgramDataSets } = storeToRefs(UseProgramStore());

  const useNewDataSourceHandler = (
    dataSet: Ref<IProgramDataSets[]>,
    metaUseNew: IProps["meta"]
  ) => {
    useNewDataSource.value =
      every(["dataset_one", "dataset_two"], partial(has, metaUseNew.value)) &&
      some(dataSet.value, {
        id: get(metaUseNew.value, ["dataset_one", "id"]),
      }) &&
      some(dataSet.value, {
        id: get(metaUseNew.value, ["dataset_two", "id"]),
      });
  };

  const dataSetOneHandler = (
    dataSet: Ref<IProgramDataSets[]>,
    dataSetMeta: IProps["meta"]
  ) => {
    dataSetOne.value = find(dataSet.value, {
      id: get(dataSetMeta.value, ["dataset_one", "id"]),
    }) as IProgramDataSets;
  };

  const dataSetTwoHandler = (
    dataSet: Ref<IProgramDataSets[]>,
    dataSetMeta: IProps["meta"]
  ) => {
    dataSetTwo.value = find(dataSet.value, {
      id: get(dataSetMeta.value, ["dataset_two", "id"]),
    }) as IProgramDataSets;
  };

  const availableColumns = computed(() => [
    "variable_cost",
    "fixed_cost",
    "total_cost",
    "clicks",
    "impressions",
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "attributed_revenue_variance",
    "attributed_revenue_variance_percent",
    "introducer_ratio",
    "cpa",
    "roas",
    "roi",
    "sales_including",
    "proportional_credit",
  ]);

  watchEffect(() => {
    useNewDataSourceHandler(getCurrentProgramDataSets, meta);
    dataSetOneHandler(getCurrentProgramDataSets, meta);
    dataSetTwoHandler(getCurrentProgramDataSets, meta);
  });
  return {
    useNewDataSource,
    dataSetOne,
    dataSetTwo,
    availableColumns,
  };
};
