<template>
  <section class="section top-section">
    <o-loading
      v-if="segmentGroups.length === 0"
      class="is-loader-overlay"
      :is-full-page="false"
      v-model:active="isLoading"
    ></o-loading>
    <section v-if="noData">
      <div class="content has-text-grey has-text-centered">
        <p>
          <o-icon icon="emoticon-sad" size="is-large"></o-icon>
        </p>
        <p>
          There're no groups to show. Please add one
          <span @click="toggleModal = true" class="toggle-modal"
            ><strong>here</strong></span
          >
        </p>
        <add-group-button
          :toggle-modal="toggleModal"
          :segment-groups="segmentGroups"
          :segment-group-url="segmentGroupUrl"
          @handleCloseModal="(val) => (toggleModal = val)"
        ></add-group-button>
      </div>
    </section>
    <section v-else>
      <GroupSegmentsCards
        :segment-groups="segmentGroups"
        :segment-group-url="segmentGroupUrl"
      />
    </section>
  </section>
</template>

<script>
import GroupSegmentsCards from "../../Modules/SegmentManagement/GroupSegmentsCards.vue";
import AddGroupButton from "../../Modules/SegmentManagement/UpdateGroupComponents/AddGroupButton.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseProgramStore } from "../../Modules/State/Stores/ProgramStore";
import { UseSegementStore } from "../../Modules/State/Stores/SegmentStore";
export default {
  name: "SegmentManagement",
  data() {
    return {
      toggleModal: false,
      groupId: 0,
      isLoading: false,
      noData: false,
    };
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { getSegmentGroupData: segmentGroups } = storeToRefs(
      UseSegementStore()
    );
    const { setSegmentGroupData } = UseSegementStore();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      segmentGroups,
      setSegmentGroupData,
    };
  },
  computed: {
    segmentGroupUrl() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/segment-groups"
      );
    },
  },
  components: { GroupSegmentsCards, AddGroupButton },
  mounted() {
    if (!this.segmentGroups.length) {
      this.loadAsyncData();
    }
  },
  methods: {
    loadAsyncData() {
      this.isLoading = true;
      axios.get(this.segmentGroupUrl).then(({ data }) => {
        this.setSegmentGroupData({ data: data.data });
        if (this.segmentGroups.length) {
          this.isLoading = false;
          this.noData = false;
        } else {
          this.noData = true;
          this.isLoading = false;
        }
      });
    },
  },
  watch: {
    getCurrentProgram() {
      this.loadAsyncData();
    },
  },
};
</script>

<style scoped lang="scss">
.toggle-modal {
  text-decoration: underline;
  cursor: pointer;
}
</style>
