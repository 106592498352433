<template>
  <outer-section>
    <o-loading full-page v-model:active="loading"></o-loading>
    <report-chart :report="data"></report-chart>
    <report-table :report="data"></report-table>
  </outer-section>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import ReportChart from '../../../Modules/Crossover/Reports/Chart.vue';
import ReportTable from '../../../Modules/Crossover/Reports/Table.vue';

import { storeToRefs } from 'pinia';
import { UseMainStore } from '../../../Modules/State/SingleViewStorePinia';
import { UseProgramStore } from '../../../Modules/State/Stores/ProgramStore';

const { getApiBaseUrl } = storeToRefs(UseMainStore());
const { getCurrentProgram } = storeToRefs(UseProgramStore());
const route = useRoute();

const data = ref(null);
const loading = ref(false);

const reportUrl = computed(() => {
  return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/performance/crossover/${route.params.id}`
})

const loadReportData = async () => {
  loading.value = true;
  axios.get(reportUrl.value)
    .then((d) => {
      data.value = d.data.data;
      loading.value = false;
    })
    .catch((e) => {
      console.error(e);
      loading.value = false;
    })
}

loadReportData();

onMounted(() => {
  document.title = "View Report | SingleView"
})
</script>