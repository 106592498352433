import { defineStore } from "pinia";

import {
  IGetReportSettings,
  TGetPerformanceMetricOne,
  TGetPerformanceMetricTwo,
  TGetPerformanceChartType,
  TGetPerformanceChartInterval,
  TColumns,
  IGetAvailablePerformanceAnnotations,
  IGetPerformanceTableColumnSets,
  IGetPerformanceAnnotations,
} from "./../Typings/SingleViewStoreTypes";

import CONSTANTS from "../Constants";
import { set, merge } from "lodash";

interface ReportSettingsState {
  reportSettings: IGetReportSettings;
}

const stringifyReportSettings = (obj: IGetReportSettings): string => {
  return JSON.stringify(obj);
};

const reportSettingsSetter = <T>(
  path: string,
  item: T,
  settings: IGetReportSettings
) => {
  set(settings, path, item);
};

export const UseReportSettingsStore = defineStore("reportSettings", {
  state: (): ReportSettingsState => ({
    reportSettings: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS) || "{}"
    ),
  }),
  getters: {
    getPerformanceAnnotations: (state) =>
      state.reportSettings.performance.annotations || [],
    getAvailablePerformanceAnnotations: (state) =>
      state.reportSettings.performance.available_annotations || [],
    getPerformanceMetricOne: (state) =>
      state.reportSettings.performance.metrics.one,
    getPerformanceMetricTwo: (state) =>
      state.reportSettings.performance.metrics.two,
    getPerformanceChartType: (state) =>
      state.reportSettings.performance.chart.type,
    getPerformanceChartInterval: (state) =>
      state.reportSettings.performance.chart.interval,
    getPerformanceTableColumns: (state) =>
      state.reportSettings.performance.table.columns ||
      CONSTANTS.SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMN_SETS,
    getPerformanceTableColumnSet: (state) =>
      state.reportSettings.performance.table.column_set || 0,
    getPerformanceTableColumnSetFavourite: (state) =>
      state.reportSettings.performance.table.column_set_favourite || 0,
    getPerformanceTableColumnSets: (state) =>
      state.reportSettings.performance.table.column_sets ||
      CONSTANTS.SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMN_SETS,
    getPerformanceTableSortMetric: (state) =>
      state.reportSettings.performance.table.sort.metric || "taxonomy_1",
    getPerformanceTableSortDirection: (state) =>
      state.reportSettings.performance.table.sort.direction || "asc",
    getAttributionSortMetric: (state) =>
      state.reportSettings.attribution.sort.metric || "taxonomy_1",
    getAttributionSortDirection: (state) =>
      state.reportSettings.attribution.sort.direction || "asc",
    getFunnelSortMetric: (state) =>
      state.reportSettings.funnel.sort.metric || "total_revenue",
    getFunnelSortDirection: (state) =>
      state.reportSettings.funnel.sort.direction || "asc",
    getMetaMetric: (state) => state.reportSettings.meta.metric,
    getMetaComparison: (state) =>
      state.reportSettings.meta.comparison || "cancellation",
    getMetaTableColumns: (state) =>
      state.reportSettings.meta.table.columns || [
        "last_click_sales",
        "last_click_revenue",
        "attributed_sales",
        "attributed_revenue",
        "cpa",
        "roas",
        "attributed_revenue_variance_percent",
      ],
    getMetaTableSortMetric: (state) =>
      state.reportSettings.meta.table.sort.metric || "taxonomy_1",
    getMetaTableSortDirection: (state) =>
      state.reportSettings.meta.table.sort.direction || "asc",
    getSegmentComparisonMetric: (state) =>
      state.reportSettings.segmentComparison.metric || "taxonomy_1",
    getSegmentComparisonSortTableColumns: (state) =>
      state.reportSettings.segmentComparison.table.columns || [
        "last_click_sales",
        "last_click_revenue",
        "attributed_sales",
        "attributed_revenue",
        "cpa",
        "roas",
        "attributed_revenue_variance_percent",
      ],
    getSegmentComparisonSortMetric: (state) =>
      state.reportSettings.segmentComparison.table.sort.metric || "taxonomy_1",
    getSegmentComparisonSortDirection: (state) =>
      state.reportSettings.segmentComparison.table.sort.direction || "asc",
  },
  actions: {
    setPerformanceMetricOne(metric: TGetPerformanceMetricOne) {
      reportSettingsSetter(
        "performance.metrics.one",
        metric,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceMetricTwo(metric: TGetPerformanceMetricTwo) {
      reportSettingsSetter(
        "performance.metrics.two",
        metric,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceChartType(type: TGetPerformanceChartType) {
      reportSettingsSetter("performance.chart.type", type, this.reportSettings);
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceChartInterval(interval: TGetPerformanceChartInterval) {
      reportSettingsSetter(
        "performance.chart.interval",
        interval,
        this.reportSettings
      );
      this.reportSettings.performance.chart.interval = interval;
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setAvailablePerformanceAnnotations(
      annotations: IGetAvailablePerformanceAnnotations[]
    ) {
      reportSettingsSetter(
        "performance.available_annotations",
        annotations,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableColumns(columns: string[]) {
      reportSettingsSetter(
        "performance.table.columns",
        columns,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableColumnSet(set: number) {
      reportSettingsSetter(
        "performance.table.column_set",
        set,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableColumnSetFavourite(set_favourite: number) {
      reportSettingsSetter(
        "performance.table.column_set_favourite",
        set_favourite,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableColumnSets(
      columnSets: IGetPerformanceTableColumnSets[]
    ) {
      reportSettingsSetter(
        "performance.table.column_sets",
        columnSets,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableSortMetric(metric: string) {
      reportSettingsSetter(
        "performance.table.sort.metric",
        metric,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceTableSortDirection(direction: string) {
      reportSettingsSetter(
        "performance.table.sort.direction",
        direction,
        this.reportSettings
      );
      const complete = stringifyReportSettings(this.reportSettings);
      localStorage.setItem(CONSTANTS.SINGLEVIEW_REPORT_SETTINGS, complete);
    },
    setPerformanceAnnotations(annotations: IGetPerformanceAnnotations[]) {
      let settings = this.reportSettings;
      set(settings, "performance.annotations", annotations);
      let complete = settings;

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setAttributionSortMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        attribution: { sort: { metric: metric } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setAttributionSortDirection(direction: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        attribution: { sort: { direction: direction } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setFunnelSortMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        funnel: { sort: { metric: metric } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setFunnelSortDirection(direction: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        funnel: { sort: { direction: direction } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setMetaMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, { meta: { metric: metric } });
      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setMetaComparison(comparison: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, { meta: { comparison: comparison } });
      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setMetaTableColumns(columns: TColumns) {
      let settings = this.reportSettings;
      let complete = set(settings, "meta.table.columns", columns);

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setMetaTableSortMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        meta: { table: { sort: { metric: metric } } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setMetaTableSortDirection(direction: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        meta: { table: { sort: { direction: direction } } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setSegmentComparisonMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, { segmentComparison: { metric: metric } });
      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setSegmentComparisonTableColumns(columns: TColumns) {
      let settings = this.reportSettings;
      let complete = set(settings, "segmentComparison.table.columns", columns);

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setSegmentComparisonTableSortMetric(metric: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        segmentComparison: { table: { sort: { metric: metric } } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
    setSegmentComparisonTableSortDirection(direction: string) {
      let settings = this.reportSettings;
      let complete = merge(settings, {
        segmentComparison: { table: { sort: { direction: direction } } },
      });

      this.reportSettings = complete;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_REPORT_SETTINGS,
        JSON.stringify(complete)
      );
    },
  },
});
