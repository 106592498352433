<template>
  <div class="box">
    <o-loading :full-page="false" :active="loading"></o-loading>
    <div class="is-clearfix">
      <!--<router-link to="/account/costs/fixed/create" class="button is-primary is-outlined is-pulled-right">Add Fixed Spend</router-link>-->
      <!--<button class="button is-primary is-pulled-right">Add Fixed Spend</button>-->
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/account/costs/fixed">Fixed Spend</router-link>
          </li>
          <li class="is-active">
            <router-link :to="'/account/costs/fixed/' + spend">{{ spend }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <br />

    <fixed-costs-fields
      v-if="!isEmpty(data)"
      v-model:cost="data.cost"
      v-model:startDate="data.start_date"
      v-model:endDate="data.end_date"
      :fixed-costs="data.fixed_costs"
      v-model:frequency="data.frequency"
      v-model:originalFrequency="data.original_frequency"
      v-model:taxonomyOne="data.taxonomy_1"
      v-model:taxonomyTwo="data.taxonomy_2"
      v-model:taxonomyThree="data.taxonomy_3"
      v-model:taxonomyFour="data.taxonomy_4"
      v-model:request="request"
      v-model:saving="saving"
      :isNew="false"
      @save="save"
    ></fixed-costs-fields>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";
import FixedCostsFields from "./Fields";
import { isEmpty } from "lodash";

export default {
  components: { FixedCostsFields },
  name: "FixedCostsEdit",
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    const { set403Redirect, set403Text } = UseAxiosErrorHelper();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      set403Redirect,
      set403Text,
      isEmpty,
    };
  },
  computed: {
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/fixed-costs/" +
        this.spend
      );
    },
  },
  data() {
    return {
      data: {},
      request: {},
      saving: false,
      loading: false,
    };
  },
  props: {
    spend: {
      required: true,
    },
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          let temp = data.data;
          let j = JSON.parse(temp.meta);
          _.assign(temp, { frequency: _.get(j, "frequency", "") });
          _.assign(temp, {
            original_frequency: _.get(j, "original_frequency", ""),
          });

          this.data = temp;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    save() {
      this.saving = true;
      axios
        .patch(this.url, this.request)
        .then(() => {
          this.saving = false;
          swal
            .fire({
              type: "success",
              title: "Success",
              text: "The spend configuration has been saved",
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
            .then(() => {
              this.$router.push("/account/costs/fixed");
            });
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
  watch: {
    url() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.set403Redirect("/account/costs/fixed");
    this.set403Text("You don't have access to this spend configuration");

    this.loadAsyncData();
  },
};
</script>
