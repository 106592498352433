import { UseProgramStore } from "./../../../Modules/State/Stores/ProgramStore";
import { UseMainStore } from "./../../../Modules/State/SingleViewStorePinia";
export const UseLogout = () => {
  const logout = () => {
    const { removeToken, removeUserMessages } = UseMainStore();
    const { removeAvailablePrograms, removeProgramMessages } =
      UseProgramStore();
    removeAvailablePrograms();
    removeProgramMessages();
    removeToken();
    removeUserMessages();
  };

  return {
    logout,
  };
};
