<template>
  <div>
    <meta-taxonomy-one
      :taxonomy="currentTaxonomy"
      :taxonomy-one="taxonomyOne"
      :taxonomy-two="taxonomyTwo"
      :taxonomy-three="taxonomyThree"
      :taxonomy-four="taxonomyFour"
      :validName="validName"
      :cancelledName="cancelledName"
      :meta="meta"
      :tab="tab"
    ></meta-taxonomy-one>
  </div>
</template>

<script>
import MetaTaxonomyOne from "../../../Modules/Meta/TaxonomyOne";

export default {
  components: { MetaTaxonomyOne },
  computed: {
    currentTaxonomy() {
      if (this.taxonomyThree !== "" && this.taxonomyThree !== undefined) {
        return 4;
      } else if (this.taxonomyTwo !== "" && this.taxonomyTwo !== undefined) {
        return 3;
      } else if (this.taxonomyOne !== "" && this.taxonomyOne !== undefined) {
        return 2;
      }

      return 1;
    },
  },
  props: {
    taxonomyOne: {
      type: String,
      default: () => "",
    },
    taxonomyTwo: {
      type: String,
      default: () => "",
    },
    taxonomyThree: {
      type: String,
      default: () => "",
    },
    taxonomyFour: {
      type: String,
      default: () => "",
    },
    validName: {
      type: String,
      default: () => "Valid",
    },
    cancelledName: {
      type: String,
      default: () => "Cancelled",
    },
    tab: {
      type: String,
      default: () => "visual",
    },
    meta: {
      type: Object,
      default: () => { },
    },
  },
};
</script>
