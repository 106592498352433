<template>
  <section class="segment-upload column">
    <div>
      <h2 class="subtitle">Upload Segement Data</h2>
      <div class="card box">
        <button type="button" class="button is-primary">
          <router-link :to="`/segment-groups/upload/${groupName}`"
            >Import Segment Data</router-link
          >
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UploadSegments",
  props: {
    groupName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.segment-upload {
  a {
    color: currentColor;
  }
}
</style>
