<template>
  <o-modal trap-focus aria-role="dialog" aria-modal v-model:active="modalActiveComputed">
    <div class="modal-card">
      <o-loading v-model:active="modalLoading"></o-loading>
      <header class="modal-card-head">
        <p class="modal-card-title">Generate a Crossover Report</p>
      </header>

      <section class="modal-card-body">
        <div class="o-field tw-mb-4">
          <label class="o-field__label label">Report Dates</label>
        </div>
        <div>
          <vue-ctk-date-time-picker
            range
            v-model="datesComputed"
            formatted="DD/MM/YYYY"
            format="YYYY-MM-DD hh:mm:ss"
            @is-hidden="validateDatesOnHidden"
            @input="rangeValidation"
            input-size="sm"
            :maxDate="currentDate"
            noClearButton
            noShortcuts
            noLabel
            class="datepicker-minimum-width"
            :first-day-of-week="1"
          ></vue-ctk-date-time-picker>
        </div>
        <hr />
        <div class="o-field tw-mb-4">
          <label class="o-field__label label">Publisher 1</label>
        </div>
        <div>
          <touchpoint-search
            v-model:touchpoint-query="publisher_1"
            :key="'publisher_1'"
            :all-taxonomies-data="allTaxonomiesData"
          ></touchpoint-search>
        </div>
        <div class="o-field tw-mb-4">
          <label class="o-field__label label">Publisher 2</label>
        </div>
        <div>
          <touchpoint-search
            v-model:touchpoint-query="publisher_2"
            :key="'publisher_2'"
            :all-taxonomies-data="allTaxonomiesData"
          ></touchpoint-search>
        </div>
      </section>
      <footer class="modal-card-foot" style="flex-direction: row-reverse">
        <div>
          <button class="button" type="button" @click="modalActiveComputed = false">
            Close
          </button>
          <o-button variant="primary" @click="generateReport"> Generate Report </o-button>
        </div>
      </footer>
    </div>
  </o-modal>
</template>

<script setup>
import { toRefs, computed, ref } from "vue";
import TouchpointSearch from "../JourneyPaths/TouchpointSearch.vue";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import { UseDateStore } from "../State/Stores/DateStore";
import { storeToRefs } from "pinia";
import moment from "moment";
import axios from "axios";
import { UseMainStore } from "../State/SingleViewStorePinia";
import { UseProgramStore } from "../State/Stores/ProgramStore";

const { getApiBaseUrl } = storeToRefs(UseMainStore());
const { getCurrentProgram } = storeToRefs(UseProgramStore());
const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());

const props = defineProps({ allTaxonomiesData: Object, modalActive: Boolean });
const emits = defineEmits(["update:modalActive"]);

const { allTaxonomiesData, modalActive } = toRefs(props);

const dates = ref({ start: getDateFrom.value, end: getDateTo.value });
const publisher_1 = ref(null);
const publisher_2 = ref(null);
const modalLoading = ref(false);

const url = computed(() => {
  return `${getApiBaseUrl.value}/programs/${getCurrentProgram.value}/performance/crossover`;
});

const currentDate = computed(() => {
  return moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
});

const modalActiveComputed = computed({
  get() {
    return modalActive.value;
  },
  set(val) {
    emits("update:modalActive", val);
  },
});

const datesComputed = computed({
  get() {
    return dates.value;
  },
  set(val) {
    dates.value = val;
  },
});

const rangeValidation = () => {
  if (!datesComputed.value.start) {
    datesComputed.value.start = moment().subtract(1, "day").format("YYYY-MM-DD");
  }

  // If its after max date, take it back to max date
  if (
    datesComputed.value.end &&
    moment(datesComputed.value.end, "YYYY-MM-DD hh:mm:ss").diff(
      moment(getDateFrom.value, "YYYY-MM-DD"),
      "days"
    ) > 0
  ) {
    datesComputed.value.end = moment(getDateFrom.value)
      .subtract(1, "day")
      .format("YYYY-MM-DD");
  }
};

const validateDatesOnHidden = () => {
  if (!datesComputed.value.end) {
    let end = moment(getDateFrom.value).subtract(1, "day");
    datesComputed.value.end = moment(end).format("YYYY-MM-DD");
  }
};

const reportData = computed(() => {
  return {
    date_from: moment(datesComputed.value.start).format("YYYY-MM-DD"),
    date_to: moment(datesComputed.value.end).format("YYYY-MM-DD"),
    entity_1: publisher_1.value,
    entity_2: publisher_2.value,
  };
});

const displayError = () => {
  modalLoading.value = false;
  swal.fire({
    toast: true,
    type: "error",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    title: "There has been an error, please try again",
  });
};

const generateReport = async () => {
  modalLoading.value = true;
  axios
    .post(url.value, reportData.value)
    .then((r) => {
      modalLoading.value = false;
      if (r.data.data.success) {
        swal
          .fire({
            type: "success",
            title: "Report Generating",
            text: "Please check back later",
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then(() => {
            emits("update:modalActive", false);
          });
      } else {
        displayError();
      }
    })
    .catch(() => {
      displayError();
    });
};
</script>
