<template>
  <div>
    <template v-if="shouldBeTruncated">
      <o-tooltip :label="text" dashed>
        {{ truncatedText }}
      </o-tooltip>
    </template>
    <template v-else>
      {{ text }}
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    shouldBeTruncated() {
      return this.text.length > this.maximumLength;
    },
    truncatedText() {
      return this.text.substr(0, this.maximumLength) + "\u2026";
    },
  },
  props: {
    text: {
      type: String,
      default: () => "",
    },
    maximumLength: {
      type: Number,
      default: () => 30,
    },
  },
};
</script>
