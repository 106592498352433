<template>
  <div class="columns is-multiline">
    <template v-for="column in availableColumnsChunked">
      <div class="column is-half is-hidden-mobile">
        <o-checkbox
          v-model="selectedColumnsCopy"
          :disabled="alwaysSelectedColumns.includes(column)"
          :native-value="column"
          >{{ columnHeaders[column] || column }}</o-checkbox
        >
      </div>
    </template>
    <template v-for="column in availableColumns">
      <div class="column is-half is-hidden-tablet">
        <o-checkbox
          v-model="selectedColumnsCopy"
          :disabled="alwaysSelectedColumns.includes(column)"
          :native-value="column"
          variant="primary"
          >{{ columnHeaders[column] || column }}</o-checkbox
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    selectedColumnsCopy: {
      get() {
        return this.selectedColumns;
      },
      set(val) {
        this.$emit("update:selectedColumns", val);
      },
    },
    availableColumnsChunked() {
      let chunked = _.chunk(
        this.availableColumns,
        _.ceil(this.availableColumns.length / 2)
      );

      let rtn = [];

      _.forEach(chunked[0], function (value, index) {
        rtn.push(value);

        if (_.get(chunked[1], index)) {
          rtn.push(chunked[1][index]);
        }
      });

      return rtn;
    },
  },
  props: {
    selectedColumns: {
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
    alwaysSelectedColumns: {
      type: Array,
      default: () => [],
    },
    columnHeaders: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
