<template>
  <o-notification
    :type="type"
    :icon="icon"
    position="is-top-right"
    style="pointer-events: all"
  >
    <div v-html="content"></div>
  </o-notification>
</template>

<script>
export default {
  computed: {
    id() {
      return _.get(this.message, "id");
    },
    content() {
      return _.get(this.message, "message", "");
    },
    icon() {
      return _.get(this.message, "meta.icon", "");
    },
    type() {
      return _.get(this.message, "meta.type", "is-info");
    },
  },
  props: {
    message: {
      type: Object,
      default: {},
    },
  },
};
</script>
