<template>
  <div></div>
</template>

<script>
import { UseMainStore } from "../../../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";

export default {
  data() {
    return {
      loading: false,
      fullPageLoading: false,
      passwordChanged: false,
      authorised: false,
      showBadLinkMsg: false,
    };
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());

    return {
      getApiBaseUrl,
    };
  },
  methods: {
    checkToken(token = "", email = "", url = "") {
      try {
        axios({
          method: "post",
          url: url + "/auth/password/validate",
          headers: {
            Accept: "application/json",
          },
          data: { token, email },
        })
          .then(() => {
            this.authorised = true;
            this.fullPageLoading = false;
          })
          .catch((err) => {
            console.log("From Axios ", err);
            this.showBadLinkMsg = true;
            this.fullPageLoading = false;
            setTimeout(() => {
              this.$router.push("/login");
            }, 5000);
          });
      } catch (err) {
        console.log("From Try-Catch ", err);
        this.showBadLinkMsg = true;
        this.fullPageLoading = false;
        setTimeout(() => {
          this.$router.push("/login");
        }, 5000);
      }
    },
    resetPassword(
      password = "",
      confirm_password = "",
      email = "",
      token = ""
    ) {
      try {
        axios({
          method: "post",
          url: this.getApiBaseUrl + "/auth/password/reset",
          headers: {
            Accept: "application/json",
          },
          data: { password, confirm_password, email, token },
        })
          .then(() => {
            this.passwordChanged = true;
            this.loading = false;
            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
          })
          .catch((err) => console.log("From Axios ", err.response));
      } catch (err) {
        console.log("From Try-Catch ", err.response);
        setTimeout(() => {
          this.$router.push("/login");
        }, 5000);
      }
    },
  },
};
</script>
