<template>
  <div>
    <o-modal v-model:active="isColumnModalActive" has-modal-card ref="columnModal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Columns</p>
        </header>
        <section class="modal-card-body">
          <div class="columns is-multiline">
            <template v-for="column in availableColumnsChunked">
              <div class="column is-half">
                <o-checkbox v-model="columnsCompute" :native-value="column">
                  {{ headers[column] || column }}
                </o-checkbox>
              </div>
            </template>
          </div>
        </section>
        <footer class="modal-card-foot">
          <o-button @click="$refs.columnModal.close()" variant="primary">Close</o-button>
          <o-button @click="setDefaultColumns" icon-left="restart"
            >Reset to Defaults</o-button
          >
        </footer>
      </div>
    </o-modal>

    <configuration
      v-model:modal-active="isConfigModalActive"
      v-model:name-prop="reportName"
      v-model:level-prop="reportLevel"
      v-model:rules-prop="rules"
      :previewing="false"
    ></configuration>

    <o-field grouped group-multiline>
      <o-field>
        <o-field
          :variant="levelProp ? '' : 'danger'"
          :message="!levelProp ? 'Please select a Level' : ''"
        >
          <o-select
            v-model="reportLevel"
            name="reportLevel"
            data-vv-as="report level"
            expanded
          >
            <option value="taxonomy_1">Taxonomy 1</option>
            <option value="taxonomy_2">Taxonomy 2</option>
            <option value="taxonomy_3">Taxonomy 3</option>
            <option value="taxonomy_4">Taxonomy 4</option>
          </o-select>
        </o-field>
      </o-field>
      <o-field>
        <p class="control">
          <button class="button" @click="isColumnModalActive = true" :disabled="loading">
            <o-icon icon="table-row-height"></o-icon>
            <span>Columns</span>
          </button>
        </p>
      </o-field>
      <o-field>
        <p class="control">
          <o-dropdown aria-role="list">
            <template #trigger
              ><button class="button" type="button">
                <template v-if="filteredAvailableChannels.length === 0">
                  <o-icon icon="filter-outline"></o-icon>
                </template>
                <template
                  v-if="
                    filteredAvailableChannels.length > 0 &&
                    filteredAvailableChannels.length < 10
                  "
                >
                  <o-icon
                    :icon="'numeric-' + filteredAvailableChannels.length + '-circle'"
                  ></o-icon>
                </template>
                <template v-if="filteredAvailableChannels.length > 9">
                  <o-icon icon="numeric-9-plus-circle"></o-icon>
                </template>

                <span>Filter Channels</span>
                <o-icon icon="menu-down"></o-icon></button
            ></template>

            <o-dropdown-item @click="setDefaultChannels">
              <o-icon icon="restart"></o-icon>
              <span>Reset filter</span>
            </o-dropdown-item>

            <o-dropdown-item @click="removeChannels">
              <o-icon icon="close"></o-icon>
              <span>Remove All</span>
            </o-dropdown-item>

            <hr class="dropdown-divider" aria-role="menuitem" />

            <template v-for="taxonomyOne in availableChannels">
              <o-dropdown-item :value="true" aria-role="listitem" custom>
                <o-checkbox
                  v-model="filteredAvailableChannelsCompute"
                  :native-value="taxonomyOne.name"
                  >{{ taxonomyOne.name }}</o-checkbox
                >
              </o-dropdown-item>
            </template>
          </o-dropdown>
        </p>
      </o-field>
      <o-field>
        <p class="control">
          <button class="button" @click="isConfigModalActive = true" :disabled="loading">
            <o-icon icon="wrench-outline"></o-icon>
            <span>Configuration</span>
          </button>
        </p>
      </o-field>
      <o-field v-if="exportAvailable">
        <slot name="export">
          <!-- Export -->
        </slot>
      </o-field>

      <new-pagination
        v-model:page="pageCompute"
        v-model:per-page="perPageCompute"
        v-model:records="recordsCompute"
      ></new-pagination>
    </o-field>
  </div>
</template>

<script>
import Configuration from "./Configuration";
import NewPagination from "../../../Modules/Breakdown/NewPagination";

export default {
  components: { NewPagination, Configuration },
  computed: {
    reportName: {
      get() {
        return this.nameProp;
      },
      set(val) {
        this.$emit("update:nameProp", val);
      },
    },
    reportLevel: {
      get() {
        return this.levelProp;
      },
      set(val) {
        this.$emit("update:levelProp", val);
      },
    },
    rules: {
      get() {
        return this.rulesProp;
      },
      set(val) {
        this.$emit("update:rulesProp", val);
      },
    },
    filteredAvailableChannelsCompute: {
      get() {
        return this.filteredAvailableChannels;
      },
      set(val) {
        this.$emit("update:filteredAvailableChannels", val);
      },
    },
    columnsCompute: {
      get() {
        return this.columns;
      },
      set(val) {
        this.$emit("update:columns", val);
      },
    },
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    recordsCompute: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },
    availableColumnsChunked() {
      let chunked = _.chunk(
        this.availableColumns,
        _.ceil(this.availableColumns.length / 2)
      );

      let rtn = [];

      _.forEach(chunked[0], function (value, index) {
        rtn.push(value);

        if (_.get(chunked[1], index)) {
          rtn.push(chunked[1][index]);
        }
      });

      return rtn;
    },
  },
  props: {
    nameProp: {
      type: String,
      required: false,
    },
    levelProp: {
      type: String,
      default: "",
    },
    rulesProp: {
      type: Array,
      required: false,
    },
    availableColumns: {
      type: Array,
      default: [],
    },
    columns: {},
    headers: {
      type: Object,
      default: {},
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    records: {
      type: Number,
      default: 0,
    },
    loading: false,
    exportAvailable: false,
    availableChannels: {
      type: Array,
      default: () => [],
    },
    filteredAvailableChannels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isColumnModalActive: false,
      isConfigModalActive: false,
    };
  },
  methods: {
    setDefaultColumns() {
      this.$emit("setDefaultColumns");
    },
    setDefaultChannels() {
      this.filteredAvailableChannelsCompute = _.map(this.availableChannels, "name");
    },
    removeChannels() {
      this.filteredAvailableChannelsCompute = [];
    },
  },
};
</script>
