export const StaticChartData = () => {
  const metrics = {
    variable_cost: "Variable Spend",
    fixed_cost: "Fixed Spend",
    total_cost: "Total Spend",
    clicks: "Page Lands",
    impressions: "Impressions",
    first_click_sales: "First Touch Sales",
    first_click_revenue: "First Touch Revenue",
    first_click_conversion_rate: "First Touch Conversion Rate",
    first_click_aov: "First Touch AOV",
    last_click_sales: "Last Touch Sales",
    last_click_revenue: "Last Touch Revenue",
    last_click_conversion_rate: "Last Touch Conversion Rate",
    last_click_aov: "Last Touch AOV",
    attributed_sales: "Attributed Sales",
    attributed_revenue: "Attributed Revenue",
    attributed_conversion_rate: "Attributed Conversion Rate",
    attributed_aov: "Attributed AOV",
    attributed_revenue_variance: "Attributed Revenue Variance",
    attributed_revenue_variance_percent: "Attributed Revenue Variance Percent",
    introducer_ratio: "Introducer Ratio",
    cpa: "Attributed CPA",
    roas: "Attributed ROAS",
    roi: "ROI",
    sales_including: "Sales Involved",
    proportional_credit: "Attributed Sale Credit",
  };

  const metricsArray = [
    "variable_cost",
    "fixed_cost",
    "total_cost",
    "clicks",
    "impressions",
    "first_click_sales",
    "first_click_revenue",
    "first_click_conversion_rate",
    "first_click_aov",
    "last_click_sales",
    "last_click_revenue",
    "last_click_conversion_rate",
    "last_click_aov",
    "attributed_sales",
    "attributed_revenue",
    "attributed_conversion_rate",
    "attributed_aov",
    "attributed_revenue_variance_percent",
    "cpa",
    "roas",
  ];

  const playTogetherMetrics = [
    "variable_cost",
    "fixed_cost",
    "total_cost",
    "first_click_revenue",
    "last_click_revenue",
    "attributed_revenue",
  ];

  return {
    metrics,
    metricsArray,
    playTogetherMetrics,
  };
};
