<template>
  <div>
    <template v-if="comparing && !isNaN(previous)">
      <p :class="[{ 'th-wrap': footer }, { 'tw-justify-center': footer }]">
        <o-icon :icon="icon" size="small" :variant="type"></o-icon>
        {{ this.footer ? "&nbsp;" : "" }}
        <span>{{ displayCurrent }}{{ this.percentage ? "%" : "" }}</span>
        {{ this.footer ? "&nbsp;" : "" }}
        <span class="is-size-7"
          >({{ displayPercentage }}{{ stillNumeric ? "%" : "" }})</span
        >
      </p>
      <p class="is-size-7 tw-text-center">
        {{ displayPrevious }}{{ this.percentage ? "%" : "" }}
      </p>
    </template>
    <template v-else>
      <span :class="[{ 'th-wrap': footer }, { 'tw-justify-center': footer }]"
        >{{ displayCurrent }}{{ this.percentage ? "%" : "" }}</span
      >
    </template>
  </div>
</template>

<script>
import { UseNumberHelper } from "../../../Helper/Functional/UseNumberHelper";

export default {
  setup() {
    const {
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueOneDp,
      displayNumericValueRounded,
    } = UseNumberHelper();

    return {
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueOneDp,
      displayNumericValueRounded,
    };
  },
  computed: {
    increased() {
      return this.comparableCurrent > this.comparablePrevious;
    },
    equal() {
      return this.comparableCurrent === this.comparablePrevious;
    },
    stillNumeric() {
      return this.displayPercentage !== "P" && this.displayPercentage !== "N";
    },
    comparableCurrent() {
      return parseFloat(
        this.rounded ? Math.round(this.current) : this.current.toFixed(2)
      );
    },
    comparablePrevious() {
      return parseFloat(
        this.rounded ? Math.round(this.previous) : this.previous.toFixed(2)
      );
    },
    displayCurrent() {
      return this.monetary
        ? this.rounded
          ? this.displayMonetaryValueRounded(this.current)
          : this.displayMonetaryValue(this.current)
        : this.rounded
        ? this.displayNumericValueRounded(this.current)
        : this.oneDecimal
        ? this.displayNumericValueOneDp(this.current)
        : this.displayNumericValue(this.current);
    },
    displayPrevious() {
      return this.monetary
        ? this.rounded
          ? this.displayMonetaryValueRounded(this.previous)
          : this.displayMonetaryValue(this.previous)
        : this.rounded
        ? this.displayNumericValueRounded(this.previous)
        : this.oneDecimal
        ? this.displayNumericValueOneDp(this.previous)
        : this.displayNumericValue(this.previous);
    },
    displayPercentage() {
      return this.previous < 0
        ? this.increased
          ? "P"
          : "N"
        : this.displayNumericValueRounded(
            ((this.current - this.previous) / (this.previous == 0 ? 1 : this.previous)) *
              100
          );
    },
    icon() {
      let performance = this.equal ? 0 : this.increased ? 1 : -1;
      return performance === 0 ? "minus" : performance === -1 ? "arrow-down" : "arrow-up";
    },
    type() {
      let performance = this.equal
        ? 0
        : this.increased
        ? this.opposite === false
          ? 1
          : -1
        : this.opposite
        ? 1
        : -1;
      return performance === 0 ? "warning" : performance === -1 ? "danger" : "success";
    },
  },
  props: {
    current: {
      type: Number,
      default: 0,
    },
    previous: {
      type: Number,
    },
    /* Defines whether the metric is measured in the opposite way */
    opposite: {
      type: Boolean,
      default: false,
    },
    monetary: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    oneDecimal: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    comparing: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
