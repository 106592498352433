<template>
  <div>
    <div class="heading-row">
      <div class="columns">
        <div class="column is-5-desktop"><strong>Rules</strong></div>
        <div class="column is-3-desktop">
          <o-select
            v-model="condition"
            @input="$emit('update:condition', $event)"
            rounded
          >
            <option value="and">And</option>
            <option value="or">Or</option>
          </o-select>
        </div>
        <div class="column is-4-desktop action-button-container">
          <o-button icon-left="plus" rounded @click="$emit('add-rule')"
            >Add Rule</o-button
          >
        </div>
      </div>
    </div>
    <striped-rows v-if="rules && rules.length > 0">
      <taxonomy-rule
        v-for="rule in rules"
        :key="rule.id"
        v-model:field="rule.field"
        @update:field="
          $emit('update:rule', {
            id: rule.id,
            rule: { ...rule, field: $event },
          })
        "
        v-model:expected-value="rule.expected_value"
        @update:expectedValue="
          $emit('update:rule', {
            id: rule.id,
            rule: { ...rule, expected_value: $event },
          })
        "
        v-model:match-type="rule.match_type"
        @update:matchType="
          $emit('update:rule', {
            id: rule.id,
            rule: { ...rule, match_type: $event },
          })
        "
        @delete="$emit('delete:rule', rule.id)"
      />
    </striped-rows>
    <div class="no-rules" v-else>No rules</div>
  </div>
</template>

<script>
import StripedRows from "../../General/StripedRows.vue";
import TaxonomyRule from "./TaxonomyRule.vue";

export default {
  components: { TaxonomyRule, StripedRows },
  props: {
    condition: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: true,
      validator(rules) {
        return rules.every(
          (rule) =>
            "id" in rule &&
            "field" in rule &&
            "expected_value" in rule &&
            "match_type" in rule
        );
      },
    },
  },
};
</script>

<style scoped>
.no-rules {
  text-align: center;
  font-style: italic;
  padding: 0 1.5rem 1.5rem;
}

.heading-row {
  padding: 1rem 1rem;
}
.heading-row .columns {
  align-items: center;
}
.action-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
}
</style>
