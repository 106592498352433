<script>
import UserMessage from "./UserMessage";
import { UseMainStore } from "../State/SingleViewStorePinia";
export default {
  mixins: [UserMessage],
  setup() {
    const { readProgramMessage } = UseMainStore();

    return {
      readProgramMessage,
    };
  },
  methods: {
    closeMessage() {
      this.readProgramMessage(this.id);
    },
  },
};
</script>
