<template>
  <section class="section top-section">
    <section v-if="noData">
      <div class="content has-text-grey has-text-centered">
        <p>
          <o-icon icon="emoticon-sad" size="is-large"></o-icon>
        </p>
        <p>There's no data to show</p>
      </div>
    </section>
    <div class="box">
      <section class="segment-title-wrapper" v-if="segmentGroupData">
        <div>
          <h2 class="segment_title tw-mb-4">
            Segment Group: {{ capitalise(segmentGroupData.group_title) }}
          </h2>
          <p class="tw-mb-2">To change group please select from the list below</p>
          <o-select
            v-model="groupName"
            aria-role="list"
            :disabled="allSegmentGroupData.length === 0"
            :selected="groupName"
          >
            <option
              v-for="(name, i) in allSegmentGroupData"
              :key="i"
              :value="name.group_name"
              aria-role="listitem"
            >
              {{ name.group_title }}
            </option>
          </o-select>
        </div>

        <div class="button-group">
          <button
            type="button"
            class="button is-primary is-outlined"
            @click="toggleModal = true"
          >
            Edit Group Name
          </button>
        </div>
      </section>
      <section class="segment-controls" v-if="segmentGroupData">
        <UpdateSegments
          :segment-data="segmentData"
          :group-name="segmentGroupData.group_name"
          :url="segmentGroupUrl"
        />
        <UploadSegments :group-name="segmentGroupData.group_name" />
      </section>
      <section class="segment-status">
        <SegmentGroupStatus :uploads="uploads" :is-upload-loading="isUploadLoading" />
      </section>
      <o-modal
        v-model:active="toggleModal"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <div class="modal-card" v-if="segmentGroupData">
          <modal-body>
            <template v-slot:header>
              <UpdateGroupHeader :text="'Change Group Name'" />
            </template>
            <template v-slot:input>
              <UpdateGroupInput v-model:value="groupTitle" />
            </template>
            <template v-slot:button>
              <UpdateGroupButton
                :text="'Change Group Name'"
                :group-name="segmentGroupData.group_name"
                :new-name="groupTitle"
                :full-update="false"
                :segment-group-url="segmentGroupUrl"
                :current-group-name="segmentGroupData.group_name"
                :current-group-ingest-method="segmentGroupData.ingest_method"
                @closeModal="toggleModal = false"
                @reload="reloadHandler"
              />
            </template>
          </modal-body>
        </div>
      </o-modal>
      <o-modal
        v-model:active="toggleDeleteModal"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
      </o-modal>
    </div>
    <o-loading
      class="is-loader-overlay"
      :is-full-page="false"
      v-model:active="isLoading"
    ></o-loading>
  </section>
</template>

<script>
import UpdateSegments from "../../../Modules/SegmentManagement/SegmentEditCards/UpdateSegments.vue";
import ModalBody from "../../../Modules/SegmentManagement/ModalBody.vue";
import UpdateGroupHeader from "../../../Modules/SegmentManagement/UpdateGroupComponents/UpdateGroupHeader.vue";
import UpdateGroupInput from "../../../Modules/SegmentManagement/UpdateGroupComponents/UpdateGroupInput.vue";
import UpdateGroupButton from "../../../Modules/SegmentManagement/UpdateGroupComponents/UpdateGroupButton.vue";
import UploadSegments from "../../../Modules/SegmentManagement/SegmentEditCards/UploadSegments.vue";
import SegmentGroupStatus from "../../../Modules/SegmentManagement/SegmentGroupStatus.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../../Modules/State/SingleViewStorePinia";
import { UseProgramStore } from "../../../Modules/State/Stores/ProgramStore";
import { UseSegementStore } from "../../../Modules/State/Stores/SegmentStore";
import { UseStringHelper } from "../../../Helper/Functional/UseStringHelper";

export default {
  name: "Segment",
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { getSegmentGroupData: allSegmentGroupData } = storeToRefs(UseSegementStore());
    const { setSegmentGroupData } = UseSegementStore();
    const { capitalise } = UseStringHelper();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      allSegmentGroupData,
      setSegmentGroupData,
      capitalise,
    };
  },
  computed: {
    segmentGroupData() {
      return this.allSegmentGroupData.find(
        (seg) => seg.group_name === this.$route.params.segment
      );
    },
    segmentData: {
      get() {
        return this.segmentGroupData ? this.segmentGroupData.segments : [];
      },
      set(val) {
        return val;
      },
    },
    segmentGroupUrl() {
      return (
        this.getApiBaseUrl + "/programs/" + this.getCurrentProgram + "/segment-groups"
      );
    },
  },
  data() {
    return {
      toggleModal: false,
      toggleDeleteModal: false,
      groupName: this.$route.params.segment,
      groupTitle: "",
      isLoading: false,
      isUploadLoading: false,
      noData: false,
      uploads: [],
    };
  },
  methods: {
    fetchAllGroupData() {
      this.isLoading = true;
      axios.get(this.segmentGroupUrl).then(({ data }) => {
        this.setSegmentGroupData({ data: data.data });
        this.segmentData = data.data.find(
          (seg) => seg.group_name === this.$route.params.segment
        )?.segments;

        this.isLoading = false;
      });
    },
    fetchGroupUploads() {
      this.isUploadLoading = true;
      axios
        .get(this.segmentGroupUrl + "/" + this.$route.params.segment + "/uploads")
        .then(({ data: upload }) => {
          this.uploads = upload.data;
          this.isUploadLoading = false;
        });
    },
    reloadHandler() {
      this.$router.replace(`/segment-groups/${this.groupName}`);
      this.fetchAllGroupData();
    },
  },
  async mounted() {
    if (!this.segmentGroupData) {
      this.fetchAllGroupData();
    }
    //Fetch any uploads
    this.fetchGroupUploads();
  },
  watch: {
    //Change program send them back to the landing page and load correct prgram data.
    getCurrentProgram() {
      this.setSegmentGroupData({ data: [] });
      this.$router.replace("/segment-groups");
    },
    groupName(val) {
      if (val) {
        this.$router.replace(`/segment-groups/${val}`);
        this.fetchAllGroupData();
      }
    },
  },
  components: {
    UpdateSegments,
    ModalBody,
    UpdateGroupHeader,
    UpdateGroupInput,
    UpdateGroupButton,
    UploadSegments,
    SegmentGroupStatus,
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../sass/variables";

.section.top-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: max(50vw, 600px);
  }
}
.segment-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $border-color;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;

  .segment_title {
    font-weight: 600;
  }
}

.segment-controls {
  margin-top: 1rem;
  border-bottom: 2px solid $border-color;
  padding-bottom: 1rem;
}
</style>
