<template>
  <component
    :is="tag"
    :aria-label="label"
    @click="clickHandler"
    v-bind="additionalProps"
    class="component"
  >
    <o-icon :icon="icon"></o-icon>
  </component>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    to: {
      required: false,
      default: null,
      type: String,
    },
    open: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    tag() {
      return this.to === null ? "button" : "router-link";
    },
    additionalProps() {
      if (this.to === null) return {};

      return {
        to: this.to,
      };
    },
  },
  methods: {
    clickHandler() {
      this.$emit("clickEv", { toggle: !this.open });
    },
  },
};
</script>

<style scoped>
.component {
  height: 2.5rem;
  width: 2.5rem;
  align-self: center;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9in;
  cursor: pointer;
  padding: 0.6rem 0.5rem 0.4rem 0.5rem;

  border: none;
  background: none;
  color: currentColor;
  position: relative;
}

.component:hover:after {
  content: "";
  position: absolute;
  inset: 0;
  background: currentColor;
  opacity: 0.2;
  border-radius: 1.5em;
}
</style>
