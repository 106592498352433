import { IGetAvailablePerformanceAnnotations } from "../../../State/Typings/SingleViewStoreTypes";
import { computed, reactive, ref } from "vue";
import {
  keyBy,
  map,
  filter,
  cloneDeep,
  get,
  find,
  forEach,
  has,
  concat,
  without,
} from "lodash";
import axios from "axios";
import moment from "moment";
import { UseProgramStore } from "../../../State/Stores/ProgramStore";
import { UseReportSettingsStore } from "../../../State/Stores/ReportSettingsStore";
import { UseDateStore } from "../../../State/Stores/DateStore";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../../State/SingleViewStorePinia";

interface IncomingAnnotations {
  data: IncomingAnnotationsInner[];
}

interface IncomingAnnotationsInner {
  added_by: {
    id: number;
    username: string;
  };
  annotation: string;
  annotation_date: Date;
  created_at: Date;
  id: number;
  programcode: string;
  updated_at: Date;
}

//Data
const isCreateModalActive = ref(false);
const isUpdateModalActive = ref(false);
const loading = ref(false);
const saving = ref(false);
const data = ref<IGetAvailablePerformanceAnnotations[]>([]);
const checkers = ref<IGetAvailablePerformanceAnnotations[]>([]);
const deleting = ref<number[]>([]);
const editing = ref<number[]>([]);
const defaultColor = ref("#775DD0");

//From the Store
export const UseAnnotations = () => {
  const { getApiBaseUrl: getApiBaseUrlStore } = storeToRefs(UseMainStore());
  const { getDateFrom: getDateFromStore, getDateTo: getDateToStore } =
    storeToRefs(UseDateStore());
  const { getPerformanceAnnotations, getAvailablePerformanceAnnotations } =
    storeToRefs(UseReportSettingsStore());
  const { setAvailablePerformanceAnnotations } = UseReportSettingsStore();
  const { getCurrentProgram: getCurrentProgramStore } = storeToRefs(
    UseProgramStore()
  );

  const form = reactive({
    new: {
      annotation: "",
      date: moment().subtract(1, "days").toDate(),
    },
    edit: {
      annotation: "",
      date: moment().subtract(1, "days").toDate(),
      id: "" || 0,
    },
  });

  //Computed
  const url = computed(() => {
    return (
      getApiBaseUrlStore.value +
      "/programs/" +
      getCurrentProgramStore.value +
      "/performance/annotations?date_from=" +
      getDateFromStore.value +
      "&date_to=" +
      getDateToStore.value
    );
  });

  const storeUrl = computed(() => {
    return (
      getApiBaseUrlStore.value +
      "/programs/" +
      getCurrentProgramStore.value +
      "/performance/annotations"
    );
  });

  const allPerformanceAnnotations = computed({
    get(): IGetAvailablePerformanceAnnotations[] {
      return getAvailablePerformanceAnnotations.value;
    },
    set(val: IGetAvailablePerformanceAnnotations[]) {
      setAvailablePerformanceAnnotations(val);
    },
  });

  //Methods

  const loadAsyncData = () => {
    loading.value = true;
    axios
      .get<IncomingAnnotations>(url.value)
      .then(({ data: axiosData }) => {
        let prev = keyBy(
          map(
            filter(cloneDeep(getPerformanceAnnotations.value), [
              "programcode",
              getCurrentProgramStore.value,
            ]),
            function (val) {
              return { id: val.id, colour: val.colour };
            }
          ),
          "id"
        );

        let tmp = map(
          axiosData.data,
          function (
            val: IncomingAnnotationsInner
          ): IGetAvailablePerformanceAnnotations {
            return {
              id: val.id,
              programcode: val.programcode,
              text: val.annotation,
              date: val.annotation_date,
              added_by: get(val, "added_by.username"),
              added_by_id: get(val, "added_by.id"),
              colour: get(
                prev,
                [val.id, "colour"],
                get(find(data, ["id", val.id]), ["colour"], defaultColor)
              ),
            };
          }.bind(this)
        );
        data.value = tmp;
        allPerformanceAnnotations.value = tmp;

        let chk = <IGetAvailablePerformanceAnnotations[]>[];
        forEach(
          data.value,
          function (val: IGetAvailablePerformanceAnnotations) {
            if (has(prev, val.id)) {
              chk.push(val);
            }
          }
        );
        checkers.value = chk;
        loading.value = false;
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const addAnnotation = () => {
    saving.value = true;
    axios
      .post(storeUrl.value, {
        annotation: form.new.annotation,
        annotation_date: moment(form.new.date).format("YYYY-MM-DD"),
      })
      .then(() => {
        loadAsyncData();
        saving.value = false;
      })
      .catch(() => {
        saving.value = false;
      });
  };

  const updateAnnotation = () => {
    editing.value = concat(editing.value, form.edit.id);
    saving.value = true;
    axios
      .patch(storeUrl.value + "/" + form.edit.id, {
        annotation: form.edit.annotation,
        annotation_date: moment(form.edit.date).format("YYYY-MM-DD"),
      })
      .then(() => {
        editing.value = without(editing.value, form.edit.id);
        loadAsyncData();
        saving.value = false;
      })
      .catch(() => {
        saving.value = false;
      });
  };
  const deleteAnnotation = (id: number) => {
    deleting.value = concat(deleting.value, id);
    axios
      .delete(storeUrl.value + "/" + id)
      .then(() => {
        deleting.value = without(deleting.value, id);
        loadAsyncData();
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
  const saveExistingAnnotation = () => {
    isUpdateModalActive.value = false;
    updateAnnotation();
  };

  const saveAnnotation = () => {
    isCreateModalActive.value = false;
    addAnnotation();
  };

  const openEdit = (row: IGetAvailablePerformanceAnnotations) => {
    form.edit.id = row.id;
    form.edit.annotation = row.text;
    form.edit.date = moment(row.date).toDate();
    isUpdateModalActive.value = true;
  };

  return {
    url,
    storeUrl,
    allPerformanceAnnotations,
    isCreateModalActive,
    isUpdateModalActive,
    loading,
    saving,
    checkers,
    data,
    deleting,
    editing,
    defaultColor,
    form,
    addAnnotation,
    updateAnnotation,
    deleteAnnotation,
    saveAnnotation,
    openEdit,
    saveExistingAnnotation,
    loadAnnotations: loadAsyncData,
  };
};
