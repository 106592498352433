<template>
  <div>
    <!-- Default -->
    <o-field grouped group-multiline>
      <o-field grouped>
        <o-field>
          <p class="control">
            <o-select v-model="sortControlHeadersCompute">
              <option
                v-for="(value, key) in controlHeaders"
                :value="key"
                :key="key"
              >
                {{ value }}
              </option>
            </o-select>
          </p>
          <p class="control">
            <button class="button is-primary" @click="toggleSortDirection">
              <o-icon :icon="'sort-' + toggleSortDirectionIcon()"></o-icon>
            </button>
          </p>
        </o-field>
      </o-field>
      <new-pagination
        :per-page-options="[8, 16, 32]"
        v-model:page="pageCompute"
        v-model:per-page="perPageCompute"
        v-model:records="recordsCompute"
      ></new-pagination>
      <export-data
        :available-columns="columns"
        :selected-columns="Object.keys(headers)"
        :column-headers="headers"
        :report-name="'Funnel Export'"
        :exportable-data="exportData"
        :order-data-by="sortMetricCompute"
        :order-data-direction="sortDirectionCompute"
      />
    </o-field>
  </div>
</template>

<script>
import NewPagination from "../Breakdown/NewPagination";
import ExportData from "../Pieces/ExportData.vue";
import { StaticColumnData } from "../../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../../Helper/Stateful/UseColumnModalHelper";
export default {
  components: { NewPagination, ExportData },
  setup() {
    const { funnelAvailableColumns } = StaticColumnData();
    const { columns } = UseColumnModalHelper(funnelAvailableColumns);
    return {
      columns,
    };
  },
  computed: {
    sortMetricCompute: {
      get() {
        return _.size(this.headers) ? this.sortMetric : "";
      },
      set(val) {
        this.$emit("update:sortMetric", val);
      },
    },
    sortDirectionCompute: {
      get() {
        return this.sortDirection;
      },
      set(val) {
        this.$emit("update:sortDirection", val);
      },
    },
    sortControlHeadersCompute: {
      get() {
        return this.sortControlHeaders;
      },
      set(val) {
        this.$emit("update:sortControlHeaders", val);
      },
    },
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    recordsCompute: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },
  },
  props: {
    headers: {
      type: Object,
      default: {},
    },
    metrics: {
      type: Array,
      default: [],
    },
    sortMetric: {
      type: String,
      default: "taxonomy_1",
    },
    sortDirection: {
      type: String,
      default: "",
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    records: {
      type: Number,
      default: 0,
    },
    exportData: {
      type: Array,
      required: true,
    },
    controlHeaders: {
      type: Object,
      default: () => {},
    },
    sortControlHeaders: {
      type: String,
      default: "Revenue",
    },
  },
  methods: {
    toggleSortDirection() {
      if (this.sortDirectionCompute === "asc") {
        this.sortDirectionCompute = "desc";
      } else {
        this.sortDirectionCompute = "asc";
      }
    },
    toggleSortDirectionIcon() {
      return this.sortDirectionCompute === "asc" ? "ascending" : "descending";
    },
    previousPage() {
      this.pageCompute = this.page - 1;
    },
    nextPage() {
      this.pageCompute = this.page + 1;
    },
  },
};
</script>
<style>
.field.space-between {
  justify-content: space-between;
}
</style>
