import {
  IDenormalised,
  IRule,
  ITaxonomy,
} from "./../../../Modules/State/Typings/SingleViewStoreTypes";
import { UseMainStore } from "./../../../Modules/State/SingleViewStorePinia";
import {
  UseClassificationStore,
  normaliseTaxonomies,
} from "./../../../Modules/State/Stores/ClassificationStore";
import { storeToRefs } from "pinia";
import axios from "axios";
import { ComputedRef } from "vue";

interface IAxiosResponse {
  data: IDenormalised[];
}

export const UseClassification = () => {
  const { getApiBaseUrl } = UseMainStore();
  const { getExtracts, taxonomies, taxonomyList, rules, extracts } =
    storeToRefs(UseClassificationStore());
  const {
    setNormalised,
    setExtract,
    setRule,
    setTaxonomies,
    setTaxonomy,
    setTaxonomyList,
  } = UseClassificationStore();

  const loadClassification = async (programCode: string) => {
    const { data } = await axios.get<IAxiosResponse>(
      `${getApiBaseUrl}/programs/${programCode}/admin/classification`
    );

    setNormalised(normaliseTaxonomies(data.data));
  };

  const deleteTaxonomy = (taxonomyId: number) => {
    const filteredTaxonomies = Object.fromEntries(
      Object.entries(taxonomies.value).map(([k, v]) => {
        return [
          k,
          "children" in v
            ? {
                ...v,
                children: v.children.filter((id) => id !== taxonomyId),
              }
            : v,
        ];
      })
    );

    const filteredTaxonomyList = taxonomyList.value.filter(
      (id) => id !== taxonomyId
    );

    setTaxonomies(filteredTaxonomies);
    setTaxonomyList(filteredTaxonomyList);
  };

  const addTaxonomy = (name: string, parentId?: number): number => {
    const newId =
      Math.max(
        ...Object.keys(taxonomies.value).map((dgt) => parseInt(dgt, 10))
      ) + 1;
    const newTaxonomy = <ITaxonomy>{
      id: newId,
      title: name,
      join_type: "and",
      rules: [] as ITaxonomy["rules"],
      extracts: [] as ITaxonomy["extracts"],
      children: [] as ITaxonomy["children"],
      new: true,
    };
    const newTaxonomies = { ...taxonomies.value, [newId]: newTaxonomy };

    if (parentId) {
      newTaxonomies[parentId] = {
        ...newTaxonomies[parentId],
        children: [...newTaxonomies[parentId].children, newId],
      };
      setTaxonomies(newTaxonomies);
    } else {
      setTaxonomyList([...taxonomyList.value, newId]);
      setTaxonomy({ id: newId, taxonomy: newTaxonomy });
    }

    return newId;
  };

  const addRule = (taxonomyId: number) => {
    const newId =
      Math.max(...Object.keys(rules.value).map((dgt) => parseInt(dgt, 10))) + 1;

    const newRule = <IRule>{
      id: newId,
      field: "",
      expected_value: "",
      match_type: "contains",
      new: true,
    };
    const newTaxonomy = {
      ...taxonomies.value,
      [taxonomyId]: {
        ...taxonomies.value[taxonomyId],
        rules: [...taxonomies.value[taxonomyId].rules, newId],
      },
    };
    setTaxonomies(newTaxonomy);
    setRule({ id: newId, rule: newRule });
  };

  const removeRule = (taxonomyId: number, ruleId: number) => {
    const taxonomy = { ...taxonomies.value[taxonomyId] };
    taxonomy.rules = taxonomy.rules.filter((id) => id !== ruleId);
    setTaxonomies({ ...taxonomies.value, [taxonomyId]: taxonomy });
  };

  const setExtractValue = (
    taxonomyId: number,
    mapping: string,
    value: string
  ) => {
    const taxonomy = taxonomies.value[taxonomyId];
    const extract = [...getExtracts.value(taxonomy.extracts)]
      .reverse()
      .find((_extract) => _extract.mapped_to === mapping);

    const extractId =
      typeof extract === "object" && "id" in extract
        ? extract.id
        : Math.max(
            ...Object.keys(extracts.value).map((dgt) => parseInt(dgt, 10))
          ) + 1;

    setExtract({
      id: extractId,
      extract: {
        id: extractId,
        field: value,
        mapped_to: mapping,
      },
    });
    if (!taxonomy.extracts.includes(extractId)) {
      setTaxonomy({
        id: taxonomyId,
        taxonomy: {
          ...taxonomy,
          extracts: [...taxonomy.extracts, extractId],
        },
      });
    }
    if (value === "" || value === null) {
      setTaxonomy({
        id: taxonomyId,
        taxonomy: {
          ...taxonomy,
          extracts: taxonomy.extracts.filter((id) => id !== extractId),
        },
      });
    }
  };

  const addTax2 = (parentId: number, btn: HTMLElement, name = "") => {
    addTaxonomy(name, parentId);
    [...btn.children].slice(-1)[0].scrollIntoView({ behavior: "smooth" });
  };

  const swapTax2 = (
    index: number,
    indexDelta: number,
    selectedClassification: ComputedRef<ITaxonomy>
  ) => {
    if (!selectedClassification.value) return;

    const destIndex = index + indexDelta;

    if (destIndex < 0) {
      return;
    }

    if (destIndex >= selectedClassification.value.children.length) {
      return;
    }

    const rule1 = selectedClassification.value.children[index];
    selectedClassification.value.children[index] =
      selectedClassification.value.children[destIndex];

    selectedClassification.value.children[destIndex] = rule1;
  };

  const updateRule = ({ id, rule }: { id: number; rule: IRule }) => {
    setRule({ id, rule });
  };

  const updateExtract = (
    taxonomyId: number,
    { mapped_to, field }: { mapped_to: string; field: string }
  ) => {
    setExtractValue(taxonomyId, mapped_to, field);
  };

  return {
    loadClassification,
    deleteTaxonomy,
    addTaxonomy,
    addRule,
    removeRule,
    setExtractValue,
    addTax2,
    swapTax2,
    updateRule,
    updateExtract,
  };
};
