import { UseStringHelper } from "./../Functional/UseStringHelper";
import { UseTaxonomyHelper } from "./UseTaxonomyHelper";
import { ref } from "vue";

const breadcrumbUrls = ref<Record<string, string>[]>([]);

export const UseBreadcrumbBuilder = () => {
  const {
    currentTaxonomyInteger,
    taxonomyOne,
    taxonomyTwo,
    taxonomyThree,
    allTaxonomies,
  } = UseTaxonomyHelper();
  const { validateInternalParameter } = UseStringHelper();

  const breadcrumbBuilder = (str: string) => {
    //Reset url array everytime the function is called
    breadcrumbUrls.value = [];
    const taxonomyStrings = [
      `${str}/`,
      `taxonomy_one/${validateInternalParameter(taxonomyOne.value)}/`,
      `taxonomy_two/${validateInternalParameter(taxonomyTwo.value)}/`,
      `taxonomy_three/${validateInternalParameter(taxonomyThree.value)}`,
    ];
    Array.from(Array(currentTaxonomyInteger.value).keys()).forEach((level) => {
      if (level === 0) {
        breadcrumbUrls.value[level] = {
          level: "Channel",
          url: taxonomyStrings[level],
        };
      } else {
        breadcrumbUrls.value[level] = {
          level: allTaxonomies.value[level - 1],
          url: `${breadcrumbUrls.value[level - 1].url}${
            taxonomyStrings[level]
          }`,
        };
      }
    });
  };

  return {
    breadcrumbUrls,
    breadcrumbBuilder,
  };
};
