<template>
  <!-- Responsiveness, gotta make 2x buttons, one for touch and one for desktop -->
  <div>
    <o-field class="is-hidden-mobile" grouped group-multiline>
      <o-field>
        <p class="control">
          <o-select v-model="firstMetricCompute">
            <option
              v-for="metric in metrics"
              :value="metric"
              :key="metric"
              :disabled="metric == secondMetric"
            >
              {{ headers[metric] || metric }}
            </option>
          </o-select>
        </p>
        <p class="control" v-if="breakdown">
          <slot name="ordering">
            <!-- Ordering -->
          </slot>
        </p>
        <p class="control">
          <o-select v-model="secondMetricCompute">
            <option
              v-for="metric in metrics"
              :value="metric"
              :key="metric"
              :disabled="metric == firstMetric"
            >
              {{ headers[metric] || metric }}
            </option>
          </o-select>
        </p>
      </o-field>
      <o-field>
        <p class="control" id="toggleChartType">
          <button class="button" @click="toggleChartType">
            <o-icon :icon="chartIconCompute"></o-icon>
            <span>Toggle {{ chartNameCompute }} chart</span>
          </button>
        </p>
        <p class="control">
          <o-select v-model="intervalCompute">
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
          </o-select>
        </p>
      </o-field>
      <o-field v-if="exportable">
        <slot name="export">
          <!-- Export -->
        </slot>
      </o-field>
      <o-field v-if="showAnnotations">
        <p class="control">
          <o-button
            icon-left="note-multiple-outline"
            @click="showAnnotationsTableCompute = !showAnnotationsTable"
            >Campaign Notes</o-button
          >
        </p>
      </o-field>
    </o-field>
    <!-- Mobile -->
    <o-field class="is-hidden-tablet">
      <p class="control">
        <o-select v-model="firstMetricCompute" expanded>
          <option
            v-for="metric in metrics"
            :value="metric"
            :key="metric"
            :disabled="metric == secondMetric"
          >
            {{ headers[metric] || metric }}
          </option>
        </o-select>
      </p>
      <p class="control" v-if="breakdown">
        <slot name="ordering">
          <!-- Ordering -->
        </slot>
      </p>
    </o-field>
    <o-field class="is-hidden-tablet">
      <p class="control">
        <o-select v-model="secondMetricCompute" expanded>
          <option
            v-for="metric in metrics"
            :value="metric"
            :key="metric"
            :disabled="metric == firstMetric"
          >
            {{ headers[metric] || metric }}
          </option>
        </o-select>
      </p>
    </o-field>
    <o-field class="is-hidden-tablet" grouped>
      <o-field>
        <p class="control">
          <button class="button" @click="toggleChartType">
            <o-icon :icon="chartIconCompute"></o-icon>
            <span>Toggle {{ chartNameCompute }} chart</span>
          </button>
        </p>
      </o-field>
      <o-field expanded>
        <p class="control">
          <o-select v-model="intervalCompute" expanded>
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
          </o-select>
        </p>
      </o-field>
    </o-field>
    <o-field v-if="exportable" class="is-hidden-tablet">
      <slot name="export">
        <!-- Export -->
      </slot>
    </o-field>
    <o-field v-if="showAnnotations" class="is-hidden-tablet">
      <o-button
        icon-left="note-multiple-outline"
        :variant="'is-primary'"
        @click="showAnnotationsTableCompute = !showAnnotationsTable"
        >Campaign Notes</o-button
      >
    </o-field>
    <br class="is-hidden-tablet" />
  </div>
</template>

<script>
export default {
  computed: {
    firstMetricCompute: {
      get() {
        return _.size(this.headers) ? this.firstMetric : "";
      },
      set(val) {
        this.$emit("update:firstMetric", val);
      },
    },
    secondMetricCompute: {
      get() {
        return _.size(this.headers) ? this.secondMetric : "";
      },
      set(val) {
        this.$emit("update:secondMetric", val);
      },
    },
    chartTypeCompute: {
      get() {
        return this.chartType;
      },
      set(val) {
        this.$emit("update:chartType", val);
      },
    },
    intervalCompute: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$emit("update:interval", val);
      },
    },
    chartNameCompute() {
      return this.chartTypeCompute === "area" ? "bar" : "area";
    },
    chartIconCompute() {
      return (
        "chart-" + (this.chartTypeCompute === "area" ? "bar" : "areaspline")
      );
    },
    showAnnotationsTableCompute: {
      get() {
        return this.showAnnotationsTable;
      },
      set(val) {
        this.$emit("update:showAnnotationsTable", val);
      },
    },
  },
  props: {
    headers: {
      type: Object,
      default: {},
    },
    metrics: {
      type: Array,
      default: [],
    },
    firstMetric: "",
    secondMetric: "",
    chartType: "",
    interval: "",
    showAnnotations: {
      type: Boolean,
      default: false,
    },
    showAnnotationsTable: {
      type: Boolean,
      default: false,
    },
    breakdown: {
      type: Boolean,
      default: false,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleChartType() {
      if (this.chartTypeCompute === "area") {
        this.chartTypeCompute = "bar";
      } else {
        this.chartTypeCompute = "area";
      }
    },
  },
};
</script>
