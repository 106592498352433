<template>
  <div class="extracts-row">
    <div class="columns">
      <div class="column">
        <o-field label="Taxonomy 3" label-position="on-border">
          <o-input
            v-model="tax3Field"
            rounded
            :placeholder="parentTax3Field"
            class="extract-input"
          />
        </o-field>
      </div>
      <div class="column">
        <o-field label="Taxonomy 4" label-position="on-border">
          <o-input
            v-model="tax4Field"
            rounded
            :placeholder="parentTax4Field"
            class="extract-input"
          />
        </o-field>
      </div>
      <div class="column">
        <o-field label="Cost" label-position="on-border">
          <o-input
            v-model="costField"
            rounded
            :placeholder="parentCostField"
            class="extract-input"
          />
        </o-field>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "../../General/IconButton.vue";
const extractValidator = (extracts) => {
  return extracts.every(
    (extract) =>
      "id" in extract &&
      "field" in extract &&
      "mapped_to" in extract &&
      ["tax3", "tax4", "costlink"].indexOf(extract.mapped_to) !== -1
  );
};
export default {
  components: { IconButton },
  computed: {
    parentTax3Field() {
      const extract = this.extractForMapping(this.parentExtracts, "tax3");

      return extract ? extract.field : undefined;
    },
    tax3() {
      return this.extractForMapping(this.extracts, "tax3");
    },
    tax3Field: {
      get() {
        return this.tax3 ? this.tax3.field : undefined;
      },
      set(value) {
        this.$emit(
          "update:extract",
          this.tax3 ? { ...this.tax3, field: value } : { mapped_to: "tax3", field: value }
        );
      },
    },
    parentTax4Field() {
      const extract = this.extractForMapping(this.parentExtracts, "tax4");

      return extract ? extract.field : undefined;
    },
    tax4() {
      return this.extractForMapping(this.extracts, "tax4");
    },
    tax4Field: {
      get() {
        return this.tax4 ? this.tax4.field : undefined;
      },
      set(value) {
        this.$emit(
          "update:extract",
          this.tax4 ? { ...this.tax4, field: value } : { mapped_to: "tax4", field: value }
        );
      },
    },
    parentCostField() {
      const extract = this.extractForMapping(this.parentExtracts, "costlink");

      return extract ? extract.field : undefined;
    },
    cost() {
      return this.extractForMapping(this.extracts, "costlink");
    },
    costField: {
      get() {
        return this.cost ? this.cost.field : undefined;
      },
      set(value) {
        this.$emit(
          "update:extract",
          this.cost
            ? { ...this.cost, field: value }
            : { mapped_to: "costlink", field: value }
        );
      },
    },
  },
  props: {
    parentExtracts: {
      type: Array,
      required: false,
      validator(extracts) {
        return extractValidator(extracts);
      },
    },
    extracts: {
      type: Array,
      required: true,
      validator(extracts) {
        return extractValidator(extracts);
      },
    },
  },
  methods: {
    extractForMapping(extracts, mapping) {
      if (!extracts) {
        return null;
      }

      return [...extracts].reverse().find((e) => e.mapped_to === mapping);
    },
  },
};
</script>

<style lang="scss" scoped>
.extracts-row {
  padding: 1rem;

  :deep(.extract-input) {
    &::placeholder {
      opacity: 0.5;
    }
  }
}
</style>
