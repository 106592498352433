<template>
  <p class="control">
    <slot name="trigger" v-bind:openModal="openModal">
      <button class="button" @click="openModal">
        <o-icon icon="table-row-height"></o-icon>
        <span>Columns</span>
      </button>
    </slot>
    <modal-template
      v-model:modalActive="isColumnModalActive"
      :width="960"
      :columns="columns"
      :available-columns="availableColumns"
      :column-headers="columnHeaders"
    >
    </modal-template>
  </p>
</template>

<script>
import ColumnSelection from "../Modules/Pieces/ColumnSelection";
import ModalTemplate from "./Pieces/ModalTemplate.vue";
export default {
  components: { ColumnSelection, ModalTemplate },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    availableColumns: {
      type: Array,
      required: true,
    },
    columnHeaders: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isColumnModalActive: false,
  }),
  methods: {
    openModal() {
      this.isColumnModalActive = true;
    },
  },
};
</script>
