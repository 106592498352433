<template>
  <div class="page-container">
    <outer-section>
      <div class="columns is-multiline">
        <div class="column is-full">
          <h1 class="title">Funnel Trends Over Time</h1>
        </div>
        <div class="column is-full" v-if="data">
          <div class="grid">
            <div class="toolbar">
              <div class="is-flex breadcrumo-container">
                <taxonomy-breadcrumbs
                  :taxonomyUrls="taxonomyUrls"
                  :taxonomyLocation="taxonomyLocation"
                  baseUrl="/funnel-trends/"
                />
              </div>

              <SvSkeleton :loading="loading">
                <export-data
                  :available-columns="
                    [...alwaysExport].concat(availableColumns)
                  "
                  :selected-columns="exportColumns"
                  :column-headers="{
                    taxonomy_1: 'Taxonomy 1',
                    taxonomy_2: 'Taxonomy 2',
                    taxonomy_3: 'Taxonomy 3',
                    taxonomy_4: 'Taxonomy 4',
                    conversion_date: 'Date',
                    solo_revenue_percent: 'Solo Revenue Percent',
                    upper_funnel_revenue_percent: 'Upper Revenue Percent',
                    mid_funnel_revenue_percent: 'Mid Revenue Percent',
                    lower_funnel_revenue_percent: 'Lower Revenue Percent',
                    solo_revenue: 'Solo Revenue',
                    upper_funnel_revenue: 'Upper Revenue',
                    mid_funnel_revenue: 'Mid Revenue',
                    lower_funnel_revenue: 'Lower Revenue',
                  }"
                  :report-name="
                    (
                      getCurrentProgramName +
                      '_funnelTrends_' +
                      getDateFrom +
                      '_' +
                      getDateTo
                    )
                      .toLowerCase()
                      .split(' ')
                      .join('_')
                  "
                  :exportable-data="exportData"
                  v-if="exportData.length || loading"
                >
                  <template v-slot:trigger>
                    <SvButton startIcon="download">Export</SvButton>
                  </template>
                </export-data>
              </SvSkeleton>
            </div>

            <div class="grid page-layout-columns">
              <div class="taxonomy-sidebar sv-reset">
                <SvNavigationList
                  :items="navigationItems"
                  v-if="!loading"
                  :parent="navigationParent"
                />
                <div class="tw-flex tw-flex-col tw-gap-2" v-else>
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                  <SvSkeleton radius="sm" />
                </div>
              </div>

              <div class="tw-flex tw-flex-col tw-gap-12 tw-relative">
                <o-loading
                  :full-page="false"
                  v-model:active="loading"
                ></o-loading>

                <funnel-trend-chart
                  v-if="selectedData"
                  :multi-funnel-data="selectedData"
                  type="bar"
                  :use-absolute-values="false"
                />

                <funnel-trend-table
                  v-if="selectedData"
                  :data="selectedData"
                  :columns="columns"
                  @update:columns="columns = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </outer-section>
  </div>
</template>

<script>
import TaxonomyNavigation from "../Mixins/TaxonomyNavigation";
import TaxonomyBreadcrumbs from "../Modules/TaxonomyBreadcrumbs.vue";
import IconButton from "../Modules/General/IconButton.vue";
import FunnelTrendChart from "../Modules/FunnelTrends/FunnelTrendChart.vue";
import FunnelTrendTable from "../Modules/FunnelTrends/FunnelTrendTable.vue";
import ExportData from "../Modules/Pieces/ExportData";
import { UseMainStore } from "../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";
import { UseDateStore } from "../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../Modules/State/Stores/ProgramStore";
import { UseFunnelStore } from "../Modules/State/Stores/FunnelStore";
import { UseAxiosErrorHelper } from "../Helper/ThirdParty/UseAxiosErrorHelper";
import { StaticColumnData } from "../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../Helper/Stateful/UseColumnModalHelper";

export default {
  title: "Funnel Trends",
  components: {
    TaxonomyBreadcrumbs,
    IconButton,
    FunnelTrendChart,
    FunnelTrendTable,
    ExportData,
  },
  mixins: [TaxonomyNavigation],
  props: {
    selectedTaxonomy: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    availableColumns: [
      "conversion_date",
      "solo_revenue",
      "upper_funnel_revenue",
      "mid_funnel_revenue",
      "lower_funnel_revenue",
      "solo_revenue_percent",
      "upper_funnel_revenue_percent",
      "mid_funnel_revenue_percent",
      "lower_funnel_revenue_percent",
    ],
  }),
  setup() {
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram, getCurrentProgramName } = storeToRefs(
      UseProgramStore()
    );
    const { getFunnelTrends: data, getColumnHeaders } = storeToRefs(
      UseFunnelStore()
    );
    const { loadFunnelTrends: load } = UseFunnelStore();
    const { funnelTrendColumns } = StaticColumnData();
    const { columns } = UseColumnModalHelper(funnelTrendColumns);

    UseAxiosErrorHelper();

    return {
      getDateFrom,
      getDateTo,
      getApiBaseUrl,
      getCurrentProgram,
      getCurrentProgramName,
      data,
      getColumnHeaders,
      columns,
      load,
    };
  },
  computed: {
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/funnel/multi/taxonomy_1" +
        (this.taxonomyOne
          ? "/" + this.validateApiParameter(this.taxonomyOne) + "/taxonomy_2"
          : "") +
        (this.taxonomyTwo
          ? "/" + this.validateApiParameter(this.taxonomyTwo) + "/taxonomy_3"
          : "") +
        (this.taxonomyThree
          ? "/" + this.validateApiParameter(this.taxonomyThree) + "/taxonomy_4"
          : "") +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    taxonomies() {
      return this.data.map((d, index) => ({
        name: d.name,
        active:
          this.selectedTaxonomy !== null
            ? d.name === this.selectedTaxonomy
            : index === 0,
      }));
    },
    taxonomy() {
      return this.taxonomies.find((t) => t.active);
    },
    requiredDates() {
      const list = [];
      const endDate = new Date(this.getDateTo);
      const workingDate = new Date(this.getDateFrom);

      while (workingDate <= endDate) {
        list.push(workingDate.toISOString().slice(0, 10));
        workingDate.setDate(workingDate.getDate() + 1);
      }

      return list;
    },
    blankFilledData() {
      return this.data.map((a) => {
        const dates = a.data?.map((d) => d.conversion_date);
        const datesToAdd = this.requiredDates.filter((d) => !dates.includes(d));
        const taxonomyData = this.buildTaxonomyLocationObject([
          ...this.taxonomyLocation,
          a.name,
        ]);

        return {
          ...a,
          data: [
            ...a.data,
            ...datesToAdd.map((d) => ({
              conversion_date: d,
              lower_funnel_revenue: 0,
              lower_funnel_revenue_percent: 0,
              mid_funnel_revenue: 0,
              mid_funnel_revenue_percent: 0,
              solo_revenue: 0,
              solo_revenue_percent: 0,
              upper_funnel_revenue: 0,
              upper_funnel_revenue_percent: 0,
              ...taxonomyData,
            })),
          ].sort((a, b) =>
            a.conversion_date < b.conversion_date
              ? -1
              : a.conversion_date === b.conversion_date
              ? 0
              : 1
          ),
        };
      });
    },
    dataByTaxonomy() {
      return Object.fromEntries(this.blankFilledData.map((d) => [d.name, d]));
    },
    activeTaxonomy() {
      return this.selectedTaxonomy !== null
        ? this.selectedTaxonomy
        : Object.keys(this.dataByTaxonomy)[0];
    },
    navigationItems() {
      return Object.values(this.dataByTaxonomy).map((d) => ({
        name: d.name,
        to: [
          "/funnel-trends",
          this.taxonomyUrls[this.taxonomyUrls.length - 1],
          d.name,
        ]
          .filter((_d) => _d)
          .join("/"),
        active: d.name === this.activeTaxonomy,
        children:
          d.children?.map((child) => ({
            name: child,
            to:
              "/funnel-trends/" +
              this.buildTaxonomyUrl([...this.taxonomyLocation, d.name]) +
              "/" +
              child,
          })) ?? [],
      }));
    },
    navigationParent() {
      const name = this.taxonomyLocation[this.taxonomyLocation.length - 1];
      return this.taxonomyLocation.length > 0
        ? {
            name,
            to: [
              "/funnel-trends",
              this.buildTaxonomyUrl(
                this.taxonomyLocation.slice(0, this.taxonomyLocation.length - 1)
              ),
              name,
            ]
              .filter((s) => s)
              .join("/"),
          }
        : undefined;
    },
    selectedData() {
      const selectedName = this.taxonomies.find((d) => d.active)?.name;
      if (!selectedName) return undefined;

      return this.blankFilledData.find((d) => d.name === selectedName);
    },
    alwaysExport() {
      return [
        "taxonomy_1",
        ...(this.taxonomyOne !== null ? ["taxonomy_2"] : []),
        ...(this.taxonomyTwo !== null ? ["taxonomy_3"] : []),
        ...(this.taxonomyThree !== null ? ["taxonomy_4"] : []),
      ];
    },
    exportColumns() {
      return [...this.alwaysExport, "conversion_date", ...this.columns];
    },
    exportData() {
      return this.data?.flatMap((_d) =>
        _d.data.map((d) => ({
          ...d,
          solo_revenue_percent: d.solo_revenue_percent / 100,
          lower_funnel_revenue_percent: d.lower_funnel_revenue_percent / 100,
          mid_funnel_revenue_percent: d.mid_funnel_revenue_percent / 100,
          upper_funnel_revenue_percent: d.upper_funnel_revenue_percent / 100,
        }))
      );
    },
  },
  watch: {
    url: {
      immediate: true,
      async handler() {
        this.loading = true;
        try {
          await this.load(this.url);
        } finally {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.page-container {
  background: white;
  border-bottom: 1px solid #e2e5ed;
}

.breadcrumo-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.taxonomy-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.grid {
  display: grid;
  gap: 1.5rem 3rem;
}

.page-layout-columns {
  grid-template-columns: minmax(min-content, 20vw) 1fr;
}
</style>
