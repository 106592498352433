<template>
  <p v-if="showDuplicateTagAlert" class="help is-danger">{{ alertString }}</p>
</template>

<script>
export default {
  name: "SegmentAlert",
  props: {
    showDuplicateTagAlert: {
      type: Boolean,
      default: false
    },
    alertString: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.help {
  margin-bottom: 0.5rem;
}
</style>