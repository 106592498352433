export const UseStringHelper = () => {
  const validateInternalParameter = (str: string) =>
    encodeURIComponent(str).replaceAll(/%2F/g, "%252F");
  const validateApiParameter = (str: string) =>
    encodeURIComponent(str).replaceAll(/%2F/g, "%252F");
  const decodeApiParameter = (str: string) => decodeURIComponent(str);
  const capitalise = (str: string) => {
    if (!str) return "";
    str = str.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  /**
   * Takes a string an removes spaces and replaces them with underscores
   * @param str Any string containing letters or numbers
   * @returns a handelised string separated by underscores
   */
  const convertNameToHandle = (str: string) => {
    if (!str) return "";
    return str.toLowerCase().replaceAll(" ", "_");
  };
  return {
    validateInternalParameter,
    validateApiParameter,
    decodeApiParameter,
    capitalise,
    convertNameToHandle,
  };
};
