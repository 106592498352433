<template>
  <div>
    <outer-section ref="performanceSearchSection">
      <performance-table-search class="box" :search="search"></performance-table-search>
    </outer-section>
  </div>
</template>

<script>
import OuterSection from "../../Elements/OuterSection";
import PerformanceTableSearch from "../../Modules/Performance/Search/Table";

export default {
  title() {
    return '"' + this.search + '" | Performance Report';
  },
  components: { OuterSection, PerformanceTableSearch },
  props: {
    search: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>
