<template>
  <outer-section>
    <crossover-table></crossover-table>
  </outer-section>
</template>

<script setup>
import { onMounted } from 'vue';
import CrossoverTable from '../../Modules/Crossover/Table.vue';

onMounted(() => {
  document.title = "Crossover Analysis | SingleView"
})
</script>