import { IGeTAllTableHeaders } from "../../../../typings/DataFetching";
import { defineStore } from "pinia";

type urlObject = {
  tag: string;
  table: string;
  chart: string;
};

interface SegmentComparisonState {
  taxonomy: number;
  taxonomyOne: string;
  taxonomyTwo: string;
  taxonomyThree: string;
  allFetchUrls: urlObject[];
  topName: string;
  bottomName: string;
  tabularHeaders: Partial<IGeTAllTableHeaders> | null;
  segmentMetric: string;
  comparisonMetricLeft: string;
  comparisonMetricRight: string;
}

export const UseSegmentComparisonStore = defineStore("segmentComparison", {
  state: (): SegmentComparisonState => ({
    taxonomy: 1,
    taxonomyOne: "",
    taxonomyTwo: "",
    taxonomyThree: "",
    allFetchUrls: [],
    topName: "",
    bottomName: "",
    tabularHeaders: null,
    segmentMetric: "attributed_aov",
    comparisonMetricLeft: "",
    comparisonMetricRight: "",
  }),
  getters: {
    getTaxonomy: (state) => state.taxonomy,
    getTaxonomyOne: (state) => state.taxonomyOne,
    getTaxonomyTwo: (state) => state.taxonomyTwo,
    getTaxonomyThree: (state) => state.taxonomyThree,
    getAllFetchUrls: (state) => state.allFetchUrls,
    getTopName: (state) => state.topName,
    getBottomName: (state) => state.bottomName,
    getTabularHeaders: (state) => state.tabularHeaders,
    getSegmentMetric: (state) => state.segmentMetric,
    getComparisonMetricLeft: (state) => state.comparisonMetricLeft,
    getComparisonMetricRight: (state) => state.comparisonMetricRight,
  },
  actions: {
    setTaxonomy(taxonomy: number) {
      this.taxonomy = taxonomy;
    },
    setTaxonomyOne(taxOne: string) {
      this.taxonomyOne = taxOne || "";
    },
    setTaxonomyTwo(taxTwo: string) {
      this.taxonomyTwo = taxTwo || "";
    },
    setTaxonomyThree(taxThree: string) {
      this.taxonomyThree = taxThree || "";
    },
    setAllFetchUrls(urlObj: urlObject[]) {
      this.allFetchUrls = urlObj;
    },
    setTopName(top: string) {
      this.topName = top;
    },
    setBottomName(bottom: string) {
      this.bottomName = bottom;
    },
    setTabularHeaders(headers: Partial<IGeTAllTableHeaders>) {
      this.tabularHeaders = headers;
    },

    setSegmentMetric(metric: string) {
      this.segmentMetric = metric;
    },
    setComparisonMetricLeft(metric: string) {
      this.comparisonMetricLeft = metric;
    },
    setComparisonMetricRight(metric: string) {
      this.comparisonMetricRight = metric;
    },
  },
});
