<template>
  <o-modal v-model:active="isCreateModalActive" @close="activeStep = 0" width="960">
    <div class="modal-card" style="width: auto">
      <div class="card">
        <div class="card-content">
          <o-steps v-model="activeStep" animated :has-navigation="false">
            <o-step-item label="Filters" step="1">
              <section>
                <br />
                <p class="content">
                  <button class="button is-primary" @click="add" :disabled="canAddRule">
                    Add filtered metric
                  </button>
                </p>
                <p class="content">
                  <template v-if="savedRules.length > 0">
                    Filtered metrics:
                    <br />
                    <br />
                    <o-field grouped group-multiline>
                      <template v-for="rule in savedRules">
                        <div class="control">
                          <p
                            type="is-metric-one"
                            class="tw-cursor-pointer"
                            size="is-medium"
                            closable
                            attached
                            @click="deleteRule(rule.configuration.rule)"
                          >
                            <SvBadge :color="'blue'" :addonText="'X'">
                              {{ displayRule(rule) }}
                            </SvBadge>
                          </p>
                        </div>
                      </template>
                    </o-field>
                  </template>
                  <template v-else>There are currently no filters applied.</template>
                </p>
                <template v-for="rule in editingRules">
                  <o-field>
                    <report-filter
                      v-model:value="
                        find(rules, {
                          configuration: { rule: rule.configuration.rule },
                        }).configuration
                      "
                      @delete="deleteRule"
                      v-model:existing="savedRules"
                      :fields="allowedFields"
                      :filters="allowedFilters"
                      @emittedValidations="(val) => (twoValidated = val)"
                    ></report-filter>
                  </o-field>
                </template>
                <br />
                <br />
                <div class="is-clearfix">
                  <button class="button" @click="isCreateModalActive = false">
                    Cancel
                  </button>
                  <button
                    class="button is-pulled-right"
                    @click="activeStep = 2"
                    :disabled="canAddRule || savedRules.length === 0"
                  >
                    Continue
                  </button>
                </div>
              </section>
            </o-step-item>

            <o-step-item label="Configuration" step="2">
              <section>
                <br />
                <form>
                  <o-field
                    label="Report Name"
                    :variant="
                      errorMessageReportName && errorMessageReportName.length
                        ? 'danger'
                        : ''
                    "
                    :message="errorMessageReportName"
                  >
                    <o-input
                      v-model="reportNameValue"
                      name="reportName"
                      data-vv-as="report name"
                      @input="validatePartThree"
                    ></o-input>
                  </o-field>

                  <o-field
                    label="Report Level"
                    :variant="
                      errorMessageReportLevel && errorMessageReportLevel.length
                        ? 'danger'
                        : ''
                    "
                    :message="errorMessageReportLevel"
                  >
                    <o-select
                      v-model="reportLevelValue"
                      expanded
                      name="reportLevel"
                      data-vv-as="report level"
                      class="o-sel o-sel--placeholder o-sel-arrow"
                    >
                      <option value="taxonomy_1" selected="selected">Taxonomy 1</option>
                      <option value="taxonomy_2">Taxonomy 2</option>
                      <option value="taxonomy_3">Taxonomy 3</option>
                      <option value="taxonomy_4">Taxonomy 4</option>
                    </o-select>
                  </o-field>
                </form>

                <br />
                <br />

                <div class="is-clearfix">
                  <button class="button" @click="activeStep = 0">Previous</button>
                  <button
                    type="button"
                    class="button is-pulled-right"
                    @click="activeStep = 3"
                    :disabled="!canProgress"
                  >
                    Continue
                  </button>
                </div>
              </section>
            </o-step-item>

            <o-step-item label="Finish" step="3">
              <section class="flex-section">
                <div>
                  <h4 class="is-size-4 title">{{ reportName }}</h4>
                  <p class="is-size-6 subtitle is-capitalized">
                    Showing at {{ showReportLevel }}
                  </p>
                </div>

                <div>
                  <h4 class="is-size-4 title mo-1">Applied Filters</h4>
                  <o-field grouped group-multiline>
                    <template v-for="rule in savedRules">
                      <div class="control">
                        <p
                          type="is-metric-one"
                          class="tw-cursor-pointer"
                          size="is-medium"
                          attached
                          @click="deleteRule(rule.configuration.rule)"
                        >
                          <SvBadge :color="'blue'" :addonText="'X'">
                            {{ displayRule(rule) }}
                          </SvBadge>
                        </p>
                      </div>
                    </template>
                  </o-field>
                </div>

                <div class="is-clearfix">
                  <button class="button" @click="activeStep = 1">Previous</button>
                  <template v-if="previewing">
                    <button class="button is-pulled-right" @click="preview">
                      Preview
                    </button>
                  </template>
                  <template v-else>
                    <button class="button is-pulled-right" @click="click">Apply</button>
                  </template>
                </div>
              </section>
            </o-step-item>
          </o-steps>
        </div>
      </div>
    </div>
  </o-modal>
</template>

<script>
import RulesHelper from "./RulesHelper";
import ReportFilter from "../Filter";
import { useField } from "vee-validate";
import { UseValidateHelper } from "../../../Helper/Functional/UseValidateHelper";
import { UseStringHelper } from "../../../Helper/Functional/UseStringHelper";
import { find } from "lodash";
import { UseReport } from "../Hooks/UseReport";
export default {
  mixins: [RulesHelper],
  setup() {
    const { isRequired } = UseValidateHelper();
    const {
      errorMessage: errorMessageReportName,
      value: reportNameValue,
      validate: reportNameValidated,
    } = useField("report_name", isRequired("The report name is required"));
    const {
      errorMessage: errorMessageReportLevel,
      value: reportLevelValue,
      validate: reportLevelValidated,
    } = useField("report_name", isRequired("The report level is required"));
    const { validateInternalParameter } = UseStringHelper();
    const { reportLevel: reportLevelState, setReportLevel } = UseReport();

    return {
      errorMessageReportName,
      reportNameValue,
      reportNameValidated,
      errorMessageReportLevel,
      reportLevelValue,
      reportLevelValidated,
      validateInternalParameter,
      find,
      reportLevelState,
      setReportLevel,
    };
  },
  watch: {
    reportNameValue(val) {
      this.reportName = val;
    },
    reportLevelValue(val) {
      this.reportLevel = val;
    },
  },
  components: {
    ReportFilter,
  },
  computed: {
    isCreateModalActive: {
      get() {
        return this.modalActive;
      },
      set(val) {
        this.$emit("update:modalActive", val);
      },
    },
    reportName: {
      get() {
        return this.nameProp;
      },
      set(val) {
        this.$emit("update:nameProp", val);
      },
    },
    reportLevel: {
      get() {
        return this.reportLevelState;
      },
      set(val) {
        this.setReportLevel(val);
      },
    },
    rules: {
      get() {
        return this.rulesProp;
      },
      set(val) {
        this.$emit("update:rulesProp", val);
      },
    },
    showReportLevel() {
      return this.levelProp ? this.levelProp.replace(/_/g, " ") : "";
    },
    canAddRule() {
      return _.includes(
        _.map(this.rules, function (value) {
          return value.configuration.editing;
        }),
        true
      );
    },
    savedRules() {
      return _.filter(this.rules, function (value) {
        return !value.configuration.editing;
      });
    },
    editingRules() {
      return _.filter(this.rules, function (value) {
        return value.configuration.editing;
      });
    },
  },
  data() {
    return {
      activeStep: 1,
      twoValidated: false,
      threeValidated: false,
      canProgress: false,
    };
  },
  props: {
    nameProp: {
      type: String,
      default: () => "",
    },
    levelProp: {
      type: String,
      default: "taxonomy_1",
    },
    rulesProp: {
      type: Array,
      default: () => [],
    },
    previewing: false,
    modalActive: false,
  },
  mounted() {
    this.reportLevelValue = "taxonomy_1";
    this.twoValidated = this.savedRules.length ? true : false;
  },
  // props: ['nameProp', 'levelProp', 'rulesProp', 'previewing', 'modalActive'],
  methods: {
    add() {
      let copy = this.rulesProp;

      copy.push({
        configuration: {
          metric: "",
          filter: "",
          value: "",
          editing: true,
          rule:
            _.maxBy(this.rulesProp, function (value) {
              return value.configuration.rule;
            }) === undefined
              ? 1
              : _.get(
                  _.maxBy(this.rulesProp, function (value) {
                    return value.configuration.rule;
                  }),
                  ["configuration", "rule"],
                  0
                ) + 1,
        },
      });
      this.rules = copy;
    },
    deleteRule(val) {
      this.rules.splice(
        _.findIndex(this.rules, function (value) {
          return value.configuration.rule === val;
        }),
        1
      );
    },
    displayRule(rule) {
      return (
        _.get(
          _.find(this.allowedFields, { name: rule.configuration.metric }),
          "display"
        ) +
        " " +
        _.get(
          _.find(this.allowedFilters, { type: rule.configuration.filter }),
          "display",
          ""
        ).toLowerCase() +
        " " +
        rule.configuration.value
      );
    },
    preview() {
      this.$router.push(
        "/reports/preview?name=" +
          this.reportName +
          "&level=" +
          this.reportLevel +
          "&rules=" +
          JSON.stringify(this.rules)
      );
    },
    click() {
      this.activeStep = 0;
      this.isCreateModalActive = false;
    },
    validatePartTwo() {
      if (this.twoValidated) {
        this.activeStep = 3;
      }
    },
    validatePartThree() {
      Promise.all([this.reportNameValidated, this.reportLevelValidated]).then(
        async (res) => {
          const result = await Promise.all(res.map((f) => f()));
          const indicator = result.every((v) => v.valid);
          this.threeValidated = indicator;
          this.canProgress = indicator;
        }
      );
    },
  },
};
</script>

<style scoped>
.mo-1 {
  margin-bottom: 0.5rem;
}

.flex-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

:deep(.animation-content) {
  width: 100%;
  padding: 0 20px;
}
</style>
