<template>
  <div>
    <outer-section>
      <div class="box">
        <account-connections-breadcrumb></account-connections-breadcrumb>

        <h1 class="title">Manage connections</h1>

        <section>
          <div class="columns is-multiline">
            <!-- Google Ads -->
            <div class="column is-full-tablet is-one-third-desktop">
              <a @click="routeWarning('/account/connections/google-ads')">
                <div class="is-child card custom-card-shadowed">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-128x128">
                        <img
                          src="https://singleview-assets.s3.eu-west-2.amazonaws.com/images/connections/google_ads.png"
                          alt="Google Ads"
                        />
                      </figure>
                    </div>
                    <div class="media-right custom-card-right">
                      <p class="is-size-4">Google Ads</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- Facebook -->
            <div class="column is-full-tablet is-one-third-desktop">
              <a @click="openWarning(facebookLink)">
                <div class="is-child card custom-card-shadowed">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-128x128">
                        <img
                          src="https://singleview-assets.s3.eu-west-2.amazonaws.com/images/connections/facebook.png"
                          alt="Facebook"
                        />
                      </figure>
                    </div>
                    <div class="media-right custom-card-right">
                      <p class="is-size-4">Facebook</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- Bing -->
            <div class="column is-full-tablet is-one-third-desktop">
              <a @click="openWarning(bingLink, true)">
                <div class="is-child card custom-card-shadowed">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-128x128">
                        <img
                          src="https://singleview-assets.s3.eu-west-2.amazonaws.com/images/connections/microsoft.png"
                          alt="Microsoft Advertising"
                        />
                      </figure>
                    </div>
                    <div class="media-right custom-card-right">
                      <p class="is-size-4">Microsoft Advertising</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!-- Awin -->
            <div class="column is-full-tablet is-one-third-desktop">
              <a @click="manager">
                <div class="is-child card custom-card-shadowed">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-128x128">
                        <img
                          src="https://singleview-assets.s3.eu-west-2.amazonaws.com/images/connections/awin.png"
                          alt="Awin"
                        />
                      </figure>
                    </div>
                    <div class="media-right custom-card-right">
                      <p class="is-size-4">Awin</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <br />
      </div>
    </outer-section>
  </div>
</template>

<script>
import AccountConnectionsBreadcrumb from "../../../Modules/Account/Connections/Breadcrumb";
import CONSTANTS from "../../../Modules/State/Constants";

import { storeToRefs } from "pinia";
import { UseMainStore } from "../../../Modules/State/SingleViewStorePinia";

export default {
  title: "Account Connections",
  components: { AccountConnectionsBreadcrumb },
  setup() {
    const { getUserId } = storeToRefs(UseMainStore());

    return {
      getUserId,
    };
  },
  computed: {
    bingLink() {
      return `${CONSTANTS.SINGLE_VIEW_BING_CONNECTOR}?svid=${this.getUserId}`;
    },
    facebookLink() {
      return (
        "https://singleview-facebook-ui.singleview.tech?svid=" + this.getUserId
      );
    },
  },
  methods: {
    manager() {
      swal.fire({
        type: "info",
        title: "Account manager required",
        text: "Please contact your account manager to setup this connection.",
      });
    },
    routeWarning(route) {
      swal
        .fire({
          type: "info",
          title: "Warning",
          text: "Are you sure you want to edit this connection",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result && result.value) {
            this.$router.push(route);
          }
        });
    },
    openWarning(route, blank) {
      swal
        .fire({
          type: "info",
          title: "Warning",
          text: "Are you sure you want to edit this connection",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result && result.value) {
            window.open(route, blank ? "_blank" : "_self");
          }
        });
    },
  },
};
</script>

<style scoped>
.custom-card-right {
  justify-content: center;
  left: 76px;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  align-items: center;
  display: flex;
}
</style>
