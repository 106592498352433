import { IAxiosFunnelDataShape } from "./../../../../generics/generics";
import {
  IFunnelTrends,
  TCacheKey,
  TColumnHeaders,
} from "./../Typings/SingleViewStoreTypes";
import { defineStore } from "pinia";
import axios from "axios";

interface FunnelState {
  funnelTrends: IFunnelTrends[];
  cacheKey: TCacheKey;
  abortController: AbortController | undefined;
  columnHeaders: TColumnHeaders | undefined;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    signal: AbortSignal;
  }
}

export const UseFunnelStore = defineStore("funnel", {
  state: (): FunnelState => ({
    funnelTrends: [],
    cacheKey: "",
    abortController: undefined,
    columnHeaders: undefined,
  }),
  getters: {
    getFunnelTrends: (state) => state.funnelTrends,
    getColumnHeaders: (state) => state.columnHeaders,
    geTCacheKey: (state) => state.cacheKey,
  },
  actions: {
    setFunnelTrends({
      funnelTrends,
      columnHeaders,
      key,
    }: {
      funnelTrends: IFunnelTrends[] | [];
      columnHeaders: TColumnHeaders;
      key: TCacheKey;
    }) {
      this.funnelTrends = funnelTrends;
      this.columnHeaders = columnHeaders;
      this.cacheKey = key;
    },
    setAbortController(abortController: AbortController) {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = abortController;
    },
    async loadFunnelTrends(url: string) {
      if (this.cacheKey === url) {
        return;
      }

      const controller = new AbortController();
      this.setAbortController(controller);
      this.setFunnelTrends({
        funnelTrends: [],
        columnHeaders: {},
        key: "",
      });

      try {
        const { data } = await axios.get<
          IAxiosFunnelDataShape<IFunnelTrends[], TColumnHeaders>
        >(url, { signal: controller.signal });

        this.setFunnelTrends({
          funnelTrends: data.data,
          columnHeaders: data.column_headers,
          key: url,
        });
      } catch (err: unknown) {
        if (err instanceof Error && err.message !== "canceled") {
          throw err;
        }
      }
    },
  },
});
