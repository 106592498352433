import {
  TColumnSets,
  TColumns,
  IGetPerformanceTableColumnSets,
} from "./Typings/SingleViewStoreTypes";

const SINGLEVIEW_API_BASE =
  process.env.NODE_ENV === "development"
    ? "https://api.singleview.dev"
    : "https://api.singleview.tech";

const SINGLE_VIEW_BING_CONNECTOR =
  process.env.NODE_ENV === "development"
    ? "https://singleview-bing-ui.singleview.dev/"
    : "https://singleview-bing-ui.singleview.tech/";

const SINGLEVIEW_THIRD_PARTY_CONNECTORS_API_BASE =
  "https://thirdparty-connectors-api.roeye.com";
const SINGLEVIEW_USER_ID = "SINGLEVIEW_USER_ID";
const SINGLEVIEW_USER_MESSAGES = "SINGLEVIEW_USER_MESSAGES";
const SINGLEVIEW_PROGRAM_MESSAGES = "SINGLEVIEW_PROGRAM_MESSAGES";
const SINGLEVIEW_USER_ADMIN = "SINGLEVIEW_USER_ADMIN";
const SINGLEVIEW_SYSTEM_MESSAGES = "SINGLEVIEW_SYSTEM_MESSAGES";
const SINGLEVIEW_TOKEN = "SINGLEVIEW_TOKEN";
const SINGLEVIEW_PROGRAM = "SINGLEVIEW_CURRENT_PROGRAM";
const SINGLEVIEW_AVAILABLE_PROGRAMS = "SINGLEVIEW_AVAILABLE_PROGRAMS";
const SINGLEVIEW_DATE_FROM = "SINGLEVIEW_DATE_FROM";
const SINGLEVIEW_DATE_TO = "SINGLEVIEW_DATE_TO";
const SINGLEVIEW_SELECTED_METRICS = "SINGLEVIEW_SELECTED_METRICS";
const SINGLEVIEW_SELECTED_COLUMNS = "SINGLEVIEW_SELECTED_COLUMNS";
const SINGLEVIEW_REPORT_SETTINGS = "SINGLEVIEW_REPORT_SETTINGS";

const SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMNS: TColumns = [
  "total_cost",
  "clicks",
  "last_click_sales",
  "last_click_revenue",
  "attributed_sales",
  "attributed_revenue",
  "cpa",
  "roas",
  "attributed_revenue_variance_percent",
];
const SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMN_SETS:
  | TColumnSets[]
  | IGetPerformanceTableColumnSets[] = [
  { id: -1, favourite: false, name: "Custom Set", columns: [] },
  {
    id: 0,
    favourite: false,
    name: "SingleView Default",
    columns: SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMNS,
  },
];

export default {
  SINGLEVIEW_API_BASE,
  SINGLEVIEW_AVAILABLE_PROGRAMS,
  SINGLEVIEW_DATE_FROM,
  SINGLEVIEW_DATE_TO,
  SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMNS,
  SINGLEVIEW_DEFAULT_PERFORMANCE_COLUMN_SETS,
  SINGLEVIEW_PROGRAM,
  SINGLEVIEW_PROGRAM_MESSAGES,
  SINGLEVIEW_REPORT_SETTINGS,
  SINGLEVIEW_SELECTED_COLUMNS,
  SINGLEVIEW_SELECTED_METRICS,
  SINGLEVIEW_SYSTEM_MESSAGES,
  SINGLEVIEW_THIRD_PARTY_CONNECTORS_API_BASE,
  SINGLEVIEW_TOKEN,
  SINGLEVIEW_USER_ADMIN,
  SINGLEVIEW_USER_ID,
  SINGLEVIEW_USER_MESSAGES,
  SINGLE_VIEW_BING_CONNECTOR,
};
