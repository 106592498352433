<template>
  <div style="display: contents">
    <!-- Variable Cost -->
    <td class="has-text-right" v-show="isColumnVisible('variable_cost')">
      <performance-field
        :current="row.variable_cost"
        :previous="fetchComparisonMetric(row[taxonomy], 'variable_cost')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Fixed Cost -->
    <td class="has-text-right" v-show="isColumnVisible('fixed_cost')">
      <performance-field
        :current="row.fixed_cost"
        :previous="fetchComparisonMetric(row[taxonomy], 'fixed_cost')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Cost -->
    <td class="has-text-right" v-show="isColumnVisible('total_cost')">
      <performance-field
        :current="row.total_cost"
        :previous="fetchComparisonMetric(row[taxonomy], 'total_cost')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Page Lands -->
    <td class="has-text-right" v-show="isColumnVisible('clicks')">
      <performance-field
        :current="row.clicks"
        :previous="fetchComparisonMetric(row[taxonomy], 'clicks')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Impressions -->
    <td class="has-text-right" v-show="isColumnVisible('impressions')">
      <performance-field
        :current="row.impressions"
        :previous="fetchComparisonMetric(row[taxonomy], 'impressions')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- First Click Sales -->
    <td class="has-text-right" v-show="isColumnVisible('first_click_sales')">
      <performance-field
        :current="row.first_click_sales"
        :previous="fetchComparisonMetric(row[taxonomy], 'first_click_sales')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- First Click Revenue -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('first_click_revenue')"
    >
      <performance-field
        :current="row.first_click_revenue"
        :previous="fetchComparisonMetric(row[taxonomy], 'first_click_revenue')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- First Click Conversion Rate -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('first_click_conversion_rate')"
    >
      <performance-field
        :current="row.first_click_conversion_rate"
        :previous="
          fetchComparisonMetric(row[taxonomy], 'first_click_conversion_rate')
        "
        percentage
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- First Click AOV-->
    <td class="has-text-right" v-show="isColumnVisible('first_click_aov')">
      <performance-field
        :current="row.first_click_aov"
        :previous="fetchComparisonMetric(row[taxonomy], 'first_click_aov')"
        monetary
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Last Click Sales -->
    <td class="has-text-right" v-show="isColumnVisible('last_click_sales')">
      <performance-field
        :current="row.last_click_sales"
        :previous="fetchComparisonMetric(row[taxonomy], 'last_click_sales')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Last Click Revenue -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('last_click_revenue')"
    >
      <performance-field
        :current="row.last_click_revenue"
        :previous="fetchComparisonMetric(row[taxonomy], 'last_click_revenue')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Last Click Conversion Rate -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('last_click_conversion_rate')"
    >
      <performance-field
        :current="row.last_click_conversion_rate"
        :previous="
          fetchComparisonMetric(row[taxonomy], 'last_click_conversion_rate')
        "
        percentage
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Last Click AOV-->
    <td class="has-text-right" v-show="isColumnVisible('last_click_aov')">
      <performance-field
        :current="row.last_click_aov"
        :previous="fetchComparisonMetric(row[taxonomy], 'last_click_aov')"
        monetary
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Sales -->
    <td class="has-text-right" v-show="isColumnVisible('attributed_sales')">
      <performance-field
        :current="row.attributed_sales"
        :previous="fetchComparisonMetric(row[taxonomy], 'attributed_sales')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Revenue -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('attributed_revenue')"
    >
      <performance-field
        :current="row.attributed_revenue"
        :previous="fetchComparisonMetric(row[taxonomy], 'attributed_revenue')"
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Conversion Rate -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('attributed_conversion_rate')"
    >
      <performance-field
        :current="row.attributed_conversion_rate"
        :previous="
          fetchComparisonMetric(row[taxonomy], 'attributed_conversion_rate')
        "
        percentage
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed AOV-->
    <td class="has-text-right" v-show="isColumnVisible('attributed_aov')">
      <performance-field
        :current="row.attributed_aov"
        :previous="fetchComparisonMetric(row[taxonomy], 'attributed_aov')"
        monetary
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Revenue Variance -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('attributed_revenue_variance')"
    >
      <performance-field
        :current="row.attributed_revenue_variance"
        :previous="
          fetchComparisonMetric(row[taxonomy], 'attributed_revenue_variance')
        "
        monetary
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Revenue Variance Percent -->
    <td
      class="has-text-right"
      v-show="isColumnVisible('attributed_revenue_variance_percent')"
    >
      <performance-field
        :current="row.attributed_revenue_variance_percent"
        :previous="
          fetchComparisonMetric(
            row[taxonomy],
            'attributed_revenue_variance_percent'
          )
        "
        percentage
        one-decimal
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Introducer Ratio -->
    <td class="has-text-right" v-show="isColumnVisible('introducer_ratio')">
      <performance-field
        :current="row.introducer_ratio"
        :previous="fetchComparisonMetric(row[taxonomy], 'introducer_ratio')"
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- CPA -->
    <td class="has-text-right" v-show="isColumnVisible('cpa')">
      <performance-field
        :current="row.cpa"
        :previous="fetchComparisonMetric(row[taxonomy], 'cpa')"
        monetary
        opposite
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- ROAS -->
    <td class="has-text-right" v-show="isColumnVisible('roas')">
      <performance-field
        :current="row.roas"
        :previous="fetchComparisonMetric(row[taxonomy], 'roas')"
        monetary
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- ROI -->
    <td class="has-text-right" v-show="isColumnVisible('roi')">
      <performance-field
        :current="row.roi"
        :previous="fetchComparisonMetric(row[taxonomy], 'roi')"
        monetary
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Sales Involving -->
    <td class="has-text-right" v-show="isColumnVisible('sales_including')">
      <performance-field
        :current="row.sales_including"
        :previous="fetchComparisonMetric(row[taxonomy], 'sales_including')"
        rounded
        :comparing="comparing"
      ></performance-field>
    </td>

    <!-- Attributed Sale Credit -->
    <td class="has-text-right" v-show="isColumnVisible('proportional_credit')">
      <performance-field
        :current="row.proportional_credit"
        :previous="fetchComparisonMetric(row[taxonomy], 'proportional_credit')"
        one-decimal
        :comparing="comparing"
      ></performance-field>
    </td>
  </div>
</template>

<script>
import PerformanceFieldVue from "./PerformanceField.vue";
export default {
  components: {
    PerformanceField: PerformanceFieldVue,
  },
  props: {
    taxonomy: {
      type: String,
      default: "taxonomy_2",
    },
    columns: {},
    row: {},
    comparisonData: {},
    comparing: false,
  },
  methods: {
    isColumnVisible(column) {
      return _.includes(this.columns, column);
    },
    fetchComparisonMetric(taxonomy, field) {
      return _.get(this.comparisonData, [taxonomy, field], NaN);
    },
  },
};
</script>
