import { ISegmentGroup } from "./../Typings/SingleViewStoreTypes";
import { defineStore } from "pinia";

interface SegementsState {
  segmentGroupData: ISegmentGroup[];
}

export const UseSegementStore = defineStore("segments", {
  state: (): SegementsState => ({
    segmentGroupData: [],
  }),
  getters: {
    getSegmentGroupData: (state) => state.segmentGroupData,
  },
  actions: {
    setSegmentGroupData({ data }: { data: ISegmentGroup[] }) {
      this.segmentGroupData = data;
    },
    updateSegmentGroupDataWithNewGroup({ data }: { data: ISegmentGroup }) {
      this.segmentGroupData.push(data);
    },
    updateSegmentGroupName({ group_name, group_title }) {
      const group = this.segmentGroupData.find(
        (g) => g.group_name === group_name
      );
      if (group) {
        group.group_title = group_title;
      }
    },
    addSegmentData({
      group_name,
      segment_title,
      segment_name,
    }: {
      group_name: string;
      segment_title: string;
      segment_name: string;
    }) {
      const segment = this.segmentGroupData.find(
        (seg) => seg.group_name === group_name
      );
      if (segment) {
        segment.segments = segment.segments || [];
        segment.segments.push({
          segment_title: segment_title,
          segment_name: segment_name,
        });
      }
    },
    editSegmentTitle({
      group_name,
      segment_title,
      segment_name,
    }: {
      group_name: string;
      segment_title: string;
      segment_name: string;
    }) {
      const segment = this.segmentGroupData.find(
        (seg) => seg.group_name === group_name
      );
      if (segment) {
        const segmentIndex = segment.segments.findIndex(
          (seg) => seg.segment_name === segment_name
        );
        if (segmentIndex !== -1) {
          segment.segments[segmentIndex].segment_title = segment_title;
        }
      }
    },
  },
});
