<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <o-loading
        :is-full-page="true"
        v-model:active="fullPageLoading"
      ></o-loading>
      <div class="container has-text-centered" v-if="authorised">
        <div class="column is-4 is-offset-4">
          <img src="/singleview_logo.png" class="is-centered" width="200" />
          <br />
          <br />
          <p
            class="subtitle has-text-grey has-text-weight-light"
            v-show="!passwordChanged"
          >
            Please type your new password here
          </p>
          <form class="box" @submit.prevent v-if="!passwordChanged">
            <section>
              <o-field>
                <o-input
                  placeholder="New password"
                  type="password"
                  icon="form-textbox-password"
                  v-model="password"
                  password-reveal
                ></o-input>
              </o-field>
              <o-field>
                <o-input
                  lazy
                  placeholder="Retype password"
                  type="password"
                  icon="form-textbox-password"
                  v-model="passwordCheck"
                  password-reveal
                ></o-input>
              </o-field>
              <p class="padding-16">
                <span class="is-danger" v-if="showErrorMsg">
                  These passwords don't match. Please ensure both fields match.
                </span>
              </p>
              <button
                :class="[
                  'button',
                  'is-block',
                  'is-info',
                  'is-fullwidth',
                  { 'is-loading': loading },
                ]"
                type="submit"
                @click="submit"
              >
                Submit
              </button>
            </section>
            <!--<br/>-->
          </form>
          <div class="box" v-else>
            <p class="subtitle">
              Thank you for changing your password. If you are not automatically
              redirected to the login page then please click
              <router-link to="/login">here</router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="column is-8 is-offset-2" v-show="showBadLinkMsg">
        <p class="notification is-warning">
          Sorry that link seems to be wrong. Maybe it has expired or sent in
          error. Please contact your Success Manager or alternatively return to
          the login screen and start the Recover Password process again
          <router-link to="/login">HERE</router-link>. This page will
          automatically redirect in 5 seconds.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
//import axios from 'axios';
import AxiosCalls from "./AxiosCalls.vue";
import { UseMainStore } from "../../../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";
export default {
  name: "CreateNewPassword",
  mixins: [AxiosCalls],
  data() {
    return {
      password: "",
      passwordCheck: "",
      showErrorMsg: false,
      token: "",
      email: "",
    };
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());

    return {
      getApiBaseUrl,
    };
  },
  methods: {
    submit() {
      //Check if passwords match.
      if (this.password !== this.passwordCheck) {
        this.showErrorMsg = true;
        return;
      }
      this.showErrorMsg = false;
      this.loading = true;
      this.resetPassword(
        this.password,
        this.passwordCheck,
        this.email,
        this.token
      );
    },
  },
  mounted() {
    this.fullPageLoading = true;
    //To be added once API is done:
    this.token = this.$route.params.token;
    this.email = decodeURIComponent(this.$route.query.email);
    this.checkToken(this.token, this.email, this.getApiBaseUrl);
  },
};
</script>

<style scoped>
.padding-16 {
  padding: 1rem 0;
}

.is-danger {
  color: #ff1f4b;
}
</style>
