<script>
import { h } from "vue";
export default {
  props: {
    selfClosing: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    return () =>
      h(
        "div",
        {},
        context.slots.default()?.map((child) =>
          typeof child.children[0].props.field !== "undefined"
            ? h(
                "div",
                {
                  class: {
                    "striped-row": true,
                    "self-closing": props.selfClosing,
                  },
                },
                [child]
              )
            : child
        )
      );
  },
};
</script>

<style lang="scss" scoped>
.striped-row {
  color: #3a4954;
  padding: 0.5rem 1rem;
  overflow: hidden;
}

.self-closing:nth-child(even):last-child {
  border-bottom: 4px solid #e9eff4;
}
</style>
