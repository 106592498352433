<template>
  <v-chart
    class="chart is-family-monospace"
    :option="option"
    v-if="data.length"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { UseNumberHelper } from "../../Helper/Functional/UseNumberHelper";

use([
  BarChart,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  components: {
    VChart,
  },
  name: "FunnelChart",
  props: {
    reference: {
      type: String,
      default: "",
    },
    provide: {
      [THEME_KEY]: "light",
    },
    data: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Object,
      default: () => {},
    },
    showPercent: {
      type: Boolean,
      default: true,
    },
    dataset: {
      type: String,
      default: "Revenue Percent",
    },
    baseProperties: {
      type: Array,
      default: () => [],
    },
    taxonomyLevel: {
      type: String,
      default: null,
    },
  },
  setup() {
    const {
      displayNumericValueRounded,
      displayNumericValue,
      displayMonetaryValueRounded,
    } = UseNumberHelper();

    return {
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueRounded,
    };
  },
  computed: {
    selectedOption() {
      return this.dataset.replace(" ", "_").toLowerCase()
    },
    option() {
      return this.data
        ? {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // Use axis to trigger tooltip
                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
              },
              axisLabel: {
                formatter: this.chartFormatter(),
              },
            },
            color: ["#009AFF", "#89D0FF", "#A6A6A6", "#5D5D5D"],
            grid: {
              left: "4%",
              right: "5%",
              top: 12,
              bottom: 45,
              containLabel: true,
            },
            xAxis: {
              axisLine: {
                lineStyle: {
                  color: "#c4c4c4",
                },
              },
              axisTick: {
                show: false,
              },
              type: "value",
              max: this.showPercent
                ? function () {
                    return 100;
                  }
                : function () {
                    return null;
                  },
              axisLabel: {
                color: "#4a4a4a",
                fontSize: 16,
                margin: 20,
                formatter: this.chartFormatter(),
              },
            },
            textStyle: {
              color: "#4a4a4a",
              fontSize: 16,
            },
            yAxis: {
              type: "category",
              data: this.showDataX(this.data),
              inverse: true,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#c4c4c4",
                },
              },
              axisLabel: {
                color: "#4a4a4a",
                fontSize: 16,
                margin: 20,
              },
            },
            series: this.prepareChartData(this.data),
            legend: {
              data: this.baseProperties.map((prop) => ({
                name: this.headers[
                  prop + this.selectedOption
                ],
              })),
              bottom: 0,
              icon: "roundRect",
              itemGap: 30,
              itemHeight: 16,
              itemWidth: 16,
            },
          }
        : {};
    },
  },
  methods: {
    showDataX(arr = []) {
      return this.extractTitles(arr);
    },
    extractTitles(arr = []) {
      return arr.map((rev) => rev[this.taxonomyLevel]);
    },
    chartFormatter() {
      return (value) => {
        if (this.showPercent) return `${this.displayNumericValueRounded(value)}%`;

        return this.dataset === 'credit'
          ? this.displayNumericValue(value)
          : this.displayMonetaryValueRounded(value);
      };
    },
    prepareChartData(arr = []) {
      const titles = this.baseProperties.map((prop) => prop + this.selectedOption);
      const series = [];
      titles.forEach((title) => {
        series.push({
          name: this.headers[title],
          type: "bar",
          stack: "total",
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            borderColor: "transparent",
            borderWidth: 2,
          },
          axisLabel: {
            formatter: this.chartFormatter(),
          },
          data: arr.map((el) => el[title]),
          tooltip: {
            valueFormatter: this.chartFormatter(),
          },
        });
      });
      return series;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 30em;
  widows: 100%;
}
</style>
