import { UseDateStore } from "../../Modules/State/Stores/DateStore";
import { storeToRefs } from "pinia";
import { computed, Ref, ref } from "vue";
import moment from "moment";

const comparing = ref(false);
const comparisonDates = ref({
  start: "",
  end: "",
});
const dates = ref({
  start: "",
  end: "",
});
export const UseComparisonHelper = () => {
  const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
  dates.value = {
    start: getDateFrom.value,
    end: getDateFrom.value,
  };
  const setComparing = <T, U extends T>(ac: Ref<T>, pr: U) => {
    ac.value = pr;
  };

  const comparingComputed = computed({
    get() {
      return comparing.value;
    },
    set(val: boolean) {
      setComparing(comparing, val);
    },
  });

  const setDatesGeneric = <T, U extends T>(ac: Ref<T>, pr: U) => {
    ac.value = pr;
  };

  const setIndividualDate = <T, U extends keyof T, V extends T[U]>(
    obj: Ref<T>,
    dt: U,
    val: V
  ): Ref<T> => {
    obj.value[dt] = val;

    return obj;
  };

  const comparisonDatesComputed = computed({
    get() {
      return comparisonDates.value;
    },
    set(val: { start: string; end: string }) {
      setDatesGeneric(comparisonDates, val);
    },
  });

  const datesComputed = computed({
    get() {
      return dates.value;
    },
    set(val: { start: string; end: string }) {
      setDatesGeneric(dates, val);
    },
  });

  const init = () => {
    let to = moment(getDateFrom.value).subtract(1, "day");
    let fromx = to.clone();
    fromx.subtract(
      moment(getDateTo.value).diff(moment(getDateFrom.value), "days"),
      "days"
    );

    let toyo = to.format("YYYY-MM-DD");
    let fromxyo = fromx.format("YYYY-MM-DD");

    if (toyo != comparisonDates.value.end) {
      comparisonDates.value.end = toyo;
    }

    if (fromxyo != comparisonDates.value.start) {
      comparisonDates.value.start = fromxyo;
    }
  };

  return {
    comparisonDates,
    comparisonDatesComputed,
    comparing,
    comparingComputed,
    datesComputed,
    setComparing,
    setDatesGeneric,
    setIndividualDate,
    init,
  };
};
