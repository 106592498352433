import {
  IDenormalised,
  IExtract,
  IExtracts,
  IRule,
  TRules,
  TTaxonimies,
  TTaxonomyList,
  ITaxonomy,
  INormalizeImport,
} from "./../Typings/SingleViewStoreTypes";
import { defineStore } from "pinia";
import { normalize, denormalize, schema } from "normalizr";

//Set up normalizr stuff
const taxonomyRuleSchema = new schema.Entity(
  "taxonomyRules",
  {},
  {
    processStrategy: (value: IRule) => {
      const {
        parent_group_id,
        expected_value,
        match_type,
        execution_order,
        field,
        id,
      } = value;

      return {
        field,
        id,
        execution_order,
        parent_group_id,
        expected_value,
        match_type,
      };
    },
  }
);

const taxonomyExtractSchema = new schema.Entity(
  "taxonomyExtracts",
  {},
  {
    processStrategy: (value: IExtract) => {
      const { parent_group_id, mapped_to, ...others } = value;

      return {
        ...others,
        parent_group_id,
        mapped_to,
      };
    },
  }
);

const taxonomySchema = new schema.Entity(
  "taxonomies",
  {},
  {
    processStrategy: (value: ITaxonomy) => {
      const { parent_id, join_type, programcode, execution_order, ...others } =
        value;

      return {
        ...others,
        parent_id,
        join_type,
        programcode,
        execution_order,
      };
    },
  }
);

taxonomySchema.define({
  rules: [taxonomyRuleSchema],
  extracts: [taxonomyExtractSchema],
  children: [taxonomySchema],
});

export const normaliseTaxonomy = (taxonomy: IDenormalised) => {
  return normalize(taxonomy, taxonomySchema);
};
const sortFunction = (arr: IDenormalised[]) => {
  return arr.sort((a, b) => a.execution_order - b.execution_order);
};
export const normaliseTaxonomies = (taxonomies: IDenormalised[]) => {
  return normalize(sortFunction(taxonomies), [
    taxonomySchema,
  ]) as INormalizeImport;
};

export const denormaliseTaxonomy = (
  taxonomyList: TTaxonomyList,
  taxonomies: TTaxonimies,
  rules: TRules,
  extracts: IExtracts
): IDenormalised[] => {
  const mapObjectValues = <T extends object, U>(
    obj: T,
    // eslint-disable-next-line no-unused-vars
    fn: (param: U, index?: number) => U
  ) =>
    Object.fromEntries(
      Object.entries(obj).map(([id, param], index) => {
        return [id, fn(param, index)];
      })
    );

  const entities = {
    taxonomies: mapObjectValues(
      taxonomies,
      (taxonomy: ITaxonomy, index?: number) => {
        const {
          parent_id: parent_id,
          join_type: join_type,
          programcode: programcode,
          ...others
        } = taxonomy;

        return {
          ...others,
          parent_id,
          join_type,
          programcode,
          execution_order: index ? index + 1 : NaN,
        };
      }
    ),
    taxonomyRules: mapObjectValues(rules, (rule: IRule, index?: number) => {
      const { parent_group_id, expected_value, match_type, ...others } = rule;

      return {
        ...others,
        parent_group_id,
        expected_value,
        match_type,
        execution_order: index ? index + 1 : NaN,
      };
    }),
    taxonomyExtracts: mapObjectValues(extracts, (extract: IExtract) => {
      const { parent_group_id, mapped_to, ...others } = extract;

      return {
        ...others,
        parent_group_id,
        mapped_to,
      };
    }),
  };

  return denormalize(taxonomyList, [taxonomySchema], entities);
};

interface ClassificationState {
  showSidebar: boolean;
  loaded: boolean;
  taxonomies: TTaxonimies;
  rules: TRules;
  extracts: IExtracts;
  taxonomyList: TTaxonomyList;
}

export const UseClassificationStore = defineStore("classification", {
  state: (): ClassificationState => ({
    showSidebar: false,
    loaded: false,
    taxonomies: {},
    rules: {},
    extracts: {},
    taxonomyList: [],
  }),
  getters: {
    getShowSidebar: (state) => state.showSidebar,
    getTaxonomies:
      (state) =>
      (taxonomyList: TTaxonomyList): ITaxonomy[] =>
        taxonomyList?.map((id) => state.taxonomies[id]),
    taxonomyOnes(): ITaxonomy[] {
      return this.getTaxonomies(this.taxonomyList);
    },
    getTaxonomy: (state) => (taxonomyId: number) =>
      state.taxonomies[taxonomyId],
    getTaxonomyChildren() {
      return (taxonomyId: number): ITaxonomy[] =>
        this.getTaxonomy(taxonomyId).children?.map((id) =>
          this.getTaxonomy(id)
        );
    },
    getRules: (state) => (ruleIds: number[]) => {
      if (ruleIds) {
        return ruleIds.map((ruleId) => state.rules[ruleId]);
      }
      return [];
    },
    getExtracts: (state) => (extractIds: number[]) =>
      extractIds?.map((extractId) => state.extracts[extractId]),
    denormalised: (state) =>
      denormaliseTaxonomy(
        state.taxonomyList,
        state.taxonomies,
        state.rules,
        state.extracts
      ),
  },
  actions: {
    setShowSidebar(val: boolean) {
      this.showSidebar = val;
    },
    setNormalised({
      result,
      entities: { taxonomies, taxonomyRules, taxonomyExtracts },
    }: {
      result: TTaxonomyList;
      entities: {
        taxonomies: TTaxonimies;
        taxonomyRules: TRules;
        taxonomyExtracts: IExtracts;
      };
    }) {
      this.taxonomies = taxonomies;
      this.rules = taxonomyRules;
      this.extracts = taxonomyExtracts;
      this.taxonomyList = result;
      this.loaded = true;
    },
    setTaxonomyList(taxonomyList: TTaxonomyList) {
      this.taxonomyList = taxonomyList;
    },
    setTaxonomies(taxonomies: TTaxonimies) {
      this.taxonomies = taxonomies;
    },
    setTaxonomy({ id, taxonomy }: { id: number; taxonomy: ITaxonomy }) {
      this.taxonomies[id] = taxonomy;
    },
    renameTaxonomy({ id, name }: { id: number; name: string }) {
      this.taxonomies[id] = { ...this.taxonomies[id], title: name };
    },
    setTaxonomyCondition({ id, condition }: { id: number; condition: string }) {
      this.taxonomies[id] = { ...this.taxonomies[id], join_type: condition };
    },
    setRule({ id, rule }: { id: number; rule: IRule }) {
      this.rules[id] = rule;
    },
    setExtract({ id, extract }: { id: number; extract: IExtract }) {
      this.extracts[id] = extract;
    },
  },
});
