import { UseReportSettingsStore } from "./../../../State/Stores/ReportSettingsStore";
import {
  TGetPerformanceMetricOne,
  TGetPerformanceMetricTwo,
  TGetPerformanceChartType,
  TGetPerformanceChartInterval,
} from "../../../State/Typings/SingleViewStoreTypes";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";

export const UsePerformanceChart = () => {
  //Set up data.
  const playTogetherMetrics = ref([
    "variable_cost",
    "fixed_cost",
    "total_cost",
    "first_click_revenue",
    "last_click_revenue",
    "attributed_revenue",
  ]);

  //Load in Store Getters

  const {
    getPerformanceMetricOne,
    getPerformanceMetricTwo,
    getPerformanceChartType,
    getPerformanceChartInterval,
  } = storeToRefs(UseReportSettingsStore());

  //Load in Store Actions
  const {
    setPerformanceMetricOne,
    setPerformanceMetricTwo,
    setPerformanceChartType,
    setPerformanceChartInterval,
  } = UseReportSettingsStore();

  //Set up computed

  const firstMetric = computed({
    get(): TGetPerformanceMetricOne {
      return getPerformanceMetricOne.value;
    },
    set(val: TGetPerformanceMetricOne) {
      setPerformanceMetricOne(val);
    },
  });
  const secondMetric = computed({
    get(): TGetPerformanceMetricTwo {
      return getPerformanceMetricTwo.value;
    },
    set(val: TGetPerformanceMetricTwo) {
      setPerformanceMetricTwo(val);
    },
  });
  const typeOfChart = computed({
    get(): TGetPerformanceChartType {
      return getPerformanceChartType.value;
    },
    set(val: TGetPerformanceChartType) {
      "changing chart";
      setPerformanceChartType(val);
    },
  });
  const interval = computed({
    get(): TGetPerformanceChartInterval {
      return getPerformanceChartInterval.value;
    },
    set(val: TGetPerformanceChartInterval) {
      setPerformanceChartInterval(val);
    },
  });

  return {
    playTogetherMetrics,
    firstMetric,
    secondMetric,
    typeOfChart,
    interval,
  };
};
