<template>
  <outer-section>
    <o-loading v-model:active="loading"></o-loading>
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="box">
          <funnel-breadcrumb
            :taxonomy-one="taxonomyOne"
            :taxonomy-two="taxonomyTwo"
            :taxonomy-three="taxonomyThree"
          />
          <funnel-controls
            :headers="headers"
            :metrics="metrics"
            v-model:sort-metric="sortMetric"
            :control-headers="controlHeaders"
            v-model:sort-control-headers="sortControlHeaders"
            v-model:sort-direction="sortMetricDirection"
            v-model:page="page"
            v-model:per-page="perPage"
            v-model:records="sortedData.length"
            :export-data="exportData"
          />
        </div>
      </div>
      <template v-if="!data.length && !loading">
        <div class="column is-full">
          <div class="box">
            <div class="content has-text-centered">
              <p>There are no results, try expanding the dates of your search</p>
            </div>
          </div>
        </div>
      </template>

      <funnel-chart
        :data="percentageFixedData"
        :headers="headers"
        :dataset="filterDataBy"
        :show-percent="showPercent"
        :taxonomy-level="`taxonomy_${taxonomyLevel}`"
        :base-properties="baseProperties"
      />

      <funnel-table
        :formatted-data="formattedData"
        :table-headers="headers"
        :taxonomy-level="taxonomyLevel"
        v-model:page="page"
        v-model:perPage="perPage"
        v-model:records="formattedData.length"
        @update:visibleData="(data) => (visibleTableData = data)"
      />
    </div>
  </outer-section>
</template>

<script>
import OuterSection from "../Elements/OuterSection.vue";
import FunnelBreadcrumb from "../Modules/Funnel/Breadcrumb.vue";
import FunnelControls from "../Modules/Funnel/Controls.vue";
import FunnelChart from "../Modules/Funnel/Chart.vue";
import FunnelTable from "../Modules/Funnel/Table/Table.vue";
import { UseAxiosErrorHelper } from "../Helper/ThirdParty/UseAxiosErrorHelper";
import { storeToRefs } from "pinia";
import { UseDateStore } from "../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../Modules/State/Stores/ProgramStore";
import { UseMainStore } from "../Modules/State/SingleViewStorePinia";
import { UseReportSettingsStore } from "../Modules/State/Stores/ReportSettingsStore";
import { StaticColumnData } from "../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../Helper/Stateful/UseColumnModalHelper";

export default {
  title: "Funnel Report",
  components: {
    OuterSection,
    FunnelBreadcrumb,
    FunnelControls,
    FunnelChart,
    FunnelTable,
  },
  props: {
    taxonomyOne: {
      type: String,
      required: false,
      default: null,
    },
    taxonomyTwo: {
      type: String,
      required: false,
      default: null,
    },
    taxonomyThree: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      data: [],
      headers: {},
      controlHeaders: {
        revenue: 'Revenue',
        revenue_percent: 'Revenue Percent',
        credit: 'Attributed Sales',
        credit_percent: 'Attributed Sales Percent'
      },
      loading: false,
      interval: "day",
      page: 1,
      perPage: 10,
      filterDataBy: "revenue_percent",
      showPercent: false,
      visibleTableData: [],
      creditHeaders: {
        lower_funnel_credit: "Lower Funnel Attributed Sales",
        upper_funnel_credit: "Upper Funnel Attributed Sales",
        mid_funnel_credit: "Mid Funnel Attributed Sales",
        solo_credit: "Solo Attributed Sales",
        lower_funnel_credit_percent: "Lower Funnel Attributed Sales Percent",
        upper_funnel_credit_percent: "Upper Funnel Attributed Sales Percent",
        mid_funnel_credit_percent: "Mid Funnel Attributed Sales Percent",
        solo_credit_percent: "Solo Attributed Sales Percent",
      },
    };
  },
  setup() {
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { getFunnelSortDirection, getFunnelSortMetric } = storeToRefs(
      UseReportSettingsStore()
    );
    const { setFunnelSortDirection, setFunnelSortMetric } = UseReportSettingsStore();
    const { funnelAvailableColumns } = StaticColumnData();
    const { columns } = UseColumnModalHelper(funnelAvailableColumns);
    UseAxiosErrorHelper();
    return {
      getDateFrom,
      getDateTo,
      getApiBaseUrl,
      getFunnelSortDirection,
      getFunnelSortMetric,
      getCurrentProgram,
      columns,
      funnelAvailableColumns,
      setFunnelSortDirection,
      setFunnelSortMetric,
    };
  },
  computed: {
    sortMetric: {
      get() {
        return this.getFunnelSortMetric.startsWith("taxonomy")
          ? this.taxonomyThree
            ? "taxonomy_4"
            : this.taxonomyTwo
            ? "taxonomy_3"
            : this.taxonomyOne
            ? "taxonomy_2"
            : "taxonomy_1"
          : this.getFunnelSortMetric;
      },
      set(val) {
        this.setFunnelSortMetric(val);
      },
    },
    sortMetricDirection: {
      get() {
        return this.getFunnelSortDirection;
      },
      set(val) {
        this.setFunnelSortDirection(val);
      },
    },
    taxonomyLevel() {
      let taxonomy = 1;
      [this.taxonomyOne, this.taxonomyTwo, this.taxonomyThree].forEach((tax) => {
        if (tax) {
          taxonomy++;
        }
      });

      return taxonomy;
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/performance/funnel/single/taxonomy_1" +
        (this.taxonomyOne
          ? "/" + this.validateApiParameter(this.taxonomyOne) + "/taxonomy_2"
          : "") +
        (this.taxonomyTwo
          ? "/" + this.validateApiParameter(this.taxonomyTwo) + "/taxonomy_3"
          : "") +
        (this.taxonomyThree
          ? "/" + this.validateApiParameter(this.taxonomyThree) + "/taxonomy_4"
          : "") +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo +
        "&interval=" +
        this.interval
      );
    },
    metrics() {
      return [
        "taxonomy_1",
        ...(this.taxonomyOne !== null ? ["taxonomy_2"] : []),
        ...(this.taxonomyTwo !== null ? ["taxonomy_3"] : []),
        ...(this.taxonomyThree !== null ? ["taxonomy_4"] : []),
        ...this.data?.map((name) => name.taxonomy_1),
      ];
    },
    formattedData() {
      return (
        this.data?.map((d) => ({
          ...d,
          total_sales: d.solo_credit + d.upper_funnel_credit + d.mid_funnel_credit + d.lower_funnel_credit,
          total_revenue: d.solo_revenue + d.upper_funnel_revenue + d.mid_funnel_revenue + d.lower_funnel_revenue,
          location: [
            this.taxonomyOne,
            this.taxonomyTwo,
            this.taxonomyThree,
            d.taxonomy_4 || d.taxonomy_3 || d.taxonomy_2 || d.taxonomy_1,
          ].filter((v) => v !== null),
        })) || []
      );
    },
    exportData() {
      return this.formattedData?.map((d) => ({
        ...d,
        taxonomy_1: d.location[0],
        ...(d.location.length > 1 ? { taxonomy_2: d.location[1] } : {}),
        ...(d.location.length > 2 ? { taxonomy_3: d.location[2] } : {}),
        ...(d.location.length > 3 ? { taxonomy_4: d.location[3] } : {}),
        upper_funnel_revenue: d.upper_funnel_revenue,
        mid_funnel_revenue: d.mid_funnel_revenue,
        lower_funnel_revenue: d.lower_funnel_revenue,
        solo_revenue: d.solo_revenue,
        upper_funnel_revenue_percent: d.upper_funnel_revenue_percent / 100,
        mid_funnel_revenue_percent: d.mid_funnel_revenue_percent / 100,
        lower_funnel_revenue_percent: d.lower_funnel_revenue_percent / 100,
        solo_revenue_percent: d.solo_revenue_percent / 100,
        upper_funnel_credit_percent: d.upper_funnel_credit_percent / 100,
        mid_funnel_credit_percent: d.mid_funnel_credit_percent / 100,
        lower_funnel_credit_percent: d.lower_funnel_credit_percent / 100,
        solo_funnel_credit_percent: d.solo_credit_percent / 100,
        upper_funnel_credit: d.upper_funnel_credit,
        mid_funnel_credit: d.mid_funnel_credit,
        lower_funnel_credit: d.lower_funnel_credit,
        solo_credit: d.solo_credit,
        total_revenue:
          d.solo_revenue +
          d.upper_funnel_revenue +
          d.mid_funnel_revenue +
          d.lower_funnel_revenue,
        total_sales:
          d.solo_credit +
          d.upper_funnel_credit +
          d.mid_funnel_credit +
          d.lower_funnel_credit,
      }));
    },
    sortedData() {
      return this.formattedData
        ? _.orderBy(this.visibleTableData, this.sortMetric, this.sortMetricDirection)
        : [];
    },
    chunkedData() {
      return _.chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.formattedData ? this.chunkedData[this.page - 1] : [];
    },
    percentageFixedData() {
      return this.visibleTableData ? this.percentageFixer(this.pageData) : [];
    },
    sortControlHeaders: {
      get() {
        return this.filterDataBy;
      },
      set(val) {
        this.filterDataBy = val;
        if (val.includes('percent')) {
          this.showPercent = true;
        } else {
          this.showPercent = false;
        }
      },
    },
    baseProperties() {
      return ["solo_", "upper_funnel_", "mid_funnel_", "lower_funnel_"];
    },
    tableHeaders() {
      return Object.keys(this.headers).map((header) => {
        return {
          field: header,
          label: this.headers[header],
        };
      });
    },
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      axios
        .get(this.url)
        .then(({ data }) => {
          this.data = data.data;
          this.headers =
            data.column_headers["solo_credit"] &&
            data.column_headers["solo_credit_percent"] &&
            data.column_headers["solo_revenue"] &&
            data.column_headers["solo_revenue_percent"]
              ? data.column_headers
              : { ...data.column_headers, ...this.creditHeaders };
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.headers = {};
          this.loading = false;
        });
    },

    percentageFixer(arr = []) {
      if (this.showPercent) {
        return arr.map((props) => {
          const {
            lower_funnel_revenue_percent,
            mid_funnel_revenue_percent,
            upper_funnel_revenue_percent,
            solo_revenue_percent,
          } = props;
          const total =
            lower_funnel_revenue_percent +
            mid_funnel_revenue_percent +
            upper_funnel_revenue_percent +
            solo_revenue_percent;
          return {
            ...props,
            lower_funnel_revenue_percent: (lower_funnel_revenue_percent / total) * 100,
            mid_funnel_revenue_percent: (mid_funnel_revenue_percent / total) * 100,
            upper_funnel_revenue_percent: (upper_funnel_revenue_percent / total) * 100,
            solo_revenue_percent: (solo_revenue_percent / total) * 100,
          };
        });
      }
      return arr;
    },
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.loadAsyncData();
      },
    },
  },
};
</script>

<style>
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}

.solo-square {
  background-color: #009aff;
}
.upper-square {
  background-color: #89d0ff;
}
.mid-square {
  background-color: #a6a6a6;
}
.lower-square {
  background-color: #5d5d5d;
}
</style>
