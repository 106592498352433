import axios, { AxiosError } from "axios";
import { defineStore } from "pinia";

interface DiminishingReturnsState {
  diminishingReturns: {};
  columnHeaders: {};
  cacheKey: null | string;
  abortController: null | AbortController;
}

export const UseDiminishingReturnsStore = defineStore("diminishingReturns", {
  state: (): DiminishingReturnsState => ({
    diminishingReturns: {},
    columnHeaders: {},
    cacheKey: null,
    abortController: null,
  }),
  getters: {
    getDiminishingReturns: (state) => state.diminishingReturns,
    getColumnHeaders: (state) => state.columnHeaders,
  },
  actions: {
    async loadDiminishingReturns(url: string, seasonalityRequest) {
      if (this.cacheKey === url) {
        return;
      }

      const controller = new AbortController();
      this.abortController = controller;

      this.diminishingReturns = [];
      this.columnHeaders = {};
      this.cacheKey = null;

      try {
        const [{ data }, { data: seasonalityData }] = await Promise.all([
          axios.get(url),
          seasonalityRequest,
        ]);

        const diminishingReturns = Object.fromEntries(
          data.data.map((taxonomy: { name: string; data: object }) => [
            taxonomy.name,
            {
              ...taxonomy,
              data: Object.fromEntries(
                Object.entries(taxonomy.data).map(([date, itm]) => [
                  date,
                  {
                    ...itm,
                    seasonality: (
                      seasonalityData.data?.find(
                        (s) => s.start_date === itm.conversion_date
                      )?.seasonality ?? "none"
                    ).toLocaleLowerCase(),
                  },
                ])
              ),
            },
          ])
        );

        this.diminishingReturns = diminishingReturns;
        this.columnHeaders = data.column_headers;
        this.cacheKey = url;
      } catch (err) {
        const error = err as AxiosError;
        if (error?.message !== "canceled") {
          throw err;
        }
      }
    },
  },
});
