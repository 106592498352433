<template>
  <div>
    <template v-if="redirect">
      <section class="hero">
        <div class="hero-body" v-if="!loading">
          <div class="container has-text-centered">
            <h1 class="is-size-5">
              Your account isn't setup with a Google Ads connection.
            </h1>
            <a class="is-size-5 is-link" :href="redirect">
              <img
                style="margin-top: 0.5rem"
                src="https://singleview-assets.s3.eu-west-2.amazonaws.com/images/partners/partner-google-signin-sm.png"
                srcset="
                  https://singleview-assets.s3.eu-west-2.amazonaws.com/images/partners/partner-google-signin-md.png 2x
                "
                alt="Sign in with Google"
              />
            </a>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <template v-if="!data">
        <section class="hero">
          <div class="hero-body" v-if="!loading">
            <div class="container has-text-centered">
              <h1 class="is-size-5">
                Your account has a Google Ads connection, but no ad accounts have been
                found.
              </h1>
              <p class="is-size-5 is-link">Speak to us if you think this is wrong.</p>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <o-table
          class="has-sticky-table-headers"
          ref="connectionGoogleAdsTable"
          narrowed
          hoverable
          :data="data"
        >
          <template slot-scope="props">
            <o-table-column field="accountId" label="Account" sortable>{{
              props.row.accountId
            }}</o-table-column>

            <o-table-column field="account_name" label="Account Name" sortable>{{
              props.row.account_name
            }}</o-table-column>

            <o-table-column field="active" label="Active" sortable>
              <template v-if="props.row.active">Active</template>
              <template v-else>Inactive</template>
            </o-table-column>

            <o-table-column label="Actions" numeric>
              <template v-if="props.row.active">
                <button
                  class="button is-danger is-outlined is-small"
                  @click="disableConnection(props.row.accountId)"
                >
                  Disable
                </button>
              </template>
              <template v-else>
                <button
                  class="button is-success is-outlined is-small"
                  @click="enableConnection(props.row.accountId)"
                >
                  Enable
                </button>
              </template>
            </o-table-column>
          </template>
        </o-table>
      </template>
    </template>

    <o-loading :full-page="false" v-model:active="loading"></o-loading>
  </div>
</template>

<script>
import AuthHelper from "../../../Helper/Component/AuthHelper";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";

export default {
  setup() {
    const { getThirdPartyConnectorsApiBaseUrl, getUserId } = storeToRefs(UseMainStore());

    UseAxiosErrorHelper();

    return {
      getThirdPartyConnectorsApiBaseUrl,
      getUserId,
    };
  },
  mixins: [AuthHelper],

  computed: {
    baseUrl() {
      return this.getThirdPartyConnectorsApiBaseUrl + "/adwords/" + this.getUserId;
    },
    url() {
      return this.baseUrl + "/listAccounts" + this.getMetaForUrl;
    },
    getMetaForUrl() {
      if (this.paramCode && this.paramState) {
        return "?code=" + this.paramCode + "&state=" + this.paramState;
      }

      return "";
    },
    paramCode() {
      return this.validateInternalParameter(_.get(this.$route.query, "code", ""));
    },
    paramState() {
      return this.validateInternalParameter(_.get(this.$route.query, "state", ""));
    },
    hasRequiredParams() {
      return this.paramCode !== "" && this.paramState !== "";
    },
  },
  data() {
    return {
      redirect: "",
      data: [],
      failed: 0,
      loading: false,
    };
  },
  methods: {
    loadAsyncData() {
      this.loading = true;

      axios
        .get(this.url)
        .then(({ data }) => {
          this.loading = false;

          if (_.has(data, "redirect_to")) {
            this.redirect = data.redirect_to;

            return;
          } else {
            this.redirect = "";
            this.data = data;
            // this.data = [{"accountId":"3493408114","account_name":"Leesa (UK)","active":true},{"accountId":"2536161557","account_name":"Leesa","active":false},{"accountId":"2391320641","account_name":"Leesa (CA)","active":false},{"accountId":"7371907225","account_name":"Leesa (DE)","active":false},{"accountId":"3954394319","account_name":"Leesa (EU - Multi Territory)","active":false},{"accountId":"8594818046","account_name":"Leesa (IE)","active":false},{"accountId":"3493408114","account_name":"Leesa (UK)","active":false},{"accountId":"3487832001","account_name":"Leesa (UK) - BP 07 White Approach WI","active":false},{"accountId":"5560691217","account_name":"Leesa Sleep - Europe","active":false},{"accountId":"7371907225","account_name":"Leesa (DE)","active":false},{"accountId":"3487832001","account_name":"Leesa (UK) - BP 07 White Approach WI","active":false},{"accountId":"3744655886","account_name":"Leesa (UK) - BP 06","active":false},{"accountId":"6050904497","account_name":"Leesa (UK) - BP 05","active":false},{"accountId":"5201403486","account_name":"Leesa (UK) - BP 04","active":false},{"accountId":"8391899091","account_name":"Leesa (UK) - BP 02","active":false},{"accountId":"8345146495","account_name":"Leesa (UK) - BP 03","active":false},{"accountId":"5651462356","account_name":"Leesa (UK) - BP 01","active":false}];

            return;
          }
        })
        .catch(() => {
          this.loading = false;
          if (this.failed < 10) {
            this.failed = this.failed += 1;

            this.sleep(1000).then(() => {
              this.loadAsyncData();
            });
          }
        });
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    enableConnection(account) {
      this.loading = true;

      axios
        .get(this.baseUrl + "/enableSync/" + account)
        .then(() => {
          this.loading = false;

          this.data[_.findIndex(this.data, { accountId: account })] = _.merge(
            _.find(this.data, { accountId: account }),
            {
              active: true,
            }
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
    disableConnection(account) {
      this.loading = true;

      axios
        .get(this.baseUrl + "/disableSync/" + account)
        .then(() => {
          this.loading = false;

          this.data[_.findIndex(this.data, { accountId: account })] = _.merge(
            _.find(this.data, { accountId: account }),
            {
              active: false,
            }
          );
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>
