<script>
export default {
  data() {
    return {
      allowedFields: [
        {
          name: "total_cost",
          type: "money",
          display: "Spend",
        },
        {
          name: "clicks",
          type: "number",
          display: "Page Lands",
        },
        {
          name: "attributed_sales",
          type: "number",
          display: "Attributed Sales",
        },
        {
          name: "attributed_revenue",
          type: "number",
          display: "Attributed Revenue",
        },
        {
          name: "attributed_revenue_variance_percent",
          type: "percentage",
          display: "Attr Revenue Variance Percent",
        },
        {
          name: "cpa",
          type: "money",
          display: "Attributed CPA",
        },
        {
          name: "roas",
          type: "money",
          display: "Attributed ROAS",
        },
        {
          name: "roi",
          type: "money",
          display: "ROI",
        },
      ],
      allowedFilters: [
        {
          name: "eq",
          display: "Equal to",
          type: "=",
          bitwise: 2,
        },
        {
          name: "gt",
          display: "Greater than",
          type: ">",
          bitwise: 8,
        },
        {
          name: "lt",
          display: "Less than",
          type: "<",
          bitwise: 4,
        },
        {
          name: "gte",
          display: "Greater than or equal to",
          type: ">=",
          bitwise: 8,
        },
        {
          name: "lte",
          display: "Less than or equal to",
          type: "<=",
          bitwise: 4,
        },
      ],
    };
  },
};
</script>
