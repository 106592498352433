<template>
  <section class="field">
    <p class="control">
      <o-dropdown v-model="columnSet" aria-role="list">
        <template v-slot:trigger>
          <button
            class="button is-primary"
            :class="[{ 'is-loading': saving || deleting || favouriting }]"
            variant="primary"
            type="button"
          >
            <o-icon icon="table-row-height"></o-icon>
            <span>
              Column Set: Custom
              <!-- {{
              columnSet === -1
                ? newSetName
                : columnSets.find((set) => set.id === columnSet).name ||
                  "Custom"
            }} -->
            </span>
            <o-icon icon="menu-down"></o-icon>
          </button>
        </template>

        <o-dropdown-item aria-role="listitem" :disabled="columnSet !== -1" :value="-1">
          <o-field grouped>
            <o-field expanded>
              <o-input
                :disabled="columnSet !== -1"
                v-model="newSetName"
                placeholder="Set Name"
              ></o-input>
            </o-field>
            <o-field>
              <o-button :disabled="!canBeSaved" type="is-success" @click="save">
                <o-icon icon="content-save-outline"></o-icon>
              </o-button>
            </o-field>
          </o-field>
        </o-dropdown-item>

        <hr class="dropdown-divider" />

        <o-dropdown-item
          v-for="(set, i) in columnSets"
          :key="i"
          aria-role="listitem"
          :value="set.id"
        >
          <o-icon
            @click.native.stop="favourite(set)"
            :icon="set.id === columnSetFavourite ? 'star' : 'star-outline'"
            :type="set.id === columnSetFavourite ? 'is-warning' : ''"
          ></o-icon>

          <span style="vertical-align: super">{{ set.name }}</span>

          <o-icon
            v-if="set.id > 0"
            @click.native.stop="deleteSet(set)"
            icon="delete"
            type="is-danger"
          ></o-icon>
        </o-dropdown-item>
      </o-dropdown>
    </p>
  </section>
</template>

<script>
import { UseMainStore } from "../State/SingleViewStorePinia";
import { UseReportSettingsStore } from "../State/Stores/ReportSettingsStore";

import { map, join, sortBy, includes, get, find, findIndex } from "lodash";
import { storeToRefs } from "pinia";
import { UseAxiosErrorHelper } from "../../Helper/ThirdParty/UseAxiosErrorHelper";

export default {
  setup() {
    const {
      getPerformanceTableColumnSets,
      getPerformanceTableColumns,
      getPerformanceTableColumnSet,
      getPerformanceTableColumnSetFavourite,
    } = storeToRefs(UseReportSettingsStore());
    const {
      setPerformanceTableColumns,
      setPerformanceTableColumnSet,
      setPerformanceTableColumnSetFavourite,
      setPerformanceTableColumnSets,
    } = UseReportSettingsStore();
    const MainStore = UseMainStore();
    UseAxiosErrorHelper();
    return {
      MainStore,
      getPerformanceTableColumnSets,
      getPerformanceTableColumns,
      getPerformanceTableColumnSet,
      getPerformanceTableColumnSetFavourite,
      setPerformanceTableColumns,
      setPerformanceTableColumnSet,
      setPerformanceTableColumnSetFavourite,
      setPerformanceTableColumnSets,
    };
  },
  computed: {
    getApiBaseUrl() {
      return this.MainStore.getApiBaseUrl;
    },
    columns: {
      get() {
        return this.getPerformanceTableColumns;
      },
      set(val) {
        this.setPerformanceTableColumns(val);
      },
    },
    columnSet: {
      get() {
        return this.getPerformanceTableColumnSet;
      },
      set(val) {
        this.setPerformanceTableColumnSet(val);
      },
    },
    columnSetFavourite: {
      get() {
        return this.getPerformanceTableColumnSetFavourite;
      },
      set(val) {
        this.setPerformanceTableColumnSetFavourite(val);
      },
    },
    columnSets: {
      get() {
        return this.getPerformanceTableColumnSets;
      },
      set(val) {
        this.setPerformanceTableColumnSets(val);
      },
    },
    existingColumnSetsMapped() {
      return map(this.columnSets, (d) => ({
        flat: join(sortBy(d.columns), ","),
        id: d.id,
      }));
    },
    canBeSaved() {
      return (
        this.columnSet === -1 &&
        !includes(
          map(this.columnSets, (d) => d.name),
          this.newSetName
        ) &&
        this.columns.length > 0
      );
    },
  },
  data() {
    return {
      newSetName: "",
      favouriting: false,
      shouldNotFavourite: false,
      deleting: false,
      saving: false,
    };
  },
  methods: {
    favourite(set) {
      this.columnSetFavourite = set.id;
    },
    selectSet(id) {
      this.columnSet = id;
      this.columns = get(find(this.columnSets, { id: id }), "columns", []);
    },
    deleteSet(set) {
      if (!(set.id > 0)) {
        return;
      }

      this.deleting = true;

      axios
        .delete(this.getApiBaseUrl + "/column-sets/" + set.id)
        .then(
          function () {
            this.columnSets.splice(
              findIndex(this.columnSets, (d) => d.id === set.id),
              1
            );
            // This cant come after favouriting it :/
            this.columnSets = this.columnSets;

            if (set.id === this.columnSetFavourite) {
              this.shouldNotFavourite = true;
              this.favourite(find(this.columnSets, { id: 0 }));
            }

            if (findIndex(this.columnSets, (d) => d.id === this.columnSet) < 0) {
              this.selectSet(0);
            }
          }.bind(this)
        )
        .catch(() => {
          this.deleting = false;
        });

      this.deleting = false;
    },
    save() {
      this.saving = true;

      axios
        .post(this.getApiBaseUrl + "/column-sets", {
          name: this.newSetName,
          columns: this.columns,
        })
        .then(
          function ({ data }) {
            this.columnSets = this.columnSets.concat({
              id: data.data.id,
              name: this.newSetName,
              columns: this.columns,
            });

            this.newSetName = "";
            this.selectSet(id);
          }.bind(this)
        )
        .catch(() => {
          this.saving = false;
        });

      this.saving = false;
    },
  },
  watch: {
    columnSet(newVal) {
      if (newVal !== -1) {
        this.selectSet(newVal);
      }
    },
    columns(newVal) {
      let current = find(this.existingColumnSetsMapped, {
        flat: join(sortBy(newVal), ","),
      });

      if (current === undefined) {
        this.columnSet = -1;
      } else {
        this.selectSet(current.id);
      }
    },
    columnSetFavourite(newVal, oldVal) {
      // Its been deleted, can't make an api call to unfave something that is deleted
      if (this.shouldNotFavourite) {
        this.shouldNotFavourite = false;
        return;
      }

      this.favouriting = true;

      // Figure out what we're gonna send to the api
      let sent;
      if (newVal > 0) {
        sent = newVal;
      } else {
        sent = oldVal;
      }

      axios
        .post(this.getApiBaseUrl + "/column-sets/" + sent + "/favourite", {})
        .then(
          function () {
            //Don't need to do anything here
          }.bind(this)
        )
        .catch(() => {
          //Don't need to do anything here
        });

      this.favouriting = false;
    },
  },
};
</script>
