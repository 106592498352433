<template>
  <div>
    <o-field grouped>
      <o-field>
        <template v-for="i in 4 * (level - 1)">&nbsp;</template>
        <o-checkbox
          v-model.lazy="selectedCompute"
          :indeterminate="selected === 'some'"
          true-value="all"
          false-value="none"
          @change.native="cascadeSelect"
          >{{ name }}</o-checkbox
        >
        <a
          class="control"
          @click="showChildren = !showChildren"
          v-if="children.length > 0 && name !== 'None'"
        >
          <o-icon icon="menu-down"></o-icon>
        </a>
      </o-field>
    </o-field>
    <div v-if="showChildren">
      <tree-bwoi
        v-for="d in children"
        :parent="parent + name"
        :name="d.name"
        v-model:children="d.children"
        v-model:selected="d.selected"
        :level="d.level"
        :key="'tree' + parent + d.name + d.level"
        @cascadeSelect="cascadeSelect"
        @childSelect="childSelect"
      ></tree-bwoi>
    </div>
  </div>
</template>

<script>
import TreeBwoi from "./TreeBwoi";

// Vue.component('tree-bwoi', TreeBwoi);

export default {
  name: "tree-bwoi",
  components: {
    TreeBwoi,
  },
  // components: ''
  // fffff:
  //
  // b = _.flatMapDeep(a, (d) => _.flatMapDeep(d.children, (dd) => _.chain(dd.children).flatMapDeep('children').filter(function (d) { console.log(d.selected); return d.selected !== 'none' && d.selected !== 'None' }).map(function (ddd) {  return ddd.raw.taxonomy_1 + ddd.raw.taxonomy_2 + ddd.raw.taxonomy_3 + ddd.raw.taxonomy_4 ;}).value()  ));
  computed: {
    allSelected() {},
    selectedCompute: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  name: "tree-bwoi",
  data() {
    return {
      f: false,
      showChildren: false,
    };
  },
  props: {
    parent: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    level: {
      type: Number,
      default: 1,
    },
    selected: {
      type: String,
      default: "all",
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    // holyshitevan () {
    //     this.$emit('holymoly')
    // },
    cascadeSelect() {
      let recursive = function (d, s) {
        function setAll(d, s) {
          _.each(d, function (item) {
            item.selected = s;
            setAll(item.children, s);

            // if (item.children.length === 0) {
            //     this.$emit('childSelect');
            // }
          });
        }

        setAll(d, s);
      };

      if (this.selected === "all") {
        // console.log('Ayo', 'all');
        recursive(this.children, "all");
      } else {
        // console.log('Ayo', 'Not ALL!!');
        recursive(this.children, "none");
        // Catch 'some' and 'none' going to ALL
        // Going to all
      }

      // console.log(this.selected, this.children);
    },
    childSelect() {
      if (_.every(_.map(this.children, "selected"), (d) => d === "all")) {
        this.selectedCompute = "all";
      } else {
        if (_.every(_.map(this.children, "selected"), (d) => d === "none")) {
          this.selectedCompute = "none";
        } else {
          this.selectedCompute = "some";
        }
      }

      this.$emit("childSelect");
    },
  },
  watch: {
    selected: function () {
      // if (this.level === 1) {
      //     this.childSelect();
      // } else {
      this.$emit("childSelect");
      // }
    },
  },
};
</script>

<style scoped>
.field:not(:last-child) {
  margin-bottom: 0;
}
</style>
