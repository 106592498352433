import { UseChartHelper } from "../../../../Helper/ThirdParty/UseChartHelper";
import { TChartData } from "./../../../../../typings/DataFetching";
import { UseMathsHelper } from "../../../../Helper/ThirdParty/UseMathsHelper";
import { StaticChartData } from "./../../../../StaticData/StaticChartData";
import { UseTaxonomyHelper } from "../../../../Helper/Stateful/UseTaxonomyHelper";
import { PerformanceTableAndChartData } from "../../../../Helper/Stateful/PerformanceTableAndChartData";
import { UseProgramStore } from "../../../State/Stores/ProgramStore";
import { storeToRefs } from "pinia";
import { UseReportSettingsStore } from "./../../../State/Stores/ReportSettingsStore";
import { UseMetricHelper } from "../../../../Helper/Functional/UseMetricHelper";
import moment from "moment";
import {
  chain,
  map,
  flatten,
  values,
  forEach,
  groupBy,
  filter,
  cloneDeep,
  includes,
  get,
  maxBy,
} from "lodash";
import { ApexOptions } from "apexcharts";
import { computed, readonly, ref } from "vue";

const showAnnotationTable = ref(false);

export const UseChart = () => {
  const { chartData: data, chartHeaders: headers } =
    PerformanceTableAndChartData();
  const {
    getAvailablePerformanceAnnotations,
    getPerformanceAnnotations,
    getPerformanceMetricOne,
    getPerformanceMetricTwo,
  } = storeToRefs(UseReportSettingsStore());
  const { getCurrentProgram } = storeToRefs(UseProgramStore());
  const { fetchMetricValue } = UseMetricHelper();
  const { currentTaxonomyInteger } = UseTaxonomyHelper();
  const { metricsArray, playTogetherMetrics } = StaticChartData();
  const { dryFunctionMinMax, tickAmountSingleDataSource } = UseMathsHelper();
  const { metricsShareScale, annotationPoints, chartFormatter, seriesHeader } =
    UseChartHelper();

  const seriesOneHeaders = computed(() =>
    seriesHeader(headers, getPerformanceMetricOne)
  );
  const seriesTwoHeaders = computed(() =>
    seriesHeader(headers, getPerformanceMetricTwo)
  );
  const tickAmountOne = computed(() =>
    tickAmountSingleDataSource(
      data,
      getPerformanceMetricOne,
      getPerformanceMetricOne,
      5
    )
  );
  const tickAmountTwo = computed(() =>
    tickAmountSingleDataSource(
      data,
      getPerformanceMetricTwo,
      getPerformanceMetricTwo,
      5
    )
  );
  const tickAmountBoth = computed(() =>
    tickAmountSingleDataSource(
      data,
      getPerformanceMetricOne,
      getPerformanceMetricTwo,
      7
    )
  );

  const firstMetricMinMax = computed(() =>
    dryFunctionMinMax(
      [0],
      getPerformanceMetricOne,
      { name: seriesOneHeaders.value, data: data.value },
      {} as TChartData
    )
  );

  const secondMetricMinMax = computed(() =>
    dryFunctionMinMax(
      [0],
      getPerformanceMetricTwo,
      {
        name: seriesTwoHeaders.value,
        data: data.value,
      },
      {} as TChartData
    )
  );

  const computedSeries = computed(() => {
    return [
      {
        name: seriesOneHeaders.value,
        data: chain(data.value)
          .mapValues((value) => ({
            x: moment.utc(value.conversion_date, "YYYY-MM-DD"),
            y: <number>value[getPerformanceMetricOne.value],
          }))
          .values()
          .value(),
      },
      {
        name: seriesTwoHeaders.value,
        data: chain(data.value)
          .mapValues((value) => ({
            x: moment.utc(value.conversion_date, "YYYY-MM-DD"),
            y: <number>value[getPerformanceMetricTwo.value],
          }))
          .values()
          .value(),
      },
    ];
  });

  const annotations = (): ApexAnnotations => {
    return {
      position: "front",
      points: map(
        flatten(
          values(
            forEach(
              groupBy(
                filter(cloneDeep(getAvailablePerformanceAnnotations.value), [
                  "programcode",
                  getCurrentProgram.value,
                ]),
                "date"
              ),
              function (val) {
                return annotationPoints(val);
              }
            )
          )
        ),
        function (val) {
          return {
            x: moment(val.date).unix() * 1000,
            y: 0,
            strokeDashArray: 0,
            seriesIndex: 0,
            borderColor: val.first ? val.colour : null,
            marker: {
              // size: 6,
              // fillColor: "#fff",
              strokeColor: val.colour,
              // radius: 2
            },
            label: {
              offsetY: -20,
              borderColor: val.colour,
              style: {
                color: "#fff",
                background: val.colour,
              },
              text: undefined,
              // text: val.text,
              orientation: "horizontal",
            },
          };
        }
      ),
      xaxis: map(
        flatten(
          values(
            forEach(
              groupBy(
                filter(cloneDeep(getPerformanceAnnotations.value), [
                  "programcode",
                  getCurrentProgram.value,
                ]),
                "date"
              ),
              function (val) {
                return annotationPoints(val);
              }
            )
          )
        ),
        function (val) {
          return {
            x: moment(val.date).unix() * 1000,
            strokeDashArray: 0,
            borderColor: val.first ? val.colour : null,
            label: {
              offsetY: val.offset || 0,
              borderColor: val.colour,
              style: {
                color: "#fff",
                background: val.colour,
              },
              text: val.text,
              orientation: "horizontal",
            },
          };
        }.bind(this)
      ),
    };
  };

  const chartOptionsCompute = computed((): ApexOptions => {
    let yaxis: ApexYAxis[];
    let tooltip: ApexTooltip;
    if (
      !(
        (includes(playTogetherMetrics, getPerformanceMetricOne.value) &&
          includes(playTogetherMetrics, getPerformanceMetricTwo.value)) ||
        metricsShareScale(getPerformanceMetricOne, getPerformanceMetricTwo)
      )
    ) {
      yaxis = [
        {
          seriesName: seriesOneHeaders.value,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#36E7A6",
          },
          tickAmount: tickAmountOne.value,
          min: firstMetricMinMax.value.min,
          forceNiceScale: true,
          max: firstMetricMinMax.value.max,
          labels: {
            show: true,
            formatter: chartFormatter(getPerformanceMetricOne),
          },
        },
        {
          seriesName: seriesTwoHeaders.value,
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#2799FF",
          },
          tickAmount: tickAmountTwo.value,
          min: secondMetricMinMax.value.min,
          forceNiceScale: true,
          max: secondMetricMinMax.value.max,
          labels: {
            formatter: chartFormatter(getPerformanceMetricTwo),
          },
        },
      ];
      tooltip = {
        y: [
          {
            formatter: chartFormatter(getPerformanceMetricOne),
          },
          {
            formatter: chartFormatter(getPerformanceMetricTwo),
          },
        ],
      };
    } else {
      yaxis = [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#36E7A6",
          },
          tickAmount: tickAmountBoth.value,
          min: 0,
          forceNiceScale: true,
          max: Math.max(
            get(
              maxBy(data.value, getPerformanceMetricOne.value),
              getPerformanceMetricOne.value
            ),
            get(
              maxBy(data.value, getPerformanceMetricTwo.value),
              getPerformanceMetricTwo.value
            ),
            1
          ),
          labels: {
            formatter: function (value) {
              return fetchMetricValue(getPerformanceMetricOne.value, value);
            },
          },
        },
        {
          show: false,
          showAlways: false,
          tickAmount: tickAmountBoth.value,
          min: 0,
          forceNiceScale: true,
          max: Math.max(
            get(
              maxBy(data.value, getPerformanceMetricOne.value),
              getPerformanceMetricOne.value
            ),
            get(
              maxBy(data.value, getPerformanceMetricTwo.value),
              getPerformanceMetricTwo.value
            ),
            1
          ),
        },
      ];

      tooltip = {
        y: [
          {
            formatter: chartFormatter(getPerformanceMetricOne),
          },
          {
            formatter: chartFormatter(getPerformanceMetricTwo),
          },
        ],
      };
    }
    return {
      annotations: annotations(),
      chart: {
        id: "performance-taxonomy-" + currentTaxonomyInteger.value + "-chart",
        stacked: false,
        toolbar: {
          show: true,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 300,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
        },
        zoom: {
          enabled: true,
          type: "x",
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      colors: ["#36E7A6", "#2799FF"],
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      tooltip: tooltip,
      xaxis: {
        type: "datetime",
        categories: [],
      },
      yaxis: yaxis,
    };
  });
  return {
    metricsShareScale,
    annotations,
    metrics: readonly(metricsArray),
    chartOptionsCompute,
    showAnnotationTable,
    computedSeries,
  };
};
