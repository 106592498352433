import { computed, Ref } from "vue";
// eslint-disable-next-line no-unused-vars
export type emitFunction<T> = { (e: string, val: T): void };

export const UseFixedCosts = () => {
  const computedWatcherHookForEmits = <T>(
    getter: Ref<T>,
    fn: emitFunction<T>,
    action: string
  ) =>
    computed({
      get(): T {
        return getter.value;
      },
      set(val: T) {
        fn(action, val);
      },
    });

  return {
    computedWatcherHookForEmits,
  };
};
