import { onMounted, ref } from "vue";
import axios, { AxiosError } from "axios";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert2";

interface IAxiosErrorHelper {
  open: boolean;
  ["403"]: {
    redirect: string;
    text: string;
  };
}

interface IAxiosErrorResponse {
  response: {
    status: number;
  };
}

export const UseAxiosErrorHelper = () => {
  const route = useRoute();
  const router = useRouter();

  const axiosErrorConfiguration = ref<IAxiosErrorHelper>({
    open: false,
    "403": {
      redirect: "logout",
      text: "You don't have access to this resource",
    },
  });

  const set403Redirect = (redirect: string) => {
    axiosErrorConfiguration.value["403"].redirect = redirect;
  };

  const set403Text = (text: string) => {
    axiosErrorConfiguration.value["403"].text = text;
  };

  const interceptorHelper = (
    title: string,
    text: string,
    useText: boolean,
    pushNum: boolean
  ) => {
    if (!axiosErrorConfiguration.value.open) {
      axiosErrorConfiguration.value.open = true;
      swal
        .fire({
          type: "error",
          title,
          text: useText ? axiosErrorConfiguration.value["403"].text : text,
          confirmButtonText: "Okay",
          allowOutsideClick: false,
          allowEscapeKey: false,
          footer: "If the problem persists, let us know",
        })
        .then(() => {
          axiosErrorConfiguration.value.open = false;
          pushNum
            ? router.go(0)
            : router.push(axiosErrorConfiguration.value["403"].redirect);
        });
    }
  };

  const setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError<IAxiosErrorResponse>) => {
        if (error.isAxiosError) {
          switch (error.response?.status) {
            case 401:
              return route.path !== "login"
                ? router.push("/logout")
                : router.push("/login");
            case 403:
              return interceptorHelper(
                "Forbidden",
                axiosErrorConfiguration.value["403"].text,
                true,
                false
              );
            case 500:
            case 501:
            case 502:
            case 503:
            case 504:
            case 505:
            case 506:
            case 507:
            case 508:
            case 509:
            case 510:
            case 511:
            case 529:
            case 530:
            case 561:
            case 598:
            case 599:
              return interceptorHelper(
                "Whoops",
                `There's been a server error trying to complete your request.`,
                false,
                true
              );
            default:
              return interceptorHelper(
                "Whoops",
                "Somethings gone wrong",
                false,
                true
              );
          }
        }
      }
    );
  };

  onMounted(() => {
    setInterceptors();
  });

  return {
    axiosErrorConfiguration,
    set403Redirect,
    set403Text,
    setInterceptors,
  };
};
