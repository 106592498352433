<template>
  <div>
    <outer-section>
      <div class="box contains-loader">
        <o-loading :full-page="false" :active="loading || dataLoading"></o-loading>
        <div class="is-clearfix">
          <button
            class="button is-primary is-pulled-right"
            :disabled="!canSaveReport || dataLoading"
            @click="saveReportData"
          >
            Save Report
          </button>
          <breadcrumb :previewing="false" v-model:report-name="nameData"></breadcrumb>
        </div>
        <br />

        <template v-if="!loading">
          <report
            v-model:name="nameData"
            v-model:level="levelData"
            v-model:rules="rulesData"
            v-model:channels="channelData"
            :preview="false"
            v-model:loading="dataLoading"
          ></report>
        </template>
      </div>
    </outer-section>
  </div>
</template>

<script>
import Report from "./Report";
import Breadcrumb from "./Configuration/Breadcrumb";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseAxiosErrorHelper } from "../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseReport } from "./Hooks/UseReport";

export default {
  title() {
    return this.nameData + " (Custom)";
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { set403Redirect, set403Text } = UseAxiosErrorHelper();
    const { setReportLevel } = UseReport();
    return {
      getApiBaseUrl,
      set403Redirect,
      set403Text,
      setReportLevel,
    };
  },
  computed: {
    reportUrl() {
      return this.getApiBaseUrl + "/reports/configuration/" + this.report;
    },
    canSaveReport() {
      return (
        this.nameData !== this.previousName ||
        this.levelData !== this.previousLevel ||
        !_.isEqual(this.rulesData, this.previousRules) ||
        !_.isEqual(this.channelData, this.previousChannels)
      );
    },
  },
  components: { Breadcrumb, Report },
  props: {
    report: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameData: "",
      previousName: "",
      levelData: "",
      previousLevel: "",
      rulesData: [],
      previousRules: [],
      channelData: [],
      previousChannels: [],
      loading: true,
      dataLoading: false,
    };
  },
  methods: {
    invalid() {
      this.$router.push("/reports?invalid=true");
    },
    loadReportData() {
      this.loading = true;
      window.axios
        .get(this.reportUrl)
        .then(({ data }) => {
          this.nameData = data.data.name;
          this.levelData = data.data.level;
          this.rulesData = data.data.rules;
          this.channelData = _.get(data.data.meta, "channels", []);
          this.setReportLevel(data.data.level);
          this.matchConfig();

          this.loading = false;
          this.dataLoading = true;
        })
        .catch(() => {
          this.channelData = [];
          this.loading = false;
          this.dataLoading = false;
        });
    },
    saveReportData() {
      this.dataLoading = true;
      axios
        .patch(this.reportUrl, {
          name: this.nameData,
          level: this.levelData,
          rules: this.rulesData,
          meta: {
            channels: this.channelData,
          },
        })
        .then(() => {
          this.matchConfig();

          swal.fire({
            type: "success",
            title: "Saved",
            text: "Your report configuration has been saved",
          });

          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    matchConfig() {
      this.previousName = this.nameData;
      this.previousLevel = this.levelData;
      this.previousRules = _.cloneDeep(this.rulesData);
      this.previousChannels = _.cloneDeep(this.channelData);
    },
  },
  mounted() {
    this.set403Redirect("/reports");
    this.set403Text("You don't have access to this report");

    this.loadReportData();
  },
};
</script>
