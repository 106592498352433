import { UseSegmentComparisonStore } from "./../../../Modules/State/Stores/SegmentComparisonStore";
import { storeToRefs } from "pinia";
import { segmentComparisonLoadAsyncData } from "./../../../../generics/generics";
import {
  IChartObject,
  TChartData,
  TAllTableHeaderValues,
  IGeTAllTableHeaders,
} from "../../../../typings/DataFetching";
import { computed, ref, toRef } from "vue";

export const SegmentComparisonTableAndChartaData = () => {
  const { getAllFetchUrls } = storeToRefs(UseSegmentComparisonStore());

  //State
  const tableData = ref<
    { tag: string; table: TAllTableHeaderValues<IGeTAllTableHeaders>[] }[]
  >([]);
  const chartData = ref<IChartObject[]>([]);
  const allFetch = computed(() =>
    getAllFetchUrls.value.map((url) => ({
      tag: url.tag,
      table: segmentComparisonLoadAsyncData<
        TAllTableHeaderValues<IGeTAllTableHeaders>,
        IGeTAllTableHeaders
      >()(toRef(url, "table")),
      chart: segmentComparisonLoadAsyncData<TChartData, IGeTAllTableHeaders>()(
        toRef(url, "chart")
      ),
    }))
  );

  //Actions
  const setTableData = (
    arr: { tag: string; table: TAllTableHeaderValues<IGeTAllTableHeaders>[] }[]
  ) => {
    tableData.value = arr;
  };

  const setChartData = (arr: IChartObject[]) => {
    chartData.value = arr;
  };

  return {
    tableData,
    chartData,
    allFetch,
    setTableData,
    setChartData,
  };
};
