<template>
  <footer class="tw-pt-12 tw-pb-24 tw-px-12">
    <div class="tw-text-center">
      <p>
        <strong>SingleView</strong>
        &copy; 2019 - {{ CURRENT_YEAR }}
        <br />
        Version {{ VERSION }}
      </p>
    </div>
  </footer>
</template>

<script>
// const version = require("../../../../public/version.md").default;

export default {
  created() {
    this.CURRENT_YEAR = new Date().getFullYear();
    this.VERSION = '1.2.3';
  },
};
</script>
