import {
  IGetAllColumns,
  TAllTableHeaderValues,
  TChartData,
} from "../../../typings/DataFetching";
import { Ref } from "vue";
import { UseArrayHelper } from "../Functional/UseArrayHelper";
const { checkMultipleArrayLengths, checkSingleObject } = UseArrayHelper();
export const UseMathsHelper = () => {
  const dryFunctionMinMax = <T extends TChartData, U extends TChartData>(
    m: number[],
    metric: Ref<string>,
    top: T,
    bottom: U
  ) => {
    const topChecked = checkSingleObject(top) ? top.data : [];
    const bottomChecked = checkSingleObject(bottom) ? bottom.data : [];
    const availableArrays = checkMultipleArrayLengths([
      topChecked,
      bottomChecked,
    ]);

    if (!availableArrays.length) {
      return {
        max: 1,
        min: 0,
      };
    }

    availableArrays[0].forEach((list, i) => {
      m.push(
        list[metric.value] +
          (availableArrays[1] && availableArrays[1].length
            ? availableArrays[1][i][metric.value]
            : 0)
      );
    });
    return {
      max: Math.max(...m),
      min: Math.min(...m),
    };
  };

  const tickAmountHelper = (
    limit: number,
    maximumFirst: number[],
    maximumSecond: number[]
  ) => {
    const max = Math.max(Math.max(...maximumFirst), Math.max(...maximumSecond));
    if (max < limit) {
      return Math.ceil(max);
    }
    return limit;
  };

  const tickAmountTwoDatasources = <T extends TChartData, U extends TChartData>(
    metricOne: Ref<string>,
    top: T,
    bottom: U,
    limit: number
  ): number => {
    const maximumFirst = checkSingleObject(top)
      ? top.data.map((d) => +d[metricOne.value])
      : [0];
    const maximumSecond = checkSingleObject(bottom)
      ? bottom.data.map((d) => +d[metricOne.value])
      : [0];
    return tickAmountHelper(limit, maximumFirst, maximumSecond);
  };

  const tickAmountSingleDataSource = (
    chartData: Ref<TAllTableHeaderValues<IGetAllColumns>[]>,
    metricOne: Ref<string>,
    metricTwo: Ref<string>,
    limit: number
  ) => {
    if (chartData.value) {
      const maximumFirst = chartData.value.map((d) => +d[metricOne.value]);
      const maximumSecond = chartData.value.map((d) => +d[metricTwo.value]);
      return tickAmountHelper(limit, maximumFirst, maximumSecond);
    }
    return limit;
  };

  return {
    dryFunctionMinMax,
    tickAmountSingleDataSource,
    tickAmountTwoDatasources,
  };
};
