import { UseMetricHelper } from "../Functional/UseMetricHelper";
import { IGetAllColumns } from "../../../typings/DataFetching";
import { Ref } from "vue";
import { forEach, assign, first } from "lodash";

export const UseChartHelper = () => {
  const { fetchMetricValue } = UseMetricHelper();

  const chartFormatter =
    (metricParam: Ref<string>) =>
    (value: number): string => {
      if (!metricParam.value) {
        return value.toString();
      }
      return fetchMetricValue(metricParam.value, value);
    };
  const seriesHeader = <T extends IGetAllColumns>(
    headers: Ref<T>,
    metric: Ref<string>
  ) => {
    if (headers.value) {
      return headers.value[metric.value] as keyof T;
    }
    return metric.value;
  };
  const annotationPoints = <T>(val: T[]) => {
    return forEach(val, function (innerVal, index, all) {
      assign(innerVal, {
        offset: index * 20,
        first: first(all) == innerVal ? true : false,
      });
    });
  };

  const metricsShareScale = (
    metricOne: Ref<string>,
    metricTwo: Ref<string>
  ): boolean => {
    let rtn = false;
    let sharing = [
      ["first_click_sales", "last_click_sales", "attributed_sales"],
      [
        "first_click_conversion_rate",
        "last_click_conversion_rate",
        "attributed_conversion_rate",
      ],
    ];
    sharing.forEach((val) => {
      if (val.includes(metricOne.value) && val.includes(metricTwo.value)) {
        rtn = true;
      }
    });
    return rtn;
  };
  return {
    chartFormatter,
    seriesHeader,
    annotationPoints,
    metricsShareScale,
  };
};
