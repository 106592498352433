import { UseReportSettingsStore } from "./../../../Modules/State/Stores/ReportSettingsStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
export const UseBreakdown = () => {
  const {
    getPerformanceMetricOne,
    getPerformanceMetricTwo,
    getPerformanceChartType,
    getPerformanceChartInterval,
  } = storeToRefs(UseReportSettingsStore());
  const {
    setPerformanceMetricOne,
    setPerformanceMetricTwo,
    setPerformanceChartType,
    setPerformanceChartInterval,
  } = UseReportSettingsStore();

  const page = ref(1);
  const perPage = ref(6);
  const sortDirection = ref("desc");

  const firstMetric = computed({
    get() {
      return getPerformanceMetricOne.value;
    },
    set(val: string) {
      setPerformanceMetricOne(val);
    },
  });

  const secondMetric = computed({
    get() {
      return getPerformanceMetricTwo.value;
    },
    set(val: string) {
      setPerformanceMetricTwo(val);
    },
  });

  const monetaryMetrics = computed(() => {
    return ["total_cost", "attributed_revenue", "cpa", "roas"];
  });
  const typeOfChart = computed({
    get() {
      return getPerformanceChartType.value;
    },
    set(val: string) {
      setPerformanceChartType(val);
    },
  });
  const interval = computed({
    get() {
      return getPerformanceChartInterval.value;
    },
    set(val: string) {
      setPerformanceChartInterval(val);
    },
  });

  return {
    page,
    perPage,
    sortDirection,
    firstMetric,
    secondMetric,
    monetaryMetrics,
    typeOfChart,
    interval,
  };
};
