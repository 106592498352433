<template>
  <div>
    <o-field grouped class="is-hidden-mobile" group-multiline>
      <table-columns
        :columns="columns"
        :available-columns="availableColumns"
        :column-headers="headers"
      />
      <comparison-dates />
    </o-field>
    <o-loading v-model:active="loading"></o-loading>
    <o-table
      :data="tableData"
      paginated
      v-model:current-page="currentPage"
      pagination-position="both"
      per-page="10"
      default-sort="total_funnel_revenue"
      default-sort-direction="desc"
      ref="table"
    >
      <o-table-column
        :field="`taxonomy_${taxonomy}`"
        label="Taxonomy"
        sortable
        v-slot="props"
      >
        <router-link
          :to="linkBuilder(props.row.location)"
          v-if="linkBuilder && props.row.location.length < 4"
        >
          {{ props.row.location.slice(-1)[0] }}
        </router-link>
        <span v-else>{{ props.row.location.slice(-1)[0] }}</span>
      </o-table-column>
      <o-table-column
        field="upper_solo_revenue"
        label="Solo + Upper Revenue (Initiator)"
        numeric
        sortable
        :visible="columns.includes('upper_solo_revenue')"
        v-slot="props"
        position="right"
      >
        <performance-field
          :current="props.row.upper_solo_revenue"
          :previous="
            fetchComparisonMetric(
              props.row.location[props.row.location.length - 1],
              'upper_solo_revenue'
            )
          "
          monetary
          rounded
          right
          :comparing="comparing"
        >
        </performance-field>
      </o-table-column>
      <o-table-column
        field="mid_lower_revenue"
        label="Mid + Lower Revenue"
        numeric
        sortable
        :visible="columns.includes('mid_lower_revenue')"
        v-slot="props"
        position="right"
      >
        <performance-field
          :current="props.row.mid_lower_revenue"
          :previous="
            fetchComparisonMetric(
              props.row.location[props.row.location.length - 1],
              'mid_lower_revenue'
            )
          "
          monetary
          rounded
          right
          :comparing="comparing"
        >
        </performance-field>
      </o-table-column>
      <o-table-column
        field="total_funnel_revenue"
        label="Total Revenue"
        numeric
        sortable
        :visible="columns.includes('total_funnel_revenue')"
        v-slot="props"
        position="right"
      >
        <performance-field
          :current="props.row.total_funnel_revenue"
          :previous="
            fetchComparisonMetric(
              props.row.location[props.row.location.length - 1],
              'total_funnel_revenue'
            )
          "
          monetary
          rounded
          right
          :comparing="comparing"
        >
        </performance-field>
      </o-table-column>
      <o-table-column
        field="upper_solo_revenue_percent"
        label="Initiator Percent"
        numeric
        sortable
        :visible="columns.includes('upper_solo_revenue_percent')"
        v-slot="props"
        position="right"
      >
        <performance-field
          :current="props.row.upper_solo_revenue_percent"
          :previous="
            fetchComparisonMetric(
              props.row.location[props.row.location.length - 1],
              'upper_solo_revenue_percent'
            )
          "
          percentage
          rounded
          right
          :comparing="comparing"
        >
        </performance-field>
      </o-table-column>
      <o-table-column
        field="mid_lower_revenue_percent"
        label="Mid + Lower Revenue Percent"
        numeric
        sortable
        :visible="columns.includes('mid_lower_revenue_percent')"
        v-slot="props"
        position="right"
      >
        <performance-field
          :current="props.row.mid_lower_revenue_percent"
          :previous="
            fetchComparisonMetric(
              props.row.location[props.row.location.length - 1],
              'mid_lower_revenue_percent'
            )
          "
          percentage
          rounded
          right
          :comparing="comparing"
        >
        </performance-field>
      </o-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </o-table>
  </div>
</template>

<script>
import TableColumns from "../TableColumns";
import TableControls from "../Performance/Table/Controls.vue";
import PerformanceField from "../Performance/Table/PerformanceField.vue";
import ComparisonDates from "../Pieces/ComparisonDates.vue";
import { UseDateStore } from "../State/Stores/DateStore";
import { UseComparisonHelper } from "../../Helper/Stateful/UseComparisonHelper";
import { storeToRefs } from "pinia";
export default {
  components: {
    TableColumns,
    TableControls,
    PerformanceField,
    ComparisonDates,
  },
  props: {
    comparisonUrl: {
      type: String,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
      validator: (dataArray) =>
        dataArray.every(
          (tableEntry) =>
            "location" in tableEntry &&
            Array.isArray(tableEntry.location) &&
            tableEntry.location.length > 0 &&
            tableEntry.location.length <= 4 &&
            "upper_solo_revenue" in tableEntry &&
            "mid_lower_revenue" in tableEntry &&
            "total_funnel_revenue" in tableEntry &&
            "upper_solo_revenue_percent" in tableEntry &&
            "mid_lower_revenue_percent" in tableEntry
        ),
    },
    linkBuilder: {
      type: Function,
      required: false,
      default: null,
    },
    taxonomy: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { comparing, comparisonDates, init } = UseComparisonHelper();
    return {
      getDateFrom,
      getDateTo,
      init,
      comparing,
      comparisonDates,
    };
  },
  data: () => ({
    availableColumns: [
      "upper_solo_revenue",
      "mid_lower_revenue",
      "total_funnel_revenue",
      "upper_solo_revenue_percent",
      "mid_lower_revenue_percent",
    ],
    loading: false,
    comparisonData: {},
    headers: {
      upper_solo_revenue: "Upper + Solo Revenue",
      mid_lower_revenue: "Mid + Lower Revenue",
      total_funnel_revenue: "Total Revenue",
      upper_solo_revenue_percent: "Initiator Percent",
      mid_lower_revenue_percent: "Mid + Lower Revenue Percent",
    },
    currentPage: 1,
  }),
  watch: {
    comparisonUrl() {
      if (this.comparing) {
        this.loadComparisonData();
      }
    },
    comparing() {
      this.loadComparisonData();
      if (!this.comparing) {
        this.init();
      }
    },
    comparisonDates() {
      this.loadComparisonData();
    },
    getDateFrom() {
      this.init();
    },
    getDateTo() {
      this.init();
    },
  },
  methods: {
    formatComparisonData(data) {
      const dataArr = Object.values(data);
      if (!dataArr) return [];
      const formatted = dataArr.map((d) => ({
        location: d.taxonomy_4 || d.taxonomy_3 || d.taxonomy_2 || d.taxonomy_1,
        upper_solo_revenue: d.upper_funnel_revenue + d.solo_revenue,
        mid_lower_revenue: d.mid_funnel_revenue + d.lower_funnel_revenue,
        total_funnel_revenue:
          d.upper_funnel_revenue +
          d.mid_funnel_revenue +
          d.lower_funnel_revenue +
          d.solo_revenue,
        upper_solo_revenue_percent:
          d.upper_funnel_revenue_percent + d.solo_revenue_percent,
        mid_lower_revenue_percent:
          d.mid_funnel_revenue_percent + d.lower_funnel_revenue_percent,
      }));
      return _.keyBy(formatted, "location");
    },
    loadComparisonData() {
      if (!this.comparing) {
        this.comparisonData = {};
        return;
      }
      this.loading = true;
      axios
        .get(
          this.comparisonUrl +
            "?date_from=" +
            this.comparisonDates.start +
            "&date_to=" +
            this.comparisonDates.end
        )
        .then(({ data }) => {
          this.comparisonData = this.formatComparisonData(data.data);
          this.loading = false;
        })
        .catch(() => {
          this.comparisonData = {};
        });
    },
    fetchComparisonMetric(taxonomy, field) {
      return _.get(this.comparisonData, [taxonomy, field], NaN);
    },
  },
  mounted() {
    this.init();
    this.$watch(
      () => this.$refs.table.visibleData,
      (val) => {
        this.$emit("update:visibleData", val || []);
      },
      {
        immediate: true,
      }
    );
  },
};
</script>
