<template>
  <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
    <ul>
      <!--<li v-if="!previewing">-->
      <li>
        <router-link to="/reports">
          All Reports
        </router-link>
      </li>
      <li :class="{ 'is-active': true }">
        <a>{{ reportName }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    previewing: false,
    reportName: "",
  },
};
</script>
