<template>
  <div>
    <section class="hero is-fullheight">
      <div class="notices is-top">
        <template v-for="message in getSystemMessages">
          <system-message :message="message"></system-message>
        </template>
      </div>
      <div class="hero-body">
        <div class="container has-text-centered">
          <div class="column is-4 is-offset-4">
            <img src="/singleview_logo.png" class="is-centered" width="200" />
            <br />
            <br />
            <p class="subtitle has-text-grey has-text-weight-light" v-show="!submitted">
              Your email address
            </p>
            <form class="box" @submit.prevent v-if="!submitted">
              <section>
                <o-field>
                  <o-input
                    placeholder="Email"
                    type="email"
                    icon="email"
                    v-model="email"
                    ref="emailValid"
                    lazy
                  >
                  </o-input>
                </o-field>
                <button
                  :class="[
                    'button',
                    'is-block',
                    'is-info',
                    'is-fullwidth',
                    { 'is-loading': loading },
                  ]"
                  type="submit"
                  @click="submit"
                >
                  Submit
                </button>
              </section>
              <!--<br/>-->
              <span class="instructions"></span>
            </form>
            <div class="box" v-else>
              <p class="subtitle">
                Thank you for submitting your email address. If your email is in our
                system please wait for a message with a link to change your password. If
                you are not automatically redirected to the login page then please click
                <router-link to="/login">here</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SystemMessage from "../../Modules/Auth/SystemMessage";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
//import axios from 'axios';

export default {
  name: "ForgottenPassword",
  title: "Recover Password",
  components: { SystemMessage },
  setup() {
    const { getApiBaseUrl, getSystemMessages } = storeToRefs(UseMainStore());
    const { loadSystemMessages } = UseMainStore();

    return {
      getApiBaseUrl,
      getSystemMessages,
      loadSystemMessages,
    };
  },
  data() {
    return {
      email: "",
      loading: false,
      submitted: false,
      isValid: true,
    };
  },
  methods: {
    submit() {
      if (!this.$refs.emailValid.checkHtml5Validity()) {
        this.isValid = false;
        return;
      } else {
        this.isValid = true;
      }

      if (this.isValid) {
        this.loading = true;
        try {
          axios({
            method: "post",
            url: this.getApiBaseUrl + "/auth/password/request-reset",
            headers: {
              Accept: "application/json",
            },
            data: { email: this.email },
          })
            .then(() => {
              this.loading = false;
              this.submitted = true;
              setTimeout(() => {
                this.$router.push("/login");
              }, 4000);
            })
            .catch((err) => console.log("From Axios ", err.response));
        } catch (err) {
          console.log("From Try-Catch ", err);
          setTimeout(() => {
            this.$router.push("/login");
          }, 5000);
        }
        this.isValid = true;
      }
    },
  },
  mounted() {
    this.loadSystemMessages();
  },
};
</script>

<style>
.instructions {
  font-size: 0.8rem;
  display: inline-block;
  margin-top: 1.5rem;
}
</style>
