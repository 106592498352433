<template>
  <outer-section>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="has-text-centered">
            <h1 class="title">There's nothing here</h1>
            <br /><br />
            <img
              src="https://s3.eu-west-2.amazonaws.com/singleview-assets/images/illustrations/taken_2799ff.svg"
              width="400px"
            />
            <br /><br /><br />
            <h2 class="subtitle">
              Try
              <a @click="$router.go(-1)">going back 🤷‍♂️</a> or
              <router-link to="/">going home 🏠</router-link>
            </h2>
            <br /><br /><br /><br />
          </div>
        </div>
      </div>
    </section>
  </outer-section>
</template>

<script>
export default {
  title: "Page not found",
};
</script>
