<template>
  <div class="column is-full">
    <div class="box">
      <div class="table-controls">
        <table-columns
          :columns="columns"
          :available-columns="availableColumns"
          :column-headers="tableHeaders"
        />
      </div>

      <o-table
        ref="funnelTable"
        class="has-sticky-table-headers"
        hoverable
        :data="formattedData"
        per-page="8"
        :default-sort="[getFunnelSortMetric, getFunnelSortDirection]"
        :default-sort-direction="getFunnelSortDirection"
        @sort="onSort"
      >
        <o-table-column
          :field="`taxonomy_${taxonomyLevel}`"
          :label="tableHeaders[`taxonomy_${taxonomyLevel}`]"
          sortable
          v-slot="props"
        >
          <router-link
            v-if="linkToTaxonomy && props.row.location.length < 4"
            :to="linkToTaxonomy(props.row)"
          >
            {{ props.row.location.slice(-1)[0] }}
          </router-link>
          <span v-else>{{ props.row.location.slice(-1)[0] }}</span>
        </o-table-column>
        <o-table-column
          field="solo_revenue"
          label="Solo Revenue"
          numeric
          position="right"
          sortable
          :visible="columns.includes('solo_revenue')"
          v-slot="props"
        >
          {{ displayMonetaryValueRounded(props.row.solo_revenue) }}
        </o-table-column>
        <o-table-column
          field="solo_revenue_percent"
          label="Solo Revenue Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('solo_revenue_percent')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.solo_revenue_percent) }}%
        </o-table-column>
        <o-table-column
          field="solo_credit"
          label="Solo Attributed Sales"
          numeric
          position="right"
          sortable
          :visible="columns.includes('solo_credit')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.solo_credit) }}
        </o-table-column>
        <o-table-column
          field="solo_funnel_credit_percent"
          label="Solo Attributed Sales Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('solo_funnel_credit_percent')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.solo_credit_percent) }}%
        </o-table-column>
        <o-table-column
          field="upper_funnel_revenue"
          label="Upper Revenue"
          numeric
          position="right"
          sortable
          :visible="columns.includes('upper_funnel_revenue')"
          v-slot="props"
        >
          {{ displayMonetaryValueRounded(props.row.upper_funnel_revenue) }}
        </o-table-column>
        <o-table-column
          field="upper_funnel_revenue_percent"
          label="Upper Revenue Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('upper_funnel_revenue_percent')"
          v-slot="props"
        >
          {{
            displayNumericValueRounded(props.row.upper_funnel_revenue_percent)
          }}%
        </o-table-column>
        <o-table-column
          field="upper_funnel_credit"
          label="Upper Attributed Sales"
          numeric
          position="right"
          sortable
          :visible="columns.includes('upper_funnel_credit')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.upper_funnel_credit) }}
        </o-table-column>
        <o-table-column
          field="upper_funnel_credit_percent"
          label="Upper Attributed Sales Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('upper_funnel_credit_percent')"
          v-slot="props"
        >
          {{
            displayNumericValueRounded(props.row.upper_funnel_credit_percent)
          }}%
        </o-table-column>
        <o-table-column
          field="mid_funnel_revenue"
          label="Mid Revenue"
          numeric
          position="right"
          sortable
          :visible="columns.includes('mid_funnel_revenue')"
          v-slot="props"
        >
          {{ displayMonetaryValueRounded(props.row.mid_funnel_revenue) }}
        </o-table-column>
        <o-table-column
          field="mid_funnel_revenue_percent"
          label="Mid Revenue Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('mid_funnel_revenue_percent')"
          v-slot="props"
        >
          {{
            displayNumericValueRounded(props.row.mid_funnel_revenue_percent)
          }}%
        </o-table-column>
        <o-table-column
          field="mid_funnel_credit"
          label="Mid Attributed Sales"
          numeric
          position="right"
          sortable
          :visible="columns.includes('mid_funnel_credit')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.mid_funnel_credit) }}
        </o-table-column>
        <o-table-column
          field="mid_funnel_credit_percent"
          label="Mid Attributed Sales Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('mid_funnel_credit_percent')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.mid_funnel_credit_percent) }}%
        </o-table-column>
        <o-table-column
          field="lower_funnel_revenue"
          label="Lower Revenue"
          numeric
          position="right"
          sortable
          :visible="columns.includes('lower_funnel_revenue')"
          v-slot="props"
        >
          {{ displayMonetaryValueRounded(props.row.lower_funnel_revenue) }}
        </o-table-column>
        <o-table-column
          field="lower_funnel_revenue_percent"
          label="Lower Revenue Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('lower_funnel_revenue_percent')"
          v-slot="props"
        >
          {{
            displayNumericValueRounded(props.row.lower_funnel_revenue_percent)
          }}%
        </o-table-column>
        <o-table-column
          field="lower_funnel_credit"
          label="Lower Attributed Sales"
          numeric
          position="right"
          sortable
          :visible="columns.includes('lower_funnel_credit')"
          v-slot="props"
        >
          {{ displayNumericValueRounded(props.row.lower_funnel_credit) }}
        </o-table-column>
        <o-table-column
          field="lower_funnel_credit_percent"
          label="Lower Attributed Sales Percent"
          numeric
          position="right"
          sortable
          :visible="columns.includes('lower_funnel_credit_percent')"
          v-slot="props"
        >
          {{
            displayNumericValueRounded(props.row.lower_funnel_credit_percent)
          }}%
        </o-table-column>
        <o-table-column
          field="total_revenue"
          label="Total Revenue"
          numeric
          position="right"
          sortable
          :visible="columns.includes('total_revenue')"
          v-slot="props"
        >
          {{ displayMonetaryValueRounded(props.row.total_revenue) }}
        </o-table-column>
        <o-table-column
          field="total_sales"
          label="Total Attributed Sales"
          numeric
          position="right"
          sortable
          :visible="columns.includes('total_sales')"
          v-slot="props"
        >
          {{ displayNumericValue(props.row.total_sales) }}
        </o-table-column>

        <template #empty>
          <div class="has-text-centered">No records</div>
        </template>
      </o-table>
    </div>
  </div>
</template>

<script>
import TableColumns from "../../TableColumns.vue";
import NewPagination from "../../Breakdown/NewPagination.vue";
import { UseNumberHelper } from "../../../Helper/Functional/UseNumberHelper";
import { storeToRefs } from "pinia";
import { UseReportSettingsStore } from "../../State/Stores/ReportSettingsStore";
import { StaticColumnData } from "../../../StaticData/StaticColumnData";
import { UseColumnModalHelper } from "../../../Helper/Stateful/UseColumnModalHelper";

export default {
  name: "FunnelTable",
  components: { TableColumns, NewPagination },
  props: {
    formattedData: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Object,
      default: () => {},
    },
    taxonomyLevel: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    records: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const {
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValueRounded,
      displayNumericValue,
    } = UseNumberHelper();
    const { getFunnelSortDirection, getFunnelSortMetric } = storeToRefs(
      UseReportSettingsStore()
    );
    const { setFunnelSortDirection, setFunnelSortMetric } =
      UseReportSettingsStore();
    const { funnelAvailableColumns } = StaticColumnData();
    const { columns } = UseColumnModalHelper(funnelAvailableColumns);
    return {
      getFunnelSortDirection,
      getFunnelSortMetric,
      columns,
      availableColumns: funnelAvailableColumns,
      displayMonetaryValue,
      displayMonetaryValueRounded,
      displayNumericValue,
      displayNumericValueRounded,
      setFunnelSortDirection,
      setFunnelSortMetric,
    };
  },
  computed: {
    pageCompute: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    perPageCompute: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      },
    },
    recordsCompute: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },
  },
  methods: {
    linkToTaxonomy(funnel) {
      return (
        "/funnel/taxonomy_one/" +
        this.validateInternalParameter(funnel.taxonomy_1) +
        "/" +
        (funnel.taxonomy_2
          ? "taxonomy_two/" +
            this.validateInternalParameter(funnel.taxonomy_2) +
            "/"
          : "") +
        (funnel.taxonomy_3
          ? "taxonomy_three/" +
            this.validateInternalParameter(funnel.taxonomy_3) +
            "/"
          : "") +
        (funnel.taxonomy_4
          ? "taxonomy_four/" + this.validateInternalParameter(funnel.taxonomy_4)
          : "")
      );
    },
    onSort(field, order) {
      this.setFunnelSortMetric(field);
      this.setFunnelSortDirection(order);
    },
  },
  mounted() {
    this.$watch(
      () => this.$refs.funnelTable.visibleData,
      (val) => {
        this.$emit("update:visibleData", val || []);
      },
      {
        immediate: true,
      }
    );
  },
};
</script>

<style scoped>
.column {
  margin-top: 60px;
}

.table-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>
