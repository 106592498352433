import { UseStringHelper } from "./../../../Helper/Functional/UseStringHelper";
import {
  IComparisonConfiguration,
  IMetaTabularExportedData,
} from "./../Typings/MetaTypes";
import { UseMetricHelper } from "../../../Helper/Functional/UseMetricHelper";
import { includes, get } from "lodash";
import { computed, ComputedRef, Ref, WritableComputedRef } from "vue";

type tProps = {
  meta: Ref<{
    cancelledName: string;
    excludedColumns: string[];
    validName: string;
  }>;
  comparisonConfiguration: ComputedRef<IComparisonConfiguration>;
  columns: WritableComputedRef<string[]>;
  sortField: WritableComputedRef<string>;
  sortOrder: WritableComputedRef<string>;
  metaComparison: WritableComputedRef<string>;
};

let topName: ComputedRef<string>;
let bottomName: ComputedRef<string>;

export const UseMetaTableMethods = ({
  meta,
  comparisonConfiguration,
  columns,
  sortField,
  sortOrder,
  metaComparison,
}: tProps) => {
  const { fetchMetricValue } = UseMetricHelper();
  const { convertNameToHandle } = UseStringHelper();

  const isColumnVisible = (column: string) => {
    return includes(columns.value, column);
  };
  const isColumnAvailable = (column: string) => {
    return !includes(get(meta.value, "excludedColumns", []), column);
  };
  const onSort = (field: string, order: string) => {
    sortField.value = field;
    sortOrder.value = order;
  };
  const calculateTotalsMetaTable = (
    arr: IMetaTabularExportedData[],
    key: string,
    av = false
  ) => {
    const total = arr
      .map((item) => item[key])
      ?.reduce((acc, cur) => {
        return acc + cur;
      }, 0);

    if (!isNaN(total)) {
      return fetchMetricValue(
        key,
        av ? total / arr.length : total,
        convertNameToHandle(bottomName.value),
        convertNameToHandle(topName.value)
      );
    }
    return "-";
  };

  topName = computed((): string => {
    return comparisonConfiguration.value[metaComparison.value].top.name;
  });
  bottomName = computed((): string => {
    return comparisonConfiguration.value[metaComparison.value].bottom.name;
  });

  return {
    isColumnAvailable,
    isColumnVisible,
    onSort,
    calculateTotalsMetaTable,
    topName,
    bottomName,
  };
};
