import { modulesLoading } from "./UseMetaFetchData";
import { computed } from "vue";

export const UseMetaVisualLoadingComputed = () => {
  const chartLoading = computed(() => {
    return modulesLoading.value.top || modulesLoading.value.bottom;
  });
  const pieOneLoading = computed(() => {
    return modulesLoading.value.p1x1 || modulesLoading.value.p1x2;
  });
  const pieTwoLoading = computed(() => {
    return modulesLoading.value.p2x1 || modulesLoading.value.p2x2;
  });
  const tableLoading = computed(() => {
    return pieOneLoading.value || pieTwoLoading.value;
  });
  const visualLoading = computed(() => {
    return chartLoading.value || pieOneLoading.value || pieTwoLoading.value;
  });

  return {
    chartLoading,
    pieOneLoading,
    pieTwoLoading,
    tableLoading,
    visualLoading,
  };
};
