<template>
  <div>
    <outer-section>
      <div class="box contains-loader">
        <o-loading :full-page="false" :active="dataLoading"></o-loading>
        <div class="is-clearfix">
          <button
            :class="[
              'button',
              'is-primary',
              'is-pulled-right',
              { 'is-loading': saveLoading },
            ]"
            @click="save"
          >
            Save Report
          </button>
          <breadcrumb :previewing="true" v-model:report-name="nameData"></breadcrumb>
        </div>
        <br />

        <report-actual
          v-model:name="nameData"
          v-model:level="levelData"
          v-model:rules="rulesData"
          v-model:channels="channelData"
          :preview="true"
          v-model:loading="dataLoading"
        ></report-actual>
      </div>
    </outer-section>
  </div>
</template>

<script>
import ReportActual from "./Report";
import Breadcrumb from "./Configuration/Breadcrumb";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseStringHelper } from "../../Helper/Functional/UseStringHelper";
export default {
  title() {
    return this.name + " (Custom)";
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());

    return {
      getApiBaseUrl,
    };
  },
  computed: {
    changed() {
      return !_.isEqual(this.rulesData, this.previousRules);
    },
    url() {
      return this.getApiBaseUrl + "/reports/configuration";
    },
  },
  components: { Breadcrumb, ReportActual },
  props: {
    name: {
      type: String,
      required: false,
    },
    level: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      nameData: "",
      levelData: "",
      rulesData: [],
      channelData: [],
      previousRules: [],
      dataLoading: false,
      saveLoading: false,
    };
  },
  methods: {
    invalid() {
      this.$router.push("/reports?invalid=true");
    },
    save() {
      this.saveLoading = true;
      axios
        .post(this.url, {
          name: this.nameData,
          level: this.levelData,
          rules: this.rulesData,
          meta: {
            channels: this.channelData,
          },
        })
        .then(({ data }) => {
          this.saveLoading = false;
          this.$router.push("/reports/view/" + data.data.id);
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
  mounted() {
    const { decodeApiParameter } = UseStringHelper();
    let name = "Unnamed Report";
    let level = "taxonomy_1";
    let rules = [];
    // If a report name has been provided, use it
    if (_.isString(this.name)) {
      name = this.name;
    }
    // If a report level has been provided, use it
    if (_.isString(this.level)) {
      level = this.level;
    }
    // There is potential that a single rule has been passed
    if (!Array.isArray(decodeApiParameter(this.rules))) {
      // Scrap that, nothing good has been passed, or nothing at all
      if (!_.isString(this.rules)) {
        this.invalid();
      }
      try {
        JSON.parse(decodeApiParameter(this.rules)).forEach(function (value) {
          rules.push(value);
        });
      } catch (e) {
        this.invalid();
      }
    } else {
      rules = this.rules;
    }
    this.nameData = name;
    this.levelData = level;
    this.rulesData = rules;
    this.previousRules = rules;
  },
};
</script>
