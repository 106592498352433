<template>
  <div class="column is-full">
    <div class="box">
      <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/crossover-analysis">
              Crossover Analysis
            </router-link>
          </li>
          <li class="is-active">Report</li>
        </ul>
      </nav>
      <vue-apex-charts
        ref="crossoverChart"
        type="bar"
        :options="chartOptionsCompute"
        :series="computedSeries"
        height="350"
      ></vue-apex-charts>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import { UseNumberHelper } from '../../../Helper/Functional/UseNumberHelper';

const { displayNumericValue, displayMonetaryValue } = UseNumberHelper();

const props = defineProps({report: Object});

const chartOptionsCompute = computed(() => {
  return {
    chart: {
      type: "bar",
      height: "350",
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "70%"
      }
    },
    xaxis: {
      labels: { show: false },
      categories: ['Average Order Value', 'Average Steps', 'Average Days', 'Average Attr Revenue']
    },
    tooltip: {
      y: {
        formatter: function (val, { dataPointIndex, w }) {
          if (
            w.globals.labels[dataPointIndex] === 'Average Order Value' || 
            w.globals.labels[dataPointIndex] === 'Average Attr Revenue'
          ) {
            return displayMonetaryValue(val);
          }
          return displayNumericValue(val)
        }
      }
    },
    colors: ["#2799FF", "#a9d2fb", "#cccaca"],
  }
})

const computedSeries = computed(() => {
  if (!props.report) return [];
  return [
    {
      name: props.report.entity_1.taxonomy_1,
      data: [
        props.report.entity_1_avg_order_value,
        props.report.entity_1_avg_steps_to_conversion,
        props.report.entity_1_avg_days_to_conversion,
        props.report.entity_1_avg_attributed_revenue
      ]
    },
    {
      name: props.report.entity_2.taxonomy_1,
      data: [
        props.report.entity_2_avg_order_value,
        props.report.entity_2_avg_steps_to_conversion,
        props.report.entity_2_avg_days_to_conversion,
        props.report.entity_2_avg_attributed_revenue
      ]
    },
    {
      name: 'Crossover',
      data: [
        props.report.crossover_avg_order_value,
        props.report.crossover_avg_steps_to_conversion,
        props.report.crossover_avg_days_to_conversion,
        props.report.crossover_avg_attributed_revenue
      ]
    },
  ]
})

</script>

<style scoped>
  .breadcrumb li::before {
    margin-right: 10px;
  }
</style>