<template>
  <OuterSection class="container">
    <h1 class="title">Seasonality Configuration</h1>
    <h3 class="subtitle has-text-grey pt-3">
      {{ prettifyDate(getDateFrom) }} - {{ prettifyDate(getDateTo) }}
    </h3>

    <div class="box">
      <div class="message is-info">
        <div class="message-body">
          Here you can choose a
          <strong>seasonality</strong> for each week. Select a
          <strong>date range</strong>, and choose an option for each week using
          the table below.
        </div>
      </div>
    </div>

    <div class="box is-relative">
      <div class="seasonality-config">
        <transition name="fade" mode="out-in">
          <div key="loader" v-if="loading" class="table-loader">
            <o-loading :full-page="false" active />
          </div>

          <o-table key="data" v-else :data="data" hoverable>
            <template>
              <o-table-column
                field="week"
                label="Week"
                width="100%"
                v-slot="props"
              >
                <span
                  >{{ props.row.start_date }} - {{ props.row.end_date }}</span
                >
              </o-table-column>

              <o-table-column
                field="seasonality"
                label="Seasonality"
                v-slot="props"
              >
                <o-dropdown aria-role="list" position="bottom-left">
                  <template #trigger="{ active }">
                    <o-button
                      class="type-dropdown"
                      size="is-small"
                      :label="getLabel(props.row.seasonality)"
                      :outlined="props.row.seasonality === 'none'"
                      :loading="props.row.loading"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>

                  <o-dropdown-item
                    v-for="(seasonality, value) in seasonality_types"
                    :key="value"
                    aria-role="listitem"
                    :value="seasonality.label"
                    @click="
                      changeSeasonalityForWeek(
                        props.row.start_date,
                        props.row.end_date,
                        value
                      )
                    "
                    >{{ seasonality.label }}</o-dropdown-item
                  >
                  <hr class="dropdown-divider" />
                  <o-dropdown-item
                    aria-role="listitem"
                    @click="
                      changeSeasonalityForWeek(
                        props.row.start_date,
                        props.row.end_date,
                        'none'
                      )
                    "
                    >None</o-dropdown-item
                  >
                </o-dropdown>
              </o-table-column>
            </template>
          </o-table>
        </transition>
      </div>
    </div>
  </OuterSection>
</template>

<script>
import OuterSection from "../../Elements/OuterSection";
import {
  SeasonalityTypes,
  getLabel,
} from "../../Modules/SeasonalityConfiguration/SeasonalityTypes";
import moment from "moment";

import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import { UseDateStore } from "../../Modules/State/Stores/DateStore";
import { UseProgramStore } from "../../Modules/State/Stores/ProgramStore";

export default {
  title() {
    return "Seasonality Configuration";
  },
  name: "SeasonalityConfigurationIndex",
  components: {
    OuterSection,
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());

    return {
      getApiBaseUrl,
      getDateFrom,
      getDateTo,
      getCurrentProgram,
    };
  },
  data() {
    return {
      seasonality_types: SeasonalityTypes,
      data: [],
      loading: false,
    };
  },
  computed: {
    seasonalityRequest() {
      return axios.get(
        `${this.getApiBaseUrl}/programs/${this.getCurrentProgram}/seasonality`,
        {
          params: {
            date_from: this.getDateFrom,
            date_to: this.getDateTo,
          },
        }
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getLabel,
    getData() {
      if (this.loading) return;
      this.loading = true;

      this.seasonalityRequest
        .then(({ data }) => {
          this.data = data.data;
        })
        .catch((error) => {
          swal.fire("Error", `Error fetching data - ${error.message}`, "error");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changeSeasonalityForWeek(start_date, end_date, seasonality) {
      let index = _.findIndex(
        this.data,
        (week) => week.start_date === start_date && week.end_date === end_date
      );

      if (this.data[index].loading) return;
      this.data[index].loading = true;

      axios
        .patch(
          `${this.getApiBaseUrl}/programs/${this.getCurrentProgram}/seasonality`,
          {
            start_date: start_date,
            end_date: end_date,
            programcode: this.getCurrentProgram,
            seasonality: seasonality,
          }
        )
        .then(() => {
          this.data[index].seasonality = seasonality;
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
        })
        .finally(() => {
          this.data[index].loading = false;
        });
    },

    prettifyDate(value) {
      return moment(value, "YYYY-MM-DD").format("Do MMM YYYY");
    },
  },
  watch: {
    seasonalityRequest: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.type-dropdown {
  min-width: 25ch;
  justify-content: space-between;
}

.seasonality-config {
  :deep(.table-wrapper, .o-table__wrapper) {
    overflow-x: unset;
  }
}

:deep(.button-wrapper) {
  width: 100%;
  span {
    flex: 1;
  }

  span:first-child {
    justify-content: flex-start;
    text-align: left;
  }

  span:last-child {
    justify-content: flex-end;
  }
}

.table-loader {
  padding: 40px;
}
</style>
