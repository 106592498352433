<template>
  <o-field>
    <o-input
      placeholder="Search..."
      type="search"
      icon="magnify"
      v-model="searchTerm"
      :expanded="isMobile"
      @keyup.enter.native="submitSearch"
    ></o-input>
    <p class="control">
      <o-button variant="primary" @click="submitSearch">Search</o-button>
    </p>
  </o-field>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    setSearchTerm() {
      this.searchTerm = this.search;
    },
    submitSearch() {
      this.$router.push(
        "/performance/search/" + this.validateInternalParameter(this.searchTerm)
      );
    },
  },
  mounted() {
    this.setSearchTerm();
  },
};
</script>
