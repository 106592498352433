<template>
  <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
    <ul>
      <li :class="{ 'is-active': connection == '' }">
        <router-link to="/account/connections">
          Connections
        </router-link>
      </li>
      <li v-if="connection != ''" class="is-active">
        <router-link :to="'/account/connections/' + connection">
          {{ connectionName }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    connection: {
      type: String,
      default: () => "",
    },
    connectionName: {
      type: String,
      default: () => "",
    },
  },
};
</script>
