<template>
  <div>
    <h2 class="subtitle">{{ text }}</h2>
  </div>
</template>

<script>
export default {
  name: "UpdateGroupHeader",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
