import {
  TGetCurrentProgram,
  TGetCurrentProgramName,
} from "./../Modules/State/Typings/SingleViewStoreTypes";
import { ref, readonly } from "vue";

//Got to keep these outside of the exported function as they need to be shared across 2 components.
const activeBrand = ref<TGetCurrentProgramName>("");
const activeProgram = ref<TGetCurrentProgram>("");

const UseMainNavigation = () => {
  //Active Dropdown State
  const markDropDownActive = ref(false);

  const handleDropDownClick = () =>
    (markDropDownActive.value = !markDropDownActive.value);

  //Program state updater
  const programHandler = (
    newActiveProgram: string = "",
    newActiveBrand: string = ""
  ) => {
    activeBrand.value = newActiveBrand;
    activeProgram.value = newActiveProgram;
    markDropDownActive.value = !markDropDownActive.value;
  };

  return {
    markDropDownActive,
    handleDropDownClick,
    activeBrand: readonly(activeBrand),
    activeProgram: readonly(activeProgram),
    programHandler,
  };
};

export default UseMainNavigation;
