import { Ref } from "vue";
import { IDetailTreeSingle } from "../../../../typings/TableTypes";
import {
  TAllTableHeaders,
  TAllTableHeaderValues,
  IPossibleComparisonHeaders,
} from "../../../../typings/DataFetching";

export const UsePerformanceTableDetails = () => {
  const setOpenedDetails = (
    str: string,
    data: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,
    detailTree: Ref<IDetailTreeSingle>
  ) => {
    data.value.forEach((val) => {
      const taxonomy = val[str] as keyof IPossibleComparisonHeaders;
      detailTree.value[taxonomy] = {
        open: false,
        data: {},
      };
    });
  };

  const toggleTree = (taxonomy: string, tree: Ref<IDetailTreeSingle>) => {
    tree.value[taxonomy].open = !tree.value[taxonomy].open;
  };

  const isTreeOpen = (
    taxonomy: string,
    tree: Ref<IDetailTreeSingle>
  ): boolean => {
    return tree.value[taxonomy].open;
  };
  const detailOpenedFinal =
    (row: TAllTableHeaders) =>
    (taxonomy: string, tree: Ref<IDetailTreeSingle>) => {
      tree.value[row[taxonomy] as string].open = true;
    };

  const detailClosedFinal =
    (row: TAllTableHeaders) =>
    (taxonomy: string, tree: Ref<IDetailTreeSingle>) => {
      tree.value[row[taxonomy] as string].open = false;
    };

  return {
    setOpenedDetails,
    toggleTree,
    isTreeOpen,
    detailOpenedFinal,
    detailClosedFinal,
  };
};
