<template>
  <div>
    <o-notification variant="warning" aria-close-label="Close notification">
      We've changed the way data loads in this page! The date range will now be
      included when loading fixed costs.
    </o-notification>

    <div class="box">
      <div class="is-clearfix">
        <o-field class="is-pulled-right">
          <p class="control">
            <router-link
              to="/account/costs/fixed/create"
              class="button is-primary is-outlined is-pulled-right"
              >Add Fixed Spend</router-link
            >
          </p>
          <p class="control">
            <router-link
              to="/account/costs/fixed/batch"
              class="button is-primary is-outlined is-pulled-right"
              >Batch Import</router-link
            >
          </p>
        </o-field>

        <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
          <ul>
            <li class="is-active">
              <router-link to="/account/costs/fixed">Fixed Spend</router-link>
            </li>
          </ul>
        </nav>
      </div>
      <o-field grouped group-multiline>
        <new-pagination
          v-model:page="page"
          v-model:per-page="perPage"
          v-model:records="sortedData.length"
        ></new-pagination>

        <o-field>
          <o-input
            placeholder="Search..."
            type="search"
            icon="magnify"
            v-model="searchTerm"
          ></o-input>
        </o-field>
      </o-field>

      <o-table
        ref="fixedCostsIndexTable"
        narrowed
        hoverable
        :data="pageData"
        :custom-row-key="id"
        :backend-sorting="true"
        :default-sort-direction="sortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      >
        <!-- Id -->
        <o-table-column field="id" label="ID" sortable v-slot="props">
          <router-link :to="'/account/costs/fixed/' + props.row.id">
            {{ props.row.id }}
          </router-link>
        </o-table-column>

        <!-- Taxonomy 1 -->
        <o-table-column
          field="taxonomy_1"
          label="Taxonomy 1"
          sortable
          v-slot="props"
          >{{ props.row.taxonomy_1 }}</o-table-column
        >

        <!-- Taxonomy 2 -->
        <o-table-column
          field="taxonomy_2"
          label="Taxonomy 2"
          sortable
          v-slot="props"
          >{{ props.row.taxonomy_2 }}</o-table-column
        >

        <!-- Taxonomy 3 -->
        <o-table-column
          field="taxonomy_3"
          label="Taxonomy 3"
          sortable
          v-slot="props"
        >
          <truncate-string-with-tooltip
            :text="props.row.taxonomy_3"
            :maximum-length="30"
          ></truncate-string-with-tooltip>
        </o-table-column>

        <!-- Taxonomy 4 -->
        <o-table-column
          field="taxonomy_4"
          label="Taxonomy 4"
          sortable
          v-slot="props"
        >
          <truncate-string-with-tooltip
            :text="props.row.taxonomy_4"
            :maximum-length="30"
          ></truncate-string-with-tooltip>
        </o-table-column>

        <!-- Cost -->
        <o-table-column
          field="cost"
          label="Spend"
          sortable
          v-slot="props"
          centered
          >{{ displayMonetaryValue(props.row.cost) }}</o-table-column
        >

        <!-- Start Date -->
        <o-table-column
          field="start_date"
          label="Start Date"
          sortable
          v-slot="props"
          centered
          >{{ props.row.start_date }}</o-table-column
        >

        <!-- Start Date -->
        <o-table-column
          field="end_date"
          label="End Date"
          sortable
          v-slot="props"
          centered
          >{{ props.row.end_date }}</o-table-column
        >

        <!-- Frequency -->
        <o-table-column
          field="original_frequency"
          label="Frequency"
          sortable
          v-slot="props"
          centered
        >
          <span class="is-capitalized">
            {{ props.row.original_frequency }}
          </span>
        </o-table-column>

        <!-- Days -->
        <o-table-column
          field="days"
          label="Days"
          sortable
          v-slot="props"
          centered
          >{{ props.row.days }}</o-table-column
        >

        <o-table-column numeric label="Actions" v-slot="props" position="right">
          <o-field class="is-pulled-right">
            <p class="control">
              <router-link
                class="button is-small is-primary"
                :to="'/account/costs/fixed/' + props.row.id"
                >Edit</router-link
              >
            </p>
            <p class="control">
              <button
                class="button is-small is-danger"
                @click="removeCost(props.row.id)"
              >
                Delete
              </button>
            </p>
          </o-field>
        </o-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <template v-if="!loading">
                <p>
                  <o-icon icon="emoticon-sad" size="is-large"></o-icon>
                </p>
                <p>There are no fixed spend configurations to show</p>
              </template>
            </div>
          </section>
        </template>
      </o-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NewPagination from "../../Breakdown/NewPagination";
import TruncateStringWithTooltip from "../../Pieces/TruncateStringWithTooltip.vue";
import { storeToRefs } from "pinia";
import { UseMainStore } from "../../State/SingleViewStorePinia";
import { UseProgramStore } from "../../State/Stores/ProgramStore";
import { UseDateStore } from "../../State/Stores/DateStore";
import { UseAxiosErrorHelper } from "../../../Helper/ThirdParty/UseAxiosErrorHelper";
import { UseNumberHelper } from "../../../Helper/Functional/UseNumberHelper";

export default {
  components: {
    NewPagination,
    TruncateStringWithTooltip,
  },
  setup() {
    const { getApiBaseUrl } = storeToRefs(UseMainStore());
    const { getCurrentProgram } = storeToRefs(UseProgramStore());
    const { getDateFrom, getDateTo } = storeToRefs(UseDateStore());

    UseAxiosErrorHelper();
    const { displayMonetaryValue, displayCurrencySymbol } = UseNumberHelper();

    return {
      getApiBaseUrl,
      getCurrentProgram,
      getDateFrom,
      getDateTo,
      displayMonetaryValue,
      displayCurrencySymbol,
    };
  },
  computed: {
    dateUrl() {
      return (
        this.url +
        "?date_from=" +
        this.getDateFrom +
        "&date_to=" +
        this.getDateTo
      );
    },
    url() {
      return (
        this.getApiBaseUrl +
        "/programs/" +
        this.getCurrentProgram +
        "/fixed-costs"
      );
    },
    loadingCompute: {
      get() {
        return this.loading;
      },
      set(val) {
        return this.$emit("update:loading", val);
      },
    },
    sortedData() {
      return this.data
        ? _.orderBy(
            _.filter(
              this.data,
              function (value) {
                return this.searchTermHelper(value.search);
              }.bind(this)
            ),
            this.sortField,
            this.sortOrder
          )
        : [];
    },
    chunkedData() {
      return _.chunk(this.sortedData, this.perPage);
    },
    pageData() {
      return this.data ? this.chunkedData[this.page - 1] : [];
    },
    //Hack to stop the console shouting about no id referenced in the table as custom-row-key
    id() {
      return this.pageData ? this.pageData[0].id.toString() : "1";
    },
  },
  data() {
    return {
      data: [],
      sortField: "id",
      sortOrder: "desc",
      page: 1,
      perPage: 15,
      searchTerm: "",
    };
  },
  props: {
    loading: false,
  },
  methods: {
    loadAsyncData() {
      this.loadingCompute = true;
      axios
        .get(this.dateUrl)
        .then(({ data }) => {
          this.data = _.map(data.data, function (value) {
            let j = JSON.parse(value.meta);
            _.assign(value, { frequency: _.get(j, "frequency", "") });
            _.assign(value, {
              original_frequency: _.get(j, "original_frequency", ""),
            });
            _.assign(value, {
              days:
                moment(_.get(value, "end_date")).diff(
                  _.get(value, "start_date"),
                  "days"
                ) + 1,
            });
            _.assign(value, {
              search:
                _.get(value, "taxonomy_1", "") +
                " " +
                _.get(value, "taxonomy_2", "") +
                " " +
                _.get(value, "taxonomy_3", "") +
                " " +
                _.get(value, "taxonomy_4", "") +
                " " +
                _.get(value, "id", "") +
                " " +
                _.get(value, "cost", ""),
            });

            return data;
          });

          this.data = _.sortBy(data.data, ["id"]);
          this.loadingCompute = false;
        })
        .catch(() => {
          this.loadingCompute = false;
        });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    removeCost(id) {
      swal
        .fire({
          type: "warning",
          title: "Warning",
          text: "Are you sure you want to remove this spend configuration?",
          confirmButtonText: "I'm sure",
          showCancelButton: true,
        })
        .then(
          function ({ value }) {
            if (value) {
              axios
                .delete(this.url + "/" + id)
                .then(() => {
                  swal.fire({
                    type: "success",
                    title: "Success",
                    text: "The spend configuration was successfully removed",
                  });
                })
                .then(() => {
                  this.loadAsyncData();
                });
            }
          }.bind(this)
        );
    },
    searchTermHelper(val = "") {
      if (this.searchTerm.includes(this.displayCurrencySymbol)) {
        this.searchTerm = this.searchTerm.replace(
          this.displayCurrencySymbol,
          ""
        );
      }
      return val.toLowerCase().includes(this.searchTerm.toLowerCase());
    },
  },
  watch: {
    dateUrl() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>
