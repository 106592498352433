<template>
  <div>
    <outer-section>
      <div class="box">
        <template v-if="isAvailableConnection">
          <account-connections-breadcrumb
            :connection="connection"
            :connection-name="currentConnection.name"
          ></account-connections-breadcrumb>

          <component :is="componentName"></component>
        </template>
        <template v-else>
          <account-connections-breadcrumb
            :connection="connection"
            connection-name="Invalid Connection"
          ></account-connections-breadcrumb>
        </template>
      </div>
    </outer-section>
  </div>
</template>

<script>
import AccountConnectionsBreadcrumb from "../../../Modules/Account/Connections/Breadcrumb";
import ConnectionGoogleAds from "../../../Modules/Account/Connections/GoogleAds";


export default {
  title: "Account Connections",
  components: { AccountConnectionsBreadcrumb, ConnectionGoogleAds },
  computed: {
    isAvailableConnection() {
      return _.has(this.availableConnections(), this.connection);
    },
    currentConnection() {
      return _.get(this.availableConnections(), this.connection, {});
    },
    componentName() {
      return _.get(this.currentConnection, "component", "");
    },
  },
  props: {
    connection: {
      required: true,
    },
  },
  methods: {
    availableConnections() {
      return {
        "google-ads": {
          name: "Google Ads",
          component: "connection-google-ads",
        },
        facebook: {
          name: "Facebook",
          component: "connection-facebook",
        },
      };
    },
  },
};
</script>
