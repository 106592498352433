import { UseProgramStore } from "./Stores/ProgramStore";
import {
  TGetUserId,
  TGetApiBaseUrl,
  TGetThirdPartyConnectorsApiBaseUrl,
  TGetUserAdmin,
  TGetLocale,
  TGetAvailablePrograms,
  TGetCurrency,
  TGetUserMessages,
  TGetSystemMessages,
  TGetDateFrom,
  TGetDateTo,
} from "./Typings/SingleViewStoreTypes";

import CONSTANTS from "./Constants";
import { defineStore } from "pinia";
import moment from "moment";
import { useCookies } from "vue3-cookies";
import { remove } from "lodash";
import axios from "axios";

const { cookies } = useCookies();

export const getDefaultDateFrom = () => moment().subtract(47, "days").toDate();
export const getDefaultDateTo = () => moment().subtract(21, "days").toDate();

interface MainStoreState {
  apiBaseUrl: TGetApiBaseUrl;
  thirdPartyConnectorsApiBase: TGetThirdPartyConnectorsApiBaseUrl;
  userId: TGetUserId;
  userAdmin: TGetUserAdmin;
  userMessages: TGetUserMessages;
  systemMessages: TGetSystemMessages;
  token: string;
  selectedMetrics: string;
  selectedColumns: string[];
  dateFrom: TGetDateFrom;
  dateTo: TGetDateTo;
}

export const UseMainStore = defineStore("main", {
  state: (): MainStoreState => ({
    apiBaseUrl: CONSTANTS.SINGLEVIEW_API_BASE,
    thirdPartyConnectorsApiBase:
      CONSTANTS.SINGLEVIEW_THIRD_PARTY_CONNECTORS_API_BASE,
    userId: localStorage.getItem(CONSTANTS.SINGLEVIEW_USER_ID) || "",
    userAdmin:
      JSON.parse(
        localStorage.getItem(CONSTANTS.SINGLEVIEW_USER_ADMIN) || "{}"
      ) || false,
    userMessages: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_USER_MESSAGES) || "[]"
    ),
    systemMessages: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_SYSTEM_MESSAGES) || "[]"
    ),
    token: localStorage.getItem(CONSTANTS.SINGLEVIEW_TOKEN) || "",
    selectedMetrics: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_SELECTED_METRICS) || "[]"
    ),
    selectedColumns: JSON.parse(
      localStorage.getItem(CONSTANTS.SINGLEVIEW_SELECTED_COLUMNS) || "[]"
    ),
    dateFrom: getDefaultDateFrom(),
    dateTo: getDefaultDateTo(),
  }),
  getters: {
    getUserId: (state): TGetUserId => state.userId,
    getApiBaseUrl: (state): TGetApiBaseUrl => state.apiBaseUrl,
    getThirdPartyConnectorsApiBaseUrl: (
      state
    ): TGetThirdPartyConnectorsApiBaseUrl => state.thirdPartyConnectorsApiBase,
    getToken: (state) => state.token,
    getUserAdmin: (state): Partial<TGetUserAdmin> =>
      state.userAdmin === undefined ? false : state.userAdmin,
    getLocale: (): TGetLocale => {
      const { availablePrograms, currentProgram: currentProgramImport } =
        UseProgramStore();
      if (availablePrograms?.length) {
        const currentProgram = (
          availablePrograms as TGetAvailablePrograms[]
        ).find((prog) => prog.programcode === currentProgramImport);

        if (currentProgram) {
          return currentProgram.locale;
        }
      }
      return "";
    },
    getCurrency: (): TGetCurrency => {
      const { availablePrograms, currentProgram: currentProgramImport } =
        UseProgramStore();
      if (availablePrograms?.length) {
        const currentProgram = (
          availablePrograms as TGetAvailablePrograms[]
        ).find((prog) => prog.programcode === currentProgramImport);

        if (currentProgram) {
          return currentProgram.currency;
        }
      }
      return "";
    },
    getUserMessages: (state) => state.userMessages,
    getSystemMessages: (state) => state.systemMessages,
    getUtcOffset: () =>
      moment().utcOffset() <= 0
        ? Math.abs(moment().utcOffset())
        : -moment().utcOffset(),
  },
  actions: {
    updateToken(token: string) {
      this.token = token;
      localStorage.setItem(CONSTANTS.SINGLEVIEW_TOKEN, token);
      cookies.set(CONSTANTS.SINGLEVIEW_TOKEN, token);
    },
    updateDateFrom(date: Date) {
      this.dateFrom = date;
    },
    updateDateTo(date: Date) {
      this.dateTo = date;
    },
    updateUserId(id: string) {
      this.userId = id;
      localStorage.setItem(CONSTANTS.SINGLEVIEW_USER_ID, id);
    },
    updateUserAdmin(admin: boolean) {
      this.userAdmin = admin;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_USER_ADMIN,
        JSON.stringify(admin)
      );
    },
    updateUserMessages(messages: string[]) {
      this.userMessages = messages;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_USER_MESSAGES,
        JSON.stringify(messages)
      );
    },
    updateSystemMessages(messages: string[]) {
      this.systemMessages = messages;
      localStorage.setItem(
        CONSTANTS.SINGLEVIEW_SYSTEM_MESSAGES,
        JSON.stringify(messages)
      );
    },
    removeUserMessages() {
      this.userMessages = [];
      localStorage.removeItem(CONSTANTS.SINGLEVIEW_USER_MESSAGES);
    },
    removeToken() {
      this.token = "";
      localStorage.removeItem(CONSTANTS.SINGLEVIEW_TOKEN);
    },
    readMessage(id: string) {
      return new Promise((_resolve, reject) => {
        axios
          .get(CONSTANTS.SINGLEVIEW_API_BASE + "/user-messages/" + id + "/read")
          .then(() => {
            let messages = this.getUserMessages;
            remove(messages, function (value) {
              return value[id] === id;
            });

            this.updateUserMessages(messages);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadSystemMessages() {
      return new Promise((_resolve, reject) => {
        axios
          .get(CONSTANTS.SINGLEVIEW_API_BASE + "/system-messages")
          .then((response) => {
            if (response) {
              this.updateSystemMessages(response.data.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
