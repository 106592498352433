import { UseTaxonomyHelper } from "../../../../Helper/Stateful/UseTaxonomyHelper";
import { PerformanceTableAndChartData } from "../../../../Helper/Stateful/PerformanceTableAndChartData";
import { UseReportSettingsStore } from "../../../State/Stores/ReportSettingsStore";
import { StaticColumnData } from "../../../../StaticData/StaticColumnData";
import { storeToRefs } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";
import {
  TAllTableHeaders,
  TAllTableHeaderValues,
} from "../../../../../typings/DataFetching";
import { find, remove, chunk, ceil, forEach, get, orderBy } from "lodash";

const page = ref(1);
const perPage = ref(10);
const records = ref(0);
const { availableColumns } = StaticColumnData();

export const UsePerformanceTable = () => {
  //Set up data
  const {
    tableData: data,
    tableHeaders: headers,
    comparisonData,
  } = PerformanceTableAndChartData();
  const { fetchNavigationUrlHandler } = UseTaxonomyHelper();
  const fetchNavigationUrl = computed(() =>
    fetchNavigationUrlHandler("/performance")
  );

  //Load in Store Getters

  const {
    getPerformanceTableColumns,
    getPerformanceTableSortMetric,
    getPerformanceTableSortDirection,
  } = storeToRefs(UseReportSettingsStore());

  //Load in Store Actions
  const {
    setPerformanceTableColumns,
    setPerformanceTableSortDirection,
    setPerformanceTableSortMetric,
  } = UseReportSettingsStore();

  //Set up computed
  const columns = computed({
    get(): string[] {
      return getPerformanceTableColumns.value;
    },
    set(val: string[]) {
      setPerformanceTableColumns(val);
    },
  });
  const sortField = computed({
    get(): string {
      return getPerformanceTableSortMetric.value;
    },
    set(val: string) {
      setPerformanceTableSortMetric(val);
    },
  });
  const sortOrder = computed({
    get(): string {
      return getPerformanceTableSortDirection.value;
    },
    set(val: string) {
      setPerformanceTableSortDirection(val);
    },
  });

  const sortedData = computed(() => {
    let rtn = data.value;
    let findData = find(data.value, { taxonomy_2: "None" });

    if (findData) {
      remove(rtn, function (val) {
        return val["taxonomy_2"] === "None";
      });

      rtn.push(findData);
    }

    return rtn;
  });

  const chunkedData = computed(() => {
    return chunk(sortedData.value, perPage.value);
  });
  const pageData = computed(() => {
    return data.value ? chunkedData.value[page.value - 1] : [];
  });

  const columnsCompute = computed({
    get(): string[] {
      return getPerformanceTableColumns.value;
    },
    set(val: string[]) {
      setPerformanceTableColumns(val);
    },
  });

  const pageCompute = computed({
    get(): number {
      return page.value;
    },
    set(val: number) {
      page.value = val;
    },
  });

  const perPageCompute = computed({
    get(): number {
      return perPage.value;
    },
    set(val: number) {
      perPage.value = val;
    },
  });

  const recordsCompute = computed({
    get(): number {
      return records.value;
    },
    set(val: number) {
      records.value = val;
    },
  });

  const availableColumnsChunked = computed((): string[] => {
    let chunked = chunk(availableColumns, ceil(availableColumns.length / 2));

    let rtn: string[] = [];

    forEach(chunked[0], function (value, index) {
      rtn.push(value);

      if (get(chunked[1], index)) {
        rtn.push(chunked[1][index]);
      }
    });

    return rtn;
  });

  //Methods
  //Update Comparison dates

  const isColumnVisible = (column: string): boolean => {
    return columns.value.includes(column);
  };

  const onSort = (field: string, order: string) => {
    setPerformanceTableSortMetric(field);
    setPerformanceTableSortDirection(order);
  };

  const fetchComparisonMetric = (taxonomyField: string, field: string) => {
    return get(comparisonData.value, [taxonomyField, field], NaN);
  };

  //For Taxonomy 3 & 4.
  const sortedDataHook = (
    dataSorted: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,
    sortFieldParam: Ref<string>,
    sortOrderParam: Ref<string>
  ) => {
    return computed(() => {
      return dataSorted.value
        ? orderBy(
            dataSorted.value,
            [sortFieldParam.value],
            [sortOrderParam.value as "desc" | "asc"]
          )
        : [];
    });
  };

  const chunkedDataHook = (
    arr: ComputedRef<TAllTableHeaderValues<TAllTableHeaders>[]>,
    amt: Ref<number>
  ) => {
    return computed(() => {
      return chunk(arr.value, amt.value);
    });
  };

  const pageDataHook = (
    arr: ComputedRef<TAllTableHeaderValues<TAllTableHeaders>[][]>,
    dataPage: Ref<TAllTableHeaderValues<TAllTableHeaders>[]>,
    amt: Ref<number>
  ) => {
    return computed(() => {
      return dataPage.value ? arr.value[amt.value - 1] : [];
    });
  };

  return {
    columns,
    headers,
    getPerformanceTableColumns,
    getPerformanceTableSortDirection,
    getPerformanceTableSortMetric,
    sortField,
    sortOrder,
    page,
    perPage,
    records,
    sortedData,
    chunkedData,
    pageData,
    fetchNavigationUrl,
    columnsCompute,
    pageCompute,
    perPageCompute,
    recordsCompute,
    availableColumnsChunked,
    isColumnVisible,
    onSort,
    fetchComparisonMetric,
    sortedDataHook,
    chunkedDataHook,
    pageDataHook,
  };
};
