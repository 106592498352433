import { TCacheKey } from "./../components/Modules/State/Typings/SingleViewStoreTypes";
import axios from "axios";
import { ComputedRef, Ref } from "vue";

export type NestedKeyOf<T> = T extends Array<infer R>
  ? NestedKeyOf<R>
  : T extends object
  ? {
      [K in keyof T]: T extends object ? NestedKeyOf<T[K]> : T[K];
    }[keyof T]
  : never;
export interface IAxiosDataShape<T, U> {
  data: T;
  column_headers: U;
}

export interface IAxiosFunnelDataShape<T, U> extends IAxiosDataShape<T, U> {
  key: TCacheKey;
}

export const performanceLoadAsyncData = <T, U, D>(
  data: Ref<T> | Ref<T[]> | never,
  headers?: Ref<U>,
  tree?: Ref<D> | Ref<{}>
) => {
  return <H extends string, A extends IAxiosDataShape<T[] | T, U>>(
    url: Ref<H>,
    loading?: Ref<boolean>
  ) => {
    if (loading) {
      loading.value = true;
    }
    axios
      .get<A>(url.value)
      .then(({ data: axiosData }) => {
        data.value = Array.isArray(axiosData.data)
          ? axiosData.data
          : [axiosData.data];
        if (headers) {
          headers.value = axiosData.column_headers;
        }
        if (loading) {
          loading.value = false;
        }
      })
      .catch(() => {
        data.value = [];

        if (tree) {
          tree.value = {};
        }
      });
  };
};

export const segmentComparisonLoadAsyncData = <T, U>() => {
  return async <H extends string, A extends IAxiosDataShape<T[], U>>(
    url: Ref<H>,
    loading?: Ref<boolean>
  ) => {
    if (loading) {
      loading.value = true;
    }
    return axios
      .get<A>(url.value)
      .then(async ({ data: axiosData }) => {
        if (loading) {
          loading.value = false;
        }
        return {
          data: axiosData.data,
          headers: axiosData.column_headers,
        };
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
};

const convertData = <T>(arr: T[], key: string) => {
  return (arr || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
};

export const performanceLoadComparisonData = <T, U>(
  comparing: Ref<boolean>,
  data: Ref<T[]> | Ref<{}>
) => {
  return <
    H extends string,
    A extends IAxiosDataShape<T[], U>,
    F extends string
  >(
    url: Ref<H> | ComputedRef<H>,
    loading: Ref<boolean>,
    taxonomy: F
  ) => {
    if (!comparing.value) {
      data.value = {};
      return;
    }
    loading.value = true;
    axios
      .get<A>(url.value)
      .then(({ data: axiosData }) => {
        data.value = convertData<T>(axiosData.data, taxonomy);
        loading.value = false;
      })
      .catch(() => {
        data.value = {};
      });
  };
};
