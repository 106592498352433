<template>
  <div v-if="!isUploadLoading && tableData.length">
    <h2 class="subtitle">Segment Group Status</h2>
    <o-table
      class="has-sticky-table-headers"
      ref="segmentGroupStatusTable"
      narrowed
      hoverable
      :show-detail-icon="true"
      detailed
      detail-key="upload_id"
      :data="tableData"
      v-model:default-sort-direction="sortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <o-table-column
        field="upload_id"
        label="Upload ID"
        sortable
        style="vertical-align: center"
        v-slot="props"
      >
        <p>{{ props.row.upload_id }}</p>
      </o-table-column>
      <o-table-column
        field="segment_group_id"
        label="Segment Group ID"
        sortable
        style="vertical-align: center"
        v-slot="props"
      >
        <p>{{ props.row.segment_group_id }}</p>
      </o-table-column>
      <o-table-column
        field="processing"
        label="Processing Status"
        sortable
        style="vertical-align: center"
        v-slot="props"
      >
        <p>{{ props.row.processing }}</p>
      </o-table-column>
      <o-table-column
        field="errors"
        label="Errors"
        sortable
        style="vertical-align: center"
        v-slot="props"
      >
        <p>{{ props.row.errors }}</p>
      </o-table-column>

      <template #detail="props">
        <tr class="detail-wrapper">
          <td class="detail-grid-wrapper">
            <h3 class="th-wrap details-header">Processing</h3>
            <p class="details-grid">
              Aggregation:
              <span class="processing-values">{{
                props.row.details.processing.aggregation
              }}</span>
            </p>
            <p class="details-grid">
              Dataset:
              <span class="processing-values">{{
                props.row.details.processing.dataset
              }}</span>
            </p>
            <p class="details-grid">
              Update Sales:
              <span class="processing-values">{{
                props.row.details.processing.update_sales
              }}</span>
            </p>
            <p class="details-grid">
              Validation:
              <span class="processing-values">{{
                props.row.details.processing.validation
              }}</span>
            </p>
          </td>
          <td class="detail-grid-wrapper">
            <h3 class="th-wrap details-header">
              Errors
              <a
                role="button"
                v-if="errorChecker(props.row.upload_id).length"
                @click="updateDetail(props.row.upload_id)"
              >
                <o-icon
                  :icon="
                    showDetails[props.row.upload_id] &&
                    showDetails[props.row.upload_id].open === true
                      ? 'chevron-down'
                      : 'chevron-right'
                  "
                ></o-icon>
              </a>
            </h3>
            <p class="details-grid">
              Duplicates:
              <span>{{ props.row.details.validation.duplicates.length }}</span>
            </p>
            <p class="details-grid">
              Not Found:
              <span>{{ props.row.details.validation.not_found.length }}</span>
            </p>
            <p class="details-grid">
              Unassignable With Segment:
              <span>{{
                Object.keys(
                  props.row.details.validation.unassignable_with_segment
                ).length
              }}</span>
            </p>
          </td>
        </tr>
        <tr
          v-if="
            showDetails[props.row.upload_id] &&
            showDetails[props.row.upload_id].open === true
          "
          class="tw-mt-4"
        >
          <td
            v-if="Object.keys(showDetails[props.row.upload_id].errors).length"
            class="error-wrapper"
          >
            <div
              v-for="(error, i) in showDetails[props.row.upload_id].errors"
              :key="i"
            >
              <div v-if="Array.isArray(error.list)">
                <p class="error-title">{{ error.name }}</p>
                <ul>
                  <li v-for="index in error.list" :key="index">
                    {{ value }}
                  </li>
                </ul>
              </div>
              <div v-if="error.list instanceof Object">
                <p class="error-title">{{ error.name }}</p>
                <div
                  v-for="(value, propName, index) in error.list"
                  :key="index"
                >
                  <p class="details-grid">
                    {{ propName }}: <span>{{ value }}</span>
                  </p>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </o-table>
  </div>
  <div v-else>
    <o-loading
      class="is-loader-overlay"
      :is-full-page="false"
      v-model:active="isUploadLoading"
    ></o-loading>
  </div>
</template>

<script>
export default {
  name: "SegmentGroupStatus",
  props: {
    uploads: {
      type: Array,
      default: () => [],
    },
    isUploadLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortField: "order_id",
      sortOrder: "asc",
      hasErrors: [],
      showDetails: {},
      errors: {
        duplicates: "Duplicates",
        not_found: "Not Found",
        unassignable_with_segment: "Unassignable with Segment",
      },
    };
  },
  computed: {
    tableData() {
      return this.uploads.map((u) => ({
        upload_id: u.upload_id,
        segment_group_id: u.segment_group_id.toString(),
        details: u.details,
        processing: this.checkStatus(u.details.processing),
        errors: this.uploadValidation(u.upload_id, u.details.validation),
      }));
    },
  },
  methods: {
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    checkStatus(statusObj = {}) {
      const statusArray = Object.values(statusObj);
      if (!statusArray.length) return;
      return statusArray.every((status) => status === "complete")
        ? "Complete"
        : "Pending";
    },
    uploadValidation(id, { duplicates, not_found, unassignable_with_segment }) {
      const total = {
        duplicates: duplicates.length,
        not_found: not_found.length,
        unassignable_with_segment: Object.keys(unassignable_with_segment)
          .length,
      };
      if (this.itemsTotaller(total) > 0) {
        this.hasErrors.push({
          id,
          keys: Object.keys(total).map((item) => {
            if (total[item] > 0) {
              return {
                key: item,
              };
            } else {
              return null;
            }
          }),
        });
      }
      return this.itemsTotaller(total) > 0 ? "Errors" : "No Errors";
    },
    itemsTotaller(obj = {}) {
      return Object.values(obj).reduce((acc, cur) => parseInt(acc + cur), 0);
    },
    errorChecker(id = "") {
      return this.hasErrors.filter((item) => item.id === id);
    },
    updateDetail(id = "") {
      if (this.showDetails[id]) {
        this.showDetails[id].open = !this.showDetails[id].open;
      } else {
        this.showDetails = {
          [id]: {
            open: true,
            errors:
              this.hasErrors
                .find((item) => item.id === id)
                ?.keys.filter((k) => !!k?.key)
                ?.map((e) => ({
                  name: this.errors[e.key],
                  list: this.uploads.find((u) => u.upload_id === id)?.details
                    .validation[e.key],
                })) || {},
          },
          ...this.showDetails,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
h2.subtitle {
  margin-top: 1.5rem;
}
.details-header {
  font-size: 0.8rem;
  font-weight: 600;
  color: #363636;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.detail-wrapper {
  display: flex;
  flex-wrap: wrap;

  td {
    flex: 1;
  }

  .detail-grid-wrapper {
    @media screen and (max-width: 768px) {
      display: block !important;
    }
  }
}
.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding: 0.25em 0.5em;

  @media screen and (max-width: 768px) {
    text-align: left !important;
  }

  span {
    justify-self: flex-end;
    &.processing-values {
      font-style: italic;
    }
  }
}

.error-wrapper {
  justify-content: flex-start !important;
  text-align: left !important;
  .error-title {
    font-weight: 600;
    color: #363636;
    padding-left: 0.5em;
  }
}
</style>
