import { storeToRefs } from "pinia";
import { UseMainStore } from "../../Modules/State/SingleViewStorePinia";
import getSymbolFromCurrency from "currency-symbol-map";
import { computed } from "vue";

export const UseNumberHelper = () => {
  const { getLocale, getCurrency } = storeToRefs(UseMainStore());
  const displayCurrencySymbol = computed(() => {
    let symbol: string = getSymbolFromCurrency(getCurrency.value);
    return typeof symbol === "undefined" ? "£" : symbol;
  });
  const displayNumericValue = (value: string | number): string => {
    return parseFloat(
      typeof value === "string"
        ? parseFloat(value).toFixed(2)
        : value.toFixed(2)
    ).toLocaleString(getLocale.value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const displayNumericValueOneDp = (value: string | number): string => {
    return parseFloat(
      typeof value === "string"
        ? parseFloat(value).toFixed(1)
        : value.toFixed(1)
    ).toLocaleString(getLocale.value, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  };
  const displayNumericValueRounded = (value: string | number): string => {
    return Math.round(
      typeof value === "string" ? parseFloat(value) : value
    ).toLocaleString(getLocale.value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const displayMonetaryValue = (value: string | number): string => {
    return parseFloat(
      typeof value === "string"
        ? parseFloat(value).toFixed(2)
        : value.toFixed(2)
    ).toLocaleString(getLocale.value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: getCurrency.value,
    });
  };
  const displayMonetaryValueRounded = (value: string | number): string => {
    return Math.round(
      typeof value === "string" ? parseFloat(value) : value
    ).toLocaleString(getLocale.value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "currency",
      currency: getCurrency.value,
    });
  };
  return {
    displayCurrencySymbol,
    displayNumericValue,
    displayMonetaryValueRounded,
    displayNumericValueOneDp,
    displayMonetaryValue,
    displayNumericValueRounded,
  };
};
