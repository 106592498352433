<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">
        <o-input
          v-model="title"
          class="title-input"
          @input="$emit('update:title', $event)"
        />
      </p>
      <div class="action-button-container">
        <slot name="buttons" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.action-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  margin-right: 0.5rem;
}

.index-tag {
  margin-right: 0.5rem;
}

:deep(.title-input) {
  box-shadow: none;
  font-weight: bold;
}
</style>
