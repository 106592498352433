<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section class="section top-section">
    <!-- eslint-disable-next-line vue/no-v-for-template-key -->
    <template
      v-for="message in getUserMessages"
      :key="'userMessage' + message.id"
    >
      <user-message :message="message"></user-message>
    </template>
    <!-- eslint-disable-next-line vue/no-v-for-template-key -->
    <template
      v-for="message in getCurrentProgramMessages"
      :key="'programMessage' + message.id"
    >
      <program-message :message="message"></program-message>
    </template>
    <slot></slot>
  </section>
</template>

<script>
import UserMessage from "../Modules/Auth/UserMessage";
import ProgramMessage from "../Modules/Auth/ProgramMessage";
import { UseMainStore } from "../Modules/State/SingleViewStorePinia";
import { storeToRefs } from "pinia";
import { UseProgramStore } from "../Modules/State/Stores/ProgramStore";

export default {
  components: {
    UserMessage,
    ProgramMessage,
  },
  setup() {
    const { getToken, getUserMessages } = storeToRefs(UseMainStore());
    const { getCurrentProgramMessages } = storeToRefs(UseProgramStore());

    return {
      getToken,
      getUserMessages,
      getCurrentProgramMessages,
    };
  },
};
</script>
