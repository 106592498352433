<template>
  <div>
    <o-table :data="pageData" :loading="loading">
      <o-table-column
        field="conversion_date"
        label="Week Commencing"
        sortable
        v-slot="props"
      >
        {{ props.row.conversion_date }}
      </o-table-column>

      <o-table-column
        field="total_cost"
        label="Spend"
        numeric
        position="right"
        sortable
        v-slot="props"
      >
        <PerformanceField monetary :current="parseFloat(props.row.total_cost)" />
      </o-table-column>

      <o-table-column
        field="attributed_revenue"
        label="Attributed Revenue"
        numeric
        position="right"
        sortable
        v-slot="props"
      >
        <PerformanceField monetary :current="parseFloat(props.row.attributed_revenue)" />
      </o-table-column>
    </o-table>

    <div class="tw-flex tw-justify-end">
      <new-pagination
        v-model:page="page"
        v-model:per-page="perPage"
        :records="data.length"
        :per-page-options="[20, 50, 100]"
      />
    </div>
  </div>
</template>

<script>
import PerformanceField from "../Performance/Table/PerformanceField";
import NewPagination from "../Breakdown/NewPagination";
export default {
  name: "DiminishingReturnsTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 20,
    };
  },

  computed: {
    chunkedData() {
      return _.chunk(this.data, this.perPage);
    },
    pageData() {
      return this.data ? this.chunkedData[this.page - 1] : [];
    },
  },

  components: {
    PerformanceField,
    NewPagination,
  },
};
</script>
