import { TColumns } from "./../../../Modules/State/Typings/SingleViewStoreTypes";
import { UseReportSettingsStore } from "./../../../Modules/State/Stores/ReportSettingsStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
export const UseSegmentComparisonState = () => {
  const {
    getMetaTableSortDirection,
    getMetaTableSortMetric,
    getMetaTableColumns,
  } = storeToRefs(UseReportSettingsStore());
  const {
    setMetaTableSortDirection,
    setMetaTableSortMetric,
    setMetaTableColumns,
  } = UseReportSettingsStore();
  const sortField = computed({
    get() {
      return getMetaTableSortMetric.value;
    },
    set(val: string) {
      setMetaTableSortMetric(val);
    },
  });
  const sortOrder = computed({
    get() {
      return getMetaTableSortDirection.value;
    },
    set(val: string) {
      setMetaTableSortDirection(val);
    },
  });
  const onSort = (field: string, order: string) => {
    sortField.value = field;
    sortOrder.value = order;
  };

  const columns = computed({
    get() {
      return getMetaTableColumns.value;
    },
    set(val: TColumns) {
      setMetaTableColumns(val);
    },
  });

  return {
    sortField,
    sortOrder,
    columns,
    onSort,
  };
};
